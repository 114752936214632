export const ComplianceStatus = {
    PASSED: "Passed",
    FAILED: "Failed",
    DISABLED: "Disabled",
    WARNINIG: "Warning",
    TRACKING: "Tracking",
    PENDING_CHECK: "PendingCheck",
    WARNINIG_WITH_NOTE: "WarningWithNote",
    OVERRIDE_NOT_ALLOWED: "OverrideNotAllowed",
};
export const ComplianceStatusLabelMap = {
    Passed: "Passed",
    Failed: "Failed",
    Disabled: "Fund Disabled",
    Warning: "Warning",
    Tracking: "Tracking",
    PendingCheck: "Pending Review",
    WarningWithNote: "Warning - Note Required",
    OverrideNotAllowed: "Failed(Override not allowed)",
};
export const complianceStateTypeOptions = Object.entries(ComplianceStatusLabelMap).map(([key, value]) => ({
    label: value,
    value: key,
}));
