import { useEverTrue } from "@enfusion-ui/hooks";
import { css, styled } from "@enfusion-ui/web-core";
import * as React from "react";
const VisibleIfContainer = styled.div `
  ${({ visible }) => visible
    ? css `
          height: 100%;
        `
    : css `
          height: 0px;
          max-height: 0px;
          overflow: hidden;
          pointer-events: none;
        `}
`;
const VisibleIfCore = ({ visible, style, visibleStyle, className, children, }) => {
    const shown = useEverTrue(visible);
    return (React.createElement(VisibleIfContainer, { style: visible ? visibleStyle || style : style, className: className, visible: visible }, shown ? children : null));
};
export const VisibleIf = React.memo(VisibleIfCore);
