import { useMounted } from "@enfusion-ui/hooks";
import { replaceDoubleSlashWithSingleSlash } from "@enfusion-ui/utils";
import { AppLogging, styled, useContextMenu, useFolderActionContext, useSyncedRef, } from "@enfusion-ui/web-core";
import { faCaretDown, faFolderPlus } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Menu, { MenuItem } from "rc-menu";
import * as React from "react";
import { Spinner } from "../display/Spinner";
import { FileExplorer } from "../files/FileExplorer/FileExplorer";
import { CreateFolderModal } from "../modal/CreateFolderModal";
import { Portal, PositionedPortal } from "../portal";
import { CenterContent } from "../Styled";
import { TextInput } from "./TextInput";
const InputWrapper = styled.div `
  width: 100%;
  min-width: 300px;
  margin: 5px 0px;
`;
const OptionsContainer = styled.div `
  position: relative;
  overflow-y: auto;
  overflow-x: hidden;
  background-color: var(--background-color-1);
  max-height: 150px;
`;
export const FolderSelector = React.forwardRef(function FolderSelector(props, ref) {
    const { onSelect, label, name, defaultValue = "", root = "", clearable, ...rest } = props;
    const isMounted = useMounted();
    const [loading, setLoading] = React.useState(false);
    const [options, setOptions] = React.useState([]);
    const [inputValue, setInputValue] = React.useState(defaultValue);
    const [filterPath, setFilterPath] = React.useState(defaultValue);
    const [menuOpen, setMenuOpen] = React.useState(false);
    const [createFolderModalOpen, setCreateFolderModalOpen] = React.useState(false);
    const [folderPath, setFolderPath] = React.useState("");
    const inputRef = useSyncedRef(ref);
    const { contextMenuOpen, contextMenuNode, handleContextMenuPlacement, closeContextMenu, openContextMenu, } = useContextMenu();
    const { createFolder, retrieveNodes } = useFolderActionContext();
    React.useEffect(() => {
        !defaultValue && handleClear();
    }, [defaultValue]);
    const filterNodes = React.useCallback((nodes) => {
        return nodes.filter((i) => {
            const fileTreeEntry = i;
            if (filterPath &&
                !fileTreeEntry.path.startsWith(`${root ? `${root}/` : ""}${filterPath}`)) {
                return false;
            }
            return !i.file;
        });
    }, [filterPath]);
    const getOptions = React.useCallback(async (path) => {
        setLoading(true);
        try {
            const nodes = [];
            if (retrieveNodes) {
                const childNodes = await retrieveNodes(path);
                nodes.push({
                    id: "/",
                    name: "/",
                    path: replaceDoubleSlashWithSingleSlash(path + "/"),
                    file: false,
                    nodes: childNodes,
                    defaultOpen: true,
                    root: "/",
                });
            }
            if (isMounted())
                setOptions(nodes);
        }
        catch (error) {
            AppLogging.safeError("Failed to get folder nodes", error);
            if (isMounted())
                setOptions([]);
        }
        finally {
            setLoading(false);
        }
    }, [retrieveNodes]);
    React.useEffect(() => {
        const path = replaceDoubleSlashWithSingleSlash(`${root ? `${root}/` : ""}${filterPath}`);
        getOptions(path);
        if (onSelect)
            onSelect(path
                ? {
                    id: path,
                    name: "",
                    file: false,
                    path,
                }
                : null);
    }, [filterPath, root]);
    const handleInputFocus = () => {
        setMenuOpen(true);
    };
    const closeMenu = () => {
        setMenuOpen(false);
    };
    const handleClick = React.useCallback(async (node) => {
        const { path } = node;
        setInputValue(path.startsWith("/") ? path : "/" + path);
        if (onSelect)
            onSelect(node);
    }, [root]);
    const handleClear = () => {
        setFilterPath("");
        setInputValue("");
        if (onSelect)
            onSelect(null);
    };
    const handleEntryContext = React.useCallback(({ node, clientX, clientY }) => {
        // can edit or admin enabled or share file
        if (node && !node.file && !!createFolder)
            openContextMenu({ node, clientX, clientY });
    }, [createFolder]);
    const openCreateFolderConfirmation = () => {
        const node = contextMenuNode?.node;
        if (!node.file) {
            const path = root ? node.path.split("/").slice(1).join("/") : node.path;
            setCreateFolderModalOpen(true);
            setFolderPath(path);
            closeContextMenu();
        }
    };
    const closeCreateFolderConfirmation = () => {
        setCreateFolderModalOpen(false);
        closeContextMenu();
    };
    const handleCreateFolder = (folderName, folderPath) => {
        createFolder && createFolder(folderName, folderPath);
        setCreateFolderModalOpen(false);
        setTimeout(() => {
            const path = `${root ? `${root}/` : ""}${filterPath}`;
            getOptions(path);
        }, 1000);
    };
    return (React.createElement(InputWrapper, null,
        React.createElement(TextInput, { ...rest, name: name ? name : "", label: label, autoFocus: true, ref: inputRef, value: inputValue, icon: React.createElement(FontAwesomeIcon, { icon: faCaretDown }), onFocus: handleInputFocus, clearable: clearable, onClearValue: clearable ? handleClear : undefined }),
        React.createElement(Portal, { open: menuOpen, attachedRef: inputRef, onClickOutside: closeMenu },
            React.createElement(OptionsContainer, null,
                loading ? (React.createElement(CenterContent, { style: { minHeight: 30 } },
                    React.createElement(Spinner, { size: "lg" }))) : null,
                !loading ? (options.length > 0 ? (React.createElement(React.Fragment, null,
                    React.createElement(FileExplorer, { nodes: options, onEntryContext: handleEntryContext, onEntryClick: handleClick, multiSelect: false, filterNodes: filterNodes, retrieveNodes: retrieveNodes }),
                    React.createElement(CreateFolderModal, { open: createFolderModalOpen, folderPath: folderPath, onCancel: closeCreateFolderConfirmation, onSubmit: handleCreateFolder, root: root }),
                    React.createElement(PositionedPortal, { open: contextMenuOpen, setPlacement: handleContextMenuPlacement, onClickOutside: closeContextMenu },
                        React.createElement(Menu, { onClick: closeContextMenu },
                            React.createElement(MenuItem, { key: "create-folder", onClick: openCreateFolderConfirmation },
                                React.createElement(FontAwesomeIcon, { icon: faFolderPlus, style: { marginRight: "var(--spacing-l)" }, size: "sm" }),
                                "Create Folder"))))) : (React.createElement(CenterContent, { style: { minHeight: 30 } }, inputValue ? "No sub folders found" : "No folders found"))) : null))));
});
