import { faSearchDollar } from "@fortawesome/pro-solid-svg-icons";
export const marketSecurityInfoPanel = {
    name: "Market and Security Info Panel",
    minSize: [14, 2],
    defaultSize: [17, 5],
    maxSize: [18, 12],
    icon: faSearchDollar,
    description: "Display market and security info",
    defaultConfig: {
        defaultValue: null,
        key: "MarketSecurityInfoPanel",
    },
    type: "display",
    category: "orderForm",
    webOnly: true,
};
export const securityInfoPanel = {
    name: "Security Info Panel",
    minSize: [2, 2],
    defaultSize: [10, 3],
    maxSize: [10, 12],
    icon: faSearchDollar,
    description: "Display security info",
    defaultConfig: {
        defaultValue: null,
        key: "SecurityInfoPanel",
    },
    type: "display",
    category: "orderForm",
};
export const marketInfoPanel = {
    name: "Market Info Panel",
    minSize: (mobile) => (mobile ? [3, 2] : [10, 2]),
    defaultSize: [10, 3],
    maxSize: [10, 12],
    icon: faSearchDollar,
    description: "Display market info",
    defaultConfig: {
        defaultValue: null,
        key: "MarketInfoPanel",
    },
    type: "display",
    category: "orderForm",
};
