import { styled } from "@enfusion-ui/web-core";
import * as React from "react";
const Svg = styled.svg `
  display: inline-block;
  font-size: inherit;
  height: 1em;
  width: 1em;
  overflow: visible;
`;
export const MacExpandIcon = () => {
    return (React.createElement(Svg, { viewBox: "0 0 10 10", "data-radium": "true" },
        React.createElement("path", { fill: "currentColor", d: "M2,3c0,0 0,2.744 0,4.167c0,0.221 0.088,0.433 0.244,0.589c0.156,0.156 0.368,0.244 0.589,0.244c1.423,0 4.167,0 4.167,0l-5,-5Z" }),
        React.createElement("path", { fill: "currentColor", d: "M8,7c0,0 0,-2.744 0,-4.167c0,-0.221 -0.088,-0.433 -0.244,-0.589c-0.156,-0.156 -0.368,-0.244 -0.589,-0.244c-1.423,0 -4.167,0 -4.167,0l5,5Z" })));
};
