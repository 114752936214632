import { convertDatePeriodSelection, getDateSelection } from "./date";
const defaultAccountSelection = {
    fundIds: [],
    accountIds: [],
    fundGroupNames: [],
    fundCustodianPairs: [],
    liquidationTypes: null,
    includeTestContainers: false,
    enableExcludeExpiredFund: false,
    includeExpiredFund: false,
    expiredAsOfDate: null,
    includeAllAccounts: null,
};
export const formatChannelParamValues = (paramChannelDataIds, paramChannelData) => {
    let params = {};
    Object.keys(paramChannelDataIds).forEach((eachKey) => {
        if (paramChannelData[eachKey] !== undefined) {
            switch (eachKey) {
                case "accountSelection": {
                    params = {
                        ...params,
                        accountSelection: {
                            ...defaultAccountSelection,
                            accountIds: paramChannelData?.accountSelection?.accountIds,
                            fundIds: paramChannelData?.accountSelection?.fundIds,
                        },
                    };
                    break;
                }
                case "portfolioGroupIds": {
                    params = {
                        ...params,
                        portfolioGroupIds: paramChannelData?.portfolioGroupIds
                            ? paramChannelData.portfolioGroupIds.map((e) => Number(e))
                            : null,
                    };
                    break;
                }
                case "marketEnvironmentId": {
                    params = {
                        ...params,
                        marketEnvironmentId: Number(paramChannelData?.marketEnvironmentId),
                    };
                    break;
                }
                case "datePeriodSelection": {
                    params = {
                        ...params,
                        datePeriodSelection: convertDatePeriodSelection(paramChannelData?.datePeriodSelection?.dateSelection),
                    };
                    break;
                }
                case "valueDate": {
                    params = {
                        ...params,
                        valueDate: getDateSelection(paramChannelData?.valueDate),
                    };
                    break;
                }
                case "generalLedgerIds": {
                    params = {
                        ...params,
                        generalLedgerIds: paramChannelData?.generalLedgerIds
                            ? paramChannelData.generalLedgerIds.map((id) => Number(id))
                            : null,
                    };
                    break;
                }
                default:
                    params = { ...params, [eachKey]: paramChannelData[eachKey] };
            }
        }
    });
    return params;
};
