import { faSearchDollar } from "@fortawesome/pro-solid-svg-icons";
export const positionDetailsPanel = {
    name: "Position Details Panel",
    minSize: [18, 2],
    defaultSize: [18, 10],
    maxSize: [23, 12],
    icon: faSearchDollar,
    description: "Display position details",
    defaultConfig: {
        defaultValue: null,
        key: "PositionDetailsPanel",
    },
    type: "display",
    category: "orderForm",
    webOnly: true,
};
