import { UNKNOWN_INSTRUMENT_ID } from "@enfusion-ui/core";
import { faThumbtack } from "@fortawesome/pro-solid-svg-icons";
export const marketdata = {
    name: "Market Data",
    minSize: [2, 2],
    icon: faThumbtack,
    description: "Subscribe to Market Data",
    defaultConfig: {
        defaultValue: { id: UNKNOWN_INSTRUMENT_ID },
    },
    type: "simpleDisplay",
    category: "dashboard",
};
