import "react-resizable/css/styles.css";
import { useEverTrue } from "@enfusion-ui/hooks";
import { useLocalStorage } from "@enfusion-ui/hooks/build/web";
import { AuthContext, styled, useIsTabletDevice } from "@enfusion-ui/web-core";
import { useWindowSize } from "@react-hook/window-size/throttled";
import { debounce, noop } from "lodash";
import * as React from "react";
import { ResizableBox } from "react-resizable";
import { TOP_APP_BAR_HEIGHT } from "../TopAppBar";
import { BOTTOM_INFO_BAR_HEIGHT } from "./BottomInfoBar";
import { NAV_BAR_SIZE } from "./NavBar";
const SideBarContainer = styled(ResizableBox) `
  ${({ open }) => (open ? "" : "display: none;")};
  width: 100%;
  height: 100%;
  position: relative;
  overflow: hidden;
  background-color: var(--background-color-1);
  padding-right: 3px;

  & .react-resizable-handle-e {
    margin: 0;
    transform: none;
    position: absolute;
    top: 0;
    right: 0;
    height: 100%;
    width: 3px;
    background-image: none;
  }
`;
export const SidebarContext = React.createContext({ setSize: noop });
export const SidebarProvider = ({ children, onResize, refreshFlag, storageKey = "e-sidebar-size", noUser = false, }) => {
    const { user } = React.useContext(AuthContext) || {};
    const [size, setSize] = useLocalStorage(`${storageKey}${noUser ? "" : `-${user?.username}`}`, {});
    const handleSetSize = (size) => {
        setSize(size);
        onResize();
    };
    return (React.createElement(SidebarContext.Provider, { value: { ...size, setSize: handleSetSize, refreshFlag } }, children));
};
export const SIDE_BAR_MIN_WIDTH = 215;
export const SIDE_BAR_BASE_WIDTH = 375;
export const SideBar = ({ open, fullWidth, heightOffset, children, }) => {
    const opened = useEverTrue(open);
    const { width: baseWidth = SIDE_BAR_BASE_WIDTH, setSize } = React.useContext(SidebarContext);
    const [windowWidth, windowHeight] = useWindowSize();
    const isTabletDevice = useIsTabletDevice();
    const height = windowHeight -
        (typeof heightOffset === "number"
            ? heightOffset
            : isTabletDevice
                ? NAV_BAR_SIZE + TOP_APP_BAR_HEIGHT
                : TOP_APP_BAR_HEIGHT + BOTTOM_INFO_BAR_HEIGHT);
    const minWidth = fullWidth ? windowWidth : SIDE_BAR_MIN_WIDTH;
    const maxWidth = isTabletDevice ? windowWidth : Math.floor(windowWidth / 2);
    const width = fullWidth ? windowWidth : baseWidth;
    const handleResize = debounce((e, data) => {
        setSize(data.size);
    }, 350);
    return (React.createElement(SideBarContainer, { height: Math.max(height, 10), width: width, minConstraints: [minWidth, 10], maxConstraints: [maxWidth, windowHeight], open: open, axis: "x", resizeHandles: ["e"], onResize: handleResize }, opened ? children : null));
};
