import { identity, noop } from "lodash";
import * as React from "react";
import { EMPTY_RECORD } from "../../constants";
export const FileExplorerContext = React.createContext({
    onEntryClick: noop,
    onEntryContext: noop,
    onFolderCheckChange: noop,
    onFileCheckChange: noop,
    getFolderCheckedState: () => 0,
    getFileSelectionState: () => false,
    getChildNodeSelectionState: () => false,
    sortNodes: identity,
    changeSelection: noop,
    checkSelections: false,
    subscribeToSelectionChange: () => noop,
    getSubNodes: () => undefined,
    setSubNodes: noop,
    getAllSubNodes: () => undefined,
    MainContentContainer: (() => null),
    FileNode: (() => null),
    DirectoryAccordion: (() => null),
    Checkbox: undefined,
    RightContent: undefined,
    FolderError: undefined,
    FolderLoadingIndicator: undefined,
    MainContentContainerProps: EMPTY_RECORD,
    FileNodeProps: EMPTY_RECORD,
    DirectoryAccordionProps: EMPTY_RECORD,
    RightContentProps: EMPTY_RECORD,
    updateNodeSelectionState: noop,
});
export function useFileExplorerContext() {
    const context = React.useContext(FileExplorerContext);
    if (typeof context === "undefined") {
        throw new Error("useFileExplorerContext must be used within a FileExplorer component");
    }
    return context;
}
