import { css, styled } from "@enfusion-ui/web-core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { TextButton } from "../../control";
import { BoldText, MutedText } from "../../Styled";
export const GridContainer = styled.div `
  display: flex;
  flex-direction: column;
  gap: var(--spacing-xl);
`;
export const ContentContainer = styled.div `
  display: grid;
  grid-template-columns: auto 220px;
  border-top: 1px solid var(--border);
  height: 700px;
`;
export const LeftContentContainer = styled.div `
  border-right: 1px solid var(--border);
  border-bottom: 1px solid var(--border);
  padding-right: var(--spacing);
  display: flex;
  flex-direction: column;
  max-height: 100%;
  overflow: hidden;
`;
export const RightContentContainer = styled.div `
  display: flex;
  flex-direction: column;
  border-bottom: 1px solid var(--border);
  padding-left: var(--spacing);
  padding-bottom: var(--spacing);
  height: 100%;
  min-height: 100%;
  max-height: 100%;
`;
export const SearchContainer = styled.div `
  display: grid;
  grid-template-columns: 2fr 8fr;
  margin-top: var(--spacing);
`;
export const CategoryTitle = styled(BoldText) `
  margin-left: var(--spacing-l);
  margin-top: 0px;
  display: flex;
  align-items: center;
`;
export const FilterContainer = styled.div `
  background-color: var(--input-background);
  margin-top: var(--spacing);
  border-radius: 3px;
`;
export const FilterButtonContainer = styled.div `
  display: grid;
  grid-template-columns: 9fr 1fr;
  grid-column-gap: var(--spacing);
`;
export const FilterContentContainer = styled.div `
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-column-gap: var(--spacing);
`;
export const AvailableColumnsContainer = styled.div `
  background-color: var(--input-background);
  margin-top: var(--spacing);
  margin-bottom: var(--spacing);
  padding: var(--spacing);
  flex: 1;
  overflow: hidden;
  display: grid;
  grid-template-rows: 38px calc(100% - 38px);
  border-radius: 3px;
`;
export const AvailableColumnsTitleContainer = styled.div `
  display: grid;
  grid-template-columns: 9fr 1fr;
  grid-column-gap: var(--spacing);
`;
export const AvailableColumnsScrollContainer = styled.div `
  height: 100%;
`;
export const AvailableColumnsContentContainer = styled.div `
  align-content: flex-start;
  gap: var(--spacing);
  padding: var(--spacing);
  padding-top: 0px;
  overflow-y: auto;
  max-height: 100%;
  min-height: 100%;
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
`;
export const AvailableColumnsDataContainer = styled.div `
  display: flex;
  flex-direction: row;
  background-color: var(--background-color-0);
  align-items: center;
  min-width: 200px;
  width: 100%;
  height: 30px;
  border: 1px solid var(--border);
  border-radius: 3px;
  overflow: hidden;
  padding-left: var(--spacing);
`;
export const SelectedColumnTitle = styled.div `
  display: flex;
  align-items: center;
  background-color: var(--input-background);
  font-weight: bold;
  text-transform: uppercase;
  height: 28px;
  padding: 0 0.25rem 0 0.75rem;
  margin-top: var(--spacing);
  ${({ open }) => open
    ? css `
          border-top-left-radius: 3px;
          border-top-right-radius: 3px;
        `
    : css `
          border-radius: 3px;
        `}
`;
export const SelectedColumnsHeader = styled.div `
  display: flex;
  flex-direction: row;
  gap: var(--spacing);
  margin-top: var(--spacing);
`;
export const SelectedColumnsDroppableContainer = styled.div `
  display: grid;
  grid-template-columns: auto;
  align-content: flex-start;
  background-color: var(--input-background);
  min-height: 200px;
  padding: var(--spacing);
  padding-top: 0px;
  gap: var(--spacing);
  overflow-x: hidden;
  overflow-y: auto;
  flex: 1;
  border-bottom-left-radius: 3px;
  border-bottom-right-radius: 3px;
`;
export const SelectedColumnDraggableContainer = styled.div `
  display: grid;
  grid-template-columns: var(--spacing-xxl) auto;
  background-color: var(--background-color-0);
  align-items: center;
  width: 100%;
  height: 30px;
  border: 1px solid var(--border);
  border-radius: 3px;
`;
export const SelectedColumnGripHandle = styled(FontAwesomeIcon) `
  margin-left: var(--spacing-l);
  color: var(--text-muted);
`;
export const SelectedColumnLabel = styled.div `
  margin-left: var(--spacing);
  padding-right: var(--spacing-s);
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  font-size: 14px;
`;
export const SelectedColumnsInstruction = styled(MutedText) `
  margin-bottom: var(--spacing-s);
  font-size: 14px;
`;
export const MutedCategoryTitle = styled(MutedText) `
  margin-left: var(--spacing-l);
  margin-top: var(--spacing-l);
  font-size: 14px;
`;
export const ResetTextButton = styled(TextButton) `
  color: var(--primary);
`;
export const ActionContainer = styled.div `
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-column-gap: var(--spacing-l);
`;
export const SelectedColumnsContainer = styled.div `
  display: flex;
  width: 100%;
  height: 100%;
  max-height: 100%;
  overflow: auto;
  flex-direction: column;
`;
