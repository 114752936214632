import { getNormalizedInstrumentInfo } from "@enfusion-ui/utils";
import * as React from "react";
import { useWatch } from "react-hook-form";
export function useInstrument() {
    const instrument = useWatch({ name: "instrument" });
    return React.useMemo(() => instrument
        ? {
            ...instrument,
            ...getNormalizedInstrumentInfo(instrument),
        }
        : null, [JSON.stringify(instrument)]);
}
