/* eslint-disable @typescript-eslint/no-explicit-any */
import { useModalState, useRefCallback } from "@enfusion-ui/hooks";
import * as React from "react";
import { getContextMenuPlacement } from "../contextPlacement";
export function useContextMenu() {
    const state = useModalState();
    const handleContextMenuPlacement = useRefCallback((windowWidth, windowHeight, portalElementRef) => {
        return getContextMenuPlacement(windowWidth, windowHeight, portalElementRef, state.openContentRef.current);
    }, [state]);
    const handleContextEvent = useRefCallback((e) => {
        e.preventDefault();
        // Right click event identified by e.button === 2
        if (e.button === 2) {
            state.openModal({
                clientY: e.nativeEvent.clientY,
                clientX: e.nativeEvent.clientX,
            });
        }
        else {
            e.stopPropagation();
        }
    }, [state]);
    return React.useMemo(() => ({
        contextMenuOpen: state.open,
        contextMenuNode: state.openContentRef.current,
        openContextMenu: state.openModal,
        closeContextMenu: state.closeModal,
        handleContextEvent,
        handleContextMenuPlacement,
    }), [state.open, handleContextEvent, handleContextMenuPlacement]);
}
