import { isElectron } from "@enfusion-ui/utils";
import { isDesktop } from "@enfusion-ui/web-core";
async function asyncNoop() { }
function asyncDo(cb) {
    return async () => {
        cb();
    };
}
export function getRemote() {
    try {
        if (isElectron()) {
            const { ipcRenderer } = window.require("electron");
            return {
                close: asyncDo(() => ipcRenderer.send("window-action", "close")),
                minimize: asyncDo(() => ipcRenderer.send("window-action", "minimize")),
                restore: asyncDo(() => ipcRenderer.send("window-action", "restore")),
                maximize: asyncDo(() => ipcRenderer.send("window-action", "maximize")),
                fullscreen: asyncDo(() => ipcRenderer.send("window-action", "fullscreen")),
                getState: async () => {
                    return await ipcRenderer.invoke("get-window-state");
                },
                addListener: async (event, cb) => {
                    ipcRenderer.on(event, cb);
                },
                removeListener: async (event, cb) => {
                    ipcRenderer.off(event, cb);
                },
            };
        }
        if (isDesktop()) {
            return new Proxy(fin.Window.getCurrentSync(), {
                get: (target, prop, receiver) => {
                    if (prop === "fullscreen")
                        return target.maximize;
                    return Reflect.get(target, prop, receiver);
                },
            });
        }
        return {
            close: asyncNoop,
            minimize: asyncNoop,
            restore: asyncNoop,
            maximize: asyncNoop,
            fullscreen: asyncNoop,
            getState: async () => "normal",
            addListener: asyncNoop,
            removeListener: asyncNoop,
        };
    }
    catch (err) {
        return {
            close: asyncNoop,
            minimize: asyncNoop,
            restore: asyncNoop,
            maximize: asyncNoop,
            fullscreen: asyncNoop,
            getState: async () => "normal",
            addListener: asyncNoop,
            removeListener: asyncNoop,
        };
    }
}
export async function closeWindow() {
    await getRemote().close();
}
export async function minimizeWindow() {
    await getRemote().minimize();
}
export async function maximizeWindow() {
    const WIN = getRemote();
    if ((await WIN.getState()) === "maximized") {
        await WIN.restore();
    }
    else {
        await WIN.maximize();
    }
}
export async function fullscreenWindow() {
    const WIN = getRemote();
    if (["maximized", "fullscreen"].includes(await WIN.getState())) {
        await WIN.restore();
    }
    else {
        await WIN.fullscreen();
    }
}
