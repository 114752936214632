import { faSearchDollar } from "@fortawesome/pro-solid-svg-icons";
export const instrumentPanel = {
    name: "Instrument Panel",
    minSize: (mobile) => (mobile ? [5, 2] : [6, 2]),
    maxSize: [24, 12],
    defaultSize: [17, 5],
    icon: faSearchDollar,
    description: "Choose instrument",
    defaultConfig: {
        showAdvancedSearch: true,
        showBookStrategy: true,
        showCurrentPosition: true,
        showExistingPositionLink: true,
        key: "InstrumentSelectPanel",
    },
    type: "display",
    category: "orderForm",
};
export const instrumentSelect = {
    name: "Instrument Select",
    minSize: [5, 2],
    maxSize: [5, 12],
    defaultSize: [5, 4],
    icon: faSearchDollar,
    description: "Choose instrument",
    defaultConfig: {
        defaultValue: null,
        key: "Instrument",
    },
    type: "input",
    category: "orderForm",
};
export const strategyBookSelect = {
    name: "Book Strategy Select",
    minSize: [5, 2],
    defaultSize: [5, 3],
    maxSize: [5, 12],
    icon: faSearchDollar,
    description: "Choose book strategy",
    defaultConfig: {
        defaultValue: null,
        key: "StrategyBook",
    },
    type: "input",
    category: "orderForm",
};
