import { replaceDoubleSlashWithSingleSlash } from "@enfusion-ui/utils";
import { DASHBOARD_LATEST_CONFIG_VERSION } from "../constants";
import DOCUMENTS_API, { DocumentAPIRoots } from "./documents";
import { bindDocumentsApi } from "./utils/documentsBind";
export default (callCreator) => {
    const documentsAPI = DOCUMENTS_API(callCreator);
    const storeDashboardJSON = (root) => ({ path, rootPath, name, version, config, settings, configVersion = DASHBOARD_LATEST_CONFIG_VERSION, datasources, forceWrite = false, }, mobile, abortController) => documentsAPI.UPLOAD.FETCH(root, rootPath, {
        path: replaceDoubleSlashWithSingleSlash(`/${path}/`),
        forceWrite,
        content: {
            name,
            version,
            config,
            settings,
            configVersion,
            datasources,
        },
    }, mobile, abortController);
    return {
        ...bindDocumentsApi(documentsAPI, DocumentAPIRoots.DASHBOARD),
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        DOWNLOAD_CUSTOM_ORDER: (filePath, mobile, abortController) => documentsAPI.DOWNLOAD.FETCH(DocumentAPIRoots.CUSTOM_ORDER, filePath, mobile, abortController),
        STORE_DASHBOARD: storeDashboardJSON(DocumentAPIRoots.DASHBOARD),
        STORE_CUSTOM_ORDER: storeDashboardJSON(DocumentAPIRoots.CUSTOM_ORDER),
    };
};
