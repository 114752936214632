import { getFileName } from "@enfusion-ui/utils";
import { styled } from "@enfusion-ui/web-core";
import * as React from "react";
import { ErrorAlert, Spinner } from "../display";
import { FileExplorer, } from "../files/FileExplorer/FileExplorer";
import { getFileTooltipText } from "../files/FileExplorer/utils";
import { CenterContent as DefaultCenterContent } from "../Styled";
const Explorer = styled(FileExplorer) `
  flex: 1;
  overflow-x: hidden;
  background-color: var(--background-color-1);
`;
const CenterContent = styled(DefaultCenterContent) `
  min-height: 30px;
`;
export const MultiSelectExplorer = React.memo(function MultiSelectExplorer({ emptyText = "No options found", checkSelections = false, multiSelect = true, loading, error, nodes, ...rest }) {
    if (error) {
        return (React.createElement(CenterContent, null,
            React.createElement(ErrorAlert, { error: error })));
    }
    if (loading || nodes === null) {
        return (React.createElement(CenterContent, { style: { minHeight: 140 } },
            React.createElement(Spinner, null)));
    }
    if (!nodes || nodes.length === 0) {
        return React.createElement(CenterContent, null, emptyText);
    }
    return (React.createElement(Explorer, { ...rest, nodes: nodes, checkSelections: checkSelections, getFileName: getFileName, getTooltipText: getFileTooltipText, multiSelect: multiSelect }));
});
