import { SEPARATOR } from "@enfusion-ui/core";
import { useRefCallback } from "@enfusion-ui/hooks";
import { styled } from "@enfusion-ui/web-core";
import { faGripDotsVertical } from "@fortawesome/pro-regular-svg-icons";
import { faAngleDown, faAngleRight, faBroom, } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { partition } from "lodash";
import * as React from "react";
import { DragDropContext, Draggable, Droppable, } from "react-beautiful-dnd";
import { Button, IconButton } from "../../control";
import { Checkbox, TextInput } from "../../inputs";
import { Modal } from "../../modal/Modal";
import { BoldText, CenterContent, MutedText } from "../../Styled";
import { ActionContainer, AvailableColumnsContainer, AvailableColumnsContentContainer, AvailableColumnsDataContainer, AvailableColumnsScrollContainer, AvailableColumnsTitleContainer, CategoryTitle, ContentContainer, FilterButtonContainer, FilterContainer, FilterContentContainer, GridContainer, LeftContentContainer, MutedCategoryTitle, ResetTextButton, RightContentContainer, SearchContainer, SelectedColumnDraggableContainer, SelectedColumnGripHandle, SelectedColumnLabel, SelectedColumnsContainer, SelectedColumnsDroppableContainer, SelectedColumnsHeader, SelectedColumnsInstruction, SelectedColumnTitle, } from "./styled";
import { addColumns, AVAILABLE_COLUMNS, createColumns, createDroppable, createFilters, createSelectedColumnsOpen, genDroppableId, removeColumns, reorderColumns, resetColumns, SELECTED_COLUMNS, } from "./utils";
const CenterInGridContent = styled(CenterContent) `
  width: 100%;
  grid-column-start: 1;
  grid-column-end: 3;
  height: 150px;
  font-size: 14px;
`;
export const ColumnChooserModal = ({ open, onAccept, onCancel, columns, }) => {
    const [searchTerm, setSearchTerm] = React.useState("");
    const [filterOpen, setFilterOpen] = React.useState(true);
    const [[selectedColumns], setColumnState] = React.useState([{}, []]);
    const [droppable, setDroppable] = React.useState({});
    const [selectedColumnsPanelsOpen, setSelectedColumnsPanelsOpen] = React.useState({});
    const [columnFilters, setColumnFilters] = React.useState({});
    const [availableColumns, setAvailableColumns] = React.useState([]);
    const isColAvailable = useRefCallback((col) => {
        let term = searchTerm.trim().toLocaleLowerCase();
        const categories = col.category.split(",");
        return (categories.some((category) => columnFilters[category]) &&
            (term === "" || col.name.toLocaleLowerCase().includes(term)));
    }, [columnFilters, searchTerm]);
    const allAvailableColumnsChecked = React.useMemo(() => {
        const filtered = availableColumns.filter(isColAvailable);
        return filtered.length > 0 && filtered.every(({ selected }) => !!selected);
    }, [availableColumns, columnFilters, searchTerm]);
    const handleSearchChange = useRefCallback((e) => {
        const text = e.target.value;
        setSearchTerm(text);
    }, []);
    const handleClearSearchTerm = useRefCallback(() => {
        setSearchTerm("");
    }, [availableColumns]);
    const toggleFilterContainerOpen = () => {
        setFilterOpen((val) => !val);
    };
    const toggleAllFilter = useRefCallback((value) => {
        setColumnFilters(createFilters(columns, {}, value));
    }, [columns]);
    const clickAvailableColumnsCheckbox = useRefCallback((index) => {
        setAvailableColumns((cols) => cols.map((col, idx) => {
            if (idx !== index)
                return col;
            return { ...col, selected: !col.selected };
        }));
    }, []);
    const clickFilterCheckbox = useRefCallback((key) => {
        setColumnFilters((state) => {
            return Object.keys(state).reduce((res, filterName) => {
                const value = state[filterName];
                res[filterName] = filterName === key ? !value : value;
                return res;
            }, {});
        });
    }, []);
    const toggleAllAvailableColumns = useRefCallback(() => {
        setAvailableColumns((allCols) => allCols.map((col) => ({
            ...col,
            selected: !isColAvailable(col) ? false : !allAvailableColumnsChecked,
        })));
    }, [allAvailableColumnsChecked]);
    const handleDragEnd = useRefCallback((result) => {
        const sourceColumn = result.source.droppableId.split(SEPARATOR)[0];
        const destinationColumn = result?.destination?.droppableId.split(SEPARATOR)[0] || "";
        const [draggableKey, draggableValue] = result.draggableId.split(SEPARATOR);
        const columnDragged = result.draggableId.split(SEPARATOR)[1];
        const destinationIndex = result?.destination?.index || 0;
        if (sourceColumn === AVAILABLE_COLUMNS &&
            destinationColumn === SELECTED_COLUMNS) {
            const [filteredColumns, remainedColumns] = partition(availableColumns, "selected");
            if (!filteredColumns.length) {
                // single column is dragged
                const col = remainedColumns.find((col) => col.name === columnDragged);
                if (col)
                    filteredColumns.push(col);
            }
            setAvailableColumns([
                ...remainedColumns.filter((column) => column.name !== columnDragged),
            ]);
            setColumnState((state) => addColumns(state, filteredColumns, draggableKey, destinationIndex));
        }
        else if (sourceColumn === SELECTED_COLUMNS) {
            if (destinationColumn === AVAILABLE_COLUMNS) {
                const col = selectedColumns[draggableKey].find((e) => e.name === draggableValue);
                if (col) {
                    setAvailableColumns([
                        ...availableColumns,
                        { ...col, selected: false },
                    ]);
                    setColumnState((state) => removeColumns(state, draggableKey, draggableValue));
                }
            }
            else if (destinationColumn === SELECTED_COLUMNS) {
                const sourceIndex = result.source.index;
                setColumnState((state) => reorderColumns(state, draggableKey, sourceIndex, destinationIndex));
            }
        }
    }, [availableColumns]);
    const handleDragStart = useRefCallback((initial) => {
        const [key] = initial.draggableId.split(SEPARATOR);
        setDroppable(() => ({ ...createDroppable(columns), [key]: true }));
    }, [columns]);
    const handleClickSelectedColumnsTitle = useRefCallback((key) => {
        setSelectedColumnsPanelsOpen((state) => ({
            ...state,
            [key]: !state[key],
        }));
    }, []);
    const reset = useRefCallback(() => {
        setSearchTerm("");
        setColumnFilters(() => createFilters(columns));
        const resetColumnState = resetColumns(columns);
        setColumnState(() => resetColumnState);
        setAvailableColumns([...resetColumnState[1]]);
    }, [columns]);
    const clear = useRefCallback(() => {
        setSearchTerm("");
        setColumnFilters(() => createFilters(columns));
        const resetColumnState = resetColumns(columns, true);
        setColumnState(() => resetColumnState);
        setAvailableColumns([
            ...resetColumnState[1].map((i) => ({ ...i, selected: false })),
        ]);
    }, [columns]);
    const handleAccept = useRefCallback(() => {
        let newColumns = [];
        Object.keys(selectedColumns).forEach((key) => {
            for (const column of selectedColumns[key]) {
                newColumns.push(column);
            }
        });
        onAccept([...newColumns, ...availableColumns]);
        setSearchTerm("");
    }, [onAccept, selectedColumns, availableColumns]);
    React.useEffect(() => {
        setColumnState(() => createColumns(columns));
        setDroppable(() => createDroppable(columns));
        setSelectedColumnsPanelsOpen(() => createSelectedColumnsOpen(columns));
        setColumnFilters((state) => createFilters(columns, state));
        setAvailableColumns(() => columns.filter((column) => !column.selected));
    }, [columns]);
    const handleCancel = () => {
        onCancel();
        setSearchTerm("");
    };
    return (React.createElement(Modal, { isOpen: open, onClose: handleCancel, maxWidth: "700px", title: "Customize Columns", content: React.createElement(GridContainer, null,
            React.createElement(DragDropContext, { onDragEnd: handleDragEnd, onDragStart: handleDragStart },
                React.createElement(ContentContainer, null,
                    React.createElement(LeftContentContainer, null,
                        React.createElement(SearchContainer, null,
                            React.createElement(CategoryTitle, null, "Search"),
                            React.createElement(TextInput, { name: "name", value: searchTerm, onChange: handleSearchChange, hideLabel: true, clearable: true, onClearValue: handleClearSearchTerm })),
                        React.createElement(FilterContainer, null,
                            React.createElement(FilterButtonContainer, null,
                                React.createElement(CategoryTitle, { onClick: toggleFilterContainerOpen },
                                    React.createElement(FontAwesomeIcon, { icon: filterOpen ? faAngleDown : faAngleRight, style: { marginRight: "var(--spacing-l)" }, size: "sm" }),
                                    "Filters"),
                                React.createElement(Checkbox, { label: "All", checked: Object.values(columnFilters).every((i) => i === true), onChange: toggleAllFilter })),
                            filterOpen && (React.createElement(FilterContentContainer, null, Object.keys(columnFilters).map((filter) => {
                                return (React.createElement("div", { key: `columnFilters-${filter}` },
                                    React.createElement(Checkbox, { label: filter, checked: columnFilters[filter], onChange: () => clickFilterCheckbox(filter), labelPlacement: "right" })));
                            })))),
                        React.createElement(AvailableColumnsContainer, null,
                            React.createElement(AvailableColumnsTitleContainer, null,
                                React.createElement(MutedCategoryTitle, null, "Available Columns"),
                                React.createElement(Checkbox, { label: "All", checked: allAvailableColumnsChecked, onChange: toggleAllAvailableColumns })),
                            React.createElement(Droppable, { droppableId: AVAILABLE_COLUMNS, type: "ColumnChooser" }, (provided) => (React.createElement(AvailableColumnsScrollContainer, null,
                                React.createElement(AvailableColumnsContentContainer, { ref: provided.innerRef, ...provided.droppableProps },
                                    React.createElement(React.Fragment, null,
                                        availableColumns.length === 0 && (React.createElement(CenterInGridContent, null,
                                            React.createElement(MutedText, null, "No available columns"))),
                                        availableColumns.length > 0 &&
                                            availableColumns.every((c) => !isColAvailable(c)) && (React.createElement(CenterInGridContent, null,
                                            React.createElement(MutedText, null, "No available columns for the current applied filters"))),
                                        availableColumns.map((column, idx) => {
                                            if (!isColAvailable(column))
                                                return null;
                                            return (React.createElement(Draggable, { key: genDroppableId(column), draggableId: genDroppableId(column), index: idx }, (provided) => (React.createElement(AvailableColumnsDataContainer, { ref: provided.innerRef, ...provided.draggableProps, ...provided.dragHandleProps, key: genDroppableId(column), title: column.tooltip || column.name },
                                                React.createElement(Checkbox, { checked: column.selected, labelPlacement: "right", onChange: () => clickAvailableColumnsCheckbox(idx), noContainer: true, iconSize: "1x", iconStyle: {} }),
                                                React.createElement(SelectedColumnLabel, { style: {
                                                        flex: 1,
                                                        marginRight: "var(--spacing)",
                                                    } }, column.name),
                                                React.createElement(FontAwesomeIcon, { icon: faGripDotsVertical, size: "sm", style: {
                                                        marginRight: "var(--spacing-l)",
                                                    } })))));
                                        })))))))),
                    React.createElement(RightContentContainer, null,
                        React.createElement(SelectedColumnsHeader, null,
                            React.createElement(BoldText, { style: { lineHeight: "24px", flex: 1 } }, "Selected"),
                            React.createElement(ResetTextButton, { onClick: reset }, "Reset"),
                            React.createElement(IconButton, { size: "sm", icon: faBroom, title: "Clear", onClick: clear, color: "var(--primary)" })),
                        React.createElement(SelectedColumnsInstruction, null,
                            React.createElement(MutedText, null, "Drag to re-order")),
                        React.createElement(SelectedColumnsContainer, null, Object.keys(selectedColumns).map((key) => (React.createElement(React.Fragment, { key: key },
                            React.createElement(SelectedColumnTitle, { key: key, open: selectedColumnsPanelsOpen[key], onClick: () => handleClickSelectedColumnsTitle(key) },
                                React.createElement(FontAwesomeIcon, { icon: selectedColumnsPanelsOpen[key]
                                        ? faAngleDown
                                        : faAngleRight, style: { marginRight: "var(--spacing-l)" }, size: "sm" }),
                                key),
                            selectedColumnsPanelsOpen[key] ? (React.createElement(Droppable, { key: `${SELECTED_COLUMNS}${SEPARATOR}${key}`, droppableId: `${SELECTED_COLUMNS}${SEPARATOR}${key}`, type: "ColumnChooser", isDropDisabled: !droppable[key] }, (provided) => (React.createElement(SelectedColumnsDroppableContainer, { ref: provided.innerRef, ...provided.droppableProps, keyCount: Object.keys(selectedColumns).length }, selectedColumns[key].map((column, idx) => {
                                return (React.createElement(Draggable, { key: genDroppableId(column), draggableId: genDroppableId(column), index: idx }, (provided) => (React.createElement(SelectedColumnDraggableContainer, { ref: provided.innerRef, ...provided.draggableProps, ...provided.dragHandleProps, key: genDroppableId(column), title: column.tooltip || column.name },
                                    React.createElement(SelectedColumnGripHandle, { icon: faGripDotsVertical, size: "sm" }),
                                    React.createElement(SelectedColumnLabel, null, column.name)))));
                            }))))) : null)))))),
                React.createElement(ActionContainer, null,
                    React.createElement(Button, { primary: true, onClick: handleAccept }, "Apply"),
                    React.createElement(Button, { onClick: handleCancel }, "Cancel")))) }));
};
