import { addBusinessDays, endOfDay, endOfMonth, format, startOfDay, startOfMonth, startOfWeek, subBusinessDays, subMonths, subWeeks, } from "date-fns";
import { utcToZonedTime } from "date-fns-tz";
import { words } from "lodash";
export const chicagoTimeZone = "America/Chicago";
const generalDateFormat = "yyyy-MM-dd";
const dateAndTimeFormat = "yyyy-MM-dd HH:mm:ss";
const nowZoned = (timezone) => utcToZonedTime(new Date(), timezone);
const tPlus = (timezone, days) => addBusinessDays(nowZoned(timezone), days);
export const DatePresetEval = {
    Latest: (timezone) => nowZoned(timezone),
    PreviousBusinessDate: (timezone) => subBusinessDays(nowZoned(timezone), 1),
    TMinusTwo: (timezone) => subBusinessDays(nowZoned(timezone), 2),
    Today: (timezone) => nowZoned(timezone),
    EndOfToday: (timezone) => endOfDay(nowZoned(timezone)),
    StartOfToday: (timezone) => startOfDay(nowZoned(timezone)),
    LastWeek: (timezone) => subWeeks(startOfWeek(nowZoned(timezone), {
        weekStartsOn: 1,
    }), 1),
    StartOfWeek: (timezone) => startOfWeek(nowZoned(timezone), {
        weekStartsOn: 1,
    }),
    LastMonth: (timezone) => subMonths(nowZoned(timezone), 1),
    StartOfMonth: (timezone) => startOfMonth(nowZoned(timezone)),
    EndOfThisMonth: (timezone) => endOfMonth(nowZoned(timezone)),
    StartOfPreviousMonth: (timezone) => startOfMonth(subMonths(nowZoned(timezone), 1)),
    TPlus5: (timezone) => tPlus(timezone, 5),
    TPlus20: (timezone) => tPlus(timezone, 20),
    TPlus30: (timezone) => tPlus(timezone, 30),
    TPlus50: (timezone) => tPlus(timezone, 50),
    TPlus90: (timezone) => tPlus(timezone, 90),
    AsOfDate: () => "",
};
export const DatePresetEvalLabelOverrides = {
    TMinusTwo: "T-2",
    TPlus5: "T+5",
    TPlus20: "T+20",
    TPlus30: "T+30",
    TPlus50: "T+50",
    TPlus90: "T+90",
    LastWeek: "A Week Ago",
    LastMonth: "Previous Month",
};
export const DatePresetEvalTooltipFormatOverrides = {
    EndOfToday: dateAndTimeFormat,
    StartOfToday: dateAndTimeFormat,
    LastMonth: "MMMM",
};
function getDatePresetTooltipMethod(key, formatStr = generalDateFormat) {
    if (key === "AsOfDate")
        return () => "";
    return (timezone) => format(DatePresetEval[key](timezone), formatStr);
}
const DatePresetOptionsMap = Object.keys(DatePresetEval).reduce((res, key) => {
    return {
        ...res,
        [key]: {
            value: key,
            label: DatePresetEvalLabelOverrides[key] ?? words(key).join(" "),
            tooltip: getDatePresetTooltipMethod(key, DatePresetEvalTooltipFormatOverrides[key]),
        },
    };
}, {});
export const DatePresetOptions = {
    all: [
        DatePresetOptionsMap.Latest,
        DatePresetOptionsMap.Today,
        DatePresetOptionsMap.EndOfToday,
        DatePresetOptionsMap.StartOfToday,
        DatePresetOptionsMap.PreviousBusinessDate,
        DatePresetOptionsMap.TMinusTwo,
        DatePresetOptionsMap.LastWeek,
        DatePresetOptionsMap.StartOfWeek,
        DatePresetOptionsMap.StartOfMonth,
        DatePresetOptionsMap.LastMonth,
        DatePresetOptionsMap.EndOfThisMonth,
        DatePresetOptionsMap.StartOfPreviousMonth,
        DatePresetOptionsMap.TPlus5,
        DatePresetOptionsMap.TPlus20,
        DatePresetOptionsMap.TPlus30,
        DatePresetOptionsMap.TPlus50,
        DatePresetOptionsMap.TPlus90,
        DatePresetOptionsMap.AsOfDate,
    ],
    value: [
        DatePresetOptionsMap.Today,
        DatePresetOptionsMap.PreviousBusinessDate,
        DatePresetOptionsMap.TMinusTwo,
        DatePresetOptionsMap.LastWeek,
        DatePresetOptionsMap.StartOfWeek,
        DatePresetOptionsMap.StartOfMonth,
        DatePresetOptionsMap.LastMonth,
        DatePresetOptionsMap.EndOfThisMonth,
        DatePresetOptionsMap.StartOfPreviousMonth,
        DatePresetOptionsMap.TPlus5,
        DatePresetOptionsMap.TPlus20,
        DatePresetOptionsMap.TPlus30,
        DatePresetOptionsMap.TPlus50,
        DatePresetOptionsMap.TPlus90,
        DatePresetOptionsMap.AsOfDate,
    ],
    period: [
        DatePresetOptionsMap.Latest,
        DatePresetOptionsMap.Today,
        DatePresetOptionsMap.PreviousBusinessDate,
        DatePresetOptionsMap.TMinusTwo,
        DatePresetOptionsMap.LastWeek,
        DatePresetOptionsMap.StartOfWeek,
        DatePresetOptionsMap.StartOfMonth,
        DatePresetOptionsMap.LastMonth,
        DatePresetOptionsMap.EndOfThisMonth,
        DatePresetOptionsMap.StartOfPreviousMonth,
        DatePresetOptionsMap.AsOfDate,
    ],
};
