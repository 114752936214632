import "react-resizable/css/styles.css";
import { useEverTrue } from "@enfusion-ui/hooks";
import { styled, useThisTab } from "@enfusion-ui/web-core";
import { useWindowSize } from "@react-hook/window-size/throttled";
import * as React from "react";
import { ResizableBox, } from "react-resizable";
const ResizableContainer = styled(ResizableBox) `
  ${({ open }) => (open ? "" : "display: none;")};
  width: 100%;
  height: 100% !important;
  max-height: 100% !important;
  position: relative;
  overflow: auto;

  & .react-resizable-handle-w {
    margin: 0;
    transform: none;
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    width: 3px;
    height: 100%;
    background-image: none;
  }
`;
export const HorizontallyResizable = ({ open, minHeight = 10, minWidth = 10, width = 350, maxWidth: maxWidthOverride, maxWidthRatio = 0.9, children, handle, onResizeStop, scope = "window", }) => {
    const opened = useEverTrue(open);
    const { width: tabWidth, height: tabHeight } = useThisTab();
    const [windowWidth, windowHeight] = useWindowSize();
    const [maxWidth, maxHeight] = React.useMemo(() => {
        const baseWidth = scope === "window" ? windowWidth : tabWidth || 0;
        const height = scope === "window" ? windowHeight : tabHeight || 0;
        return [maxWidthOverride ?? baseWidth * maxWidthRatio, height];
    }, [
        tabWidth,
        windowWidth,
        tabHeight,
        windowHeight,
        maxWidthOverride,
        maxWidthRatio,
        scope,
    ]);
    return (React.createElement(ResizableContainer, { height: maxHeight, width: Math.max(width, minWidth), minConstraints: [minWidth, minHeight], maxConstraints: [maxWidth, maxHeight], open: open, axis: "x", resizeHandles: ["w"], handle: handle, onResizeStop: onResizeStop }, opened ? children : null));
};
