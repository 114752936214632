import { faQuestionCircle } from "@fortawesome/pro-solid-svg-icons";
export const testChannelData = {
    name: "Test Channel Data",
    minSize: [8, 12],
    icon: faQuestionCircle,
    description: "Test Channel Data",
    adminOnly: true,
    defaultConfig: {},
    type: "simpleDisplay",
    category: "any",
};
