/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable no-console */
import { AppEvent, AppEventCategories, } from "@enfusion-ui/types";
import { FullStory } from "@fullstory/browser";
import { isUndefined } from "lodash";
import * as React from "react";
import { FULLSTORY_ENABLED, IS_LOCAL } from "./constants";
import { gaEvent } from "./ga";
const LocalStatus = {
    LocalOnly: 0,
    LocalAndRemote: 1,
    RemoteOnly: 2,
    DebugOnly: 3,
};
const debugMode = IS_LOCAL ??
    (typeof window !== "undefined" ? window : {}).allowDebugLogging ??
    false;
const createIsCheck = (list) => (l) => list.includes(l);
const isRemote = createIsCheck([
    LocalStatus.RemoteOnly,
    LocalStatus.LocalAndRemote,
]);
const isLocal = createIsCheck([
    LocalStatus.LocalOnly,
    LocalStatus.LocalAndRemote,
]);
const isDebug = createIsCheck([LocalStatus.DebugOnly]);
function logExt(local, message, level, prefix, error, extra) {
    const allowed = FULLSTORY_ENABLED && local === LocalStatus.RemoteOnly;
    const err = !isUndefined(error) ? ` - (${error.message})` : "";
    const ext = !isUndefined(extra) ? ` - (${JSON.stringify(extra)})` : "";
    const msg = `${prefix} ${message}${err}${ext}`;
    if (allowed)
        FullStory("logAsync", { msg, level });
}
function logExtEvent(local, name, properties = {}) {
    const allowed = FULLSTORY_ENABLED && isRemote(local);
    if (allowed)
        FullStory("trackEventAsync", { name, properties });
}
function logRes(local, method, args, prefix) {
    if (isLocal(local))
        method(prefix, ...args);
    if (!!debugMode && isDebug(local))
        method(`[Debug]${prefix}`, ...args);
}
const errEvent = {
    event: AppEvent.CaughtError,
    category: AppEventCategories.Navigation,
};
function createLogMethod(method, level) {
    return (prefix, local, message, error, event, extra) => {
        logExt(local, message, level, prefix, error, extra);
        if (event)
            logEvent(local, errEvent, {
                ...(extra || {}),
                message,
                error: error?.message,
            });
        const parts = [message, error, extra].filter((i) => !isUndefined(i));
        logRes(local, method, parts, prefix);
    };
}
function logEvent(local, params, extra = {}) {
    const category = params.category || "App";
    const name = params.event || "Event";
    const label = params.gaLabel || name;
    const value = params.gaValue ?? "null";
    const other = { ...extra, category, label, value };
    logExt(local, name, "log", "[EVENT]", undefined, other);
    logExtEvent(local, name, other);
    gaEvent({ category, action: name, label, value: params.gaValue });
    logRes(local, console.info, [name, category, other], "[EVENT]");
}
function logScreenView(local, screenName) {
    logExt(local, screenName, "log", "[SCREEN VIEW]");
    logExtEvent(local, AppEvent.ScreenView, { screenName });
    logRes(local, console.info, [screenName], "[SCREEN VIEW]");
}
const logDefault = createLogMethod(console.log, "log");
const logInfo = createLogMethod(console.info, "log");
const logWarn = createLogMethod(console.warn, "warn");
const logError = createLogMethod(console.error, "error");
const logSafeError = createLogMethod(console.warn, "error");
const createAppLogging = (local = LocalStatus.LocalAndRemote, prefix = "[AppLogging]") => new Proxy({}, {
    get: (target, key) => {
        if (key === "prefix")
            return (newPrefix) => createAppLogging(local, newPrefix);
        if (key === "localOnly")
            return createAppLogging(LocalStatus.LocalOnly, prefix);
        if (key === "remoteOnly")
            return createAppLogging(LocalStatus.RemoteOnly, prefix);
        if (key === "debug")
            return createAppLogging(LocalStatus.DebugOnly, prefix);
        if (key === "safeError" || key === "error")
            return (message, error, event) => {
                const method = key === "safeError" ? logSafeError : logError;
                method(prefix, local, message, error, event);
            };
        if (key === "event")
            return (event, params = {}) => {
                logEvent(local, event, params);
            };
        return (message, extra) => {
            const args = [
                prefix,
                local,
                message,
                undefined,
                undefined,
                extra,
            ];
            if (key === "log") {
                logDefault(...args);
            }
            else if (key === "info") {
                logInfo(...args);
            }
            else if (key === "warn") {
                logWarn(...args);
            }
            else if (key === "screenView") {
                logScreenView(local, message);
            }
        };
    },
});
export const AppLogging = createAppLogging();
export function useLogger(prefix) {
    return React.useMemo(() => (prefix ? AppLogging.prefix(prefix) : AppLogging), [prefix]);
}
