import { useRefCallback, useRestAbortableOptions } from "@enfusion-ui/hooks";
import { getSelectedValues } from "@enfusion-ui/utils";
import { REST_API } from "@enfusion-ui/web-core";
import * as React from "react";
import { useFormContext } from "react-hook-form";
import { ControlledInputBase } from "../forms/ControlledInputs";
import { MultiSelect } from "../inputs/MultiSelect";
export const DeskSelect = ({ selectProps = {}, value, onChange, }) => {
    const { placeholder = "All Desks", label = "Desks", inputId = "assigned-desk-multi-selection-id", ...rest } = selectProps;
    const [assignedDeskIds, setAssignedDeskIds] = React.useState();
    const { options: tradingDeskOptions } = useRestAbortableOptions(REST_API.SECURITY.GET_TRADING_DESKS.FETCH, (td) => ({ value: td.id, label: td.groupName }));
    React.useEffect(() => {
        if (value)
            setAssignedDeskIds(value);
    }, [value]);
    const handleChange = useRefCallback((values) => {
        const ids = values?.length
            ? values.map((opt) => opt?.value)
            : [];
        setAssignedDeskIds(ids);
        onChange?.(ids);
    }, [onChange]);
    return (React.createElement(MultiSelect, { ...rest, placeholder: placeholder, label: label, value: assignedDeskIds
            ? getSelectedValues(assignedDeskIds, tradingDeskOptions)
            : [], onChange: (selectedOption) => handleChange(selectedOption), options: tradingDeskOptions, inputId: inputId }));
};
export const ControlledAssignedDeskSelect = ({ reportQuery }) => {
    const { setValue, watch } = useFormContext();
    const { assignedDeskIds } = watch();
    return (React.createElement(ControlledInputBase, { name: "assignedDeskIds", render: ({ ref: _ref, onChange, ...rest }) => (React.createElement(DeskSelect, { selectProps: rest, value: assignedDeskIds, onChange: (ids) => {
                setValue("assignedDeskIds", ids);
                onChange(ids);
            } })), defaultValue: reportQuery.assignedDeskIds }));
};
