export const getRoot = (path) => {
    if (path.includes("/")) {
        return path.substring(0, path.indexOf("/"));
    }
    else
        return path;
};
export const getFilePath = (path) => {
    if (path.includes("/")) {
        return path.substring(path.indexOf("/") + 1);
    }
    else
        return path;
};
export const encodeSpecialCharacters = (path) => {
    const specialCharacters = ["%", "+"];
    return specialCharacters.reduce((prev, eachChar) => {
        return prev.replaceAll(eachChar, encodeURIComponent(eachChar));
    }, path);
};
