import { createTestId } from "@enfusion-ui/utils";
import { styled, ThemeProviderBase, useTheme } from "@enfusion-ui/web-core";
import { faTimes } from "@fortawesome/pro-solid-svg-icons";
import { AnimatePresence, motion } from "framer-motion";
import * as React from "react";
import { IconButton } from "../control";
import { PositionedPortal } from "../portal";
const DialogContainer = styled.div `
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  overflow: auto;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: var(--modal-z);
`;
const DialogOverlay = styled.div `
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: var(--overlay);
  z-index: 1;
`;
const DialogContent = styled.div `
  position: relative;
  color: var(--text-normal);
  background-color: var(--background-color-0);
  width: initial;
  min-width: 300px;
  border-radius: 4px;
  padding: var(--spacing-xl);
  z-index: 2;
  max-height: 95vh;
  overflow: auto;
`;
const Header = styled.div `
  display: flex;
  margin-bottom: var(--spacing-xl);
  gap: var(--spacing-l);
`;
export const ModalTitle = styled.h1 `
  font-size: 1rem;
  font-weight: bold;
  flex: 1;
  margin: var(--spacing-lg);
`;
const CloseButton = styled(IconButton).attrs({ icon: faTimes }) `
  font-size: 1rem;
  padding: 0 !important;
  justify-self: end;
  align-self: center;
`;
const Actions = styled.div `
  margin-top: var(--spacing-l);
`;
const Footer = styled.div `
  margin-top: var(--spacing-l);
`;
const ContentBody = styled.div `
  overflow-y: auto;
  max-width: 100%;
  width: 100%;
`;
// modal animation settings
const modalVariants = {
    initial: { opacity: 0, scale: 0.4 },
    animate: { opacity: 1, scale: 1, transition: { duration: 0.2 } },
    exit: { opacity: 0, scale: 0.5, transition: { duration: 0.2 } },
};
function handleSetPlacement() {
    return {
        top: "0px",
        left: "0px",
        right: "0px",
        bottom: "0px",
    };
}
function handleContext(e) {
    e.stopPropagation();
}
const AnimateController = ({ children, enabled }) => {
    const Wrapper = React.useMemo(() => (enabled ? AnimatePresence : React.Fragment), [enabled]);
    return React.createElement(Wrapper, null, children);
};
function getSize(size, autoSize = "auto") {
    return size === "small"
        ? "30vw"
        : size === "medium"
            ? "50vw"
            : size === "large"
                ? "85vw"
                : autoSize;
}
export function Modal({ children, isOpen, onClose, title, content, actions, allowDismissal = true, footerContent, containerStyle = {}, size = "auto", maxWidth = "calc(100vw - (var(--spacing-l) * 2))", dataTestId, animateEnabled = true, zIndex, }) {
    const { theme } = useTheme();
    const testId = typeof title === "string" ? createTestId("modal", title) : "modal";
    return (React.createElement(ThemeProviderBase, { theme: theme },
        React.createElement(PositionedPortal, { setPlacement: handleSetPlacement, open: isOpen, zIndex: zIndex },
            React.createElement(AnimateController, { enabled: animateEnabled }, isOpen && (React.createElement(DialogContainer, { onContextMenu: handleContext, "data-testid": testId },
                React.createElement(DialogOverlay, { onClick: allowDismissal ? onClose : undefined }),
                React.createElement(motion.div, { key: "modal", initial: animateEnabled ? "initial" : false, animate: animateEnabled ? "animate" : false, exit: "exit", variants: modalVariants, style: {
                        minWidth: getSize(size),
                        width: getSize(size, "max-content"),
                        maxWidth: getSize(size, maxWidth),
                        maxHeight: "calc(100vh - (var(--spacing-l) * 2))",
                    }, "data-testid": dataTestId }, children || (React.createElement(DialogContent, { "aria-label": title && typeof title === "string"
                        ? title
                        : "modal content" },
                    React.createElement(Header, null,
                        title && typeof title === "string" ? (React.createElement(ModalTitle, null, title)) : (React.createElement(React.Fragment, null, title)),
                        allowDismissal && (React.createElement(CloseButton, { onClick: onClose, "data-testid": `${testId}-close-button` }))),
                    React.createElement(ContentBody, { style: containerStyle },
                        content && React.createElement("div", null, content),
                        actions && React.createElement(Actions, null, actions)),
                    footerContent && React.createElement(Footer, null, footerContent))))))))));
}
