import { faCalendarAlt } from "@fortawesome/pro-solid-svg-icons";
export const date = {
    name: "Date(s)",
    minSize: [5, 1],
    icon: faCalendarAlt,
    description: "Choose Date / Date Ranges",
    defaultConfig: {
        defaultValue: null,
        inputType: "date",
        key: "Date",
    },
    type: "input",
    category: "dashboard",
};
