import { FileSortOptions, StorageRoots, } from "@enfusion-ui/types";
import { cleanPath, generalDateFormat } from "@enfusion-ui/utils";
import { format } from "date-fns";
import queryString from "query-string";
import { varReplace } from "./utils/varReplace";
const baseUrl = "/internal/api/storage";
const urlParams = {
    root: {
        defaultValue: StorageRoots.GENERAL,
        options: Object.values(StorageRoots),
    },
};
export const calls = {
    LIST_NODES: {
        url: `${baseUrl}/list/{root}`,
        urlParams,
        query: {
            path: {},
            query: {},
            toDate: {},
            fromDate: {},
            ascending: { defaultValue: true },
            searchFolders: { defaultValue: false },
            useCreationDate: { defaultValue: false },
            sortOption: {
                defaultValue: FileSortOptions.None,
                options: Object.values(FileSortOptions),
            },
        },
    },
    MOVE_NODE: {
        url: `${baseUrl}/move/{root}`,
        method: "POST",
        body: {
            path: "",
            newPath: "",
        },
        urlParams,
    },
    COPY_NODE: {
        url: `${baseUrl}/copy/{root}`,
        method: "POST",
        body: {
            path: "",
            newPath: "",
        },
        urlParams,
    },
    RENAME_NODE: {
        url: `${baseUrl}/rename/{root}`,
        method: "POST",
        body: {
            path: "",
            name: "",
        },
        urlParams,
    },
    DELETE_NODE: {
        url: `${baseUrl}/delete/{root}`,
        method: "POST",
        body: {
            path: "",
        },
        urlParams,
    },
    UPLOAD_FILES: {
        url: `${baseUrl}/upload/{root}`,
        method: "POST",
        body: {
            // TODO support FormData body
            path: "FormData not yet supported",
        },
        urlParams,
    },
    CREATE_FOLDER: {
        url: `${baseUrl}/create-folder/{root}`,
        method: "POST",
        body: {
            path: "",
            name: "",
        },
        urlParams,
    },
    GET_FILE: {
        url: `${baseUrl}/get/{root}`,
        query: {
            path: { optional: false, defaultValue: "" },
        },
        urlParams,
    },
};
export default (callCreator) => ({
    // GET /internal/api/storage/list/${root}?path=${path}&sortOption=${sortOption}&ascending=${ascending}&query=${query}&fromDate=${fromDate}&toDate=${toDate}&useCreationDate=${useCreationDate}&searchFolders=${searchFolders}
    // type          | name             | value           | required | default |
    // url param     | root             | StorageRoot     | required | null    |
    // query param   | path             | string          | optional | null    |
    // query param   | sortOption       | FileSortOption  | optional | None    |
    // query param   | ascending        | boolean         | optional | true    |
    // query param   | query            | string          | optional | null    |
    // query param   | fromDate         | Date            | optional | null    |
    // query param   | toDate           | Date            | optional | null    |
    // query param   | useCreationDate  | boolean         | optional | false   |
    // query param   | searchFolders    | boolean         | optional | false   |
    LIST_NODES: callCreator(({ root, ...params }) => {
        const query = {
            ascending: false,
            ...params,
            toDate: params.toDate
                ? format(params.toDate, generalDateFormat)
                : undefined,
            fromDate: params.fromDate
                ? format(params.fromDate, generalDateFormat)
                : undefined,
            searchFolders: false,
        };
        return queryString.stringifyUrl({
            url: varReplace(calls.LIST_NODES.url, { root }),
            query,
        });
    }),
    // POST /internal/api/storage/move/{root}
    // type       | name    | value        | required | default |
    // url param  | root    | StorageRoot  | required | null    |
    // body param | path    | string       | required | null    |
    // body param | newPath | string       | required | null    |
    MOVE_NODE: callCreator((root, oldPath, newPath) => [
        varReplace(calls.MOVE_NODE.url, { root }),
        {
            body: {
                path: cleanPath(oldPath),
                newPath: cleanPath(newPath, null),
            },
        },
    ]),
    // POST /internal/api/storage/copy/{root}
    // type       | name    | value        | required | default |
    // url param  | root    | StorageRoot  | required | null    |
    // body param | path    | string       | required | null    |
    // body param | newPath | string       | required | null    |
    COPY_NODE: callCreator((root, oldPath, newPath) => [
        varReplace(calls.COPY_NODE.url, { root }),
        {
            body: {
                path: cleanPath(oldPath),
                newPath: cleanPath(newPath, null),
            },
        },
    ]),
    // POST /internal/api/storage/rename/{root}
    // type       | name | value        | required | default |
    // url param  | root | StorageRoot  | required | null    |
    // body param | path | string       | required | null    |
    // body param | name | string       | required | null    |
    RENAME_NODE: callCreator((root, path, newName) => [
        varReplace(calls.RENAME_NODE.url, { root }),
        {
            body: {
                path: cleanPath(path),
                name: newName,
            },
        },
    ]),
    // POST /internal/api/storage/delete/{root}
    // type       | name | value        | required | default |
    // url param  | root | StorageRoot  | required | null    |
    // body param | path | string       | required | null    |
    DELETE_NODE: callCreator((root, path) => [
        varReplace(calls.DELETE_NODE.url, { root }),
        {
            body: { path: cleanPath(path) },
        },
    ]),
    // POST /internal/api/storage/upload/{root}
    // type                 | name      | value        | required | default |
    // url param            | root      | StorageRoot  | required | null    |
    // multipart form param | files     | Files        | required | null    |
    // multipart form param | directory | string       | optional | "/"     |
    UPLOAD_FILES: callCreator((root, files, directory) => {
        const body = new FormData();
        for (let i = 0; i < files.length; i += 1) {
            body.append("files", files[i]);
        }
        if (directory.trim() !== "" && directory.trim() !== "/") {
            body.append("directory", directory);
        }
        return [varReplace(calls.UPLOAD_FILES.url, { root }), { body }];
    }),
    // POST /internal/api/storage/create-folder/{root}
    // type       | name | value        | required | default |
    // url param  | root | StorageRoot  | required | null    |
    // body param | name | string       | required | null    |
    // body param | path | string       | optional | "/"     |
    CREATE_FOLDER: callCreator((root, name, path) => [
        varReplace(calls.CREATE_FOLDER.url, { root }),
        {
            body: {
                name,
                path: cleanPath(path, null),
            },
        },
    ]),
    GET_FILE: callCreator((root, path) => queryString.stringifyUrl({
        url: varReplace(calls.GET_FILE.url, { root }),
        query: { path },
    })),
});
