import { getSelectOption } from "@enfusion-ui/utils";
import * as React from "react";
import { Select } from "../Select";
const FONT_WEIGHT_OPTIONS = [
    {
        label: "Most Bold",
        value: "bolder",
    },
    {
        label: "Bold",
        value: "bold",
    },
    {
        label: "Normal",
        value: "normal",
    },
    {
        label: "Light",
        value: "lighter",
    },
];
export const FontWeight = ({ label = "Font Weight", name, errors, selectedValue, clearable, disabled, required, onChange, minWidth, }) => {
    return (React.createElement(Select, { label: label, name: name, errors: errors, value: getSelectOption(FONT_WEIGHT_OPTIONS, selectedValue), options: FONT_WEIGHT_OPTIONS, onChange: onChange, creatable: true, clearable: clearable, required: required, disabled: disabled, minWidth: minWidth, inputId: "font-weight-selection-id" }));
};
