import { noop, omit } from "lodash";
import * as React from "react";
import { initialCDXOrderFormState, initialFXOrderFormState, initialIRSOrderFormState, initialOrderFormState, initialVarSwapOrderFormState, } from "../orders";
export const initialOrderFormContextState = {
    initialLoading: false,
    secondaryLoading: false,
    orderId: 0,
    newOrder: true,
    initialOrder: initialOrderFormState,
    error: false,
    submitErrors: null,
    complianceRecords: null,
    isFormBusy: false,
    completed: false,
    splitOrder: false,
    isFxOrder: false,
    isIrsOrder: false,
    isSplit: false,
    isCdxOrder: false,
    isVarSwapOrder: false,
    formType: "equity",
    custom: false,
    isFutureSpreadOrder: false,
    minQuantity: 0,
};
const initialOrderFormContextResult = {
    ...initialOrderFormContextState,
    checkIfTransmittable: () => Promise.resolve(false),
    setSubmitErrors: noop,
    setComplianceRecords: noop,
    setIsFormBusy: noop,
    resetForm: noop,
    submitOrder: () => Promise.reject(new Error("Order form provider not ready")),
    reInitForm: () => Promise.resolve(),
    handleNotional: noop,
    handleQuantity: noop,
    getCurrentOrder: () => ({}),
    onOrderChange: noop,
    setFormType: noop,
    setWarningRecords: noop,
};
export const initialFXOrderFormContextState = {
    ...omit(initialOrderFormContextState, ["initialOrder", "isFxOrder"]),
    isFxOrder: true,
    formType: "fx",
    initialOrder: initialFXOrderFormState,
};
export const initialCDXOrderFormContextState = {
    ...omit(initialOrderFormContextState, ["initialOrder", "isCdxOrder"]),
    isCdxOrder: true,
    formType: "cdx",
    initialOrder: initialCDXOrderFormState,
};
export const initialVarSwapOrderFormContextState = {
    ...omit(initialOrderFormContextState, ["initialOrder", "isVarSwapOrder"]),
    isVarSwapOrder: true,
    formType: "varSwap",
    initialOrder: initialVarSwapOrderFormState,
};
export const initialIRSOrderFormContextState = {
    ...omit(initialOrderFormContextState, ["initialOrder", "isIrsOrder"]),
    isIrsOrder: true,
    formType: "irs",
    initialOrder: initialIRSOrderFormState,
};
// oems form context
export const OrderFormContext = React.createContext(initialOrderFormContextResult);
export function useOEMSOrderForm() {
    const context = React.useContext(OrderFormContext);
    if (context === undefined) {
        throw new Error("useOEMSOrderForm must be used within a OrderFormProvider");
    }
    return context;
}
