import { varReplace } from "./utils/varReplace";
const baseUrl = "/internal/api/oms/v1/watchlist";
export const calls = {
    GET_WATCHLIST_LIST: { url: baseUrl },
    GET_WATCHLIST_BY_ID: {
        url: `${baseUrl}/{watchListId}`,
        urlParams: {
            watchListId: { defaultValue: 1 },
        },
    },
    SAVE_WATCHLIST: {
        url: `${baseUrl}/save`,
        method: "POST",
        body: {
            id: 0,
            name: "Test",
            description: "",
            items: [
                {
                    id: 0,
                    instrumentId: 1,
                    listIndex: 0,
                    notes: null,
                },
            ],
        },
    },
    DELETE_WATCHLIST_ITEMS: {
        url: `${baseUrl}/delete`,
        method: "POST",
        body: {
            watchlistId: 1,
            itemIds: [],
        },
    },
    DELETE_WATCHLIST: {
        url: `${baseUrl}/delete/{watchListId}`,
        method: "DELETE",
        urlParams: {
            watchListId: { defaultValue: 1 },
        },
    },
};
export default (callCreator) => ({
    GET_WATCHLIST_LIST: callCreator(() => calls.GET_WATCHLIST_LIST.url),
    GET_WATCHLIST_BY_ID: callCreator((watchListId) => varReplace(calls.GET_WATCHLIST_BY_ID.url, { watchListId })),
    SAVE_WATCHLIST: callCreator((payload) => [calls.SAVE_WATCHLIST.url, { body: payload }]),
    DELETE_WATCHLIST_ITEMS: callCreator((watchlistId, itemIds) => [
        calls.DELETE_WATCHLIST_ITEMS.url,
        { body: { watchlistId, itemIds } },
    ]),
    DELETE_WATCHLIST: callCreator((watchListId) => varReplace(calls.DELETE_WATCHLIST.url, { watchListId })),
});
