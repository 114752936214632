import { DashboardRoots, } from "@enfusion-ui/types";
import queryString from "query-string";
import DOCUMENTS_API, { DocumentAPIRoots } from "./documents";
export const calls = {
    GET_RECONCILIATION_METADATA: {
        url: "/internal/api/reports/reconciliation/metadata",
        query: {
            report: { optional: false, defaultValue: "" },
        },
    },
};
const filePath = "/recon-dashboard.json";
export default (callCreator) => {
    const documentsAPI = DOCUMENTS_API(callCreator);
    return {
        DOWNLOAD: (abortController) => documentsAPI.DOWNLOAD.FETCH(DocumentAPIRoots.RECON_DASHBOARD, `${DashboardRoots.SHARED}${filePath}`, false, abortController),
        STORE: ({ version, config, settings, configVersion, forceWrite = true, }, abortController) => documentsAPI.UPLOAD.FETCH(DocumentAPIRoots.RECON_DASHBOARD, DashboardRoots.SHARED, {
            path: filePath,
            forceWrite,
            content: {
                version,
                config,
                settings,
                configVersion,
            },
        }, false, abortController),
        GET_RECONCILIATION_METADATA: callCreator((path) => queryString.stringifyUrl({
            url: calls.GET_RECONCILIATION_METADATA.url,
            query: { report: path },
        })),
    };
};
