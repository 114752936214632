import { getCanCreateOrders, } from "@enfusion-ui/core";
import { noop } from "lodash";
import * as React from "react";
import { OEMS_DEFAULT_SELECTED_FILTER } from "../../utils";
import { useAuth } from "../auth";
export const initialOEMSRoutesContextState = {
    orderId: undefined,
    routes: [],
    refetchRoutes: () => Promise.resolve(),
    loading: false,
};
export const initialOEMSQuotesContextState = {
    order: undefined,
    quotes: [],
    loading: false,
    error: undefined,
};
export const initialOEMSContextState = {
    orderColumns: undefined,
    selectedFilter: OEMS_DEFAULT_SELECTED_FILTER,
    currencyDetails: {},
    isInitialized: false,
    hasError: false,
    errorMessage: null,
    readyState: undefined,
    initializationStep: 0,
    initializationMessage: "",
    setSelectedFilter: () => null,
    restartSocket: noop,
    subscribe: () => noop,
    openNewOrderTab: noop,
    setNewOrderTicketReady: noop,
};
//oems context
export const OEMSContext = React.createContext(initialOEMSContextState);
export const OEMSProviderPassthrough = ({ children, ...value }) => (React.createElement(OEMSContext.Provider, { value: value }, children));
//oems route context
export const OEMSRoutesContext = React.createContext(initialOEMSRoutesContextState);
//oems quote context
export const OEMSQuotesContext = React.createContext(initialOEMSQuotesContextState);
export const OEMS_INITIALIZATION_STEPS = 13;
export function useOEMS() {
    const context = React.useContext(OEMSContext);
    if (context === undefined) {
        throw new Error("useOEMS must be used within a OEMSProvider");
    }
    return context;
}
export function useCanCreateOrders() {
    const { user } = useAuth();
    return React.useMemo(() => getCanCreateOrders(user), [user]);
}
export function useOEMSRoutes() {
    const context = React.useContext(OEMSRoutesContext);
    if (context === undefined) {
        throw new Error("useOEMSRoutes must be used within a OEMSRoutesProvider");
    }
    return context;
}
export function useOEMSQuotes() {
    const context = React.useContext(OEMSQuotesContext);
    if (context === undefined) {
        throw new Error("useOEMSQuotes must be used within a OEMSQuotesProvider");
    }
    return context;
}
export const OEMS_EVENTS = "oems_events";
export const OemsProviderPassthrough = ({ children, ...value }) => (React.createElement(OEMSContext.Provider, { value: value }, children));
