export const NAVSourceOptions = [
    { label: "GLNAV", value: "GLNAV" },
    { label: "FundNAV", value: "FundNAV" },
    { label: "AllocatedNotional", value: "AllocatedNotional" },
];
export const CalculationTypeOptions = [
    { label: "FinalQuantity", value: "FinalQuantity" },
    { label: "FinalPercentNAV", value: "FinalPercentNAV" },
];
export const AnalyticsSourceOptions = [
    { label: "Position", value: "Position" },
    { label: "CashPosition", value: "CashPosition" },
    { label: "Reported Position", value: "ReportedPosition" },
    { label: "Active Orders", value: "ActiveParentOrders" },
    { label: "Benchmark", value: "Benchmark" },
];
export const FundBenchmarkTypeOptions = [
    { label: "Primary Benchmark", value: "PrimaryBenchmark" },
    { label: "Secondary Benchmark", value: "SecondaryBenchmark" },
    { label: "Tertiary Benchmark", value: "TertiaryBenchmark" },
];
export const CalculationMethodOptions = [
    { label: "Net Of Fees", value: "NetOfFees" },
    { label: "Gross Of Fees", value: "GrossOfFees" },
];
export const FXTranslationMethodOptions = [
    { label: "Temporal", value: "Temporal" },
    { label: "CurrentRate", value: "CurrentRate" },
];
export const AccountingMethodOptions = [
    { label: "Cash Basis", value: "CashBasis" },
    { label: "Accrual Basis", value: "AccrualBasis" },
];
export const CashModeOptions = [
    { label: "SettleDate", value: "SettleDate" },
    { label: "TradeDate", value: "TradeDate" },
];
export const CashAccountingMethodOptions = [
    { label: "Settle Date", value: "CashBasis" },
    { label: "Trade Date", value: "AccrualBasis" },
];
export const generalLedgerEntrySourcesOptions = [
    { label: "TradePosting", value: "TradePosting" },
    { label: "OpeningBalance", value: "OpeningBalance" },
    { label: "Plug", value: "Plug" },
    { label: "CashBookMovement", value: "CashBookMovement" },
    { label: "ManagementFee", value: "ManagementFee" },
    { label: "PositionPosting", value: "PositionPosting" },
    { label: "Template", value: "Template" },
    { label: "FXPnL", value: "FXPnL" },
    { label: "SubscriptionRedemption", value: "SubscriptionRedemption" },
    { label: "PerformanceFee", value: "PerformanceFee" },
    { label: "ManualPosting", value: "ManualPosting" },
    { label: "AccrualPosting", value: "AccrualPosting" },
    { label: "LongShortAdjustment", value: "LongShortAdjustment" },
    { label: "Attribution", value: "Attribution" },
];
export const valueDateOffsetTypeOptions = [
    { label: "Date", value: "Date" },
    { label: "Days", value: "Days" },
];
export const IncludeCashFlowsOptions = [
    { label: "All", value: "All" },
    { label: "On Or Before", value: "OnOrBefore" },
    { label: "On Or After", value: "OnOrAfter" },
    { label: "After", value: "After" },
    { label: "Before", value: "Before" },
];
export const cashFlowTypesOption = [
    { label: "Trade Cost", value: "Trade" },
    { label: "Fees", value: "Fees" },
    { label: "Commissions", value: "Commissions" },
    { label: "Cash Entry", value: "Cash" },
    { label: "Principal Payment", value: "PrincipalPayment" },
    { label: "Settlement Accrued", value: "SettlementAccrued" },
    { label: "Implied Fees", value: "ImpliedFees" },
    { label: "Implied Commissions", value: "ImpliedCommissions" },
    { label: "Settled Proceeds", value: "SettledProceeds" },
    { label: "Settled Contra Proceeds", value: "SettledContraProceeds" },
    { label: "Taxes", value: "Taxes" },
    { label: "Dividend", value: "Dividend" },
    { label: "Principal", value: "Principal" },
    { label: "Payout", value: "Payout" },
    { label: "Interest", value: "Interest" },
    { label: "Rebate", value: "Rebate" },
    { label: "FX Realized", value: "FXRealized" },
    { label: "Repo FX Realized", value: "RepoFXRealized" },
    { label: "TRS FX Realized", value: "TRSFXRealized" },
    { label: "Unsettled Cash FX Realized", value: "UnsettledCashFXRealized" },
    { label: "TRS Interest", value: "TRSInterest" },
    { label: "Financing Interest", value: "FinancingInterest" },
    { label: "Financing Accrued Interest", value: "FinancingAccruedInterest" },
    { label: "Expiration", value: "Expiration" },
    { label: "Dividend Withholding Tax", value: "DividendWithholdingTax" },
    { label: "Interest Withholding Tax", value: "InterestWithholdingTax" },
    { label: "Financing Reset Realized", value: "FinancingResetRealized" },
    { label: "Principal Paydown Realized", value: "PrincipalPaydownRealized" },
    {
        label: "Principal Paydown FX Realized",
        value: "PrincipalPaydownFXRealized",
    },
    { label: "PIK", value: "PaymentInKind" },
    { label: "Swap Interest Exchange", value: "SwapInterestExchange" },
    { label: "Error", value: "Error" },
    { label: "Dividend Fee", value: "DividendFee" },
    { label: "Accrued Dividend", value: "AccruedDividend" },
    { label: "Accrued Dividend Fee", value: "AccruedDividendFee" },
    {
        label: "Accrued Dividend Withholding Tax",
        value: "AccruedDividendWithholdingTax",
    },
    { label: "Other Accrued Interest", value: "OtherAccruedInterest" },
    { label: "Other Interest", value: "OtherInterest" },
    { label: "Underlying FX Realized", value: "UnderlyingFXRealized" },
    { label: "Repo Principal", value: "RepoPrincipal" },
    { label: "Interest Adjustment", value: "InterestAdjustment" },
    { label: "Principal Adjustment", value: "PrincipalAdjustment" },
    { label: "Implied Taxes", value: "ImpliedTaxes" },
    { label: "Dividend FX Realized", value: "DividendFXRealized" },
    {
        label: "Dividend Withholding FX Realized",
        value: "DividendWithholdingFXRealized",
    },
    { label: "Dividend Fee FX Realized", value: "DividendFeeFXRealized" },
    {
        label: "Other Dividend Withholding Tax",
        value: "OtherDividendWithholdingTax",
    },
    { label: "Other Accrued Dividend", value: "OtherAccruedDividend" },
    { label: "Other Paid Dividend", value: "OtherPaidDividend" },
    {
        label: "Other Accrued Dividend W/H Tax",
        value: "OtherAccruedDividendWHTax",
    },
    { label: "Other Financing Accrued", value: "OtherFinancingAccrued" },
    { label: "Other Financing Interest", value: "OtherFinancingInterest" },
    { label: "Return of Capital Dividend", value: "ReturnOfCapitalDividend" },
    { label: "Delayed Compensation", value: "DelayedCompensation" },
    { label: "Cost of Carry", value: "CostOfCarry" },
    { label: "Bank Loan Interest", value: "BankLoanInterest" },
    { label: "Option Expiry FX Realized", value: "OptionExpiryFXRealized" },
    { label: "ROC Dividend FX Realized", value: "ROCDividendFXRealized" },
    { label: "Other Expenses", value: "OtherExpenses" },
    { label: "Impairment Loss", value: "ImpairmentLoss" },
    { label: "Credit Event Interest", value: "CreditEventInterest" },
    { label: "Credit Event Realized", value: "CreditEventRealized" },
    { label: "Credit Event FX Realized", value: "CreditEventFXRealized" },
    { label: "Amortization/Accretion", value: "AmortizationAccretion" },
    { label: "Notional Proceeds", value: "NotionalProceeds" },
    {
        label: "Realized Amortization/Accretion",
        value: "RealizedAmortizationAccretion",
    },
    { label: "TRS Fees", value: "TRSFees" },
    { label: "TRS Commissions", value: "TRSCommissions" },
    { label: "TRS Taxes", value: "TRSTaxes" },
    { label: "Basis Adjustment", value: "BasisAdjustment" },
    { label: "Unrealized Adjustment", value: "UnrealizedAdjustment" },
    { label: "IRS FX Realized", value: "IRSFXRealized" },
    {
        label: "Borrow Financing Unwind Interest",
        value: "BorrowFinancingUnwindInterest",
    },
    {
        label: "TRS Financing Unwind Interest",
        value: "TRSFinancingUnwindInterest",
    },
    { label: "Accrual Fees", value: "AccrualFees" },
    { label: "FX Unrealized Adjustment", value: "FXUnrealizedAdjustment" },
    { label: "Transfer Trading Realized", value: "TransferTradingRealized" },
    { label: "Transfer FX Realized", value: "TransferFXRealized" },
    {
        label: "Transfer Fees and Commissions",
        value: "TransferFeesAndCommissions",
    },
    {
        label: "Transfer Unrealized Fees Adjustment",
        value: "TransferUnrealizedFeesAdjustment",
    },
    {
        label: "Fund Transfer Notional Proceeds",
        value: "FundTransferNotionalProceeds",
    },
    { label: "Fund Transfer Commissions", value: "FundTransferCommissions" },
    { label: "Fund Transfer Fees", value: "FundTransferFees" },
    { label: "Fund Transfer Taxes", value: "FundTransferTaxes" },
    {
        label: "Other Realized Amortization/Accretion",
        value: "OtherRealizedAmortizationAccretion",
    },
    {
        label: "Other Accrued Amortization/Accretion",
        value: "OtherAccruedAmortizationAccretion",
    },
    { label: "Interest FX Realized", value: "InterestFXRealized" },
    {
        label: "Other Amortization/Accretion",
        value: "OtherAmortizationAccretion",
    },
    {
        label: "FX Settlement Unrealized Adjustment",
        value: "FXSettleUnrealizedAdjustment",
    },
    {
        label: "Instrument Dividend Withholding Tax",
        value: "InstrumentDividendWithholdingTax",
    },
    {
        label: "Accrued Instrument Dividend Withholding Tax",
        value: "AccruedInstrumentDividendWithholdingTax",
    },
    {
        label: "TRS Cash Dividend Withholding Tax",
        value: "TRSDividendWithholdingTax",
    },
    {
        label: "Accrued TRS Accrued Dividend Withholding Tax",
        value: "AccruedTRSDividendWithholdingTax",
    },
    {
        label: "Max Rate Across Feeders Dividend Withholding Tax",
        value: "MaxRateDividendWithholdingTax",
    },
    {
        label: "Accrued Max Rate Across Feeders Dividend Withholding Tax",
        value: "AccruedMaxRateDividendWithholdingTax",
    },
    {
        label: "Regular Cash Dividend Withholding Tax",
        value: "RegularCashDividendWithholdingTax",
    },
    {
        label: "Accrued Regular Cash Dividend Withholding Tax",
        value: "AccruedRegularCashDividendWithholdingTax",
    },
    {
        label: "Return of Capital Dividend Withholding Tax",
        value: "ReturnOfCapitalDividendWithholdingTax",
    },
    {
        label: "Accrued Return of Capital Dividend Withholding Tax",
        value: "AccruedReturnOfCapitalDividendWithholdingTax",
    },
    {
        label: "Interest on Capital Dividend Withholding Tax",
        value: "InterestOnCapitalDividendWithholdingTax",
    },
    {
        label: "Accrued Interest on Capital Dividend Withholding Tax",
        value: "AccruedInterestOnCapitalDividendWithholdingTax",
    },
    {
        label: "Special Cash Dividend Withholding Tax",
        value: "SpecialCashDividendWithholdingTax",
    },
    {
        label: "Accrued Special Cash Dividend Withholding Tax",
        value: "AccruedSpecialCashDividendWithholdingTax",
    },
    {
        label: "Retroactive/Arrears Cash Dividend Withholding Tax",
        value: "RetroactiveCashDividendWithholdingTax",
    },
    {
        label: "Accrued Retroactive/Arrears Cash Dividend Withholding Tax",
        value: "AccruedRetroactiveCashDividendWithholdingTax",
    },
    {
        label: "Partnership Distribution Dividend Withholding Tax",
        value: "PartnershipDistributionDividendWithholdingTax",
    },
    {
        label: "Accrued Partnership Distribution Dividend Withholding Tax",
        value: "AccruedPartnershipDistributionDividendWithholdingTax",
    },
    {
        label: "Property Income Distribution Dividend Withholding Tax",
        value: "PropertyIncomeDistributionDividendWithholdingTax",
    },
    {
        label: "Accrued Property Income Distribution Dividend Withholding Tax",
        value: "AccruedPropertyIncomeDistributionDividendWithholdingTax",
    },
    {
        label: "Unsettled Premium Adjustment",
        value: "UnsettledPremiumAdjustment",
    },
    {
        label: "Paid Return of Capital Dividend",
        value: "PaidReturnOfCapitalDividend",
    },
    { label: "Accrued Dividend Pay to Hold", value: "PTHAccruedDividend" },
    { label: "Dividend Pay to Hold", value: "PTHDividend" },
    {
        label: "IFRS9 Unsettled Amortization/Accretion",
        value: "IFRS9UnsettledAmortizationAccretion",
    },
    {
        label: "IFRS9 Unsettled Accrued Amortization/Accretion",
        value: "IFRS9UnsettledAccruedAmortizationAccretion",
    },
    { label: "Transfer Financing Interest", value: "TransferFinancingInterest" },
    {
        label: "Transfer Financing Accrued Interest",
        value: "TransferFinancingAccruedInterest",
    },
    { label: "Transfer Paid Dividend", value: "TransferPaidDividend" },
    {
        label: "Transfer Paid Dividend Withholding Tax",
        value: "TransferPaidDividendWithholdingTax",
    },
    { label: "Transfer Accrued Dividend", value: "TransferAccruedDividend" },
    {
        label: "Transfer Accrued Dividend Withholding Tax",
        value: "TransferAccruedDividendWithholdingTax",
    },
    { label: "Transfer Interest", value: "TransferInterest" },
    { label: "Transfer Accrued Interest", value: "TransferAccruedInterest" },
    { label: "Return of Capital Settled Proceeds", value: "ROCSettledProceeds" },
    { label: "PnL Realized Adjustment", value: "PnLRealizedAdjustment" },
    { label: "PnL Unrealized Adjustment", value: "PnLUnrealizedAdjustment" },
];
export const IN_GRID_SHOCK_EFFECT_OPTIONS = [
    { label: "Shock all positions in report", value: "ShockAllReport" },
    {
        label: "Shock only altered positions (Quicker)",
        value: "ShockSelectedPosition",
    },
];
export const POSITION_REQUEST_TYPE_OPTIONS = [
    { label: "Securities", value: "SecuritiesOnly" },
    { label: "Cash", value: "CashOnly" },
    { label: "Securities & Cash", value: "SecuritiesAndCash" },
    { label: "Non-Trading", value: "NonTradingOnly" },
    { label: "Cash & Non-Trading", value: "CashAndNonTrading" },
    {
        label: "Securities, Cash & Non-Trading",
        value: "SecuritiesCashAndNonTrading",
    },
];
export const IGNORED_TRADE_BOOKING_STATUS_OPTIONS = [
    { label: "Open", value: "Initial" },
    { label: "Complete", value: "Complete" },
    { label: "Reviewed", value: "Reviewed" },
];
export const TRADE_STATUS_OPTIONS = [
    { value: "Canceled", label: "Canceled" },
    { value: "Reconciled", label: "Reconciled" },
    { value: "Archived", label: "Archived" },
    { value: "Locked", label: "Locked" },
    { value: "AllocationLocked", label: "Allocation Locked" },
];
export const TRADE_TYPE_OPTIONS = [
    { label: "Trade", value: "Trade" },
    { label: "Stock Loan Transfer", value: "StockLoanTransfer" },
    { label: "Split", value: "Split" },
    { label: "Exercise", value: "Exercise" },
    { label: "Expiration", value: "Expiration" },
    { label: "Reorganization", value: "Reorganization" },
    { label: "StockDistribution", value: "StockDistribution" },
    { label: "Merge", value: "Merge" },
    { label: "Exchange", value: "Exchange" },
    { label: "Bond Call", value: "BondCall" },
    { label: "Bond Put", value: "BondPut" },
    { label: "Name Change Put", value: "NameChangePut" },
    { label: "Rights Distribution", value: "RightsDistribution" },
    { label: "PB Transfer", value: "PBTransfer" },
    { label: "Strategy Transfer", value: "StrategeyTransfer" },
    { label: "Pay In Kind", value: "PayInKind" },
    { label: "Buy In", value: "BuyIn" },
    { label: "Period Close", value: "PeriodClose" },
    { label: "Unwind", value: "Unwind" },
    { label: "Swap Conversion", value: "SwapConversion" },
    { label: "Conversion Create", value: "ConversionCreate" },
    { label: "Buyback Paydown", value: "BuybackPaydown" },
    { label: "Assignment", value: "Assignment" },
    { label: "Assign Unwind", value: "AssignUnwind" },
    { label: "TRS Reset Close", value: "TRSResetClose" },
    { label: "TRS Reset Reopen", value: "TRSResetReopen" },
    { label: "Transfer Close", value: "TransferClose" },
    { label: "Transfer Open", value: "TransferOpen" },
    { label: "Stock Dividend", value: "StockDividend" },
    { label: "Roll Open", value: "RollOpen" },
    { label: "Roll Close", value: "RollClose" },
    { label: "Roll Trade", value: "RollTrade" },
    { label: "PB Adjustment", value: "PBAdjustment" },
    { label: "Close Out", value: "CloseOut" },
    { label: "NDF Fixing", value: "NDFFixing" },
    { label: "Maturity", value: "Maturity" },
    { label: "FX Sweep", value: "FXSweep" },
    { label: "Free Deliver", value: "FreeDeliver" },
    { label: "Free Receive", value: "FreeReceive" },
    { label: "Historical Amendment", value: "HistoricalAmendment" },
    { label: "Partial Unwind", value: "PartialUnwind" },
    { label: "Partial Assignment", value: "PartialAssignment" },
    { label: "Partial Assign Unwind", value: "PartialAssignUnwind" },
    { label: "PB Transfer Close", value: "PBTransferClose" },
    { label: "PB Transfer Open", value: "PBTransferOpen" },
    { label: "Credit Event", value: "CreditEvent" },
    { label: "Rebalance Trade", value: "RebalanceTrade" },
    { label: "Strategy Transfer Out", value: "StrategyTransferOut" },
    { label: "Strategy Transfer In", value: "StrategyTransferIn" },
    { label: "New Issue", value: "NewIssue" },
    { label: "Free Reorganization Open", value: "FreeReorganizationOpen" },
    { label: "Free Reorganization Close", value: "FreeReorganizationClose" },
    { label: "Adjustment", value: "Adjustment" },
    { label: "Spread", value: "Spread" },
    { label: "Deliverable", value: "Deliverable" },
    { label: "Block", value: "Block" },
    { label: "EFP", value: "EFP" },
    { label: "Stock Dividend Adjustment", value: "StockDividendAdjustment" },
    { label: "Accrual Stream Trade", value: "AccrualStreamTrade" },
    { label: "Facility Apportionment", value: "FacilityApportionment" },
    { label: "Funded Revolver", value: "FundedRevolver" },
    { label: "Unfunded Revolver", value: "UnfundedRevolver" },
    { label: "Fund Transfer Close", value: "FundTransferClose" },
    { label: "Fund Transfer Open", value: "FundTransferOpen" },
    { label: "Reorganization Close", value: "ReorganizationClose" },
    { label: "Reorganization Open", value: "ReorganizationOpen" },
    { label: "Placement Trade", value: "PlacementTrade" },
    { label: "PNL Adjustment", value: "PNLAdjustment" },
    { label: "Drawdown", value: "Drawdown" },
    { label: "Rollover Open", value: "RolloverOpen" },
    { label: "Rollover Close", value: "RolloverClose" },
    { label: "Paydown", value: "Paydown" },
    { label: "Illogical Adjustment Open", value: "IllogicalAdjustmentOpen" },
    { label: "Illogical Adjustment Close", value: "IllogicalAdjustmentClose" },
    { label: "Initial Drawdown", value: "InitialDrawdown" },
    { label: "Facility Unwind", value: "FacilityUnwind" },
    { label: "Paydown With Interest", value: "PaydownWithInterest" },
    {
        label: "Reorganization Close With Interest",
        value: "ReorganizationCloseWithInterest",
    },
];
export const DATE_ADJUSTMENT_OPTIONS = [
    { label: "Adjust All", value: "AdjustAll" },
    { label: "Only Adjust PBD", value: "DoNotAdjustExceptForPBD" },
    { label: "Never Adjust", value: "NeverAdjust" },
];
export const OrderStatusOptions = [
    { value: "Acknowledged", label: "Acknowledged" },
    { value: "Cancelled", label: "Cancelled" },
    { value: "DoneForDay", label: "Done for Day" },
    { value: "Filled", label: "Filled" },
    { value: "NewlyCreated", label: "New" },
    { value: "PartiallyFilled", label: "Partially Filled" },
    { value: "Rejected", label: "Rejected" },
];
export const POSITION_SOURCE_OPTIONS = [
    { label: "Position", value: "Position" },
    { label: "Cash Position", value: "CashPosition" },
    { label: "Reported Position", value: "ReportedPosition" },
    { label: "Active Orders", value: "ActiveParentOrders" },
    { label: "Benchmark", value: "Benchmark" },
];
export const NAV_SOURCE_OPTIONS = [
    { label: "General Ledger NAV", value: "GLNAV" },
    { label: "Manual Historical NAV", value: "FundNAV" },
    { label: "Allocated Notional", value: "AllocationNotional" },
];
export const FUND_BENCHMARK_TYPE_OPTIONS = [
    { label: "Primary Benchmark", value: "PrimaryBenchmark" },
    { label: "Secondary Benchmark", value: "SecondaryBenchmark" },
    { label: "Tertiary Benchmark", value: "TertiaryBenchmark" },
];
export const CALCULATION_TYPE_OPTIONS = [
    { label: "Final Quantity", value: "FinalQuantity" },
    { label: "Final % of NAV", value: "FinalPercentNAV" },
];
export const LOT_TYPE_OPTIONS = [
    { value: "Trade", label: "Lot Open" },
    { value: "UnwindTrade", label: "Unwind Trade" },
    { value: "TradeLotCloseEvent", label: "Lot Unwind" },
    { value: "Error", label: "Error" },
];
export const ERROR_SEVERITIES = [
    { label: "No Error", value: "NoError" },
    { label: "Tracking", value: "Tracking" },
    { label: "Warning", value: "Warning" },
    { label: "Warning With Note", value: "WarningWithNote" },
    { label: "Severe", value: "Severe" },
    { label: "Override not allowed", value: "OverrideNotAllowed" },
];
export const ORDER_STATUS = [
    { value: "New", label: "New" },
    { value: "PartiallyFilled", label: "Partially Filled" },
    { value: "Filled", label: "Filled" },
    { value: "DoneForDay", label: "Done for Day" },
    { value: "Cancelled", label: "Cancelled" },
    { value: "Rejected", label: "Rejected" },
    { value: "Replaced", label: "Replaced" },
    { value: "PendingCancel", label: "Pending Cancel" },
    { value: "Stopped", label: "Stopped" },
    { value: "Suspended", label: "Suspended" },
    { value: "PendingNew", label: "Pending New" },
    { value: "Calculated", label: "Calculated" },
    { value: "Expired", label: "Expired" },
    { value: "PendingReplace", label: "Pending Replace" },
    { value: "NewlyCreated", label: "Newly Created" },
];
export const TradeTypesOptions = [
    { label: "Regular Trade", value: "Trade" },
    { label: "Stock Loan Transfer", value: "StockLoanTransfer" },
    { label: "Stock Split", value: "Split" },
    { label: "Exercise", value: "Exercise" },
    { label: "Expiration", value: "Expiration" },
    { label: "Reorganization", value: "Reorganization" },
    { label: "Stock Distribution", value: "StockDistribution" },
    { label: "Merger", value: "Merge" },
    { label: "Exchange", value: "Exchange" },
    { label: "Bond Call", value: "BondCall" },
    { label: "Bond Put", value: "BondPut" },
    { label: "Name Change", value: "NameChangePut" },
    { label: "Rights Distribution", value: "RightsDistribution" },
    { label: "PB Transfer", value: "PBTransfer" },
    { label: "Strategy Transfer", value: "StrategeyTransfer" },
    { label: "Pay In Kind", value: "PayInKind" },
    { label: "Buy In", value: "BuyIn" },
    { label: "Period Close", value: "PeriodClose" },
    { label: "Unwind", value: "Unwind" },
    { label: "Swap Conversion", value: "SwapConversion" },
    { label: "Conversion Create", value: "ConversionCreate" },
    { label: "Buyback/Paydown", value: "BuybackPaydown" },
    { label: "Assignment", value: "Assignment" },
    { label: "Assign Unwind", value: "AssignUnwind" },
    { label: "TRS Reset Close", value: "TRSResetClose" },
    { label: "TRS Reset Reopen", value: "TRSResetReopen" },
    { label: "Transfer Close", value: "TransferClose" },
    { label: "Transfer Open", value: "TransferOpen" },
    { label: "Stock Dividend", value: "StockDividend" },
    { label: "Roll Open", value: "RollOpen" },
    { label: "Roll Close", value: "RollClose" },
    { label: "Roll Trade", value: "RollTrade" },
    { label: "PB Adjustment", value: "PBAdjustment" },
    { label: "Close Out", value: "CloseOut" },
    { label: "NDF Fixing", value: "NDFFixing" },
    { label: "Maturity", value: "Maturity" },
    { label: "FX Sweep", value: "FXSweep" },
    { label: "Free Deliver", value: "FreeDeliver" },
    { label: "Free Receive", value: "FreeReceive" },
    { label: "Historical Amendment", value: "HistoricalAmendment" },
    { label: "Partial Unwind", value: "PartialUnwind" },
    { label: "Partial Assignment", value: "PartialAssignment" },
    { label: "Partial Assign Unwind", value: "PartialAssignUnwind" },
    { label: "PB Transfer Close", value: "PBTransferClose" },
    { label: "PB Transfer Open", value: "PBTransferOpen" },
    { label: "Credit Event", value: "CreditEvent" },
    { label: "Rebalance Trade", value: "RebalanceTrade" },
    { label: "Strategy Transfer Out", value: "StrategyTransferOut" },
    { label: "Strategy Transfer In", value: "StrategyTransferIn" },
    { label: "New Issue", value: "NewIssue" },
    { label: "Free Reorganization Open", value: "FreeReorganizationOpen" },
    { label: "Free Reorganization Close", value: "FreeReorganizationClose" },
    { label: "Adjustment", value: "Adjustment" },
    { label: "Spread", value: "Spread" },
    { label: "Deliverable", value: "Deliverable" },
    { label: "Block", value: "Block" },
    { label: "EFP", value: "EFP" },
    { label: "Stock Dividend Adjustment", value: "StockDividendAdjustment" },
    { label: "Accrual Stream Trade", value: "AccrualStreamTrade" },
    { label: "Facility Apportionment", value: "FacilityApportionment" },
    { label: "Funded Revolver", value: "FundedRevolver" },
    { label: "Unfunded Revolver", value: "UnfundedRevolver" },
    { label: "Fund Transfer Close", value: "FundTransferClose" },
    { label: "Fund Transfer Open", value: "FundTransferOpen" },
    { label: "Reorganization Close", value: "ReorganizationClose" },
    { label: "Reorganization Open", value: "ReorganizationOpen" },
    { label: "Placement Trade", value: "PlacementTrade" },
    { label: "P&L Adjustment", value: "PNLAdjustment" },
    { label: "Drawdown", value: "Drawdown" },
    { label: "Rollover Open", value: "RolloverOpen" },
    { label: "Rollover Close", value: "RolloverClose" },
    { label: "Paydown", value: "Paydown" },
    { label: "Illogical Adjustment Open", value: "IllogicalAdjustmentOpen" },
    { label: "Illogical Adjustment Close", value: "IllogicalAdjustmentClose" },
    { label: "Initial Drawdown", value: "InitialDrawdown" },
    { label: "Facility Unwind", value: "FacilityUnwind" },
    { label: "Paydown with Interest", value: "PaydownWithInterest" },
    {
        label: "Reorganization Close with Interest",
        value: "ReorganizationCloseWithInterest",
    },
];
export const CorporateActionOptions = [
    { label: "Splits", value: "Splits" },
    { label: "Cash Dividends", value: "CashDividends" },
    { label: "Stock Dividends", value: "StockDividends" },
    { label: "Payment In Kind (PIK)", value: "PaymentInKind" },
];
export const AssetMeasureOptions = [
    { label: "Price", value: "MarketPrice" },
    { label: "Underlying Market Price", value: "UnderlyingMarketPrice" },
    { label: "Net Present Value", value: "NPV" },
    { label: "Present Value", value: "PV" },
    { label: "Delta", value: "Delta" },
    { label: "Gamma", value: "Gamma" },
    { label: "Vega", value: "Vega" },
    { label: "Theta", value: "Theta" },
    { label: "Rho", value: "Rho" },
    { label: "Trade/Book currency rate", value: "TradeCurrencyFXRate" },
    { label: "Volatility", value: "UnderlyingVol" },
    { label: "Monetized Delta", value: "MonotizedDelta" },
    { label: "Monetized Gamma", value: "MonotizedGamma" },
    { label: "Monetized Theta", value: "MonotizedTheta" },
    { label: "Monetized Rho", value: "MonotizedRho" },
    { label: "Monetized Vega", value: "MonotizedVega" },
    { label: "Long Market Value", value: "LMV" },
    { label: "Short Market Value", value: "SMV" },
    { label: "Net Market Value", value: "NMV" },
    { label: "Base Net Present Value", value: "BookNPV" },
    { label: "Forward Points", value: "ForwardPoints" },
    { label: "Delta/Secondary Ccy", value: "DeltaSecondary" },
    { label: "Fair value in Primary Ccy", value: "PricePrimary" },
    { label: "Fair value in Secondary Ccy", value: "FairValueSecondary" },
    { label: "Gamma/Secondary Ccy", value: "GammaSecondary" },
    { label: "Vega/Secondary Ccy", value: "VegaSecondary" },
    { label: "Theta Secondary Ccy", value: "ThetaSecondary" },
    { label: "Hedge Amount/Primary Ccy", value: "HedgeAmountPrimary" },
    { label: "Hedge Amount/Secondary Ccy", value: "HedgeAmountSecondary" },
    { label: "Rho Primary Rate/Primary Ccy", value: "RhoPRPC" },
    { label: "Rho Seconday Rate/Primary Ccy", value: "RhoSRPC" },
    { label: "Rho Primary Rate/Secondary Ccy", value: "RhoPRSC" },
    { label: "Rho Seconday Rate/Secondary Ccy", value: "RhoSRSC" },
    { label: "Monetized Delta/Secondary Ccy", value: "MonotizedDeltaSecondary" },
    { label: "Monetized Gamma/Secondary Ccy", value: "MonotizedGammaSecondary" },
    { label: "Monetized Theta/Secondary Ccy", value: "MonotizedThetaSecondary" },
    { label: "Monetized Rho/Secondary Ccy", value: "MonotizedRhoSecondary" },
    { label: "Monetized Vega/Secondary Ccy", value: "MonotizedVegaSecondary" },
    { label: "FX Option Rate Ccy1", value: "FXOptionRateCcy1" },
    { label: "FX Option Rate Ccy2", value: "FXOptionRateCcy2" },
    { label: "FX Contribution to Unrealized", value: "FXContributionToNPV" },
    { label: "Yield to Maturity", value: "YieldToMaturity" },
    { label: "Yield to Call", value: "YieldToCall" },
    { label: "Yield to Put", value: "YieldToPut" },
    { label: "Yield to Worst", value: "YieldToWorst" },
    { label: "Yield to Best", value: "YieldToBest" },
    { label: "Yield", value: "Yield" },
    { label: "Bond Parity", value: "BondParity" },
    { label: "Bond Floor", value: "BondFloor" },
    { label: "Bond Conversion Premium", value: "BondConversionPremium" },
    { label: "Bond Investment Premium", value: "BondInvestmentPremium" },
    { label: "Bond Neutral Price", value: "BondNeutralPrice" },
    { label: "Bond Street Delta", value: "BondStreetDelta" },
    { label: "Implied Credit Spread", value: "CreditSpread" },
    { label: "Net Present Value (Fees and commissions)", value: "TotalNPV" },
    {
        label: "$ Net Present Value (Fees and commissions)",
        value: "BookTotalNPV",
    },
    { label: "Book Monotized Delta", value: "BookMonotizedDelta" },
    { label: "Book Monotized Gamma", value: "BookMonotizedGamma" },
    { label: "Book Monotized Theta", value: "BookMonotizedTheta" },
    { label: "Book Monotized Rho", value: "BookMonotizedRho" },
    { label: "Book Monotized Vega", value: "BookMonotizedVega" },
    {
        label: "Book Monotized Delta/Secondary Ccy",
        value: "BookMonotizedDeltaSecondary",
    },
    {
        label: "Book Monotized Gamma/Secondary Ccy",
        value: "BookMonotizedGammaSecondary",
    },
    {
        label: "Book Monotized Theta/Secondary Ccy",
        value: "BookMonotizedThetaSecondary",
    },
    {
        label: "Book Monotized Rho/Secondary Ccy",
        value: "BookMonotizedRhoSecondary",
    },
    {
        label: "Book Monotized Vega/Secondary Ccy",
        value: "BookMonotizedVegaSecondary",
    },
    {
        label: "Trade to Quote Currency Rate",
        value: "TradeToQuoteCurrencyFXRate",
    },
    { label: "Bond Ref. price", value: "BondReferencePrice" },
    { label: "Underlying Ref. price", value: "UnderlyingReferencePrice" },
    { label: "Reference Credit Spread", value: "ReferenceCreditSpread" },
    { label: "Reference Volatility", value: "ReferenceVolatility" },
    { label: "Fair Value", value: "FairValue" },
    { label: "Reference Parity Spread", value: "ReferenceBondParitySpread" },
    { label: "Parity Price", value: "ParityPrice" },
    { label: "Implied Volatility", value: "ImpliedVolatility" },
    { label: "Active Coupon Rate", value: "ActiveCouponRate" },
    {
        label: "$ Long Leverage Contribution",
        value: "BookLongLeverageContribution",
    },
    {
        label: "$ Short Leverage Contribution",
        value: "BookShortLeverageContribution",
    },
    {
        label: "$ Net Leverage Contribution",
        value: "BookNetLeverageContribution",
    },
    { label: "Long Leverage Contribution", value: "LongLeverageContribution" },
    { label: "Short Leverage Contribution", value: "ShortLeverageContribution" },
    { label: "Net Leverage Contribution", value: "NetLeverageContribution" },
    { label: "Duration", value: "Duration" },
    { label: "Modified Duration", value: "ModifiedDuration" },
    { label: "Convexity", value: "Convexity" },
    { label: "Accrued Interest", value: "AccruedInterest" },
    { label: "$ Accrued Interest", value: "BookAccruedInterest" },
    { label: "TRS Accrued Interest", value: "TRSAccruedInterest" },
    { label: "$ TRS Accrued Interest", value: "BookTRSAccruedInterest" },
    { label: "TRS Accrual Days", value: "TRSAccrualDays" },
    {
        label: "$ Gross Leverage Contribution",
        value: "BookGrossLeverageContribution",
    },
    { label: "Gross Leverage Contribution", value: "GrossLeverageContribution" },
    { label: "Gross Market Value", value: "GMV" },
    { label: "Dirty Long Market Value", value: "DirtyLMV" },
    { label: "Dirty Short Market Value", value: "DirtySMV" },
    { label: "Dirty Net Market Value", value: "DirtyNMV" },
    { label: "Model Credit Spread", value: "ModelCreditSpread" },
    { label: "Model Volatility", value: "ModelVolatility" },
    { label: "TRS Current Reset Rate", value: "TRSCurrentResetRate" },
    { label: "CCy1/Book currency rate", value: "CCy1CurrencyFXRate" },
    { label: "CCy2/Book currency rate", value: "CCy2CurrencyFXRate" },
    { label: "Sensitivity to 1% move in CS", value: "CreditSpread1Percent" },
    {
        label: "Monetized sensitivity to 1% move in CS",
        value: "MonetizedCS1Percent",
    },
    {
        label: "$ Monetized sensitivity to 1% move in CS",
        value: "BookMonetizedCS1Percent",
    },
    { label: "Implied Forward Points", value: "ImpliedForwardPoints" },
    { label: "Accrual Days", value: "AccrualDays" },
    {
        label: "Reference Initial Volatility",
        value: "ReferenceInitialVolatility",
    },
    {
        label: "Reference Long Term Volatility",
        value: "ReferenceLongTermVolatility",
    },
    {
        label: "Reference Speed of Mean Reversion",
        value: "ReferenceSpeedOfMeanReversion",
    },
    {
        label: "Reference Volatility of Volatility",
        value: "ReferenceVolatilityOfVolatility",
    },
    {
        label: "Reference Realized Volatility",
        value: "ReferenceRealizedVolatility",
    },
    { label: "Initial Volatility", value: "InitialVolatility" },
    { label: "Long Term Volatility", value: "LongTermVolatility" },
    { label: "Speed of Mean Reversion", value: "SpeedOfMeanReversion" },
    { label: "Volatility of Volatility", value: "VolatilityOfVolatility" },
    { label: "Realized Volatility", value: "RealizedVolatility" },
    { label: "Call Cushion", value: "ReferenceCallCushion" },
    { label: "Reference Borrow Rate", value: "ReferenceBorrowRate" },
    { label: "Fugit", value: "Fugit" },
    { label: "Bond Investment Value", value: "BondInvestmentValue" },
    { label: "Equity Downside", value: "EquityDownside" },
    { label: "Equity Upside", value: "EquityUpside" },
    { label: "Delta Offset", value: "DeltaOffset" },
    { label: "Cheapest to deliver factor", value: "CTDFactor" },
    { label: "Reference Dividend Yield", value: "ReferenceDividendYield" },
    { label: "Dividend Yield", value: "DividendYield" },
    { label: "Historical Volatility", value: "HistoricalVolatility" },
    {
        label: "Reference FX Option Rate Ccy1",
        value: "ReferenceFXOptionRateCcy1",
    },
    {
        label: "Reference FX Option Rate Ccy2",
        value: "ReferenceFXOptionRateCcy2",
    },
    { label: "Forward Outright", value: "ForwardOutright" },
    {
        label: "Underlying Price Used In FX Option",
        value: "FXUnderlyingModelPrice",
    },
    { label: "Par Swap Rate", value: "ParSwapRate" },
    { label: "Forward Delta Premium", value: "ForwardDeltaPremium" },
    { label: "Delta Premium", value: "DeltaPremium" },
    { label: "ATM Volatility", value: "ATMVolatility" },
    { label: "Monetized Delta Premium", value: "MonetizedDeltaPremium" },
    {
        label: "Monetized Forward Delta Premium",
        value: "MonetizedForwardDeltaPremium",
    },
    { label: "$ Dirty Long Market Value", value: "BookDirtyLMV" },
    { label: "$ Dirty Short Market Value", value: "BookDirtySMV" },
    { label: "$ Dirty Net Market Value", value: "BookDirtyNMV" },
    { label: "$ Monetized Delta Premium", value: "BookMonetizedDeltaPremium" },
    {
        label: "$ Monetized Forward Delta Premium",
        value: "BookMonetizedForwardDeltaPremium",
    },
    { label: "$ Long Market Value", value: "BookLMV" },
    { label: "$ Short Market Value", value: "BookSMV" },
    { label: "$ Net Market Value", value: "BookNMV" },
    { label: "Spread Duration", value: "SpreadDuration" },
    { label: "Model IR Volatility", value: "ModelInterestRateVolatility" },
    {
        label: "Reference IR Volatility",
        value: "ReferenceInterestRateVolatility",
    },
    { label: "Spread Convextity", value: "SpreadConvexity" },
    { label: "OAS", value: "OAS" },
    { label: "Beta", value: "Beta" },
    {
        label: "Fixed Leg Lifetime Accrual Days",
        value: "SwapFixedLegLifetimeAccrualDays",
    },
    {
        label: "Fixed Leg Lifetime Accrual Factor",
        value: "SwapFixedLegLifetimeAccrualFactor",
    },
    {
        label: "Fixed Leg Future Notional Value",
        value: "SwapFixedLegFutureNotionalValue",
    },
    { label: "CCy2 Discount Factor", value: "SecondaryCurrencyDiscountFactor" },
    {
        label: "CCy2/USD Forward Outright",
        value: "SecondaryCurrencyToUSDForwardOutright",
    },
    { label: "Calculated Underlying Price", value: "CalculatedUnderlyingPrice" },
    { label: "Reference EPS", value: "ReferenceEPS" },
    { label: "Reference Multiple", value: "ReferenceMultiple" },
    { label: "Entry Price", value: "EntryPrice" },
    { label: "VWAP", value: "VWAP" },
    { label: "Reference Delta", value: "ReferenceDelta" },
    { label: "Price As Rate", value: "PriceAsRate" },
    {
        label: "TRS Reference Instrument FairValue",
        value: "TRSReferenceInstrumentFairValue",
    },
    { label: "Dirty Fair Value", value: "DirtyFairValue" },
    { label: "Underlying Dirty Fair Value", value: "UnderlyingDirtyFairValue" },
    { label: "Reference Volatility Offset", value: "ReferenceVolatilityOffset" },
    { label: "Reference Recovery Rate", value: "ReferenceRecoveryRate" },
    { label: "Model Recovery Rate", value: "ModelRecoveryRate" },
    { label: "Dirty Unwind Fair Value", value: "DirtyUnwindFairValue" },
    { label: "Reference Unwind Rate", value: "ReferenceUnwindRate" },
    { label: "Model Unwind Rate", value: "ModelUnwindRate" },
    { label: "Unwind Present Value", value: "UnwindPV" },
    { label: "Reference Rho", value: "ReferenceRho" },
    { label: "Reference Vega", value: "ReferenceVega" },
    { label: "Reference Theta", value: "ReferenceTheta" },
    { label: "Reference Gamma", value: "ReferenceGamma" },
    { label: "Reference Duration", value: "ReferenceDuration" },
    { label: "Reference Volatility 2", value: "ReferenceVolatility2" },
    {
        label: "Reference Correlation Asset 1 to Asset 2",
        value: "ReferenceCorrelation1to2",
    },
    { label: "Rho/Secondary Ccy", value: "RhoSecondary" },
    { label: "Historical Volatility 2", value: "HistoricalVolatility2" },
    { label: "Dividend Yield 2", value: "DividendYield2" },
    { label: "Model Volatility Offset", value: "ModelVolatilityOffset" },
    { label: "Implied Volatility Offset", value: "ImpliedVolatilityOffset" },
    { label: "Reference Rate Differential", value: "ReferenceRateDifferential" },
    { label: "Reference Yield", value: "ReferenceYield" },
    { label: "Reference Yield to Maturity", value: "ReferenceYieldToMaturity" },
    { label: "Reference Yield to Worst", value: "ReferenceYieldToWorst" },
    { label: "Reference Yield to Best", value: "ReferenceYieldToBest" },
    { label: "Reference Yield to Call", value: "ReferenceYieldToCall" },
    { label: "Reference Yield to Put", value: "ReferenceYieldToPut" },
    {
        label: "Underlying FX Unrealized Contribution",
        value: "UnderlyingFXContributionToNPV",
    },
    { label: "Equity Downside 2", value: "EquityDownside2" },
    { label: "Equity Upside 2", value: "EquityUpside2" },
    { label: "Reference Par Swap Rate", value: "ReferenceParSwapRate" },
    {
        label: "FX Adjustment to TRS NPV",
        value: "TRSFXContributionNPVAdjustment",
    },
    { label: "Model Cheapest to deliver factor", value: "ModelCTDFactor" },
    { label: "Active Inflation Index", value: "ActiveInflationIndex" },
    { label: "Reference Inflation Rate", value: "ReferenceInflationRate" },
    { label: "Short Interest", value: "ShortInterest" },
    {
        label: "Short Interest % Equity Float",
        value: "ShortInterestPercentEquityFloat",
    },
    { label: "Target Price", value: "TargetPrice" },
    { label: "Target Price Probability", value: "TargetPriceProbability" },
    { label: "Equity Downside Probability", value: "EquityDownsideProbability" },
    { label: "Equity Upside Probability", value: "EquityUpsideProbability" },
    { label: "Market Capitalization", value: "MarketCapitalization" },
    { label: "Duration To Maturity", value: "DurationToMaturity" },
    {
        label: "Modified Duration To Maturity",
        value: "ModifiedDurationToMaturity",
    },
    { label: "Collateral Price", value: "CollateralPrice" },
    { label: "Future Spread", value: "FutureSpread" },
    { label: "Rho To Next Call Date", value: "RhoToNextCallDate" },
    { label: "Credit Spread 1% To Next Call Date", value: "CS1ToNextCallDate" },
    {
        label: "Weighted Average Maturity Years",
        value: "WeightedAverageMaturityYears",
    },
    {
        label: "Book Monetized Underlying Exposure",
        value: "BookMonetizedUnderlyingExposure",
    },
    { label: "Yield to Average Life", value: "YieldToAverageLife" },
    {
        label: "Modified Duration to Average Life",
        value: "ModifiedDurationToAverageLife",
    },
    { label: "Convexity to Average Life", value: "ConvexityToAverageLife" },
    { label: "Weighted Average Life", value: "WeightedAverageLife" },
    { label: "Custom Asset Measure 1", value: "CustomAssetMeasure1" },
    { label: "Custom Asset Measure 2", value: "CustomAssetMeasure2" },
    { label: "Custom Asset Measure 3", value: "CustomAssetMeasure3" },
    { label: "Custom Asset Measure 4", value: "CustomAssetMeasure4" },
    { label: "Custom Asset Measure 5", value: "CustomAssetMeasure5" },
    { label: "Custom Asset Measure 6", value: "CustomAssetMeasure6" },
    { label: "Custom Asset Measure 7", value: "CustomAssetMeasure7" },
    { label: "Custom Asset Measure 8", value: "CustomAssetMeasure8" },
    { label: "Custom Asset Measure 9", value: "CustomAssetMeasure9" },
    { label: "Custom Asset Measure 10", value: "CustomAssetMeasure10" },
    { label: "Custom Asset Measure 11", value: "CustomAssetMeasure11" },
    { label: "Custom Asset Measure 12", value: "CustomAssetMeasure12" },
    { label: "Custom Asset Measure 13", value: "CustomAssetMeasure13" },
    { label: "Custom Asset Measure 14", value: "CustomAssetMeasure14" },
    { label: "Custom Asset Measure 15", value: "CustomAssetMeasure15" },
    { label: "Custom Asset Measure 16", value: "CustomAssetMeasure16" },
    { label: "Custom Asset Measure 17", value: "CustomAssetMeasure17" },
    { label: "Custom Asset Measure 18", value: "CustomAssetMeasure18" },
    { label: "Custom Asset Measure 19", value: "CustomAssetMeasure19" },
    { label: "Custom Asset Measure 20", value: "CustomAssetMeasure20" },
    { label: "Reference Delta/Secondary Ccy", value: "ReferenceDeltaSecondary" },
    { label: "Reference Gamma/Secondary Ccy", value: "ReferenceGammaSecondary" },
    { label: "Reference Rho/Secondary Ccy", value: "ReferenceRhoSecondary" },
    { label: "Reference Theta/Secondary Ccy", value: "ReferenceThetaSecondary" },
    { label: "Reference Vega/Secondary Ccy", value: "ReferenceVegaSecondary" },
    { label: "IFRS9 NPV", value: "IFRS9NPV" },
    { label: "Book IFRS9 NPV", value: "BookIFRS9NPV" },
    { label: "IFRS9 FX Contribution", value: "IFRS9FXContribution" },
    { label: "IFRS9 Unrealized Adjustment", value: "IFRS9UnrealizedAdjustment" },
    {
        label: "Book IFRS9 Unrealized Adjustment",
        value: "BookIFRS9UnrealizedAdjustment",
    },
    {
        label: "$ Dirty Net Leverage Contribution",
        value: "BookDirtyNetLeverageContribution",
    },
    {
        label: "Dirty Net Leverage Contribution",
        value: "DirtyNetLeverageContribution",
    },
    {
        label: "Reference Correlation Price to Volatility",
        value: "ReferenceCorrelationPriceToVolatility",
    },
    {
        label: "$ Notional Quantity Exposure",
        value: "BookNotionalQuantityExposure",
    },
    { label: "Notional Quantity Exposure", value: "NotionalQuantityExposure" },
    { label: "Bond Floor to Put", value: "BondFloorToPut" },
];
export const ViewModeOptions = [
    { label: "Horizontal", value: "horizontal" },
    { label: "Vertical", value: "vertical" },
];
