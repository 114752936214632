import { faBuilding } from "@fortawesome/pro-solid-svg-icons";
export const accountSelection = {
    name: "Account Selection",
    minSize: [5, 1],
    icon: faBuilding,
    description: "Choose account(s)",
    defaultConfig: {
        defaultValue: null,
        key: "Account",
    },
    type: "input",
    category: "dashboard",
};
