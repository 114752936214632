import { createTestId } from "@enfusion-ui/utils";
import { styled, useTheme } from "@enfusion-ui/web-core";
import { faCog } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { kebabCase, noop } from "lodash";
import * as React from "react";
import { IconButton } from "../control";
import { Portal } from "../portal";
export const NavBarContext = React.createContext({
    sections: [],
    selectedIndex: -1,
    onSelection: noop,
    closeNavBarTabOnMobile: noop,
    onSave: noop,
});
export function useNavBarState() {
    const context = React.useContext(NavBarContext);
    if (context === undefined) {
        throw new Error("useNavBarState must be used within an NavBarProvider");
    }
    return context;
}
export const NAV_BAR_SIZE = 50;
//#region Styled Elements
const NavBarContainer = styled.div `
  display: flex;
  width: ${({ horizontal }) => (horizontal ? "100%" : `${NAV_BAR_SIZE}px`)};
  height: ${({ horizontal }) => (horizontal ? `${NAV_BAR_SIZE}px` : "100%")};
  flex-direction: ${({ horizontal }) => (horizontal ? "row" : "column")};
  background-color: var(--background-accent);
  ${({ horizontal }) => horizontal
    ? "border-top: 1px solid var(--border)"
    : "border-right: 1px solid var(--border)"};
  overflow: hidden;
  ${({ horizontal }) => (horizontal ? "overflow-x: auto !important;" : "")}
`;
const NavBarTop = styled.div `
  display: flex;
  flex: 1;
  ${(args) => args.horizontal ? "height: 50px;" : "width: 50px;"};
  flex-direction: ${(args) => args.horizontal ? "row" : "column"};
  justify-content: flex-start;
  align-items: center;
`;
const NavBarBottom = styled.div `
  display: flex;
  flex: 1;
  ${(args) => args.horizontal ? "height: 50px;" : "width: 50px;"};
  flex-direction: ${(args) => args.horizontal ? "row" : "column"};
  justify-content: flex-end;
  align-items: center;
`;
const NavBarAction = styled.div `
  ${(args) => args.horizontal ? "height: 50px;" : "width: 50px;"};
  ${(args) => `${args.horizontal ? "border-top:  2px solid" : "border-left: 2px solid"} ${args.active ? "var(--primary)" : "transparent"}`};
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  padding: ${(args) => args.horizontal ? "0px 1rem" : "1rem 0px"};

  :hover {
    border-color: ${({ theme }) => theme.key === "light" ? "var(--text-inverted)" : "var(--text-normal)"};
  }
`;
const SettingsBottomAction = styled(IconButton) `
  ${(args) => args.horizontal === "true" ? "height: 50px;" : "width: 50px;"};
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  padding: ${(args) => args.horizontal === "true" ? "0px 1rem" : "1rem 0px"};
  outline: none !important;
  background-color: transparent;
  border-style: none;
  border-color: var(--background-accent);
`;
export const NavBarSettingsBottomItem = styled.div `
  ${({ horizontal }) => (horizontal ? "height: 50px;" : "width: 50px;")};
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  padding: ${({ horizontal }) => (horizontal ? "0px 1rem" : "1rem 0px")};
  outline: none !important;
  background-color: transparent;
  border-style: none;
  border-color: ${({ theme }) => theme.key === "light" ? "var(--text-normal)" : "var(--background-accent)"};
`;
const SettingsBottomMenuPortal = styled(Portal) `
  background: var(--background-color-0) !important;
`;
export const NavBar = ({ horizontal = false, style, bottomContent, cogMenuContent, }) => {
    const { sections, selectedIndex, onSelection } = useNavBarState();
    const { theme } = useTheme();
    const [menuOpen, setMenuOpen] = React.useState(false);
    const menuIconRef = React.useRef(null);
    const handleActionClick = (idx) => () => {
        const nextIdx = selectedIndex === idx ? -1 : idx;
        onSelection(nextIdx);
    };
    const closeMenu = () => setTimeout(() => setMenuOpen(false), 300);
    const openMenu = (e) => {
        e.preventDefault();
        e.stopPropagation();
        setTimeout(() => setMenuOpen(true), 300);
    };
    return (React.createElement(React.Fragment, null,
        React.createElement(NavBarContainer, { style: style, horizontal: horizontal },
            React.createElement(NavBarTop, { horizontal: horizontal }, sections.map((section, idx) => (React.createElement(NavBarAction, { key: section.name, title: section.name, active: selectedIndex === idx && section.canSelect !== false, horizontal: horizontal, onClick: handleActionClick(idx), "data-tutorial-step": section.tutorial
                    ? `${section.tutorial}__${section.name.toLowerCase()}-tab`
                    : undefined, "data-testid": `navbar-action-${kebabCase(section.name)}` }, section.iconDef ? (React.createElement(FontAwesomeIcon, { icon: section.iconDef, size: "lg", color: theme.key === "dracula"
                    ? "var(--tab-icon-color-0)"
                    : "var(--text-normal)" })) : (section.icon))))),
            React.createElement(NavBarBottom, { horizontal: horizontal },
                bottomContent,
                React.createElement(SettingsBottomAction, { horizontal: horizontal.toString(), icon: faCog, ref: menuIconRef, size: "lg", color: theme.key === "dracula"
                        ? "var(--tab-icon-color-0)"
                        : "var(--text-normal)", "data-testid": createTestId("navbar-config-icon"), onClick: openMenu }),
                React.createElement(SettingsBottomMenuPortal, { open: menuOpen, attachedRef: menuIconRef, onClickOutside: closeMenu, align: "left" }, cogMenuContent)))));
};
