import * as React from "react";
import { useRefCallback } from "../useRefCallback";
import { createCoreBroadcastChannel } from "./useBroadcastChannel";
export const LOCAL_STORAGE_CHANNEL_NAME = "ef-ls-channel";
export const LOCAL_STORAGE_CHANNEL = createCoreBroadcastChannel(LOCAL_STORAGE_CHANNEL_NAME, { webWorkerSupport: false });
const noopStore = {
    getItem: () => {
        throw new Error("storage hooks only work with window globals");
    },
    setItem: () => {
        throw new Error("storage hooks only work with window globals");
    },
};
function createStorageHook(store, prefix) {
    function getStorageValue(key, defaultValue, isStringValue) {
        const item = store.getItem(key);
        try {
            return item !== null ? JSON.parse(item) : defaultValue;
        }
        catch (error) {
            console.error(error);
            return isStringValue ? item : defaultValue;
        }
    }
    return function useStorage(key, initialValue) {
        const [storedValue, setValueInner] = React.useState(() => getStorageValue(key, initialValue, typeof initialValue === "string"));
        const setStoredValue = useRefCallback(() => {
            const newValue = getStorageValue(key, initialValue, typeof initialValue === "string");
            setValueInner(newValue);
        }, [key, initialValue]);
        React.useEffect(() => {
            setStoredValue();
        }, [key]);
        const handleMessage = useRefCallback((ev) => {
            if (ev === `${prefix}-${key}`)
                setStoredValue();
        }, [key]);
        React.useEffect(() => {
            return LOCAL_STORAGE_CHANNEL.subscribe(handleMessage);
        }, []);
        const setValue = useRefCallback((value) => {
            try {
                const valueToStore = value instanceof Function ? value(storedValue) : value;
                if (value === null || value === undefined) {
                    store.removeItem(key);
                }
                else {
                    store.setItem(key, JSON.stringify(valueToStore));
                }
                setStoredValue();
                LOCAL_STORAGE_CHANNEL.postMessage(`${prefix}-${key}`, handleMessage);
            }
            catch (error) {
                console.error(error);
            }
        }, [key, storedValue]);
        return [storedValue, setValue];
    };
}
export const useLocalStorage = createStorageHook(typeof window !== "undefined" ? window.localStorage : noopStore, "ls");
export const useSessionStorage = createStorageHook(typeof window !== "undefined" ? window.sessionStorage : noopStore, "ss");
