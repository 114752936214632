import { faHashtag } from "@fortawesome/pro-solid-svg-icons";
export const numericInput = {
    name: "Numeric Input",
    minSize: [5, 1],
    icon: faHashtag,
    description: "Display a dynamic numeric input",
    defaultConfig: {
        defaultValue: null,
        key: "Numeric Input",
    },
    type: "input",
    category: "dashboard",
};
