import { ConfirmationModal } from "@enfusion-ui/web-components";
import { TabsProviderBase, useTabs, } from "@enfusion-ui/web-core";
import * as React from "react";
function ConfirmReopenModal() {
    const { showUndockConfirmation, reUndockTabs, closeAllUndocked } = useTabs();
    return (React.createElement(ConfirmationModal, { open: showUndockConfirmation, title: "Reopen Popped Out Windows?", onCancel: closeAllUndocked, onSubmit: reUndockTabs, submitActionTheme: "primary" }));
}
export const TabsProvider = ({ children, ...rest }) => {
    return (React.createElement(TabsProviderBase, { ...rest },
        children,
        React.createElement(ConfirmReopenModal, null)));
};
