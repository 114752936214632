/* eslint-disable @typescript-eslint/no-explicit-any */
import { EMPTY_RECORD } from "@enfusion-ui/core";
import { useMounted, useRefCallback } from "@enfusion-ui/hooks"; // TODO use a platform-agnostic way to do broadcast channels
import { debounce, isEqual, pick } from "lodash";
import * as React from "react";
import { DASHBOARD_CONTEXT_CHANNEL, DASHBOARD_CONTEXT_REFRESH, DASHBOARD_CONTEXT_REVERT, useDashboard, } from "../core/context";
import { WIDGET_DEFINITIONS } from "../widgets/definitions";
import { WidgetContext, } from "./context";
//#endregion
function reducer(state, action) {
    if (action.type === "update") {
        return {
            ...state,
            [action.payload.key]: action.payload.value,
        };
    }
    else if (action.type === "update-config") {
        return {
            ...state,
            config: {
                ...(state.config || {}),
                [action.payload.key]: action.payload.value,
            },
        };
    }
    else if (action.type === "revert") {
        return action.payload;
    }
    return state;
}
const cleanConfig = (unsavedConfig) => {
    const config = { ...unsavedConfig };
    if (config.columns) {
        config.columns = [...config.columns.filter((i) => i.value)];
    }
    if (config.categories) {
        config.categories = [...config.categories.filter((i) => i.value)];
    }
    if (config.series) {
        config.series = [...config.series.filter((i) => i.value)];
    }
    return config;
};
export const Widget = (props) => {
    const { filePath, updateChannelData, updateWidget, removeChannelKeys: removeChKeys, addChannelKeys: addChKeys, oldConfig, } = useDashboard();
    const [state, dispatchBase] = React.useReducer(reducer, props);
    const [editing, setEditing] = React.useState(false);
    const [unsavedConfig, setUnsavedConfig] = React.useState(EMPTY_RECORD);
    const [unsavedProperties, setUnsavedProperties] = React.useState(EMPTY_RECORD);
    const isMounted = useMounted();
    const oldStateRef = React.useRef(null);
    const widgetDef = WIDGET_DEFINITIONS[props.type];
    const [widgetActions, addWidgetActions] = React.useState(null);
    const dispatch = React.useCallback((action) => {
        if (isMounted())
            dispatchBase(action);
    }, [dispatchBase, isMounted]);
    const handleMessage = useRefCallback((msg) => {
        if (msg.path === filePath.replace(/\//g, "-")) {
            if (msg.action === DASHBOARD_CONTEXT_REFRESH ||
                msg.action === DASHBOARD_CONTEXT_REVERT) {
                revertConfigAndProperties();
            }
        }
    }, [dispatch, props]);
    React.useEffect(() => {
        return DASHBOARD_CONTEXT_CHANNEL?.subscribe(handleMessage);
    }, []);
    const updateWidgetDef = useRefCallback(() => {
        if (isMounted() &&
            oldStateRef.current !== null &&
            !isEqual(oldStateRef.current, state)) {
            updateWidget(state.gridId, state.id, pick(state, ["config", "properties"]));
        }
        oldStateRef.current = state;
    }, [state, updateWidget, oldStateRef.current]);
    const updateWidgetDefDebounced = React.useCallback(debounce(() => {
        updateWidgetDef();
    }, 1500), []);
    React.useEffect(() => {
        updateWidgetDefDebounced();
    }, [state]);
    const changeConfig = React.useCallback((config) => {
        dispatch({ type: "update", payload: { key: "config", value: config } });
    }, [dispatch]);
    React.useEffect(() => {
        changeConfig(props.config);
    }, [JSON.stringify(props.config)]);
    const changeProperties = React.useCallback((properties) => {
        dispatch({
            type: "update",
            payload: { key: "properties", value: properties },
        });
    }, [dispatch]);
    const applyUnsavedChanges = React.useCallback(() => {
        changeConfig({ ...state.config, ...cleanConfig(unsavedConfig) });
        revertUnsavedConfigChanges();
    }, [state.config, unsavedConfig]);
    const applyUnsavedProperties = React.useCallback(() => {
        changeProperties({ ...state.properties, ...unsavedProperties });
        revertUnsavedProperties();
    }, [state.properties, unsavedProperties]);
    const revertConfigAndProperties = () => {
        const widget = oldConfig[state.gridId].widgets.find((eachWidget) => {
            return state.id === eachWidget.id;
        });
        changeConfig(widget?.config || EMPTY_RECORD);
        changeProperties(widget?.properties || EMPTY_RECORD);
        revertUnsavedConfigChanges();
        revertUnsavedProperties();
    };
    const changeConfigKey = React.useCallback((key, value) => {
        dispatch({
            type: "update-config",
            payload: { key, value },
        });
    }, [dispatch]);
    const changeConfigKeyToBeApplied = useRefCallback((key, value) => {
        setUnsavedConfig((unsavedConfig) => ({ ...unsavedConfig, [key]: value }));
    }, []);
    const changePropertiesToBeApplied = (key, value) => {
        setUnsavedProperties((unsavedProperties) => ({
            ...unsavedProperties,
            [key]: value,
        }));
    };
    const changeConfigToBeApplied = (newConfig) => {
        setUnsavedConfig(newConfig);
    };
    const revertUnsavedConfigChanges = () => setUnsavedConfig(EMPTY_RECORD);
    const revertUnsavedProperties = () => setUnsavedProperties(EMPTY_RECORD);
    const setChannelData = React.useCallback((data) => {
        const currentData = data.reduce((acc, value, idx) => {
            return { ...acc, [`${state.id}-${idx}`]: value };
        }, {});
        updateChannelData(currentData);
    }, [state.id]);
    const removeChannelKeys = React.useCallback(() => {
        removeChKeys(state.id);
    }, [state.id]);
    const addChannelKeys = React.useCallback((keys) => {
        const indexed = keys.map((key, idx) => ({ ...key, key: idx }));
        addChKeys(state.id, indexed);
    }, [state.id]);
    React.useEffect(() => {
        return () => removeChannelKeys();
    }, []);
    return (React.createElement(WidgetContext.Provider, { value: {
            ...state,
            editing,
            widgetDef,
            editedConfig: { ...state.config, ...unsavedConfig },
            unsavedConfig,
            widgetActions,
            setEditing,
            changeConfig,
            changeConfigKeyToBeApplied,
            changeConfigToBeApplied,
            setChannelData,
            removeChannelKeys,
            addChannelKeys,
            addWidgetActions,
            applyUnsavedChanges,
            revertUnsavedConfigChanges,
            changeConfigKey,
            revertConfigAndProperties,
            changePropertiesToBeApplied,
            applyUnsavedProperties,
            editedProperties: { ...state.properties, ...unsavedProperties },
            revertUnsavedProperties,
            unsavedProperties,
        } }, props?.renderWidgetContent(props.type, widgetDef)));
};
