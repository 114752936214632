/* eslint-disable @typescript-eslint/no-explicit-any */
import { styled } from "@enfusion-ui/web-core";
const getDefaultControlStyles = (minWidth, hasError = false) => (provided, state) => {
    const borderColor = hasError
        ? "var(--danger)"
        : state.isFocused
            ? "var(--primary)"
            : "var(--input-border)";
    return {
        ...provided,
        "&:hover": {
            border: `1px solid ${borderColor}`,
        },
        color: "var(--text-normal)",
        backgroundColor: state.isDisabled
            ? "var(--background-color-0)"
            : "var(--input-background)",
        borderWidth: "1px",
        borderStyle: "solid",
        borderColor,
        fontSize: "0.875em",
        outline: "none",
        pointerEvents: state.isDisabled ? "none" : "all",
        minWidth: minWidth,
    };
};
export const selectStyles = {
    container: (provided, state) => ({
        ...provided,
        cursor: state.isDisabled ? "not-allowed" : "pointer",
        pointerEvents: "all",
    }),
    dropdownIndicator: (provided, state) => ({
        ...provided,
        "&:hover": {
            color: state.isDisabled
                ? "var(--text-muted)"
                : state.isFocused
                    ? "var(--text-muted)"
                    : "var(--text-color-1)",
        },
        color: state.isDisabled
            ? "var(--text-muted)"
            : state.isFocused
                ? "var(--text-color-1)"
                : "var(--text-normal)",
    }),
    indicatorSeparator: (provided, state) => ({
        ...provided,
        "&:hover": {
            backgroundColor: state.isDisabled
                ? "var(--text-muted)"
                : state.isFocused
                    ? "var(--text-muted)"
                    : "var(--text-color-1)",
        },
        backgroundColor: state.isDisabled
            ? "var(--text-muted)"
            : state.isFocused
                ? "var(--text-color-1)"
                : "var(--text-normal)",
    }),
    input: (provided, state) => ({
        ...provided,
        color: state.isHidden ? "transparent" : "var(--text-normal)",
    }),
    singleValue: (provided, state) => ({
        ...provided,
        color: state.isDisabled ? "var(--text-muted)" : "var(--text-normal)",
    }),
    valueContainer: (provided) => ({
        ...provided,
        flexWrap: "nowrap",
    }),
    multiValueLabel: (provided, state) => ({
        ...provided,
        color: state.isDisabled ? "var(--text-muted)" : "var(--text-normal)",
    }),
    multiValueRemove: (provided, state) => ({
        ...provided,
        color: state.isDisabled ? "var(--text-muted)" : "var(--text-normal)",
        ":hover": {
            backgroundColor: "var(--primary-hover)",
            color: "var(--text-hover)",
        },
    }),
    menu: (provided) => ({
        ...provided,
        backgroundColor: "var(--input-background)",
    }),
    option: (provided, state) => ({
        ...provided,
        backgroundColor: state.isFocused
            ? "var(--primary)"
            : "var(--input-background)",
        color: state.isDisabled ? "var(--text-muted)" : "var(--text-normal)",
        fontSize: "0.875em",
        cursor: "pointer",
        ":active": {
            backgroundColor: "var(--primary-hover)",
        },
    }),
    menuPortal: (provided) => ({ ...provided, zIndex: 9999 }),
};
export const selectTheme = (theme) => ({
    ...theme,
    spacing: {
        ...theme.spacing,
        borderRadius: 4,
        baseUnit: 4,
        controlHeight: 16,
        menuGutter: 1,
    },
});
export const getSelectStyles = (minWidth, hasError) => {
    return {
        ...selectStyles,
        control: getDefaultControlStyles(minWidth || 200, hasError),
    };
};
export const SelectContainer = styled.div `
  display: flex;
  align-items: center;
  flex: 1;
  gap: var(--spacing-l);
`;
