import { faSearchDollar } from "@fortawesome/pro-solid-svg-icons";
export const accountingMethodSelection = {
    name: "Accounting Method Selection",
    minSize: [5, 1],
    icon: faSearchDollar,
    description: "Choose accounting method",
    defaultConfig: {
        defaultValue: null,
        key: "Accounting Method",
    },
    type: "input",
    category: "dashboard",
};
