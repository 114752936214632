export function getCookies() {
    return document.cookie.split("; ").reduce((acc, cookie) => {
        const [key, ...value] = cookie.split("=");
        acc[key] = value.join("=");
        return acc;
    }, {});
}
export function setCookies(cookies) {
    Object.keys(cookies).forEach((key) => {
        document.cookie = `${key}=${cookies[key]}`;
    });
}
