import { styled } from "@enfusion-ui/web-core";
import * as React from "react";
const Svg = styled.svg `
  display: inline-block;
  font-size: inherit;
  overflow: visible;
`;
export const FactSetFIcon = ({ width, height, color = "rgb(0,174,239)" }) => (React.createElement(Svg, { viewBox: "0 0 1900 2900", width: width, height: height },
    React.createElement("g", { fill: color, stroke: color, strokeWidth: "1", fillRule: "evenodd" },
        React.createElement("path", { d: "M202 2877 C138 2848 109 2828 75 2788 C6 2706 9 2775 12 1444 L15 245 37 203 C64 151 119 97 168 73 C202 56 248 55 950 55 L1695 55 1741 82 C1828 133 1879 208 1887 300 C1897 414 1833 522 1722 576 L1665 605 1117 607 L570 610 567 1642 L565 2675 536 2732 C504 2798 446 2852 383 2874 C331 2893 240 2894 202 2877 " }),
        React.createElement("g", { d: "M900 1621 C852 1607 782 1561 760 1530 C688 1429 694 1311 777 1219 C847 1141 855 1139 1274 1140 C1631 1140 1635 1140 1685 1163 C1780 1208 1824 1283 1818 1395 C1815 1452 1809 1474 1787 1508 C1750 1563 1690 1606 1629 1619 C1570 1632 943 1634 900 1621 " }))));
