import { styled, useAuth } from "@enfusion-ui/web-core";
import { noop } from "lodash";
import * as React from "react";
import { Button } from "../../control";
import { Modal } from "../../modal";
import { SingleColumnGridWithGap } from "../../Styled";
const ContentContainer = styled.div `
  margin-top: 8px;
`;
const ActionContainer = styled.div `
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-column-gap: 8px;
`;
export function DuplicateLogInPage({ undocked = false, }) {
    const { terminateCurrentSession, terminateOtherSession } = useAuth();
    return (React.createElement(Modal, { isOpen: true, onClose: undocked ? noop : terminateCurrentSession, title: "Duplicated Log In", content: React.createElement(SingleColumnGridWithGap, null,
            React.createElement(ContentContainer, null,
                "A instance of the desktop client has been detected. Sections of this application can only have one instance running at at time.",
                undocked && "Please resolve the issue in the main window."),
            !undocked && (React.createElement(ActionContainer, null,
                React.createElement(Button, { onClick: terminateCurrentSession }, "Terminate This Session"),
                React.createElement(Button, { theme: "danger", onClick: terminateOtherSession }, "Terminate Other Session")))) }));
}
