export var PortfolioRevertMode;
(function (PortfolioRevertMode) {
    PortfolioRevertMode[PortfolioRevertMode["REVERT"] = 0] = "REVERT";
    PortfolioRevertMode[PortfolioRevertMode["CLOSE_TAB"] = 1] = "CLOSE_TAB";
})(PortfolioRevertMode || (PortfolioRevertMode = {}));
export const PORTFOLIO_REBALANCE_LABEL_MAP = {
    StraightRebalance: "Model",
    NormalizedRebalance: "Model Normalized",
};
export const PORTFOLIO_REBALANCE_COMMANDS = Object.keys(PORTFOLIO_REBALANCE_LABEL_MAP);
