export function calcFractionalNotationValues(value, multiplier) {
    /* Example => value: 0.952421875, multiplier: 32 */
    const percentValue = value * 100; // 95.2421875
    const integer = Math.floor(percentValue); // 95
    const decimal = percentValue - integer; // 0.2421875
    const multipliedDecimal = decimal * multiplier; // 0.2421875 * 32 = 7.75
    const numberPart = Math.floor(multipliedDecimal); // 7
    const fractionPart = multipliedDecimal - numberPart; // 0.75
    let sup;
    let sub;
    switch (fractionPart) {
        case 0:
            break;
        case 0.25:
            sup = "1";
            sub = "4";
            break;
        case 0.5:
            sup = "1";
            sub = "2";
            break;
        case 0.75:
            sup = "3";
            sub = "4";
            break;
        default:
            sup = "r";
    }
    return {
        integer: integer.toString(),
        numberPart: `${numberPart < 10 ? "0" : ""}${numberPart}`,
        sup,
        sub,
    };
}
