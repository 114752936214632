export const OEMS_FILTERS = {
    "Active Orders": {
        title: "Active Orders",
        filter: (rows) => rows.filter(({ columnValues }) => columnValues.IsActiveParent !== false),
    },
    "Active Routes": {
        title: "Active Routes",
        filter: (rows) => rows.filter(({ columnValues, completed }) => columnValues.Method !== "Undecided" && !completed),
    },
    "Completed Orders": {
        title: "Completed Orders",
        filter: (rows) => rows.filter(({ columnValues, completed }) => typeof columnValues.ParentOrderId !== "number" && completed),
    },
    "Inactive Routes": {
        title: "Inactive Routes",
        filter: (rows) => rows.filter(({ columnValues, completed }) => columnValues.Method !== "Undecided" && completed),
    },
    Alerts: {
        title: "Alerts",
        filter: (rows) => rows.filter(({ columnValues }) => !!columnValues.Status && columnValues.Status.value === "Rejected"),
    },
};
export const OEMS_DEFAULT_SELECTED_FILTER = "Active Orders";
