import { styled } from "@enfusion-ui/web-core";
import { faMinus, faTimes } from "@fortawesome/pro-solid-svg-icons";
import * as React from "react";
import { EnfusionIcon, MacExpandIcon } from "../icons";
import { closeWindow, fullscreenWindow, minimizeWindow } from "./actions";
import CircleIconButton from "./CircleIconButton";
const ActionContainer = styled.div `
  height: 23px;
  display: flex;
  flex-direction: row;
  -webkit-app-region: no-drag !important;
  -app-region: no-drag !important;
`;
const RightTitleContainer = styled.div `
  height: 20px;
  flex: 1;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  opacity: ${({ active }) => (active ? "1" : "0.5")};
  padding-left: ${({ actions }) => (actions ? "var(--spacing)" : "0px")};
  padding-right: var(--spacing);
`;
const MacActionsContainer = styled.div `
  display: flex;
  flex-direction: row;
  align-items: center;
  padding-left: 8px;
`;
const MacActions = ({ active, maximized, }) => {
    const [hasMouse, setHasMouse] = React.useState(false);
    const handleEnter = () => setHasMouse(true);
    const handleLeave = () => setHasMouse(false);
    return (React.createElement(MacActionsContainer, { onMouseEnter: handleEnter, onMouseLeave: handleLeave },
        React.createElement(CircleIconButton, { icon: faTimes, title: "close", color: "#FF605C", onClick: closeWindow, active: active, focus: hasMouse }),
        React.createElement(CircleIconButton, { style: { marginLeft: "0.5rem" }, icon: faMinus, title: "minimize", color: "#FFBD44", onClick: minimizeWindow, active: active, focus: hasMouse }),
        React.createElement(CircleIconButton, { style: { marginLeft: "0.5rem" }, iconComp: React.createElement(MacExpandIcon, null), title: maximized ? "restore" : "maximize", color: "#00CA4E", onClick: fullscreenWindow, active: active, focus: hasMouse })));
};
const MacTopBar = ({ title, active, actions, maximized, onContextMenu }) => {
    return (React.createElement(React.Fragment, null,
        actions ? (React.createElement(ActionContainer, null,
            React.createElement(MacActions, { active: active, maximized: maximized }))) : null,
        React.createElement(RightTitleContainer, { onContextMenu: onContextMenu, actions: actions, active: active }, title ? (React.createElement(React.Fragment, null,
            React.createElement(EnfusionIcon, null),
            title)) : null)));
};
export default MacTopBar;
