export var ConnectionStatus;
(function (ConnectionStatus) {
    ConnectionStatus[ConnectionStatus["CONNECTED"] = 0] = "CONNECTED";
    ConnectionStatus[ConnectionStatus["DISCONNECTED"] = 1] = "DISCONNECTED";
    ConnectionStatus[ConnectionStatus["DISABLED"] = 2] = "DISABLED";
    ConnectionStatus[ConnectionStatus["ERROR"] = 3] = "ERROR";
    ConnectionStatus[ConnectionStatus["CONNECTING"] = 4] = "CONNECTING";
    ConnectionStatus[ConnectionStatus["ERROR_RECONNECTING"] = 5] = "ERROR_RECONNECTING";
    ConnectionStatus[ConnectionStatus["RECONNECTING"] = 6] = "RECONNECTING";
    ConnectionStatus[ConnectionStatus["WAITING_ON_MARKET_DATA"] = 7] = "WAITING_ON_MARKET_DATA";
})(ConnectionStatus || (ConnectionStatus = {}));
