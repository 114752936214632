import { faSearchDollar } from "@fortawesome/pro-solid-svg-icons";
export const quoteSetIdsSelection = {
    name: "Quote Set Ids Selection",
    minSize: [5, 1],
    icon: faSearchDollar,
    description: "Choose quote set id(s)",
    defaultConfig: {
        defaultValue: null,
        key: "QuoteSet",
    },
    type: "input",
    category: "dashboard",
};
