import { useRefCallback } from "@enfusion-ui/hooks";
import { chicagoTimeZone, DatePresetOptions } from "@enfusion-ui/utils";
import { styled } from "@enfusion-ui/web-core";
import React, { useState } from "react";
import { Select } from "../Select";
const ToolTip = styled.div ``;
const Info = styled.div `
  font-size: 13px;
  font-style: italic;
  margin-top: var;
`;
const Container = styled.div `
  display: flex;
  flex: 1;
  justify-content: center;
  align-items: center;
`;
const getOption = (value, options) => {
    if (typeof value === "string") {
        return options.find((i) => i.value === value);
    }
};
export const DatePresetSelect = ({ onChange, value, timezone = chicagoTimeZone, clearable, optionSet = "value", dataTestId, ...rest }) => {
    const [tooltip, setTooltip] = useState("");
    const selectedValue = React.useMemo(() => {
        const option = value
            ? getOption(value, DatePresetOptions[optionSet])
            : null;
        setTooltip(option?.tooltip?.(timezone) || "");
        return option;
    }, [value, timezone, optionSet]);
    const handleChange = useRefCallback((selectedOption) => {
        onChange?.(selectedOption ? selectedOption.value : null);
        if (selectedOption?.tooltip)
            setTooltip(selectedOption.tooltip(timezone));
    }, [onChange, timezone]);
    return (React.createElement(Container, null,
        React.createElement(Select, { value: selectedValue, onChange: handleChange, options: DatePresetOptions[optionSet], minWidth: 130, clearable: clearable, inputId: dataTestId ?? "date-preset-selection-id", infoContent: React.createElement(React.Fragment, null,
                React.createElement(ToolTip, null, tooltip.toString()),
                React.createElement(Info, null,
                    "(",
                    timezone,
                    " timezone)")), ...rest })));
};
