import { faSearchDollar } from "@fortawesome/pro-solid-svg-icons";
export const assignmentPanel = {
    name: "Assignment Panel",
    minSize: (mobile) => (mobile ? [11, 2] : [17, 2]),
    defaultSize: [17, 10],
    maxSize: [25, 12],
    icon: faSearchDollar,
    description: "Choose assignment details",
    defaultConfig: {
        defaultValue: null,
        key: "AssignmentPanel",
    },
    type: "display",
    category: "orderForm",
};
export const portfolioManagerSelect = {
    name: "Portfolio Manager Select",
    minSize: [5, 2],
    defaultSize: [5, 3],
    maxSize: [5, 12],
    icon: faSearchDollar,
    description: "Choose portfolio manager",
    defaultConfig: {
        defaultValue: null,
        key: "PortfolioManagerSelect",
    },
    type: "input",
    category: "orderForm",
};
export const assignedTradingDeskSelect = {
    name: "Assigned Trading Desk Select",
    minSize: [5, 2],
    defaultSize: [5, 3],
    maxSize: [5, 12],
    icon: faSearchDollar,
    description: "Input compliance notes",
    defaultConfig: {
        defaultValue: null,
        key: "ComplianceNotesInput",
    },
    type: "input",
    category: "orderForm",
};
export const assignedTraderSelect = {
    name: "Assigned Trader Select",
    minSize: [5, 2],
    defaultSize: [5, 3],
    maxSize: [5, 12],
    icon: faSearchDollar,
    description: "Choose assigned trader",
    defaultConfig: {
        defaultValue: null,
        key: "AssignedTraderSelect",
    },
    type: "input",
    category: "orderForm",
};
export const complianceStateInput = {
    name: "Compliance State Input",
    minSize: [5, 2],
    defaultSize: [5, 3],
    maxSize: [5, 12],
    icon: faSearchDollar,
    description: "Input compliance state",
    defaultConfig: {
        defaultValue: null,
        key: "ComplianceStateInput",
    },
    type: "input",
    category: "orderForm",
};
export const reviewedOnInput = {
    name: "Reviewed On Input",
    minSize: [5, 2],
    defaultSize: [5, 3],
    maxSize: [5, 12],
    icon: faSearchDollar,
    description: "Input reviewed on",
    defaultConfig: {
        defaultValue: null,
        key: "ReviewedOnInput",
    },
    type: "input",
    category: "orderForm",
    webOnly: true,
};
export const reviewedByInput = {
    name: "Reviewed By Input",
    minSize: [5, 2],
    defaultSize: [5, 3],
    maxSize: [5, 12],
    icon: faSearchDollar,
    description: "Input reviewed by",
    defaultConfig: {
        defaultValue: null,
        key: "ReviewedByInput",
    },
    type: "input",
    category: "orderForm",
    webOnly: true,
};
