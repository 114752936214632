import { getSelectOption } from "@enfusion-ui/utils";
import * as React from "react";
import { Select } from "../Select";
const JUSTIFY_CONTENT_OPTIONS = [
    {
        label: "Left",
        value: "flex-start",
    },
    {
        label: "Right",
        value: "flex-end",
    },
    {
        label: "Center",
        value: "center",
    },
];
export const HorizontalAlignment = ({ label = "Align Horizontally", name, errors, selectedValue, clearable, disabled, required, onChange, minWidth, }) => {
    return (React.createElement(Select, { label: label, name: name, errors: errors, value: getSelectOption(JUSTIFY_CONTENT_OPTIONS, selectedValue), options: JUSTIFY_CONTENT_OPTIONS, onChange: onChange, clearable: clearable, required: required, disabled: disabled, minWidth: minWidth, inputId: "horizontal-alignment-selection-id" }));
};
