import queryString from "query-string";
const baseUrl = "/internal/api/holidays/holidayCenters";
export const calls = {
    GET_HOLIDAY_CENTERS: {
        url: baseUrl,
        query: {
            instrumentId: {},
            fundIds: {},
        },
    },
    GET_HOLIDAYS: {
        url: `${baseUrl}/holidays`,
        query: {
            holidayCenterIds: {},
        },
    },
};
export default (callCreator) => {
    return {
        GET_HOLIDAY_CENTERS: callCreator((instrumentId, fundIds) => queryString.stringifyUrl({
            url: calls.GET_HOLIDAY_CENTERS.url,
            query: { instrumentId, fundIds },
        })),
        GET_HOLIDAYS: callCreator((holidayCenterIds) => queryString.stringifyUrl({
            url: calls.GET_HOLIDAYS.url,
            query: { holidayCenterIds: holidayCenterIds.join(",") },
        })),
    };
};
