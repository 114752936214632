/* eslint-disable react/display-name */
import { useRefCallback } from "@enfusion-ui/hooks";
import { testId } from "@enfusion-ui/utils";
import { styled } from "@enfusion-ui/web-core";
import { faCaretDown, faCaretUp, faMinus, faPlus, } from "@fortawesome/pro-solid-svg-icons";
import isTouchDevice from "is-touch-device";
import * as React from "react";
import { useLongPress } from "use-long-press";
import { v4 } from "uuid";
import { IconButton, SelectButton } from "../../control";
import { TextInput } from "../TextInput";
import { useNumericInputLogic } from "./utils";
const StyledIconButton = styled(IconButton) `
  padding: 0;
  font-size: 1em;
`;
const DefaultControlsContainer = styled.div `
  display: flex;
  flex-direction: column;
  justify-content: center;
`;
const BigControlsContainer = styled.div `
  display: flex;
  justify-content: center;
  padding-right: 1px;
`;
const BigButton = styled(SelectButton) `
  height: calc(2.375rem - 2px);
`;
const e2dId = (id) => testId("spinner", id);
const NumericInputCore = React.forwardRef(({ value, onChange, disabled, step = 1, asNumber = true, forceStep = false, enableMultiplier = false, clearable = false, hideControls = false, keyboardOnly = false, bigControls = isTouchDevice(), defaultValue, onFocus, onBlur, onKeyDown, formatValue, errors, name = v4(), min, max, dataTestId = "numeric-input-text-input", onDirectChange, fractionDigits, formatStyle, ...rest }, ref) => {
    const { hasError, handleInputChange, handleInputBlur, handleInputFocus, clearInputValue, handleStepLongPress, handleStepPress, handleStepPressOut, displayValue,
    // selectionState,
    // setSelectionState,
     } = useNumericInputLogic({
        value,
        fractionDigits,
        formatStyle,
        onChange,
        enableMultiplier,
        step,
        forceStep,
        defaultValue,
        formatValue,
        min,
        max,
        asNumber,
        onFocus,
        onBlur,
        onDirectChange,
    });
    const stepRef = React.useRef(step ?? 1);
    stepRef.current = step ?? 1;
    const handleChange = (e) => {
        handleInputChange(e.target.value);
    };
    const handleKeyDown = useRefCallback((e) => {
        const { key } = e;
        if (key === "ArrowDown" || key === "ArrowUp") {
            e.preventDefault();
            if (hideControls || hasError) {
                onKeyDown?.(e);
                return;
            }
            handleStepPress(key === "ArrowUp" ? step : -step);
        }
        onKeyDown?.(e);
    }, [hideControls, hasError, handleStepPress, step, onKeyDown]);
    const inputErrors = errors || {};
    const disableStepSpinner = disabled || hasError;
    const handleUp = useLongPress(() => handleStepLongPress(stepRef.current), {
        onCancel: () => handleStepPress(stepRef.current),
        onFinish: handleStepPressOut,
    });
    const handleDown = useLongPress(() => handleStepLongPress(-stepRef.current), {
        onCancel: () => handleStepPress(-stepRef.current),
        onFinish: handleStepPressOut,
    });
    const controls = bigControls ? (React.createElement(BigControlsContainer, { "data-testid": `${dataTestId}-spinner` },
        React.createElement(BigButton, { icon: faMinus, ...handleDown(), disabled: disableStepSpinner, "data-testid": e2dId("click-down"), style: { borderRadius: "0" }, "$selected": true }),
        React.createElement(BigButton, { icon: faPlus, ...handleUp(), disabled: disableStepSpinner, "data-testid": e2dId("click-up"), style: { borderRadius: "0 4px 4px 0" }, "$selected": true }))) : (React.createElement(DefaultControlsContainer, { "data-testid": `${dataTestId}-spinner` },
        React.createElement(StyledIconButton, { icon: faCaretUp, ...handleUp(), disabled: disableStepSpinner, "data-testid": e2dId("click-up") }),
        React.createElement(StyledIconButton, { icon: faCaretDown, ...handleDown(), disabled: disableStepSpinner, "data-testid": e2dId("click-down") })));
    return (React.createElement(TextInput, { ...rest, name: name, errors: {
            [name]: inputErrors[name]
                ? inputErrors[name]
                : hasError
                    ? {
                        type: "invalid",
                        message: "Invalid input",
                    }
                    : undefined,
        }, value: displayValue, onChange: handleChange, onBlur: handleInputBlur, onFocus: handleInputFocus, onKeyDown: handleKeyDown, disabled: disabled, clearable: clearable, onClearValue: clearInputValue, icon: !keyboardOnly && !hideControls && !bigControls && controls, iconContainer: !keyboardOnly && !hideControls && bigControls && controls, ref: ref, "data-testid": dataTestId }));
});
export const NumericInput = React.forwardRef(({ onChange, ...props }, ref) => {
    const handleChange = useRefCallback((value) => {
        onChange(value);
    }, [onChange]);
    return (React.createElement(NumericInputCore, { ...props, onChange: handleChange, ref: ref, asNumber: true }));
});
export const NumericInputAsString = React.forwardRef(({ onChange, ...props }, ref) => {
    const handleChange = useRefCallback((value) => {
        onChange(value);
    }, [onChange]);
    return (React.createElement(NumericInputCore, { ...props, onChange: handleChange, ref: ref, asNumber: false }));
});
