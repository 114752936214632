import { useRefCallback } from "@enfusion-ui/hooks";
import { useUserAgent } from "@enfusion-ui/hooks/build/web";
import { isElectron } from "@enfusion-ui/utils";
import { AuthContext, isDesktop, styled } from "@enfusion-ui/web-core";
import * as React from "react";
import { getRemote } from "./actions";
import MacTopBar from "./MacTopBar";
import WinTopBar from "./WinTopBar";
export const TOP_APP_BAR_HEIGHT = 25;
const TopAppBarContainer = styled.div `
  width: 100%;
  height: ${() => TOP_APP_BAR_HEIGHT}px;
  background-color: ${({ fixed }) => fixed ? "transparent" : "var(--background-top-app-bar)"};
  display: flex;
  color: var(--text-normal);
  align-items: center;
  flex-direction: row;
  font-size: 13px;
  -webkit-app-region: drag;
  -app-region: drag;
  position: ${({ fixed }) => fixed ? "fixed" : "relative"};
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
`;
export const TopAppBar = ({ fixed = false, showTitle = true, title, titleLeftContent = null, titleRightContent = null, onContextMenu, }) => {
    const context = React.useContext(AuthContext);
    const agent = useUserAgent();
    const [windowActive, setWindowActive] = React.useState(true);
    const [windowMaximized, setWindowMaximized] = React.useState(false);
    const username = context && context.user?.username ? context.user?.username : "";
    const titleText = (React.createElement(React.Fragment, null,
        titleLeftContent,
        showTitle
            ? title
                ? title
                : `Enfusion ${process.env.REACT_APP_VERSION}${username ? ` - ${username}` : ""} - ${process.env.REACT_APP_ENVIRONMENT}`
            : null,
        titleRightContent));
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const winRef = React.useRef(null);
    const onFocus = useRefCallback(() => setWindowActive(true), []);
    const onBlur = useRefCallback(() => setWindowActive(false), []);
    const onRestore = useRefCallback(() => setWindowMaximized(false), []);
    const onMaximize = useRefCallback(() => setWindowMaximized(true), []);
    const removeListeners = useRefCallback(() => {
        winRef.current?.removeListener("focused", onFocus);
        winRef.current?.removeListener("blurred", onBlur);
        winRef.current?.removeListener("restored", onRestore);
        winRef.current?.removeListener("maximized", onMaximize);
        try {
            winRef.current?.removeListener("fullscreened", onMaximize);
        }
        catch (err) {
            // noop
        }
    }, []);
    const setWinRef = useRefCallback(() => {
        winRef.current = getRemote();
        winRef.current?.addListener("focused", onFocus);
        winRef.current?.addListener("blurred", onBlur);
        winRef.current?.addListener("restored", onRestore);
        winRef.current?.addListener("maximized", onMaximize);
        try {
            winRef.current?.addListener("fullscreened", onMaximize);
        }
        catch (err) {
            // noop
        }
    }, []);
    React.useEffect(() => {
        if (!winRef.current) {
            setWinRef();
        }
        return removeListeners;
    }, []);
    let content = (React.createElement(MacTopBar, { active: windowActive, actions: isDesktop() || isElectron(), title: titleText, maximized: windowMaximized, onContextMenu: onContextMenu }));
    if (agent.os.name === "win" || (!isDesktop() && !isElectron())) {
        content = (React.createElement(WinTopBar, { active: windowActive, actions: isDesktop() || isElectron(), title: titleText, maximized: windowMaximized, onContextMenu: onContextMenu }));
    }
    return React.createElement(TopAppBarContainer, { fixed: fixed }, content);
};
