export class IndexedDBPersistence {
    dataTable;
    constructor(tableName, db) {
        this.dataTable = db.table(tableName);
    }
    insert = async (record, key) => {
        await this.dataTable.add(record, key);
    };
    fetchAll = async () => {
        return await this.dataTable.toArray();
    };
    fetchByKey = async (key, value) => {
        return this.dataTable.where({ [key]: value }).toArray();
    };
    fetchOne = async (key) => {
        return this.dataTable.where(key).first();
    };
    fetchWithFilter = async (predicate) => {
        return this.dataTable.filter(predicate).toArray();
    };
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    deleteByPrimaryKey = async (key) => {
        await this.dataTable.delete(key);
    };
    deleteByKeys = async (key) => {
        await this.dataTable.where({ ...key }).delete();
    };
    upsert = async (updateRecord, outBoundKey) => {
        const key = await this.dataTable.put(updateRecord, outBoundKey);
        return key;
    };
    clearStore = async () => {
        await this.dataTable.clear();
    };
}
