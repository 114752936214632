import { styled } from "@enfusion-ui/web-core";
import * as React from "react";
const Svg = styled.svg `
  display: inline-block;
  font-size: inherit;
  overflow: visible;
`;
export const BloombergBIcon = ({ width = 12, height = 12, color = "#000000" }) => {
    return (React.createElement(Svg, { viewBox: "0 0 400 400", width: width, height: height },
        React.createElement("path", { d: "M42.665 200.000 L 42.665 388.444 155.110 387.487 C 279.630 386.426,275.097 386.837,301.902 374.195 C 373.686 340.339,377.258 221.039,307.447 188.995 C 294.835 183.206,294.858 183.293,304.584 178.134 C 367.089 144.975,348.092 42.962,274.806 18.225 L 260.444 13.378 151.555 12.467 L 42.665 11.556 42.665 200.000 M224.585 96.647 C 249.454 107.038,248.641 148.658,223.424 156.056 C 221.296 156.681,202.556 157.454,181.778 157.774 L 144.000 158.357 144.000 126.290 L 144.000 94.222 181.390 94.222 C 208.948 94.222,220.307 94.860,224.585 96.647 M231.794 229.742 C 259.813 238.114,266.297 281.750,241.960 298.151 C 232.067 304.818,224.860 305.758,183.556 305.768 L 144.000 305.778 144.000 266.667 L 144.000 227.556 184.238 227.556 C 210.925 227.556,226.941 228.292,231.794 229.742 ", stroke: "none", fill: color, fillRule: "evenodd" })));
};
