import { faChartBar } from "@fortawesome/pro-solid-svg-icons";
import { widgetWithDatasourceDefaultConfig } from "../../../utils";
export const graph = {
    name: "Graph",
    minSize: [10, 2],
    icon: faChartBar,
    description: "Display a chart / graph",
    datasourceConfigIds: ["datasourceId"],
    defaultConfig: {
        type: "column",
        categories: [],
        series: [],
        yMin: null,
        xMin: null,
        tooltipFractions: null,
        ...widgetWithDatasourceDefaultConfig,
    },
    type: "display",
    category: "dashboard",
};
