import { faSearchDollar } from "@fortawesome/pro-solid-svg-icons";
export const portfolioAnalyticsSourcesSelection = {
    name: "Portfolio Analytics Source",
    minSize: [5, 1],
    icon: faSearchDollar,
    description: "Choose position source",
    defaultConfig: {
        defaultValue: null,
        key: "Portfolio Analytics Sources",
    },
    type: "input",
    category: "dashboard",
};
