import { styled } from "@enfusion-ui/web-core";
import { FontAwesomeIcon, } from "@fortawesome/react-fontawesome";
import * as React from "react";
const BoxIconButtonContainer = styled.div `
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 30px;
  min-height: 20px;
  max-height: 40px;
  cursor: pointer;

  opacity: ${({ active }) => {
    if (active)
        return "0.8";
    return "0.5";
}};

  &:hover {
    opacity: 1;
    color: ${({ hoverColor }) => (hoverColor ? "black" : "inherit")};
    background-color: ${({ hoverColor = "var(--background-color-0)" }) => hoverColor};
  }
`;
const BoxIconButton = ({ color, icon, iconComp, onClick, active, style, size = "sm", title, }) => {
    return (React.createElement(BoxIconButtonContainer, { style: style, active: active, hoverColor: color, onClick: onClick, title: title }, icon ? React.createElement(FontAwesomeIcon, { icon: icon, size: size }) : iconComp));
};
export default BoxIconButton;
