export const REPORT_ROW_HEIGHT = 22;
export const REPORT_HEADER_ROW_HEIGHT = 26;
export const REPORT_DETAIL_PADDING_BOTTOM = 10;
const HEADER_AND_PADDING = REPORT_HEADER_ROW_HEIGHT + REPORT_DETAIL_PADDING_BOTTOM;
export const REPORT_BASE_GRID_HEIGHT = HEADER_AND_PADDING + REPORT_ROW_HEIGHT * 2;
export const getDetailRowHeight = (params) => {
    const isDetailRow = params.node.detail;
    // for all rows that are not detail rows, return nothing
    if (!isDetailRow)
        return REPORT_ROW_HEIGHT;
    // otherwise return height based on number of rows in detail grid
    return Math.max((params.data.children ? params.data.children.slice(0, 100).length : 1) *
        REPORT_ROW_HEIGHT +
        HEADER_AND_PADDING, REPORT_BASE_GRID_HEIGHT);
};
