export const getContextMenuPlacement = (windowWidth, windowHeight, portalElementRef, nodePlacement) => {
    const style = {};
    if (nodePlacement &&
        nodePlacement.clientX !== null &&
        nodePlacement.clientY !== null) {
        const { width, height } = portalElementRef.current
            ? portalElementRef.current.getBoundingClientRect()
            : { width: 0, height: 0 };
        let left = (nodePlacement.clientX || 0) + 5;
        let top = (nodePlacement.clientY || 0) - 5;
        if (left + width > windowWidth) {
            left = left - width;
            if (left < 0) {
                left = 0;
            }
        }
        // handle going above the view
        if (top < 0) {
            top = 0;
        }
        // handle going under the view
        if (top + height > windowHeight) {
            top = windowHeight - height;
        }
        style.display = "block";
        style.left = `${left}px`;
        style.top = `${top}px`;
    }
    return style;
};
