export const currencySymbolsBase = {
    USD: "$",
    CAD: "CA$",
    EUR: "€",
    AFN: "Af",
    ARS: "Arg$",
    AUD: "AU$",
    AZN: "₼",
    BAM: "KM",
    BDT: "৳",
    BHD: "BD",
    BIF: "FBu",
    BND: "B$",
    BOB: "Bs",
    BRL: "R$",
    BYN: "Br",
    BZD: "BZ$",
    CLP: "CL$",
    CNY: "¥",
    COP: "Col$",
    CRC: "₡",
    CVE: "CV$",
    CZK: "Kč",
    DJF: "Fdj",
    DKK: "kr.",
    DOP: "RD$",
    DZD: "DA",
    EEK: "kr",
    ERN: "Nfk",
    ETB: "Br",
    GBP: "£",
    GBp: "GBp",
    GHS: "GH₵",
    GNF: "FG",
    HKD: "HK$",
    HRK: "kn",
    HUF: "Ft",
    IDR: "Rp",
    ILS: "₪",
    INR: "₹",
    ISK: "Íkr",
    JMD: "J$",
    JOD: "د.أ",
    JPY: "¥",
    KES: "Ksh",
    KMF: "CF",
    KRW: "₩",
    KWD: "KD",
    LBP: "£L",
    LKR: "SLRs",
    LTL: "Lt",
    LVL: "Ls",
    LYD: "LD",
    MOP: "MOP$",
    MUR: "MURs",
    MXN: "Mex$",
    MYR: "RM",
    MZN: "MTn",
    NAD: "N$",
    NGN: "₦",
    NIO: "C$",
    NOK: "kr",
    NPR: "रु",
    NZD: "NZ$",
    PAB: "B/.",
    PEN: "S/",
    PHP: "₱",
    PKR: "PKRs",
    PLN: "zł",
    PYG: "₲",
    QAR: "QR",
    RSD: "din",
    SAR: "SR",
    SEK: "kr",
    SGD: "S$",
    SOS: "Sh.So.",
    SYP: "£Syr",
    THB: "฿",
    TND: "DT",
    TOP: "T$",
    TRY: "TL",
    TTD: "TT$",
    TWD: "NT$",
    TZS: "TSh",
    UAH: "₴",
    UGX: "USh",
    UYU: "$U",
    VEF: "Bs.F.",
    VND: "₫",
    XAF: "FCFA",
    XOF: "CFA",
    YER: "YR",
    ZAR: "R",
    ZMK: "ZK",
    ZWL: "Z$",
};
export const currencySymbolsMobile = {
    USD: "$",
    CAD: "CA$",
    EUR: "€",
    AFN: "Af",
    ARS: "Arg$",
    AUD: "AU$",
    AZN: "₼",
    BAM: "KM",
    BDT: "৳",
    BHD: "BD",
    BIF: "FBu",
    BND: "B$",
    BOB: "Bs",
    BRL: "R$",
    BYN: "Br",
    BZD: "BZ$",
    CLP: "CL$",
    CNY: "¥",
    COP: "Col$",
    CRC: "₡",
    CVE: "CV$",
    CZK: "Kč",
    DJF: "Fdj",
    DKK: "kr.",
    DOP: "RD$",
    DZD: "DA",
    EEK: "kr",
    ERN: "Nfk",
    ETB: "Br",
    GBP: "£",
    GBp: "GBp",
    GHS: "GH₵",
    GNF: "FG",
    HKD: "HK$",
    HRK: "kn",
    HUF: "Ft",
    IDR: "Rp",
    ILS: "₪",
    INR: "INR",
    ISK: "Íkr",
    JMD: "J$",
    JOD: "د.أ",
    JPY: "¥",
    KES: "Ksh",
    KMF: "CF",
    KRW: "KRW",
    KWD: "KD",
    LBP: "£L",
    LKR: "SLRs",
    LTL: "Lt",
    LVL: "Ls",
    LYD: "LD",
    MOP: "MOP$",
    MUR: "MURs",
    MXN: "Mex$",
    MYR: "RM",
    MZN: "MTn",
    NAD: "N$",
    NGN: "₦",
    NIO: "C$",
    NOK: "kr",
    NPR: "रु",
    NZD: "NZ$",
    PAB: "B/.",
    PEN: "S/",
    PHP: "₱",
    PKR: "PKRs",
    PLN: "zł",
    PYG: "₲",
    QAR: "QR",
    RSD: "din",
    SAR: "SR",
    SEK: "kr",
    SGD: "S$",
    SOS: "Sh.So.",
    SYP: "£Syr",
    THB: "฿",
    TND: "DT",
    TOP: "T$",
    TRY: "TL",
    TTD: "TT$",
    TWD: "NT$",
    TZS: "TSh",
    UAH: "₴",
    UGX: "USh",
    UYU: "$U",
    VEF: "Bs.F.",
    VND: "₫",
    XAF: "FCFA",
    XOF: "CFA",
    YER: "YR",
    ZAR: "R",
    ZMK: "ZK",
    ZWL: "Z$",
};
export const currencySymbols = {
    base: currencySymbolsBase,
    mobile: currencySymbolsMobile,
};
