import { generateBackgroundSVG, lightBg } from "./backgrounds";
const dark = {
    name: "Dark",
    key: "dark",
    agGridClass: "ag-theme-balham-dark ag-theme-webapp",
    backgrounds: {
        landingBg: generateBackgroundSVG(encodeURIComponent("#36393f"), // backgroundColor0
        encodeURIComponent("#2f3136") // backgroundColor1
        ),
    },
    colors: {
        primary: "#8a4eb6",
        primaryHover: "#6e3c93",
        info: "#1c294c",
        success: "#3caa81",
        warning: "#ba9b49",
        danger: "#f44336",
        tabIconColor0: "#ffffff",
        backgroundColor0: "#36393f",
        backgroundColor1: "#2f3136",
        backgroundColor1Hover: "#25272b",
        backgroundColor2: "#202225",
        backgroundAccent: "#4f545c",
        backgroundTopAppBar: "#202225",
        textNormal: "#dcddde",
        textInverted: "#2f3136",
        textColor1: "#aeb0b3",
        textHover: "#aeb0b3",
        textMuted: "#72767d",
        textInputBorder: "rgba(0, 0, 0, 0.3)",
        border: "rgba(0, 0, 0, 0.3)",
        inputBackground: "#2f3136",
        inputBorder: "#2f3136",
        overlay: "rgba(0, 0, 0, 0.7)",
        dashboardVisuals: [
            "#3CAA81",
            "#C3362B",
            "#8A4EB6",
            "#4E58B5",
            "#4EAEB7",
            "#CDB3E0",
            "#AA3C77",
            "#9CB74E",
            "#C2A304",
            "#3596B5",
            "#837B8A",
            "#B7564E",
            "#AC80CB",
            "#B6804E",
            "#F6F2FA",
        ],
    },
};
const darkColorBlind = {
    name: "Dark (Color Blind)",
    key: "darkColorBlind",
    agGridClass: "ag-theme-balham-dark ag-theme-webapp",
    backgrounds: {
        landingBg: generateBackgroundSVG(encodeURIComponent("#3c3c3c"), // backgroundColor0
        encodeURIComponent("#252526") // backgroundColor1
        ),
    },
    colors: {
        primary: "#0e639c",
        primaryHover: "#1177bb",
        info: "#75beff",
        success: "#89d185",
        warning: "#cca700",
        danger: "#f48771",
        tabIconColor0: "#75beff",
        backgroundColor0: "#3c3c3c",
        backgroundColor1: "#252526",
        backgroundColor2: "#1e1e1e",
        backgroundAccent: "#494949",
        backgroundTopAppBar: "#575749",
        textNormal: "#dcddde",
        textInverted: "#1e1e1e",
        textColor1: "#aeb0b3",
        textHover: "#aeb0b3",
        textMuted: "#72767d",
        textInputBorder: "rgba(0, 0, 0, 0.3)",
        border: "rgba(0, 0, 0, 0.3)",
        inputBackground: "#202225",
        inputBorder: "#2f3136",
        overlay: "rgba(0, 0, 0, 0.7)",
        dashboardVisuals: [
            "#3CAA81",
            "#C3362B",
            "#8A4EB6",
            "#4E58B5",
            "#4EAEB7",
            "#CDB3E0",
            "#AA3C77",
            "#9CB74E",
            "#C2A304",
            "#3596B5",
            "#837B8A",
            "#B7564E",
            "#AC80CB",
            "#B6804E",
            "#F6F2FA",
        ],
    },
    density: "high",
};
const light = {
    name: "Light",
    key: "light",
    agGridClass: "ag-theme-balham ag-theme-webapp",
    backgrounds: {
        landingBg: lightBg,
    },
    colors: {
        primary: "#AE7CD1",
        primaryHover: "#A96AD5",
        info: "#418AB3",
        success: "#3caa81",
        warning: "#ba9b49",
        danger: "#f44336",
        tabIconColor0: "#202225",
        backgroundColor0: "#f5f7f7",
        backgroundColor1: "#f5f7f7",
        backgroundColor1Hover: "#dcddde",
        backgroundColor2: "#FFFFFF",
        backgroundAccent: "#dcddde",
        backgroundTopAppBar: "#dcddde",
        textNormal: "#343A40",
        textInverted: "#FFFFFF",
        textColor1: "#2f3136",
        textHover: "#2f3136",
        textMuted: "#384955",
        textInputBorder: "rgba(165, 165, 165, 0.2)",
        border: "rgba(165, 165, 165, 0.2)",
        inputBackground: "#FFFFFF",
        inputBorder: "#E1E8EB",
        overlay: "rgba(165, 165, 165, 0.7)",
        dashboardVisuals: [
            "#7AD1AD",
            "#ED6D63",
            "#AE7CD1",
            "#757ED8",
            "#76CED6",
            "#EDE3F5",
            "#D679A0",
            "#BBD07B",
            "#D8BA6C",
            "#74A1D7",
            "#A990BB",
            "#D3776F",
            "#D0B4E4",
            "#C99550",
            "#A6A6A6",
        ],
    },
};
const monokai = {
    name: "Monokai",
    key: "monokai",
    agGridClass: "ag-theme-balham-dark ag-theme-webapp",
    backgrounds: {
        landingBg: generateBackgroundSVG(encodeURIComponent("#212921"), // backgroundColor0
        encodeURIComponent("#181818") // backgroundColor1
        ),
    },
    colors: {
        primary: "#007bce",
        primaryHover: "#424239",
        info: "#1c294c",
        success: "#a5e729",
        warning: "#848528",
        danger: "#ff6600",
        tabIconColor0: "#c5c5c5",
        backgroundColor0: "#212921",
        backgroundColor1: "#181818",
        backgroundColor2: "#202225",
        backgroundAccent: "#212921",
        backgroundTopAppBar: "#202225",
        textNormal: "#dcddde",
        textInverted: "#2f3136",
        textColor1: "#aeb0b3",
        textHover: "#aeb0b3",
        textMuted: "#72767d",
        textInputBorder: "rgba(0, 0, 0, 0.3)",
        border: "rgba(0, 0, 0, 0.3)",
        inputBackground: "#424239",
        inputBorder: "#424239",
        overlay: "rgba(0, 0, 0, 0.7)",
        dashboardVisuals: [
            "#3CAA81",
            "#C3362B",
            "#8A4EB6",
            "#4E58B5",
            "#4EAEB7",
            "#CDB3E0",
            "#AA3C77",
            "#9CB74E",
            "#C2A304",
            "#3596B5",
            "#837B8A",
            "#B7564E",
            "#AC80CB",
            "#B6804E",
            "#F6F2FA",
        ],
    },
};
const dracula = {
    name: "Dracula",
    key: "dracula",
    agGridClass: "ag-theme-balham-dark ag-theme-webapp",
    backgrounds: {
        landingBg: generateBackgroundSVG(encodeURIComponent("#191A21"), // backgroundColor0
        encodeURIComponent("#21222C") // backgroundColor1
        ),
    },
    colors: {
        primary: "#FF79C6",
        primaryHover: "#44475A75",
        info: "#8BE9FD",
        success: "#50FA7B",
        warning: "#F1FA8C",
        danger: "#FF5555",
        tabIconColor0: "#6272A4",
        backgroundColor0: "#191A21",
        backgroundColor1: "#21222C",
        backgroundColor2: "#282A36",
        backgroundAccent: "#343746",
        backgroundTopAppBar: "#21222C",
        textNormal: "#dcddde",
        textInverted: "#1e1e1e",
        textColor1: "#aeb0b3",
        textHover: "#aeb0b3",
        textMuted: "#72767d",
        textInputBorder: "rgba(0, 0, 0, 0.3)",
        border: "rgba(0, 0, 0, 0.3)",
        inputBackground: "#21222C",
        inputBorder: "#2f3136",
        overlay: "rgba(0, 0, 0, 0.7)",
        dashboardVisuals: [
            "#50FA7B",
            "#FF5555",
            "#FF79C6",
            "#7BC5FF",
            "#7AFFDF",
            "#FFA0D6",
            "#F478FF",
            "#C6FF7B",
            "#FFF67A",
            "#7A82FF",
            "#FFD9EF",
            "#FF787F",
            "#AC80CB",
            "#FFB678",
            "#FFF6FB",
        ],
    },
};
const darkCarbon = {
    name: "Dark - Carbon",
    key: "darkCarbon",
    agGridClass: "ag-theme-balham-dark ag-theme-webapp",
    backgrounds: {
        landingBg: generateBackgroundSVG(encodeURIComponent("#192842"), // backgroundColor0
        encodeURIComponent("#1C2E4A") // backgroundColor1
        ),
    },
    colors: {
        primary: "#004A46",
        primaryHover: "#203354",
        info: "#75beff",
        success: "#27D900",
        warning: "#B0B400",
        danger: "#FF555E",
        tabIconColor0: "#75beff",
        backgroundColor0: "#192842",
        backgroundColor1: "#1C2E4A",
        backgroundColor2: "#152131",
        backgroundAccent: "#152238",
        backgroundTopAppBar: "#152231",
        textNormal: "#B7C8CB",
        textInverted: "#1e1e1e",
        textColor1: "#aeb0b3",
        textHover: "#aeb0b3",
        textMuted: "#72767d",
        textInputBorder: "rgba(0, 0, 0, 0.3)",
        border: "rgba(0, 0, 0, 0.3)",
        inputBackground: "#1C2E4A",
        inputBorder: "#2f3136",
        overlay: "rgba(0, 0, 0, 0.7)",
        dashboardVisuals: [
            "#006B20",
            "#760006",
            "#004A46",
            "#123B7D",
            "#008A83",
            "#133937",
            "#66096B",
            "#96910A",
            "#AF8F20",
            "#352690",
            "#008A84",
            "#8C320E",
            "#00E7DC",
            "#9C632E",
            "#E0FFFD",
        ],
    },
    density: "high",
};
const darkCobalt = {
    name: "Dark - Cobalt",
    key: "darkCobalt",
    agGridClass: "ag-theme-balham-dark ag-theme-webapp",
    backgrounds: {
        landingBg: generateBackgroundSVG(encodeURIComponent("#001B38"), // backgroundColor0
        encodeURIComponent("#002543") // backgroundColor1
        ),
    },
    colors: {
        primary: "#00769E",
        primaryHover: "#005072",
        info: "#3588CC",
        success: "#07AF00",
        warning: "#A3A800",
        danger: "#FF575F",
        tabIconColor0: "#3588CC",
        backgroundColor0: "#001B38",
        backgroundColor1: "#002543",
        backgroundColor2: "#002948",
        backgroundAccent: "#003655",
        backgroundTopAppBar: "#002948",
        textNormal: "#B7C8CB",
        textInverted: "#1e1e1e",
        textColor1: "#aeb0b3",
        textHover: "#aeb0b3",
        textMuted: "#72767d",
        textInputBorder: "rgba(0, 0, 0, 0.3)",
        border: "rgba(0, 0, 0, 0.3)",
        inputBackground: "#002948",
        inputBorder: "#2f3136",
        overlay: "rgba(0, 0, 0, 0.7)",
        dashboardVisuals: [
            "#3CAA81",
            "#C3362B",
            "#8A4EB6",
            "#4E58B5",
            "#4EAEB7",
            "#CDB3E0",
            "#AA3C77",
            "#9CB74E",
            "#C2A304",
            "#3596B5",
            "#837B8A",
            "#B7564E",
            "#AC80CB",
            "#B6804E",
            "#F6F2FA",
        ],
    },
    density: "high",
};
const darkSolarized = {
    name: "Dark - Solarized",
    key: "darkSolarized",
    agGridClass: "ag-theme-balham-dark ag-theme-webapp",
    backgrounds: {
        landingBg: generateBackgroundSVG(encodeURIComponent("#004754"), // backgroundColor0
        encodeURIComponent("#004B58") // backgroundColor1
        ),
    },
    colors: {
        primary: "#008080",
        primaryHover: "#19505C",
        info: "#0C85CC",
        success: "#1FCE00",
        warning: "#B0B300",
        danger: "#BC0009",
        tabIconColor0: "#306167",
        backgroundColor0: "#004754",
        backgroundColor1: "#004B58",
        backgroundColor2: "#003E4B",
        backgroundAccent: "#23555F",
        backgroundTopAppBar: "#003640",
        textNormal: "#B7C8CB",
        textInverted: "#1e1e1e",
        textColor1: "#aeb0b3",
        textHover: "#aeb0b3",
        textMuted: "#72767d",
        textInputBorder: "rgba(0, 0, 0, 0.3)",
        border: "rgba(0, 0, 0, 0.3)",
        inputBackground: "#003E4B",
        inputBorder: "#3C6068",
        overlay: "rgba(0, 0, 0, 0.7)",
        dashboardVisuals: [
            "#00802B",
            "#800001",
            "#008080",
            "#123B7D",
            "#2CAFA7",
            "#133937",
            "#80007F",
            "#7F8000",
            "#BFAF21",
            "#5911A0",
            "#008A84",
            "#A43F0E",
            "#00E7DC",
            "#D17141",
            "#C8FEFF",
        ],
    },
    density: "high",
};
const darkSpaceGray = {
    name: "Dark - Space Gray",
    key: "darkSpaceGray",
    agGridClass: "ag-theme-balham-dark ag-theme-webapp",
    backgrounds: {
        landingBg: generateBackgroundSVG(encodeURIComponent("#1a2026"), // backgroundColor0
        encodeURIComponent("#2e3742") // backgroundColor1
        ),
    },
    colors: {
        primary: "#797e8a",
        primaryHover: "#373D4B",
        info: "#0C85CC",
        success: "#1FCE00",
        warning: "#B0B300",
        danger: "#BC0009",
        tabIconColor0: "#566373",
        backgroundColor0: "#1a2026",
        backgroundColor1: "#2e3742",
        backgroundColor2: "#222931",
        backgroundAccent: "#3b4654",
        backgroundTopAppBar: "#21282e",
        textNormal: "#dcddde",
        textInverted: "#2f3136",
        textColor1: "#aeb0b3",
        textHover: "#aeb0b3",
        textMuted: "#72767d",
        textInputBorder: "rgba(0, 0, 0, 0.3)",
        border: "rgba(0, 0, 0, 0.3)",
        inputBackground: "#252C35",
        inputBorder: "#46505F",
        overlay: "rgba(0, 0, 0, 0.7)",
        dashboardVisuals: [
            "#3CAA81",
            "#C3362B",
            "#8A4EB6",
            "#4E58B5",
            "#4EAEB7",
            "#CDB3E0",
            "#AA3C77",
            "#9CB74E",
            "#C2A304",
            "#3596B5",
            "#837B8A",
            "#B7564E",
            "#AC80CB",
            "#B6804E",
            "#F6F2FA",
        ],
    },
    density: "high",
};
// const dashDark: ThemeDefinition = {
//   name: "Dashboard Dark",
//   key: "dashDark",
//   agGridClass: "ag-theme-balham-dark ag-theme-webapp",
//   backgrounds: {
//     landingBg: generateBackgroundSVG(
//       encodeURIComponent("#36393f"), // backgroundColor0
//       encodeURIComponent("#2f3136") // backgroundColor1
//     ),
//   },
//   colors: {
//     primary: "#9E37AF",
//     primaryHover: "#731583",
//     info: "#0C85CC",
//     infoHover: "#16203C",
//     success: "#42DF8A",
//     successHover: "#07AB52",
//     warning: "#FF9C40",
//     warningHover: "#E86F00",
//     danger: "#FC3B3B",
//     dangerHover: "#C5180C",
//     tabIconColor0: "#ffffff",
//     backgroundColor0: "#424E6C",
//     backgroundColor0Hover: "#2C3754",
//     backgroundColor1: "#2C3345",
//     backgroundColor1Hover: "#1C2438",
//     backgroundColor2: "#080F20",
//     backgroundAccent: "#66758D",
//     backgroundTopAppBar: "#202225",
//     textNormal: "#B7C8CB",
//     textInverted: "#1E1E1E",
//     textColor1: "#AEB0B3",
//     textHover: "#AEB0B3",
//     textMuted: "#72767D",
//     textInputBorder: "rgba(0, 0, 0, 0.3)",
//     border: "rgba(0, 0, 0, 0.3)",
//     inputBackground: "#252C35",
//     inputBorder: "#46505F",
//     overlay: "rgba(0, 0, 0, 0.7)",
//   },
// };
// const dashLight: ThemeDefinition = {
//   name: "Dashboard Light",
//   key: "dashLight",
//   agGridClass: "ag-theme-balham ag-theme-webapp",
//   backgrounds: {
//     landingBg: lightBg,
//   },
//   colors: {
//     primary: "#9E37AF",
//     primaryHover: "#731583",
//     info: "#0C85CC",
//     infoHover: "#16203C",
//     success: "#099663",
//     successHover: "#146C4D",
//     warning: "#D87A2A",
//     warningHover: "#C96000",
//     danger: "#CB5A5A",
//     dangerHover: "#9A3131",
//     tabIconColor0: "#9DB1F1",
//     backgroundColor0: "#FAFAFA",
//     backgroundColor0Hover: "#ECEDF0",
//     backgroundColor1: "#F5F7F7",
//     backgroundColor1Hover: "#E9F0F5",
//     backgroundColor2: "#E9F0F5",
//     backgroundAccent: "#F5F7F7",
//     backgroundTopAppBar: "#FFFFFF",
//     textNormal: "#535960",
//     textInverted: "#FFFFFF",
//     textColor1: "#2F3543",
//     textHover: "#2F3136",
//     textMuted: "#384955",
//     textInputBorder: "rgba(165, 165, 165, 0.2)",
//     border: "rgba(165, 165, 165, 0.2)",
//     inputBackground: "#FFFFFF",
//     inputBorder: "#E1E8EB",
//     overlay: "rgba(165, 165, 165, 0.7)",
// };
export const themes = {
    dark,
    darkColorBlind,
    light,
    monokai,
    dracula,
    darkCarbon,
    darkCobalt,
    darkSolarized,
    darkSpaceGray,
};
export const themesForDashboard = {
    dashDark: dark,
    dashLight: light,
};
