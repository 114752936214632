import queryString from "query-string";
const baseUrl = "/internal/api/security";
export const calls = {
    GET_TRADERS: {
        url: `${baseUrl}/userprincipals/traders`,
        query: { deskGroupId: { optional: true, defaultValue: 1 } },
    },
    GET_PORTFOLIO_MANAGERS: {
        url: `${baseUrl}/userprincipals/portfoliomanagers`,
    },
    GET_TRADING_DESKS: { url: `${baseUrl}/groupprincipals/desks` },
};
export default (callCreator) => ({
    GET_TRADERS: callCreator((deskGroupId) => queryString.stringifyUrl({
        url: calls.GET_TRADERS.url,
        query: { deskGroupId },
    })),
    GET_ALL_TRADERS: callCreator(() => calls.GET_TRADERS.url),
    GET_PORTFOLIO_MANAGERS: callCreator(() => calls.GET_PORTFOLIO_MANAGERS.url),
    GET_TRADING_DESKS: callCreator(() => calls.GET_TRADING_DESKS.url),
});
