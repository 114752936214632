import { getFileExtensionIcon } from "./getFileExtensionIcon";
export const fileExtensionRegex = /^(.+)\.([^.]+)$/;
export const getFileParts = (fileName) => {
    const matches = fileExtensionRegex.exec(fileName);
    const name = matches ? matches[1] : fileName;
    const extension = matches ? matches[2] : "";
    return { name, extension };
};
export const getFileExt = (fileName) => getFileParts(fileName).extension;
export const getFileNameDirect = (fileName) => getFileParts(fileName).name;
export const getFileName = (node) => {
    if (!node)
        return "";
    return getFileNameDirect(node.name);
};
export const getFileIcon = (node) => {
    if (!node)
        return getFileExtensionIcon("");
    const extension = getFileExt(node.name);
    return getFileExtensionIcon(extension);
};
