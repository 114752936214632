import { ERROR_VIEW_CONTENT, } from "@enfusion-ui/core";
import { createMailto } from "@enfusion-ui/utils";
import { faCircleExclamation } from "@fortawesome/pro-solid-svg-icons";
import React from "react";
import { ContentMessage, EmptyView } from "../display";
import { CenterContent } from "../Styled";
export const SupportLinkErrorView = ({ component, type, meta, }) => {
    const [when] = React.useState(() => new Date());
    return (React.createElement(CenterContent, null,
        React.createElement(ContentMessage, { icon: faCircleExclamation, message: "" },
            React.createElement("div", null,
                ERROR_VIEW_CONTENT[type](component),
                " ",
                ERROR_VIEW_CONTENT.contactSupport(React.createElement("a", { href: createMailto("support@enfusion.com", ...ERROR_VIEW_CONTENT.supportMail(type, component, when, "Web App", 
                    // eslint-disable-next-line @typescript-eslint/no-explicit-any
                    window.appVersion, meta)) }, "support"))))));
};
export const PermissionDeniedView = ({ component, ...rest }) => {
    return (React.createElement(EmptyView, { ...rest },
        React.createElement(SupportLinkErrorView, { component: component, type: "permission" })));
};
export const MissingFileView = ({ component, filePath, ...rest }) => {
    return (React.createElement(EmptyView, { ...rest },
        React.createElement(SupportLinkErrorView, { component: component, meta: `File Path: ${filePath}`, type: "missing" })));
};
export const ForbiddenFileView = ({ component, filePath, ...rest }) => {
    return (React.createElement(EmptyView, { ...rest },
        React.createElement(SupportLinkErrorView, { component: component, meta: `File Path: ${filePath}`, type: "forbidden" })));
};
