import { faBalanceScale, faBook, faBriefcase, faBuilding, faChartLine, faClipboardCheck, faCoins, faFile, faFileCode, faFileContract, faFileCsv, faFileExcel, faFileImage, faFileInvoiceDollar, faFileLines, faFilePdf, faFileVideo, faGavel, faMoneyBill, faMoneyCheck, faPercent, faTable, faThList, } from "@fortawesome/pro-solid-svg-icons";
export const reportFileExtensions = [
    "bsr",
    "car",
    "cbx",
    "cfs",
    "gld",
    "isr",
    "mdq",
    "ppy",
    "tbr",
    "ivr",
    "cfr",
    "cmb",
    "irb",
    "orb",
    "trb",
    "par",
    "per",
    "ppr",
    "crr",
    "acr",
    "bkr",
    "crp",
    "ptr",
    "rkr",
    "rmr",
    "ler",
    "rpr",
    "ibm",
    "trr",
    "mfe",
    "ppx",
    "diff",
    "arp",
];
export function getFileExtensionIcon(extension) {
    switch (extension.toLowerCase()) {
        case "bkr": // Book Report
            return faBook;
        case "bsr": // GL balance sheet
        case "car": // GL cash activity report
        case "cbx": // GL cash balances report
        case "cfs": // GL cash flow statement report
        case "gld": // GL distribution lines report
        case "isr": // GL income statement report
        case "mdq": // Market data quotes report
        case "mfe": // Model entry report
        case "ppx": // GL position performance report
        case "ppy": // GL position performance report v2
        case "tbr": // GL trial balances report
        case "arp": // Audit report
        case "ivr": // Investment report
            return faChartLine;
        case "cfr": // Cash flow report
            return faFileInvoiceDollar;
        case "cmb": // Compliance blotter
            return faGavel;
        case "diff": // Diff report (reconciliation report)
            return faClipboardCheck;
        case "irb": // Instrument report
            return faFileContract;
        case "ibm": // Index member report
        case "trr": // Trade reconciliation report
            return faTable;
        case "orb": // Order blotter
        case "trb": // Trade blotter
            return faThList;
        case "par": // Portfolio analytics report
        case "rpr": // Reported position report
            return faMoneyCheck;
        case "per": // Position event report
        case "ppr": // Position report
            return faBriefcase;
        case "ptr": // Pricing tool report
            return faCoins;
        case "rkr": // Risk report
            return faBalanceScale;
        case "rmr": // Reported margin report
            return faPercent;
        case "ler": // Legal entity report
        case "crp": // Corporate action
            return faBuilding;
        case "crr": // Counterparty commissions report
        case "acr": // Custodian account report
            return faMoneyBill;
        case "mp4": // Video
        case "webm":
        case "mov":
            return faFileVideo;
        case "svg": // Images
        case "png":
        case "jpg":
        case "jpeg":
        case "bitmap":
        case "bmp":
        case "gif":
            return faFileImage;
        case "xls":
        case "xlsx":
            return faFileExcel;
        case "csv":
            return faFileCsv;
        case "json": // code
        case "js":
        case "ts":
        case "tsx":
        case "html":
        case "vue":
        case "css":
        case "sass":
        case "md":
        case "java":
        case "php":
        case "cs":
        case "c":
        case "cpp":
        case "xml":
            return faFileCode;
        case "txt":
            return faFileLines;
        case "pdf":
            return faFilePdf;
        default:
            return faFile;
    }
}
