import { useRefCallback } from "@enfusion-ui/hooks";
import { styled } from "@enfusion-ui/web-core";
import * as React from "react";
import { DateRangePicker } from "../inputs/DatePickers/DateRangePicker";
import { Select } from "../inputs/Select";
const dateTypeOptions = [
    { value: "CreatedAt", label: "Created At" },
    { value: "ModifiedAt", label: "Modified At" },
];
const DateFilterFormContainer = styled.form `
  width: 100%;
`;
const DateFilterTypeContainer = styled.div `
  margin-bottom: 4px;
`;
export const DateFilterForm = ({ onChange }) => {
    const [dateRange, setDateRange] = React.useState([
        null,
        null,
    ]);
    const [dateType, setDateType] = React.useState("CreatedAt");
    const handleDateTypeChange = useRefCallback((option, { action }) => {
        const { value } = option;
        if (action === "select-option") {
            setDateType(value);
            onChange(value, dateRange);
        }
    }, [dateRange, onChange]);
    const handleDateRangeChange = useRefCallback((range) => {
        setDateRange(range);
        onChange(dateType, range);
    }, [dateType, onChange]);
    return (React.createElement(DateFilterFormContainer, null,
        React.createElement(DateFilterTypeContainer, null,
            React.createElement(Select, { name: "dateType", value: dateTypeOptions.find((option) => option.value === dateType), onChange: handleDateTypeChange, options: dateTypeOptions, label: "Filter By Date", clearable: false, inputId: "date-type-selection-id" })),
        React.createElement(DateRangePicker, { defaultValue: [null, null], startName: "filter-start-date", endName: "filter-end-date", onChange: handleDateRangeChange, minWidth: "91px" })));
};
