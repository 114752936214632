import { faSearchDollar } from "@fortawesome/pro-solid-svg-icons";
export const allocationsPanel = {
    name: "Allocations Panel",
    minSize: (mobile) => (mobile ? [19, 2] : [26, 2]),
    defaultSize: [26, 10],
    maxSize: [42, 12],
    icon: faSearchDollar,
    description: "Choose scheme and allocation(s)",
    defaultConfig: {
        defaultValue: null,
        key: "AllocationPanel",
    },
    type: "display",
    category: "orderForm",
};
export const allocationsGridPanel = {
    name: "Allocations Grid Panel",
    minSize: [8, 2],
    defaultSize: [8, 8],
    icon: faSearchDollar,
    description: "Choose allocation(s)",
    defaultConfig: {
        defaultValue: null,
        key: "AllocationPanel",
    },
    type: "display",
    category: "orderForm",
    webOnly: true,
};
export const applyAllocationsPanel = {
    name: "Apply Allocations Panel",
    minSize: [5, 2],
    defaultSize: [5, 10],
    maxSize: [17, 12],
    icon: faSearchDollar,
    description: "Choose allocation template, optimize, settle currency",
    defaultConfig: {
        defaultValue: null,
        key: "ApplyAllocationPanel",
    },
    type: "display",
    category: "orderForm",
    webOnly: true,
};
export const allocationSchemeSelect = {
    name: "Allocation Scheme Select",
    minSize: [6, 2],
    defaultSize: [6, 5],
    maxSize: [6, 12],
    icon: faSearchDollar,
    description: "Choose allocation scheme",
    defaultConfig: {
        defaultValue: null,
        key: "AllocationSchemeSelect",
    },
    type: "input",
    category: "orderForm",
    webOnly: true,
};
