/* eslint-disable import/prefer-default-export */
import * as React from "react";
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function usePrevious(value) {
    const ref = React.useRef();
    React.useEffect(() => {
        ref.current = value;
    }, [value]);
    return ref.current;
}
