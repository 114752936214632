const aggregateMinOrMax = (graphData, aggregateMethod = "max") => {
    const aggregatedData = graphData.reduce((aggregatedEntries, eachDataEntry) => {
        if (!aggregatedEntries[eachDataEntry.name] ||
            (aggregateMethod === "max" &&
                aggregatedEntries[eachDataEntry.name].y < eachDataEntry.y) ||
            (aggregateMethod === "min" &&
                aggregatedEntries[eachDataEntry.name].y > eachDataEntry.y)) {
            return {
                ...aggregatedEntries,
                [eachDataEntry.name]: {
                    ...eachDataEntry,
                    y: Number.isNaN(Number(eachDataEntry.y)) ||
                        eachDataEntry.y?.toString().trim().length === 0
                        ? NaN
                        : eachDataEntry.y,
                },
            };
        }
        return aggregatedEntries;
    }, {});
    return Object.values(aggregatedData);
};
const aggregateSumOrAvg = (graphData, aggregateMethod = "sum") => {
    const aggregatedData = graphData.reduce((entries, eachDataEntry) => {
        const { aggregatedEntries, dataKeyCount } = entries;
        const entryName = eachDataEntry.name;
        if (!aggregatedEntries[entryName]) {
            dataKeyCount[entryName] = 1;
            aggregatedEntries[entryName] = {
                ...eachDataEntry,
                y: Number.isNaN(Number(eachDataEntry.y)) ||
                    eachDataEntry.y?.toString().trim().length === 0
                    ? NaN
                    : eachDataEntry.y,
            };
            return { aggregatedEntries, dataKeyCount };
        }
        dataKeyCount[entryName] += 1;
        aggregatedEntries[entryName] = {
            ...eachDataEntry,
            y: (aggregatedEntries[entryName].y ?? 0) +
                ((Number.isNaN(Number(eachDataEntry.y)) ||
                    eachDataEntry.y?.toString().trim().length === 0
                    ? NaN
                    : eachDataEntry.y) ?? 0),
            color: eachDataEntry.color ?? aggregatedEntries[entryName].color,
        };
        return { aggregatedEntries, dataKeyCount };
    }, { aggregatedEntries: {}, dataKeyCount: {} });
    if (aggregateMethod === "avg") {
        const { aggregatedEntries, dataKeyCount } = aggregatedData;
        Object.keys(dataKeyCount).forEach((eachKey) => {
            aggregatedEntries[eachKey].y /= dataKeyCount[eachKey];
        });
    }
    return Object.values(aggregatedData.aggregatedEntries);
};
export const aggregateData = (graphData, aggregateMethod = "max") => {
    switch (aggregateMethod) {
        case "max":
        case "min": {
            return aggregateMinOrMax(graphData, aggregateMethod);
        }
        case "sum":
        case "avg": {
            return aggregateSumOrAvg(graphData, aggregateMethod);
        }
        default: {
            if (aggregateMethod !== "none") {
                // eslint-disable-next-line no-console
                console.error(`Specified data aggregation method does not exist: ${aggregateMethod}`);
            }
            return graphData;
        }
    }
};
export const aggregateGraphData = (seriesEntries, aggregationMethod) => {
    const updatedGraphData = [];
    seriesEntries.forEach((eachEntry) => {
        updatedGraphData.push({
            ...eachEntry,
            data: aggregateData(eachEntry?.data ?? [], aggregationMethod),
        });
    });
    return updatedGraphData;
};
