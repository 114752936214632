/* eslint-disable react/display-name */
import * as React from "react";
import { MultiInput } from "./MultiInput";
export const TagInput = React.forwardRef(({ label, value, onChange, name = "tags", numeric = false, ...rest }, ref) => {
    const [enteredValue, setEnteredValue] = React.useState("");
    React.useEffect(() => {
        if (value) {
            const deDupedValue = [...new Set(value)];
            if (deDupedValue.length < value.length)
                onChange(deDupedValue);
        }
    }, [value]);
    const processEnteredValue = () => {
        if (enteredValue) {
            const tagsToAdd = enteredValue
                .split(",")
                .map((tag) => tag.trim())
                .filter((tag) => tag.length > 0);
            onChange([...new Set([...(value ?? []), ...tagsToAdd])]);
            setEnteredValue("");
        }
    };
    const handleKeyDown = (e) => {
        if (e.key === "Enter")
            processEnteredValue();
    };
    const handleTagRemoval = (tagToRemove) => {
        onChange(value?.filter((tag) => tag !== tagToRemove) ?? null);
    };
    const handleChange = (e) => {
        if (e) {
            setEnteredValue(e.target.value);
        }
        else {
            onChange(null);
            setEnteredValue("");
        }
    };
    const handleKeyPress = (e) => {
        if (numeric && !new RegExp(/[0-9, ]/).test(e.key))
            e.preventDefault();
    };
    const values = React.useMemo(() => (value || []).map((i) => ({ label: i, value: i })), [value]);
    return (React.createElement(MultiInput, { value: enteredValue, values: values, label: label, name: name, onValueRemoval: handleTagRemoval, onKeyDown: handleKeyDown, onChange: handleChange, onKeyPress: handleKeyPress, onBlur: processEnteredValue, ref: ref, ...rest }));
});
