import * as React from "react";
import { useDashboard } from "../core/context";
import { getDimensions } from "../core/utils";
export const useGridDimensions = (props) => {
    const { settings, editMode, mobile } = useDashboard();
    return React.useMemo(() => !props
        ? undefined
        : getDimensions(props?.widgets, props?.placement, 
        // WEB-1920: Layout Locked to take effect only in non edit mode
        settings.isLayoutLocked && !editMode, mobile), [props?.widgets, props?.placement, editMode, mobile]);
};
