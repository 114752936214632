import { REST_API } from "../../api";
import { UNKNOWN_INSTRUMENT_INFO } from "../../constants";
import { initialCDXOrderFormState } from "../orders";
import { convertCDXOrderToFormValues, convertFormValueToCDXOrder, convertFormValueToCDXSplitOrder, } from "../utils";
const overrideWithDefaults = (orderInfo) => {
    const defaultValues = {
        ...initialCDXOrderFormState,
        ...orderInfo,
        cdxRoot: orderInfo.cdxRoot || "",
        cdxIndex: orderInfo.cdxIndex,
        otrOnly: orderInfo.otrOnly || false,
        notional: orderInfo.notional?.toString() || null,
        ccp: orderInfo.ccp ?? null,
        fcmDcmId: orderInfo.fcmDcmId ?? null,
        tenor: orderInfo.tenor ?? null,
        cdxUnwind: orderInfo.cdxUnwind || false,
        complianceState: orderInfo.complianceState ?? initialCDXOrderFormState.complianceState,
        limitPrice: orderInfo.limitPrice ?? initialCDXOrderFormState.limitPrice,
        orderType: orderInfo.orderType ?? initialCDXOrderFormState.orderType,
        webOrderType: orderInfo.webOrderType,
        currentCalculatorType: orderInfo.calculatorType,
        accountAllocation: orderInfo.accountAllocation,
        counterpartyMemo: orderInfo.counterpartyMemo ?? initialCDXOrderFormState.counterpartyMemo,
        instructions: orderInfo.instructions || "",
        locateId: orderInfo.locateId ?? initialCDXOrderFormState.locateId,
    };
    return defaultValues;
};
export const handleOrderChange = async (updatedOrder, prevFormValues) => convertCDXOrderToFormValues(updatedOrder, prevFormValues);
export const getDefaultOrderState = async (restServer, id) => {
    try {
        const response = await REST_API(restServer).OEMS.GET_DEFAULT_ORDER.FETCH(id || undefined);
        const newDefaultValues = overrideWithDefaults(response);
        return newDefaultValues;
    }
    catch (err) {
        console.error(err);
        throw err;
    }
};
export const getOrderState = async (restServer, id) => {
    const orderInfo = await REST_API(restServer).OEMS.GET_ORDER("cdx", id);
    const instrument = orderInfo.cdxIndex
        ? await REST_API(restServer).INSTRUMENT.GET_INSTRUMENT_INFO.FETCH(orderInfo.cdxIndex)
        : UNKNOWN_INSTRUMENT_INFO;
    return {
        ...convertCDXOrderToFormValues(orderInfo),
        cdxInstrument: instrument,
        id,
        cdxRoot: instrument?.cdxRoot?.description ?? "",
    };
};
export const getSplitOrderState = async (restServer, id) => {
    const { childOrder } = await REST_API(restServer).OEMS.GET_SPLIT_ORDER("cdx", id);
    const instrument = childOrder.instrumentId
        ? await REST_API(restServer).INSTRUMENT.GET_INSTRUMENT_INFO.FETCH(childOrder.cdxIndex ?? childOrder.instrumentId)
        : UNKNOWN_INSTRUMENT_INFO;
    return {
        ...convertCDXOrderToFormValues(childOrder),
        cdxInstrument: instrument,
        id,
        cdxRoot: instrument?.cdxRoot?.description ?? "",
    };
};
export const convertFormValuesToOrder = (
// eslint-disable-next-line @typescript-eslint/no-explicit-any
previousFormState, 
// eslint-disable-next-line @typescript-eslint/no-explicit-any
newFormState) => {
    if (!newFormState) {
        return convertFormValueToCDXOrder(previousFormState);
    }
    return convertFormValueToCDXOrder({ ...previousFormState, ...newFormState });
};
export const submitOrder = async (restServer, submissionInfo, { transmit, orderId, splitOrder, acceptComplianceWarnings, acceptValidationWarnings, }) => {
    try {
        const bodyContent = {
            ...submissionInfo,
            id: !splitOrder || (splitOrder && acceptComplianceWarnings) ? orderId : 0,
        };
        const newContent = {
            ...bodyContent,
        };
        const response = !transmit
            ? await REST_API(restServer).OEMS.SAVE_ORDER("cdx", newContent, { acceptComplianceWarnings, acceptValidationWarnings })
            : await REST_API(restServer).OEMS.SAVE_AND_TRANSMIT_ORDER("cdx", newContent, { acceptComplianceWarnings, acceptValidationWarnings });
        return response;
    }
    catch (err) {
        console.error("Error when submitting order", err);
        throw err;
    }
};
export const getCurrentOrder = (data, orderId, splitOrder) => {
    return {
        ...(splitOrder
            ? convertFormValueToCDXSplitOrder(data, orderId)
            : convertFormValueToCDXOrder(data, orderId)),
        id: orderId,
    };
};
