/* eslint-disable @typescript-eslint/no-explicit-any */
import { DashboardRoots, } from "@enfusion-ui/types";
import { fileTreeEntriesToSortedNodeData } from "@enfusion-ui/utils";
import queryString from "query-string";
import { encodeSpecialCharacters, getFilePath, getRoot, } from "./utils/documents";
import { varReplace } from "./utils/varReplace";
const baseURL = "/internal/api/documents";
export const DocumentAPIRoots = {
    REPORT: "report",
    REPORTS: "reports",
    DASHBOARD: "dashboard",
    PORTFOLIO: "portfolio",
    CUSTOM_ORDER: "customorder",
    RECON_DASHBOARD: "recondashboard",
};
const typeAndRootQuery = {
    type: {
        optional: false,
        defaultValue: DocumentAPIRoots.REPORT,
        options: Object.values(DocumentAPIRoots),
    },
    root: {
        optional: false,
        defaultValue: DashboardRoots.USER,
        options: Object.values(DashboardRoots),
    },
};
export const calls = {
    GET_FOLDER: {
        url: `${baseURL}/v2/folder`,
        method: "GET",
        query: {
            ...typeAndRootQuery,
            path: {
                defaultValue: "",
            },
        },
    },
    SEARCH: {
        url: `${baseURL}/file/searches/{root}`,
        method: "GET",
        urlParams: {
            root: typeAndRootQuery.root,
        },
        query: {
            type: typeAndRootQuery.type,
            searchText: {
                defaultValue: "",
            },
        },
    },
    CREATE_FOLDER: {
        url: `${baseURL}/v2/folder`,
        method: "POST",
        body: { path: "" },
        query: typeAndRootQuery,
    },
    RENAME_FOLDER: {
        url: `${baseURL}/v2/file`,
        method: "PATCH",
        body: { path: "" },
        query: {
            ...typeAndRootQuery,
            newName: {
                optional: false,
                defaultValue: "",
            },
            forceWrite: {
                optional: false,
                defaultValue: false,
            },
        },
    },
    DELETE_ENTRY: {
        url: `${baseURL}/v2/file`,
        method: "DELETE",
        query: {
            ...typeAndRootQuery,
            path: {
                optional: false,
                defaultValue: "",
            },
        },
    },
    MOVE_ENTRY: {
        url: `${baseURL}/v2/file`,
        method: "PUT",
        body: { path: "" },
        query: {
            ...typeAndRootQuery,
            newPath: {
                optional: false,
                defaultValue: "",
            },
            forceWrite: {
                optional: false,
                defaultValue: false,
            },
        },
    },
    UPLOAD: {
        url: `${baseURL}/{type}/{root}`,
        method: "POST",
        body: {},
        urlParams: {
            root: typeAndRootQuery.root,
        },
        query: {
            type: typeAndRootQuery.type,
            mobile: {
                optional: false,
                defaultValue: false,
            },
        },
    },
    DOWNLOAD: {
        url: `${baseURL}/v2/file`,
        method: "GET",
        query: {
            ...typeAndRootQuery,
            mobile: {
                optional: false,
                defaultValue: false,
            },
            path: {
                optional: false,
                defaultValue: "",
            },
        },
    },
};
export default (callCreator) => {
    const getRootNodeFetch = callCreator((root, subRoot) => queryString.stringifyUrl({
        url: calls.GET_FOLDER.url,
        query: {
            type: `${root}`,
            root: getRoot(subRoot),
            path: subRoot.includes("/")
                ? encodeSpecialCharacters(getFilePath(subRoot))
                : undefined,
        },
    }));
    const getSearchRootFetch = callCreator((root, subRoot, searchText) => queryString.stringifyUrl({
        url: varReplace(calls.SEARCH.url, { root: subRoot }),
        query: { type: `${root}`, searchText },
    }));
    const getRootNode = async (root, subRoot, diffReport = false, abortController) => {
        try {
            const rootData = await getRootNodeFetch.FETCH(root, subRoot, abortController);
            return rootData?.children
                ? fileTreeEntriesToSortedNodeData(rootData.children, undefined, diffReport)
                : [];
        }
        catch (err) {
            console.error("REMOTE FILE DETAIL ERROR", err);
            throw new Error("Failed to get root node");
        }
    };
    const getSearchRootNode = async (root, subRoot, searchText, diffReport = false, abortController) => {
        try {
            const rootData = await getSearchRootFetch.FETCH(root, subRoot, searchText, abortController);
            return rootData?.children
                ? fileTreeEntriesToSortedNodeData(rootData.children, undefined, diffReport)
                : [];
        }
        catch (err) {
            console.error("REMOTE FILE DETAIL ERROR", err);
            throw new Error("Failed to get root node");
        }
    };
    return {
        GET_ENTRY: getRootNodeFetch,
        GET_ROOT_NODE: getRootNode,
        GET_SEARCH_ROOT_NODE: getSearchRootNode,
        GET_ROOT_NODE_FACTORY: (root) => (node, diffReport = false, abortController) => {
            try {
                const nodePath = typeof node === "string"
                    ? node
                    : node.path;
                return getRootNode(root, `${nodePath}`, diffReport, abortController);
            }
            catch (err) {
                throw new Error("Failed to get root details");
            }
        },
        CREATE_FOLDER: callCreator((root, path) => [
            queryString.stringifyUrl({
                url: calls.CREATE_FOLDER.url,
                query: {
                    root: getRoot(path),
                    type: `${root}`,
                },
            }),
            {
                method: "POST",
                body: { path: getFilePath(path) },
            },
        ]),
        RENAME_FOLDER: callCreator((root, filePath, newFolderName, forceWrite = false) => [
            queryString.stringifyUrl({
                url: calls.RENAME_FOLDER.url,
                query: {
                    type: `${root}`,
                    root: getRoot(filePath),
                    newName: newFolderName,
                    forceWrite,
                },
            }),
            {
                method: "PATCH",
                body: {
                    path: getFilePath(filePath),
                },
            },
        ]),
        DELETE_ENTRY: callCreator((root, filePath) => [
            queryString.stringifyUrl({
                url: calls.DELETE_ENTRY.url,
                query: {
                    type: `${root}`,
                    root: getRoot(filePath),
                    path: encodeSpecialCharacters(getFilePath(filePath)),
                },
            }),
            { method: "DELETE" },
        ]),
        MOVE_ENTRY: callCreator((root, filePath, destinationFolder, forceWrite = false) => [
            queryString.stringifyUrl({
                url: calls.MOVE_ENTRY.url,
                query: {
                    type: `${root}`,
                    root: getRoot(filePath),
                    newPath: destinationFolder,
                    forceWrite,
                },
            }),
            {
                method: "PUT",
                body: {
                    path: getFilePath(filePath),
                },
            },
        ]),
        UPLOAD: callCreator((root, subRoot, body, mobile) => [
            queryString.stringifyUrl({
                url: varReplace(calls.UPLOAD.url, {
                    root: subRoot,
                    type: root === DocumentAPIRoots.REPORTS ? "reports" : "file",
                }),
                query: { type: root, mobile },
            }),
            { body },
        ]),
        DOWNLOAD: callCreator((root, filePath, mobile) => queryString.stringifyUrl({
            url: calls.DOWNLOAD.url,
            query: {
                root: getRoot(filePath),
                type: `${root}`,
                mobile,
                path: encodeSpecialCharacters(getFilePath(filePath)),
            },
        })),
    };
};
