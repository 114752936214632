/* eslint-disable @typescript-eslint/no-explicit-any */
import { useRefCallback } from "@enfusion-ui/hooks";
import * as React from "react";
import { useFileExplorerContext } from "./context";
const FileNodeEntry = React.memo(function FileNodeEntry({ node, ...passProps }) {
    const { onEntryClick, onEntryContext, onFileCheckChange, FileNode, FileNodeProps, getFileSelectionState, subscribeToSelectionChange, updateNodeSelectionState, } = useFileExplorerContext();
    const [selected, setSelected] = React.useState(() => getFileSelectionState(node, true));
    const handleClick = useRefCallback((e) => onEntryClick(node, e), [onEntryClick, node]);
    const handleContext = useRefCallback((e) => onEntryContext(node, e), [onEntryContext, node]);
    const handleCheckChange = useRefCallback((value) => {
        onFileCheckChange(node, value);
    }, [onFileCheckChange, node]);
    React.useEffect(() => {
        return subscribeToSelectionChange(node.path, (selectionState) => {
            setSelected(selectionState === 1 ? true : false);
            updateNodeSelectionState(node, selectionState === 1 ? true : false);
        });
    }, [node]);
    return (React.createElement(FileNode, { ...FileNodeProps, ...passProps, node: node, selected: selected, onClick: handleClick, onContextMenu: handleContext, onCheckChange: handleCheckChange }));
});
export default FileNodeEntry;
