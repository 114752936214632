import { EMPTY_RECORD } from "@enfusion-ui/core";
import { useMouseOverSimple } from "@enfusion-ui/hooks";
import { createTestId } from "@enfusion-ui/utils";
import { styled, useIsMobileDevice } from "@enfusion-ui/web-core";
import { faAngleDown, faAngleRight, faEllipsisH, faSearch, } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { noop } from "lodash";
import Menu, { Divider, MenuItem, SubMenu } from "rc-menu";
import * as React from "react";
import { IconButton } from "../control";
import { Portal } from "../portal";
export const AccordionContext = React.createContext({
    setMenu: noop,
    setSearch: noop,
    toggleSearch: noop,
    setForceSearchIcon: noop,
    setAllowClickOutside: noop,
    allowClickOutside: false,
    forceSearchIcon: false,
    searchable: false,
    searchOpen: false,
});
const AccordionContentContainer = styled.div `
  width: 100%;
  min-height: 0px;
  z-index: 5;
  display: flex;
  flex-direction: column;
  ${({ open }) => (open ? "flex: 1" : "display: none")};
`;
const SearchContentContainer = styled.div `
  display: ${({ open }) => (open ? "block" : "none")};
`;
const AccordionTitleContainer = styled.div `
  z-index: 10;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-direction: row;
  cursor: pointer;
  border-color: var(--input-border);
  background-color: var(--background-color-1);
  color: var(--text-normal);
  ${({ elevated }) => elevated ? "box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.2);" : ""}
`;
const AccordionTitleText = styled.span `
  flex: 1;
  margin: 0 10px;
  font-size: 14px;

  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  min-width: 0px;
`;
const renderMenuItem = (item, idx) => {
    if (item === "divider") {
        return React.createElement(Divider, { key: `divider-${idx}` });
    }
    else if (Array.isArray(item.children)) {
        return (React.createElement(SubMenu, { key: item.key, title: item.title },
            item.icon ? (React.createElement(FontAwesomeIcon, { icon: item.icon, style: { marginRight: "var(--spacing-l)" }, size: "sm" })) : null,
            item.children.map(renderMenuItem)));
    }
    return (React.createElement(MenuItem, { key: item.key, onClick: item.onClick, "data-testid": typeof item.title === "string"
            ? `menu-item-${item.title?.toLowerCase().split(" ").join("-")}`
            : "menu-item" },
        item.icon ? (React.createElement(FontAwesomeIcon, { icon: item.icon, style: { marginRight: "var(--spacing-l)" }, size: "sm" })) : null,
        item.title));
};
export const AccordionTitle = ({ title, open, leftIcon, onClick, className, elevated, style, onContext, iconPlacement = "left", rightContent, icon = (openIcon) => openIcon ? (React.createElement(FontAwesomeIcon, { "data-testid": createTestId("angle-down-icon"), icon: faAngleDown })) : (React.createElement(FontAwesomeIcon, { "data-testid": createTestId("angle-right-icon"), icon: faAngleRight, style: { marginLeft: 2 } })), dataTestId, }) => {
    const { menu, allowClickOutside, selectedKeys, searchable, searchOpen, toggleSearch, forceSearchIcon, } = React.useContext(AccordionContext);
    const [menuOpen, setMenuOpen] = React.useState(false);
    const menuIconRef = React.useRef(null);
    const isMobileDevice = useIsMobileDevice();
    const { onMouseEnter, onMouseLeave, over: isHovering } = useMouseOverSimple();
    const closeMenu = () => setMenuOpen(false);
    const openMenu = (e) => {
        e.preventDefault();
        e.stopPropagation();
        setMenuOpen(true);
    };
    const handleMenuClick = () => {
        closeMenu();
    };
    const openSearch = (e) => {
        e.preventDefault();
        e.stopPropagation();
        toggleSearch();
    };
    return (React.createElement(AccordionTitleContainer, { elevated: elevated, className: className, style: style, onClick: onClick, onContextMenu: onContext, onMouseEnter: onMouseEnter, onMouseLeave: onMouseLeave, "data-testid": createTestId("accordion-title", title, dataTestId), open: open },
        leftIcon,
        iconPlacement === "left" ? icon(open) : null,
        React.createElement(AccordionTitleText, { title: typeof title === "string" ? title : undefined }, title),
        rightContent,
        iconPlacement === "right" ? icon(open) : null,
        searchable &&
            (isMobileDevice || forceSearchIcon || searchOpen || isHovering) ? (React.createElement(IconButton, { title: "Search", icon: faSearch, onClick: openSearch, style: {
                color: forceSearchIcon ? "var(--primary)" : "var(--text-normal)",
            }, hoverable: true, "data-testid": createTestId("search-icon") })) : null,
        menu && menu.length > 0 ? (React.createElement(React.Fragment, null,
            React.createElement(IconButton, { title: "Menu", "data-testid": createTestId(`menu-icon-${title}`), icon: faEllipsisH, ref: menuIconRef, onClick: openMenu, hoverable: true }),
            React.createElement(Portal, { open: menuOpen, attachedRef: menuIconRef, onClickOutside: allowClickOutside ? undefined : closeMenu, align: "right" },
                React.createElement(Menu, { onClick: handleMenuClick, selectedKeys: selectedKeys }, menu.map(renderMenuItem))))) : null));
};
export const Accordion = React.memo(function Accordion({ icon, title, leftIcon, rightContent, iconPlacement, className, defaultOpen = false, last = true, contentComponent: ContentComponent, contentContainerStyle = EMPTY_RECORD, style, onClick, open, elevated, onContext, children, dataTestId, }) {
    const [isOpen, setOpen] = React.useState(open || defaultOpen);
    const [allowClickOutside, setAllowClickOutside] = React.useState(false);
    const [searchComponent, setSearch] = React.useState(null);
    const [searchOpen, setSearchOpen] = React.useState(false);
    const [forceSearchIcon, setForceSearchIcon] = React.useState(false);
    const [menuState, setMenu] = React.useState();
    const { menu, selectedKeys } = menuState || {};
    React.useEffect(() => {
        if (typeof open !== "undefined") {
            setOpen(open);
        }
    }, [open]);
    const toggleOpen = (e) => {
        if (typeof open === "undefined") {
            setOpen((val) => !val);
        }
        if (onClick)
            onClick(e);
    };
    const toggleSearch = () => {
        setSearchOpen((val) => !val);
    };
    return (React.createElement(AccordionContext.Provider, { value: {
            menu,
            searchable: searchComponent !== null,
            searchOpen,
            selectedKeys,
            forceSearchIcon,
            setMenu,
            setSearch,
            toggleSearch,
            setForceSearchIcon,
            allowClickOutside,
            setAllowClickOutside,
        } },
        React.createElement(AccordionTitle, { className: className, style: typeof style === "function" ? style({ open: isOpen }) : style, leftIcon: leftIcon, icon: icon, iconPlacement: iconPlacement, title: title, rightContent: rightContent, open: isOpen, onClick: toggleOpen, elevated: elevated, onContext: onContext, dataTestId: dataTestId }),
        React.createElement(AccordionContentContainer, { open: isOpen, style: contentContainerStyle, "data-testid": createTestId("accordion-content", title) },
            searchComponent ? (React.createElement(SearchContentContainer, { "data-testid": createTestId("search-container-content"), open: searchOpen }, searchComponent)) : null,
            ContentComponent && isOpen ? (React.createElement(ContentComponent, { open: isOpen, last: last })) : null,
            children)));
});
export const AccentBarAccordion = styled(Accordion) `
  background-color: var(--background-accent);
  border-color: var(--border);
  border-bottom-width: 1px;
  padding: 0px 10px;
`;
export const FormSectionAccordion = styled(Accordion).attrs({
    contentContainerStyle: {
        padding: "0px var(--spacing-l) var(--spacing-l)",
        backgroundColor: "var(--background-accent)",
        borderBottomLeftRadius: "5px",
        borderBottomRightRadius: "5px",
    },
    style: ({ open }) => ({
        marginBottom: open ? "0px" : "var(--spacing-l)",
        borderBottomLeftRadius: open ? "0px" : "5px",
        borderBottomRightRadius: open ? "0px" : "5px",
    }),
}) `
  background-color: var(--background-accent);
  border-color: var(--border);
  border-bottom-width: 1px;
  padding: var(--spacing-xl);
  border-radius: 5px;
`;
