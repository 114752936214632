import { faBookOpen } from "@fortawesome/pro-solid-svg-icons";
import { widgetWithDatasourceDefaultConfig } from "../../../utils";
export const tile = {
    name: "Tile",
    minSize: [5, 2],
    icon: faBookOpen,
    description: "Add a tile",
    datasourceConfigIds: ["datasourceId"],
    defaultConfig: {
        ...widgetWithDatasourceDefaultConfig,
    },
    type: "simpleDisplay",
    category: "dashboard",
};
