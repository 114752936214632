import { getSelectOption } from "@enfusion-ui/utils";
import * as React from "react";
import { Select } from "../Select";
const FONT_SIZE_OPTIONS = [
    {
        label: "Extra Large",
        value: "x-large",
    },
    {
        label: "Large",
        value: "large",
    },
    {
        label: "Medium",
        value: "medium",
    },
    {
        label: "Small",
        value: "small",
    },
    {
        label: "Extra Small",
        value: "x-small",
    },
];
export const FontSize = ({ label = "Font Size", name, errors, selectedValue, clearable, disabled, required, onChange, minWidth, }) => {
    return (React.createElement(Select, { label: label, name: name, errors: errors, value: getSelectOption(FONT_SIZE_OPTIONS, selectedValue), options: FONT_SIZE_OPTIONS, onChange: onChange, creatable: true, clearable: clearable, required: required, disabled: disabled, minWidth: minWidth, inputId: "font-size-selection-id" }));
};
