import { formatDateToStandardFormat } from "@enfusion-ui/utils";
import { REST_API } from "../../api";
import { UNKNOWN_INSTRUMENT_INFO } from "../../constants";
import { initialIRSOrderFormState } from "../orders";
import { convertFormValueToIRSOrder, convertFormValueToIRSSplitOrder, convertIRSOrderToFormValues, } from "../utils";
const overrideWithDefaults = (orderInfo) => {
    const defaultValues = {
        ...initialIRSOrderFormState,
        ...orderInfo,
        fixedNotional: orderInfo.fixedNotional?.toString() || null,
        ccp: orderInfo.ccp ?? null,
        fcmDcmId: orderInfo.fcmDcmId ?? null,
        tenor: orderInfo.tenor ?? null,
        quickTemplate: orderInfo.quickTemplate ?? null,
        atm: orderInfo.atm ?? true,
        rate: orderInfo.rate ?? 0,
        startDate: orderInfo.startDate ?? "",
        endDate: orderInfo.endDate ?? "",
        complianceState: orderInfo.complianceState ?? initialIRSOrderFormState.complianceState,
        limitPrice: orderInfo.limitPrice ?? initialIRSOrderFormState.limitPrice,
        orderType: orderInfo.orderType ?? initialIRSOrderFormState.orderType,
        webOrderType: orderInfo.webOrderType,
        currentCalculatorType: orderInfo.calculatorType,
        accountAllocation: orderInfo.accountAllocation,
        counterpartyMemo: orderInfo.counterpartyMemo ?? initialIRSOrderFormState.counterpartyMemo,
        instructions: orderInfo.instructions || "",
        locateId: orderInfo.locateId ?? initialIRSOrderFormState.locateId,
    };
    return defaultValues;
};
export const handleOrderChange = async (updatedOrder, prevFormValues, restServer) => {
    let instrument;
    if (updatedOrder.instrumentId) {
        const api = REST_API(restServer);
        instrument = await api.INSTRUMENT.GET_INSTRUMENT_INFO.FETCH(updatedOrder.instrumentId);
    }
    let enrichedOrder = updatedOrder;
    if (instrument)
        enrichedOrder = { ...enrichedOrder, irsInstrument: instrument };
    return convertIRSOrderToFormValues(enrichedOrder, prevFormValues);
};
export const getDefaultOrderState = async (restServer, id) => {
    try {
        const response = await REST_API(restServer).OEMS.GET_DEFAULT_ORDER.FETCH(id || undefined);
        const newDefaultValues = overrideWithDefaults(response);
        return newDefaultValues;
    }
    catch (err) {
        console.error(err);
        throw err;
    }
};
export const getOrderState = async (restServer, id) => {
    const orderInfo = await REST_API(restServer).OEMS.GET_ORDER("irs", id);
    const instrument = orderInfo.instrumentId
        ? await REST_API(restServer).INSTRUMENT.GET_INSTRUMENT_INFO.FETCH(orderInfo.instrumentId)
        : UNKNOWN_INSTRUMENT_INFO;
    return {
        ...convertIRSOrderToFormValues(orderInfo),
        irsInstrument: instrument,
    };
};
export const getSplitOrderState = async (restServer, id) => {
    const { childOrder } = await REST_API(restServer).OEMS.GET_SPLIT_ORDER("irs", id);
    const instrument = UNKNOWN_INSTRUMENT_INFO;
    return {
        ...convertIRSOrderToFormValues(childOrder),
        irsInstrument: instrument,
    };
};
export const convertFormValuesToOrder = (
// eslint-disable-next-line @typescript-eslint/no-explicit-any
previousFormState, 
// eslint-disable-next-line @typescript-eslint/no-explicit-any
newFormState) => {
    if (!newFormState) {
        return convertFormValueToIRSOrder(previousFormState);
    }
    return convertFormValueToIRSOrder({ ...previousFormState, ...newFormState });
};
export const submitOrder = async (restServer, submissionInfo, { transmit, orderId, splitOrder, acceptComplianceWarnings, }) => {
    try {
        const bodyContent = {
            ...submissionInfo,
            id: !splitOrder || (splitOrder && acceptComplianceWarnings) ? orderId : 0,
        };
        const newContent = {
            ...bodyContent,
            startDate: formatDateToStandardFormat(new Date(bodyContent.startDate ?? new Date())),
            endDate: formatDateToStandardFormat(new Date(bodyContent.endDate ?? new Date())),
            rate: bodyContent.rate * 0.01 ??
                undefined,
        };
        const response = !transmit
            ? await REST_API(restServer).OEMS.SAVE_ORDER("irs", newContent)
            : await REST_API(restServer).OEMS.SAVE_AND_TRANSMIT_ORDER("irs", newContent, { acceptComplianceWarnings });
        return response;
    }
    catch (err) {
        console.error("Error when submitting order", err);
        throw err;
    }
};
export const getCurrentOrder = (data, orderId, splitOrder) => {
    return {
        ...(splitOrder
            ? convertFormValueToIRSSplitOrder(data, orderId)
            : convertFormValueToIRSOrder(data)),
        id: orderId,
    };
};
