/* eslint-disable react/display-name */
import { useRefCallback } from "@enfusion-ui/hooks";
import { styled } from "@enfusion-ui/web-core";
import * as React from "react";
const SimpleMenuDrawer = styled.div `
  width: 100%;
  min-width: 100%;
  max-width: 300px;
  box-shadow: 0px 3px 8px 1px var(--overlay);
  background-color: var(--background-color-2);
  overflow-x: hidden;
  max-height: 400px;
  overflow-y: auto;
  position: relative;
  margin-top: 0.1rem;
  padding: 0.25rem 0;
  border: 1px solid var(--input-border);
  border-radius: 3px;
  color: var(--text-muted);
  display: flex;
  flex-direction: column;
  align-items: center;
`;
const MenuEntryContainer = styled.div `
  padding: 0.25rem 0.5rem;
  padding-bottom: 0.4rem;
  cursor: pointer;
  width: 100%;
  background-color: ${({ selected }) => selected ? "var(--primary-hover)" : "transparent"};
  color: var(--text-normal);
  border-top: 1px solid
    ${({ first }) => !first ? "var(--background-accent)" : "transparent"};

  :hover {
    background-color: var(--primary-hover);
  }
`;
const MenuEntryText = styled.div `
  white-space: nowrap;
  max-width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  line-height: 18px;
`;
const SimpleMenuEntry = React.memo(({ index, onSelect, label, display, selected, first }) => {
    const handleClick = () => onSelect(index);
    return (React.createElement(MenuEntryContainer, { selected: selected, onClick: handleClick, title: label, first: first },
        React.createElement(MenuEntryText, null, display ? display : label)));
});
const PlaceholderCard = styled.div `
  width: max-content;
  white-space: nowrap;
  max-width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  line-height: 18px;
  color: var(--text-color-1);
`;
export const SimpleMenu = ({ onSelect, value, options, placeholder, open, }) => {
    const getOptionIdx = React.useCallback((value) => {
        return options.findIndex((entry) => Object.is(entry.value, value));
    }, [options]);
    const [focusedIndex, setFocusedIndex] = React.useState(getOptionIdx(value));
    React.useEffect(() => {
        setFocusedIndex(getOptionIdx(value));
    }, [value]);
    const handleKeyDown = useRefCallback((e) => {
        if (open) {
            e.preventDefault();
            e.stopPropagation();
            if (e.key === "ArrowDown") {
                setFocusedIndex((idx) => {
                    idx += 1;
                    return idx >= options.length - 1 ? options.length - 1 : idx;
                });
            }
            if (e.key === "ArrowUp") {
                setFocusedIndex((idx) => {
                    idx -= 1;
                    return idx <= 0 ? 0 : idx;
                });
            }
            if (e.key === "Enter") {
                onSelect(focusedIndex);
            }
        }
    }, [open, focusedIndex]);
    React.useEffect(() => {
        window.addEventListener("keydown", handleKeyDown);
        return () => {
            window.removeEventListener("keydown", handleKeyDown);
        };
    }, [open, handleKeyDown]);
    return (React.createElement(SimpleMenuDrawer, null, options.length ? (options.map((entry, idx) => (React.createElement(SimpleMenuEntry, { key: idx, index: idx, onSelect: onSelect, label: entry.label, display: entry.display, first: idx === 0, selected: idx === focusedIndex })))) : (React.createElement(PlaceholderCard, null, placeholder))));
};
