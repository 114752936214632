import { styled } from "@enfusion-ui/web-core";
import { faCheckCircle, faExclamationCircle, faExclamationTriangle, faInfoCircle, } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import * as React from "react";
const AlertContainer = styled.div `
  background-color: ${({ type }) => `var(--${type})`};
  color: var(--text-normal);
  width: 100%;
  border-radius: 4px;
  border: 1px solid var(--text-input-border);
  padding: 0.5em;
  font-size: 0.75em;
`;
const Message = styled.span `
  font-weight: bold;
  margin-left: 0.25em;
`;
export function Alert({ type, message, icon }) {
    return (React.createElement(AlertContainer, { type: type },
        React.createElement(FontAwesomeIcon, { icon: icon, size: "lg" }),
        React.createElement(Message, null, message)));
}
export function ErrorAlert({ error }) {
    return (React.createElement(Alert, { type: "danger", message: typeof error === "string" ? error : error.message, icon: faExclamationCircle }));
}
export function WarningAlert({ warning }) {
    return (React.createElement(Alert, { type: "warning", message: warning, icon: faExclamationTriangle }));
}
export function SuccessAlert({ message }) {
    return React.createElement(Alert, { type: "warning", message: message, icon: faCheckCircle });
}
export function InfoAlert({ message }) {
    return React.createElement(Alert, { type: "info", message: message, icon: faInfoCircle });
}
