/* eslint-disable import/prefer-default-export */
import * as React from "react";
import { useRefCallback } from "./useRefCallback";
export function useMounted(defaultValue = true) {
    const mountedRef = React.useRef(defaultValue);
    const get = useRefCallback(() => mountedRef.current, [mountedRef]);
    React.useEffect(() => {
        mountedRef.current = true;
        return () => {
            mountedRef.current = false;
        };
    }, [mountedRef]);
    return get;
}
