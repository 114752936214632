import { styled } from "@enfusion-ui/web-core";
import * as React from "react";
const ToggleSwitchContainer = styled.div `
  position: relative;
`;
const ToggleSwitchLabel = styled.label `
  position: absolute;
  top: 0;
  left: 0;
  width: 42px;
  height: 24px;
  border: 1px solid var(--input-border);
  border-radius: var(--spacing-xl);
  background: var(--input-background);
  cursor: pointer;

  &::after {
    display: block;
    content: "";
    border-radius: 50%;
    width: 16px;
    height: 16px;
    margin: 3px;
    background: var(--text-normal);
    transition: 0.2s;
  }
`;
const ToggleSwitchInput = styled.input `
  opacity: 0;
  z-index: 1;
  border-radius: 15px;
  width: 42px;
  height: 24px;
  &:checked + ${ToggleSwitchLabel} {
    background: var(--primary);

    &::after {
      display: block;
      border-radius: 50%;
      width: 16px;
      height: 16px;
      margin-left: var(--spacing-xxl);
      transition: 0.2s;
    }
  }
`;
export const ToggleSwitch = ({ id, checked, disabled, onChange, }) => {
    return (React.createElement(React.Fragment, null,
        React.createElement(ToggleSwitchContainer, null,
            React.createElement(ToggleSwitchInput, { id: id, type: "checkbox", checked: disabled || checked, onChange: onChange }),
            React.createElement(ToggleSwitchLabel, { htmlFor: id }))));
};
