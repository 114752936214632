import { styled, useTheme } from "@enfusion-ui/web-core";
import * as React from "react";
const Svg = styled.svg `
  display: inline-block;
  font-size: inherit;
  overflow: visible;
  margin: 0 0.25rem;
`;
export const EnfusionIcon = ({ width = "1.2rem", height = "1.2rem", style = { margin: "0 0.25rem" }, }) => {
    const { theme } = useTheme();
    return (React.createElement(Svg, { viewBox: "0 0 400 400", width: width, height: height, style: style },
        React.createElement("path", { d: "M188.9 332.9c0 6.1 5 11.1 11.1 11.1s11.1-5 11.1-11.1-5-11.1-11.1-11.1-11.1 5-11.1 11.1m-5.5-60.9c0 9.2 7.4 16.6 16.6 16.6s16.6-7.4 16.6-16.6c0-9.2-7.4-16.6-16.6-16.6s-16.6 7.4-16.6 16.6M78.2 200c0-6.1-5-11.1-11.1-11.1S56 193.9 56 200s5 11.1 11.1 11.1 11.1-5 11.1-11.1m66.4-72c0-9.2-7.4-16.6-16.6-16.6s-16.6 7.4-16.6 16.6c0 9.2 7.4 16.6 16.6 16.6s16.6-7.4 16.6-16.6m0 72c0-9.2-7.4-16.6-16.6-16.6s-16.6 7.4-16.6 16.6c0 9.2 7.4 16.6 16.6 16.6s16.6-7.4 16.6-16.6m0 72c0-9.2-7.4-16.6-16.6-16.6s-16.6 7.4-16.6 16.6c0 9.2 7.4 16.6 16.6 16.6s16.6-7.4 16.6-16.6m66.5-204.9c0-6.1-5-11.1-11.1-11.1s-11.1 5-11.1 11.1 5 11.1 11.1 11.1 11.1-5 11.1-11.1m5.5 60.9c0-9.2-7.4-16.6-16.6-16.6s-16.6 7.4-16.6 16.6c0 9.2 7.4 16.6 16.6 16.6s16.6-7.4 16.6-16.6m5.6 72c0-12.2-9.9-22.2-22.2-22.2-12.2 0-22.2 9.9-22.2 22.2 0 12.2 9.9 22.2 22.2 22.2 12.2 0 22.2-10 22.2-22.2m66.4-72c0-9.2-7.4-16.6-16.6-16.6s-16.6 7.4-16.6 16.6c0 9.2 7.4 16.6 16.6 16.6s16.6-7.4 16.6-16.6m0 72c0-9.2-7.4-16.6-16.6-16.6s-16.6 7.4-16.6 16.6c0 9.2 7.4 16.6 16.6 16.6s16.6-7.4 16.6-16.6m55.4 0c0-6.1-5-11.1-11.1-11.1s-11.1 5-11.1 11.1 5 11.1 11.1 11.1 11.1-5 11.1-11.1", fill: ["light"].includes(theme.key) ? "#7D5FFF" : "#FFFFFF" })));
};
