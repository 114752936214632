export function getBlobFromXHR(xhr, contentType) {
    let filename = "";
    const contentDisposition = xhr.getResponseHeader("Content-Disposition");
    if (contentDisposition && contentDisposition.indexOf("attachment") !== -1) {
        const filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
        const matches = filenameRegex.exec(contentDisposition);
        if (matches != null && matches[1])
            filename = matches[1].replace(/['"]/g, "");
    }
    const type = contentType || xhr.getResponseHeader("Content-Type") || "";
    let blob;
    if (typeof File === "function") {
        try {
            blob = new File([xhr.response], filename, { type });
        }
        catch (e) {
            /* Edge */
        }
    }
    if (typeof blob === "undefined") {
        blob = new Blob([xhr.response], { type });
    }
    return { blob, filename };
}
export async function blobToDataURL(blob) {
    return new Promise((accept, reject) => {
        try {
            const a = new FileReader();
            a.onload = (e) => {
                if (e.target) {
                    accept(e.target.result);
                }
            };
            a.onerror = (e) => reject(e);
            a.readAsDataURL(blob);
        }
        catch (e) {
            reject(e);
        }
    });
}
