import { AppEvent, AppEventCategories, } from "@enfusion-ui/types";
import base64 from "base-64";
import { camelCase } from "lodash";
import queryString from "query-string";
import { TOAST_CONTENT } from "./textConstants";
const copyToClipboardProps = (successToast, errorToast, getText, successText, successLinkText, errorText, onSuccess = () => {
    /** noop */
}) => {
    return {
        getText,
        onSuccess: (successType = "success") => {
            successToast(successType === "success" ? successText : successLinkText);
            onSuccess();
        },
        onError: () => errorToast(errorText),
    };
};
const sectionMap = {
    Services: "serviceFile",
};
function getQueryKey(section) {
    if (sectionMap[section])
        return sectionMap[section];
    return camelCase(section).slice(0, -1);
}
export const createShareActionCreator = (origin, logging, successToast, errorToast) => {
    return (section) => {
        return (name, path) => {
            return copyToClipboardProps(successToast, errorToast, () => queryString.stringifyUrl({
                url: `${origin}/linking`,
                query: {
                    [getQueryKey(section)]: base64.encode(JSON.stringify({ p: path })),
                },
            }), TOAST_CONTENT[section].share.success, TOAST_CONTENT[section].share.linkSuccess, TOAST_CONTENT[section].share.failure, () => {
                logging.event({
                    event: AppEvent.CopyShareLink,
                    category: AppEventCategories[section],
                }, { name, path });
            });
        };
    };
};
function isValidString(value) {
    return typeof value === "string" && value.trim().length !== 0;
}
function getRoot(path) {
    if (path.includes("/")) {
        return path.substring(0, path.indexOf("/"));
    }
    else
        return path;
}
export function parseShareLink(url) {
    const { query } = queryString.parseUrl(url);
    function parseSection(part, section) {
        try {
            const { p: path, r: rootBase = "null" } = JSON.parse(base64.decode(part));
            const root = rootBase === "null" ? getRoot(path || "") : rootBase;
            if (!isValidString(path) || !isValidString(root))
                throw new Error("Invalid root or filePath - root: " + root + ", filePath: " + path);
            return { type: section, path, root };
        }
        catch (err) {
            const msg = TOAST_CONTENT[section].deepLink.notValid;
            console.warn(msg, err);
            throw new Error(msg);
        }
    }
    if (query.report)
        return parseSection(query.report, "Reports");
    if (query.serviceFile)
        return parseSection(query.serviceFile, "Services");
    if (query.generalFile)
        return parseSection(query.generalFile, "GeneralFiles");
    if (query.portfolio)
        return parseSection(query.portfolio, "Portfolios");
    return null;
}
