/* eslint-disable @typescript-eslint/no-explicit-any */
import { FACTSET_LOADED_KEY } from "@enfusion-ui/core";
import { useUserAgent } from "@enfusion-ui/hooks/build/web";
import { mobilePostMessage } from "@enfusion-ui/utils";
import { useAuth } from "@enfusion-ui/web-core";
import queryString from "query-string";
import * as React from "react";
import { Spinner } from "../display";
import { CenterContent, SVGBackground } from "../Styled";
import { DuplicateLogInPage } from "./login";
function addListener(listener) {
    if (navigator.appVersion.includes("Android")) {
        document.addEventListener("message", listener);
    }
    else {
        window.addEventListener("message", listener);
    }
}
function removeListener(listener) {
    if (navigator.appVersion.includes("Android")) {
        document.removeEventListener("message", listener);
    }
    else {
        window.removeEventListener("message", listener);
    }
}
export function MobileAppAuth() {
    const { user, isEnabled, logout, isLoggedInDesktop } = useAuth();
    // eslint-disable-next-line no-restricted-globals
    const parsed = queryString.parse(location.search);
    const agent = useUserAgent();
    const FactSetLoaded = localStorage.getItem(FACTSET_LOADED_KEY);
    const doAuthActions = React.useMemo(() => !isEnabled("FactSet") ||
        (agent.os.name !== "ios" && agent.browser.name !== "safari") ||
        (isEnabled("FactSet") &&
            (!process.env.REACT_APP_FACTSET_REDIRECT ||
                (process.env.REACT_APP_FACTSET_REDIRECT && FactSetLoaded === "1"))), [user, isEnabled, FactSetLoaded, agent]);
    React.useEffect(() => {
        if (doAuthActions || user === null) {
            mobilePostMessage({
                source: "mobile-app-auth",
                action: "set-user",
                user,
            });
        }
    }, [user, doAuthActions]);
    React.useEffect(() => {
        const listener = async (event) => {
            if (event.data) {
                if (typeof event.data === "string") {
                    try {
                        const { source, action } = JSON.parse(event.data);
                        if (source === "mobile-app" && action === "logout") {
                            console.log("logout");
                            await logout("mobile app called");
                        }
                    }
                    catch (err) {
                        console.log("failed to parse event data", event.data);
                    }
                }
                else if (event.data.type === "token_updated" &&
                    event.origin === "https://mlp.factset.com") {
                    mobilePostMessage({
                        source: "mobile-app-auth",
                        action: "factset-token",
                        token: event.data.token,
                    });
                }
                else {
                    mobilePostMessage(event.data);
                }
            }
        };
        if (doAuthActions)
            addListener(listener);
        return () => {
            removeListener(listener);
        };
    }, [doAuthActions]);
    return (React.createElement(React.Fragment, null,
        React.createElement(SVGBackground, null,
            isLoggedInDesktop && React.createElement(DuplicateLogInPage, null),
            React.createElement(CenterContent, null,
                React.createElement(Spinner, null))),
        user && doAuthActions && isEnabled("FactSet") ? (React.createElement("iframe", { id: "factset auth frame", title: "factset auth frame", src: `https://mlp.factset.com/datafeed?idpid=${user.flags?.FactSet.idpId}`, style: {
                display: parsed.showFeed ? "block" : "none",
                width: "100%",
                minHeight: 100,
            } })) : null));
}
