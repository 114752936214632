import * as React from "react";
import { useMounted } from "./useMounted";
export function useRect(ref, pollInterval = 1000) {
    const isMounted = useMounted();
    const [rect, setRect] = React.useState(() => ref.current?.getBoundingClientRect());
    React.useEffect(() => {
        requestAnimationFrame(() => {
            if (isMounted())
                setRect(ref.current?.getBoundingClientRect());
        });
        let timer;
        if (pollInterval !== null) {
            timer = setInterval(() => {
                if (isMounted())
                    setRect(ref.current?.getBoundingClientRect());
            }, pollInterval);
        }
        return () => clearInterval(timer);
    }, [pollInterval]);
    const { x, y, width, height } = rect || { x: 0, y: 0, width: 0, height: 0 };
    return React.useMemo(() => ({ x, y, width, height }), [x, y, width, height]);
}
