import { faSearchDollar } from "@fortawesome/pro-solid-svg-icons";
export const orderDetailsPanel = {
    name: "Order Details Panel",
    minSize: (mobile) => (mobile ? [12, 2] : [18, 2]),
    defaultSize: [18, 10],
    maxSize: [42, 12],
    icon: faSearchDollar,
    description: "Choose order details",
    defaultConfig: {
        defaultValue: null,
        key: "OrderDetailsPanel",
    },
    type: "display",
    category: "orderForm",
};
export const quantityNotionalPanel = {
    name: "Quantity Notional Panel",
    minSize: [5, 2],
    defaultSize: [7, 5],
    maxSize: [11, 12],
    icon: faSearchDollar,
    description: "Set Quantity/Notional",
    defaultConfig: {
        defaultValue: null,
        key: "QuantityNotionalPanel",
    },
    type: "display",
    category: "orderForm",
};
export const borrowBrokerPanel = {
    name: "Locates Selection Panel",
    minSize: [5, 2],
    defaultSize: [5, 5],
    maxSize: [10, 12],
    icon: faSearchDollar,
    description: "Choose Locates",
    defaultConfig: {
        defaultValue: null,
        key: "BorrowBrokerPanel",
    },
    type: "display",
    category: "orderForm",
};
export const orderSideSelect = {
    name: "Order Side Select",
    minSize: [4, 2],
    defaultSize: [4, 4],
    maxSize: [4, 12],
    icon: faSearchDollar,
    description: "Select order side",
    defaultConfig: {
        defaultValue: null,
        key: "OrderSideSelect",
    },
    type: "input",
    category: "orderForm",
};
export const darkPoolSelect = {
    name: "Dark Pool Select",
    minSize: [5, 2],
    defaultSize: [5, 3],
    maxSize: [5, 12],
    icon: faSearchDollar,
    description: "Choose dark pool",
    defaultConfig: {
        defaultValue: null,
        key: "DarkPoolSelect",
    },
    type: "input",
    category: "orderForm",
};
export const quickOrderEntryInput = {
    name: "Quick Order Entry Input",
    minSize: (mobile) => (mobile ? [3, 2] : [10, 2]),
    defaultSize: [10, 3],
    maxSize: [10, 12],
    icon: faSearchDollar,
    description: "Quickly fill order details",
    defaultConfig: {
        defaultValue: null,
        key: "QuickOrderEnterInput",
    },
    type: "input",
    category: "orderForm",
};
export const swapOrderCheckbox = {
    name: "Route to swap desk checkbox",
    minSize: [3, 1],
    defaultSize: [3, 5],
    maxSize: [3, 12],
    icon: faSearchDollar,
    description: "Select whether route to swap desk",
    defaultConfig: {
        defaultValue: null,
        key: "SwapOrderCheckbox",
    },
    type: "input",
    category: "orderForm",
};
export const traderDiscretionCheckbox = {
    name: "Trader Discretion Checkbox",
    minSize: [3, 1],
    defaultSize: [3, 5],
    maxSize: [3, 12],
    icon: faSearchDollar,
    description: "Select whether trader discretion",
    defaultConfig: {
        defaultValue: null,
        key: "TraderDiscretionCheckbox",
    },
    type: "input",
    category: "orderForm",
};
