import { isUndefined } from "lodash";
import * as React from "react";
export const useScale = ({ parentHeight, parentWidth, height, width, }) => {
    const calcSize = React.useCallback(() => {
        if (!isUndefined(parentHeight) &&
            !isUndefined(parentWidth) &&
            !isUndefined(width) &&
            !isUndefined(height)) {
            const newScale = Math.min(Math.min(parentWidth / width, parentHeight / height), 1);
            return isNaN(newScale) || newScale === 1 ? 1 : newScale - 0.05; // reduce the scale to add some space
        }
        return 1;
    }, [parentHeight, parentWidth, width, height]);
    const [scale, setScale] = React.useState(calcSize);
    React.useEffect(() => {
        setScale(calcSize());
    }, [parentHeight, parentWidth, width, height]);
    return scale;
};
