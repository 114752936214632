/* eslint-disable @typescript-eslint/no-explicit-any */
import * as React from "react";
import { useMounted } from "./useMounted";
import { useRefCallback } from "./useRefCallback";
const SET_ACTION = "__SET__";
const EMPTY_ARRAY = [];
function addMiddleware(reducer) {
    return (state, action) => {
        if (action.type === SET_ACTION) {
            if (action.nextAction === null)
                return action.payload;
            return reducer(action.payload, action.nextAction);
        }
        return reducer(state, action);
    };
}
export function useReducerWithMiddleware(reducer, initialState, middlewareFns = EMPTY_ARRAY, afterwareFns = EMPTY_ARRAY, allowStateModification = false) {
    const isMounted = useMounted();
    const currentActionRef = React.useRef(null);
    const timeoutRef = React.useRef();
    const qRef = React.useRef([]);
    const readyRef = React.useRef(false);
    const [state, dispatchCore] = React.useReducer(addMiddleware(reducer), initialState);
    const dispatch = useRefCallback((action) => {
        if (isMounted())
            dispatchCore(action);
    }, [dispatchCore]);
    const dispatchWithMiddleware = useRefCallback((action, force) => {
        clearTimeout(timeoutRef.current);
        if (!readyRef.current || (!force && currentActionRef.current !== null)) {
            qRef.current.push(action);
        }
        else {
            const res = middlewareFns.reduce((res, middlewareFn) => {
                const change = middlewareFn(res, currentActionRef.current);
                return allowStateModification ? change : res;
            }, { ...state });
            currentActionRef.current = action;
            if (allowStateModification) {
                dispatch({
                    type: SET_ACTION,
                    payload: res,
                    nextAction: action,
                });
            }
            else {
                dispatch(action);
            }
        }
        timeoutRef.current = setTimeout(() => {
            if (!currentActionRef.current && qRef.current.length > 0) {
                dispatchWithMiddleware(qRef.current.shift(), true);
            }
        }, 1000);
    }, [state, allowStateModification, middlewareFns]);
    const runAfterware = useRefCallback(() => {
        if (!!currentActionRef.current) {
            const res = afterwareFns.reduce((res, afterwareFn) => {
                const change = afterwareFn(res, currentActionRef.current);
                return allowStateModification ? change : res;
            }, { ...state });
            if (allowStateModification) {
                dispatchCore({
                    type: SET_ACTION,
                    payload: res,
                    nextAction: null,
                });
            }
            currentActionRef.current = null;
        }
        if (qRef.current.length > 0) {
            dispatchWithMiddleware(qRef.current.shift());
        }
    }, [state, allowStateModification, afterwareFns]);
    React.useEffect(() => {
        readyRef.current = true;
        runAfterware();
    }, [state, runAfterware]);
    return [state, dispatchWithMiddleware];
}
