import { useRefCallback } from "@enfusion-ui/hooks";
import * as React from "react";
export function useGridApi(onGridReady) {
    const gridApiRef = React.useRef();
    const handleGridReady = useRefCallback((event) => {
        gridApiRef.current = event;
        onGridReady?.(event);
    }, [onGridReady]);
    return { gridApiRef, onGridReady: handleGridReady };
}
