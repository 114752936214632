export var ChannelDataType;
(function (ChannelDataType) {
    ChannelDataType[ChannelDataType["Number"] = 0] = "Number";
    ChannelDataType[ChannelDataType["String"] = 1] = "String";
    ChannelDataType[ChannelDataType["Boolean"] = 2] = "Boolean";
    ChannelDataType[ChannelDataType["Date"] = 3] = "Date";
    ChannelDataType[ChannelDataType["Fund"] = 4] = "Fund";
    ChannelDataType[ChannelDataType["Unknown"] = 5] = "Unknown";
    ChannelDataType[ChannelDataType["DateSelection"] = 6] = "DateSelection";
    ChannelDataType[ChannelDataType["DatePeriodSelection"] = 7] = "DatePeriodSelection";
    ChannelDataType[ChannelDataType["DateRange"] = 8] = "DateRange";
    ChannelDataType[ChannelDataType["DateSymbolRange"] = 9] = "DateSymbolRange";
    ChannelDataType[ChannelDataType["SubTableDatasource"] = 10] = "SubTableDatasource";
})(ChannelDataType || (ChannelDataType = {}));
export const DashboardRoots = {
    USER: "user",
    SHARED: "shared",
    GLOBAL: "global",
    FIXED: "global/fixed",
};
export var DashboardRevertMode;
(function (DashboardRevertMode) {
    DashboardRevertMode[DashboardRevertMode["REVERT"] = 0] = "REVERT";
    DashboardRevertMode[DashboardRevertMode["CLOSE_EDIT"] = 1] = "CLOSE_EDIT";
    DashboardRevertMode[DashboardRevertMode["CLOSE_TAB"] = 2] = "CLOSE_TAB";
    DashboardRevertMode[DashboardRevertMode["REVERT_DEFAULT"] = 3] = "REVERT_DEFAULT";
})(DashboardRevertMode || (DashboardRevertMode = {}));
