import { useEverTrue } from "@enfusion-ui/hooks";
import { css, styled } from "@enfusion-ui/web-core";
import * as React from "react";
const TabViewContentContainer = styled.div `
  ${({ active }) => active
    ? css `
          height: 100%;
        `
    : css `
          height: 0px;
          max-height: 0px;
          overflow: hidden;
        `}
`;
export const TabViewContent = React.memo(function TabViewContent({ active, tabKey, Content, keepRendered, defaultRendered, }) {
    const everActive = useEverTrue(keepRendered ? defaultRendered || active : false);
    return (React.createElement(TabViewContentContainer, { active: active }, everActive && React.createElement(Content, { open: active, tabKey: tabKey })));
});
