export function statusText(code) {
    if (code === 404) {
        return "Not Found";
    }
    else if (code === 401 || code === 402) {
        return "Not Allowed";
    }
    else if (code === 403) {
        return "Forbidden";
    }
    else if (code >= 500 && code <= 599) {
        return "Server Error";
    }
    return "Unknown";
}
