/* eslint-disable @typescript-eslint/no-explicit-any */
// #region -- Imports & Styles
import { DASHBOARD_LATEST_CONFIG_VERSION, INTERNAL_PO_IDS_LIST, themesForDashboard, useOEMSOrderForm, } from "@enfusion-ui/core";
import { useMounted, useRefCallback } from "@enfusion-ui/hooks";
import { DashboardRevertMode, } from "@enfusion-ui/types";
import { getFileName } from "@enfusion-ui/utils";
import { last, noop, omit } from "lodash";
import * as React from "react";
import { v4 as uuidv4 } from "uuid";
import { WIDGET_DEFINITIONS } from "../widgets/definitions";
import { DashboardContext, } from "./context";
import { dashboardsProviderDefaultState, dashboardsProviderReducer, } from "./reducer";
import { cleanDashboardsConfigV2, cleanWidgetsConfigV2, convertV1ConfigToV2, } from "./utils";
export const DashboardProvider = ({ onSave, loadReport, children, renameTab, closeTab, user, pdf = false, hasPerm, isUserType, isPoId, loadDashboard: loadDashboardCall, storeDashboard, widgetDefinitions, mobile: mobileBase, onStateChange, ...tabConfig }) => {
    // #region -- Variables
    const isMounted = useMounted();
    const [state, dispatchBase] = React.useReducer(dashboardsProviderReducer, dashboardsProviderDefaultState);
    const dispatch = useRefCallback((action) => {
        if (isMounted())
            dispatchBase(action);
    }, [dispatchBase, isMounted]);
    const [mobile, setMobile] = React.useState(mobileBase);
    const isLayoutChangedRef = React.useRef(mobileBase);
    const subscriptionsRef = React.useRef({
        onClearStorage: [],
    });
    const [orientation, setOrientationInternal] = React.useState(state.settings.orientation);
    const [enablePdfLayout, setEnablePdfLayoutInternal] = React.useState(false);
    const [revertMode, setRevertModeInternal] = React.useState(DashboardRevertMode.REVERT);
    const { config, hasUnsavedChanges } = state;
    const { custom } = useOEMSOrderForm();
    const [configState, setConfigState] = React.useState({
        ...tabConfig,
        filePath: tabConfig.filePath,
    });
    const { filePath, root, openInEditMode } = configState;
    // #endregion
    // #region -- Edit Mode
    const [editMode, setEditMode] = React.useState(openInEditMode ? true : false);
    const startEditMode = useRefCallback(() => setEditMode(true), [setEditMode]);
    const stopEditModeInternal = useRefCallback(() => setEditMode(false), [setEditMode]);
    const stopEditMode = useRefCallback(() => {
        if (hasUnsavedChanges) {
            setRevertModeInternal(DashboardRevertMode.CLOSE_EDIT);
            throw new Error("Cannot stop edit mode while there are unsaved changes");
        }
        stopEditModeInternal();
    }, [setRevertModeInternal, stopEditModeInternal]);
    // #endregion
    // #region -- Permission
    const getCanEdit = useRefCallback(() => {
        if (isUserType("Express") || root.startsWith("global/"))
            return false;
        if (root === "global" && !hasPerm("DashboardEditor"))
            return false;
        if (hasPerm("DashboardEditor") ||
            !!user?.adminUser ||
            INTERNAL_PO_IDS_LIST.some(isPoId))
            return true;
        if ([...(config.main.widgets || []), ...(config?.sticky?.widgets || [])].some((i) => WIDGET_DEFINITIONS[i.type]?.adminOnly === true))
            return false;
        return true;
    }, [user, hasPerm, root, config, isUserType, isPoId]);
    const [canEdit, setCanEdit] = React.useState(getCanEdit);
    React.useEffect(() => {
        setCanEdit(getCanEdit());
    }, [user, hasPerm, root, config]);
    //#endregion Edit Permission
    // #region -- Load Dashboard
    const hydrate = useRefCallback((payload, revertDefault) => {
        dispatch({
            type: revertDefault ? "revert-default" : "hydrate",
            payload,
        });
    }, [dispatch]);
    const subscribe = useRefCallback((callbacks) => {
        if (!callbacks)
            return noop;
        for (const key of Object.keys(callbacks)) {
            const subs = subscriptionsRef.current[key].filter((i) => i !== callbacks[key]);
            subscriptionsRef.current = {
                ...subscriptionsRef.current,
                [key]: [...subs, callbacks[key]],
            };
        }
        return () => {
            for (const key of Object.keys(callbacks)) {
                const subs = subscriptionsRef.current[key].filter((i) => i !== callbacks[key]);
                subscriptionsRef.current = {
                    ...subscriptionsRef.current,
                    [key]: subs,
                };
            }
        };
    }, [subscriptionsRef.current]);
    const loadDashboard = useRefCallback(async (filePath, preloadDatasources = false, revertDefault) => {
        try {
            const dashboardData = await loadDashboardCall(filePath, mobile, revertDefault);
            // restSeverFetch<DashboardDataV2>(
            //   `/internal/api/documents/dashboards/download/${filePath}`
            // );
            /**
             * Earlier file name was taken from dashboardData.
             * However it seems the rename API does not change the dashboardData.
             * Therefore for now the new fileName is extracted from the ${filePath}
             */
            let dashboardDisplayName = getFileName({
                id: filePath,
                name: filePath,
                path: filePath,
            });
            /**
             * getFileName gives the entire path including the root.
             * So we need to separate just the filename by substring-ing from "/"
             * So basically we take "user/fileName.json" and extract out just "fileName"
             */
            const rootPrefixIndex = dashboardDisplayName.lastIndexOf("/");
            if (rootPrefixIndex !== -1) {
                dashboardDisplayName = dashboardDisplayName.substring(rootPrefixIndex + 1);
            }
            const handleLoadLatest = ({ datasources, settings, config, version, configVersion, }) => {
                renameTab?.(`${pdf ? "(PDF VIEW) " : ""}${dashboardDisplayName}`);
                const newDatasources = datasources || [];
                if (preloadDatasources) {
                    newDatasources.map((datasource) => new Promise(() => loadReport({
                        reportId: datasource.id,
                        name: datasource.name || last(datasource.path.split("/")) || "",
                        path: datasource.path,
                    })));
                }
                // web needs to clear local storage after switching layout
                if (mobile !== undefined && isLayoutChangedRef.current !== mobile) {
                    for (const handler of subscriptionsRef.current.onClearStorage) {
                        handler();
                    }
                    isLayoutChangedRef.current = mobile;
                }
                hydrate({
                    datasources,
                    config,
                    settings,
                    version,
                    configVersion,
                    name: dashboardDisplayName,
                    instanceId: uuidv4(),
                    hasUnsavedChanges: false,
                }, revertDefault);
                setOrientation(settings.orientation || "portrait");
            };
            const updateConfigData = (dashboardData) => {
                const configVersion = dashboardData.configVersion;
                if (configVersion === DASHBOARD_LATEST_CONFIG_VERSION) {
                    return cleanDashboardsConfigV2(dashboardData);
                }
                if (typeof configVersion !== "number") {
                    return updateConfigData(convertV1ConfigToV2(dashboardData));
                }
                return dashboardData;
            };
            if (dashboardData) {
                handleLoadLatest(updateConfigData(dashboardData));
            }
        }
        catch (err) {
            dispatch({
                type: "set-has-error",
            });
            if (err?.message?.startsWith("Could not locate file")) {
                dispatch({
                    type: "set-file-missing",
                });
            }
            console.error("dashboard load error", err);
        }
    }, [
        dispatch,
        loadReport,
        renameTab,
        hydrate,
        loadDashboardCall,
        mobile,
        subscriptionsRef.current,
        isLayoutChangedRef.current,
    ]);
    React.useEffect(() => {
        loadDashboard(filePath);
    }, [filePath, loadDashboard, mobile]);
    const reloadDashboard = useRefCallback(() => {
        return loadDashboard(filePath);
    }, [filePath, loadDashboard]);
    // #endregion
    // #region -- Provider Methods
    const getDatasource = useRefCallback((args) => {
        if (args === null)
            return undefined;
        return state.datasources?.find((i) => {
            if (typeof args === "string") {
                return i.id === args;
            }
            return (i.type === args.type &&
                i.path === args.path &&
                i.name === args.name &&
                (typeof args.pathParams !== "undefined"
                    ? JSON.stringify(i.pathParams) === JSON.stringify(args.pathParams)
                    : true) &&
                (typeof args.key !== "undefined" ? i.key === args.key : true));
        });
    }, [state.datasources]);
    const addDatasource = useRefCallback(({ type, key, summary }) => {
        const source = getDatasource({
            type,
            key,
            path: summary.path,
            name: summary.name,
            pathParams: summary.pathParams,
        });
        if (source)
            return source.id;
        const newId = uuidv4();
        if (type === "report" || type === "fixed-report") {
            loadReport({ reportId: newId, ...summary });
        }
        dispatch({
            type: "add-datasource",
            payload: { ...summary, type, id: newId, key },
        });
        return newId;
    }, [getDatasource]);
    const updateLayout = useRefCallback((gridId, layout) => {
        dispatch({
            type: "update-layout",
            payload: { gridId, layout },
        });
        onStateChange?.();
    }, [dispatch, onStateChange]);
    const changeSettingsKey = useRefCallback((key, value) => {
        dispatch({
            type: "change-setting",
            payload: { key, value },
        });
    }, [dispatch]);
    // #endregion
    // #region -- Widget Methods
    const updateWidget = useRefCallback((gridId, id, def) => {
        dispatch({ type: "update-widget", payload: { gridId, id, def } });
    }, [dispatch]);
    const addWidget = useRefCallback((gridId, type, widgetLayout, breakpointKey) => {
        dispatch({
            type: "add-widget",
            payload: {
                widgetLayout,
                breakpointKey,
                type,
                gridId,
            },
        });
    }, [dispatch]);
    const removeWidget = useRefCallback((gridId, id) => {
        dispatch({
            type: "remove-widget",
            payload: { gridId, id },
        });
    }, [dispatch]);
    const clearAllWidgets = useRefCallback(() => {
        dispatch({
            type: "clear-all-widgets",
        });
    }, [dispatch]);
    // #endregion
    // #region -- Channel Methods
    const updateChannelData = useRefCallback((data) => {
        dispatch({
            type: "update-channel-data",
            payload: { data },
        });
    }, [dispatch]);
    const addChannelKeys = useRefCallback((widgetId, keys) => {
        dispatch({
            type: "add-channel-keys",
            payload: { widgetId, keys },
        });
    }, [dispatch]);
    const removeChannelKeys = useRefCallback((widgetId) => {
        dispatch({
            type: "remove-channel-keys",
            payload: { widgetId },
        });
    }, [dispatch]);
    //#endregion channels
    // #region -- Revert Modal
    const resetRevertMode = useRefCallback(() => {
        setRevertModeInternal(DashboardRevertMode.REVERT);
    }, [setRevertModeInternal]);
    const setRevertMode = useRefCallback((mode) => {
        setRevertModeInternal(mode);
    }, [setRevertModeInternal]);
    // TODO web needs to clear local storage before calling this
    const revertChanges = useRefCallback((revertModeOverride) => {
        const currentRevertMode = revertModeOverride ?? revertMode;
        if (currentRevertMode === DashboardRevertMode.REVERT_DEFAULT) {
            if (isMounted()) {
                dispatch({ type: "loading" });
                loadDashboard(filePath, false, true);
            }
            return;
        }
        if (hasUnsavedChanges)
            dispatch({ type: "revert" });
        if (isMounted()) {
            if (currentRevertMode === DashboardRevertMode.CLOSE_EDIT) {
                stopEditModeInternal();
                setRevertModeInternal(DashboardRevertMode.REVERT);
            }
            else if (currentRevertMode === DashboardRevertMode.CLOSE_TAB) {
                setTimeout(() => closeTab?.(), 500);
            }
            else {
                setRevertModeInternal(DashboardRevertMode.REVERT);
            }
        }
    }, [dispatch, isMounted, revertMode, closeTab, hasUnsavedChanges]);
    // #endregion
    // #region -- Refresh View
    // web needs to clear local storage after calling this
    const refreshDashboard = useRefCallback(() => {
        // Revert to last saved state before re-mounting the component
        if (hasUnsavedChanges)
            dispatch({ type: "revert" });
    }, [dispatch, hasUnsavedChanges]);
    // #endregion
    // #region -- Save Modal
    const saveDashboard = useRefCallback(async (newFileName, newFilePath, newRootPath, forceWrite) => {
        if (canEdit) {
            try {
                /**
                 * DashboardSaveForm allows user to change the root, folder and fileName of the dashboard
                 * The arguments to  handleSave from DashboardSaveForm are -
                 *
                 * newFileName -- new file name if changed by user else older name
                 * newFilePath -- new folder name if changed by user else older name
                 * newRootPath -- new root path if changed by the user or the old root path
                 *
                 * use these arguments to call the upload API to always create a new dashboard unless none of the arguments are changed
                 *
                 * Avoid saving dashboard if user's datasource being used in for shared/global root
                 */
                const path = `/${newFilePath}${newFileName}.json`;
                const allDatasourceIdsUsed = new Set();
                const cleanedConfig = cleanWidgetsConfigV2(config, allDatasourceIdsUsed);
                const cleanDatasources = state.datasources
                    ? state.datasources.reduce((res, def) => {
                        if (!allDatasourceIdsUsed.has(def.id))
                            return res;
                        return [
                            ...res,
                            {
                                id: def.id,
                                type: def.type,
                                key: def.key,
                                path: def.path,
                                name: def.name,
                                pathParams: def.pathParams,
                            },
                        ];
                    }, [])
                    : [];
                if (newRootPath === "shared" || newRootPath === "global") {
                    const userDataSource = (cleanDatasources || []).filter((dataSource) => dataSource.path.split("/")[0] === "user");
                    if (userDataSource.length > 0) {
                        throw new Error(`Failed to save dashboard. Cannot add user's datasource in ${newRootPath} dashboard.`);
                    }
                }
                // restAPI.DASHBOARD.STORE_DASHBOARD
                const res = await storeDashboard({
                    path,
                    rootPath: newRootPath,
                    name: newFileName,
                    version: state.version + 1,
                    config: cleanedConfig,
                    settings: state.settings,
                    forceWrite,
                    datasources: cleanDatasources,
                }, mobile);
                if (res.success) {
                    dispatch({
                        type: "save",
                        payload: { name: newFileName },
                    });
                    renameTab?.(newFileName);
                    if (!custom) {
                        // Post saving the New dashboard, update the config object
                        setConfigState((currentState) => ({
                            ...currentState,
                            filePath: res.filePath,
                            root: newRootPath,
                        }));
                    }
                    onSave?.({
                        path,
                        rootPath: newRootPath,
                        name: newFileName,
                        version: state.version + 1,
                        config: cleanedConfig,
                        settings: state.settings,
                        forceWrite,
                        datasources: cleanDatasources,
                    });
                }
                else {
                    console.error("dashboard save error", res);
                    throw new Error("Failed to save dashboard changes");
                }
            }
            catch (err) {
                console.error("dashboard save error", err);
                if (err.message?.includes("already exists")) {
                    throw new Error("Failed to save dashboard. Dashboard already exists");
                }
                if (err.message?.startsWith("Failed to save dashboard")) {
                    throw err;
                }
                throw new Error("Failed to save dashboard changes");
            }
        }
        else {
            throw new Error("Failed to save dashboard changes. Missing permissions.");
        }
    }, [
        config,
        state.version,
        state.settings,
        state.datasources,
        dispatch,
        renameTab,
        onSave,
        canEdit,
        storeDashboard,
    ]);
    // #endregion
    // #region -- Overwrite Modal
    const overwriteDashboard = useRefCallback((overwriteDashboardSettings) => {
        if (overwriteDashboardSettings) {
            saveDashboard(overwriteDashboardSettings.newFileName, overwriteDashboardSettings.newFilePath, overwriteDashboardSettings.newRootPath, true);
        }
    }, [saveDashboard]);
    // #endregion
    // #region -- Pdf Orientation
    const setOrientation = useRefCallback((orientation) => {
        setOrientationInternal(orientation);
    }, [setOrientationInternal]);
    const setEnablePdfLayout = useRefCallback((flag) => {
        setEnablePdfLayoutInternal(flag);
    }, [setEnablePdfLayoutInternal]);
    // #endregion
    const theme = React.useMemo(() => {
        const themeKey = state?.settings?.theme || "dashDark";
        return themesForDashboard[themeKey] ?? themesForDashboard.dashDark;
    }, [state?.settings?.theme]);
    const results = React.useMemo(() => ({
        ...omit(state, ["oldSettings", "oldDatasources"]),
        pdf,
        root,
        filePath,
        editMode,
        theme,
        updateLayout,
        getDatasource,
        addDatasource,
        addWidget,
        removeWidget,
        updateWidget,
        clearAllWidgets,
        updateChannelData,
        addChannelKeys,
        removeChannelKeys,
        changeSettingsKey,
        canEdit,
        save: saveDashboard,
        reload: reloadDashboard,
        refresh: refreshDashboard,
        overwrite: overwriteDashboard,
        resetRevertMode,
        setRevertMode,
        revertMode,
        revertChanges,
        startEditMode,
        stopEditMode,
        orientation,
        setOrientation,
        enablePdfLayout,
        setEnablePdfLayout,
        hydrate,
        widgetDefinitions,
        mobile,
        setMobile,
        subscribe,
    }), [
        state,
        pdf,
        root,
        filePath,
        editMode,
        theme,
        updateLayout,
        getDatasource,
        addDatasource,
        addWidget,
        removeWidget,
        updateWidget,
        clearAllWidgets,
        updateChannelData,
        addChannelKeys,
        removeChannelKeys,
        changeSettingsKey,
        canEdit,
        saveDashboard,
        reloadDashboard,
        refreshDashboard,
        overwriteDashboard,
        resetRevertMode,
        setRevertMode,
        revertMode,
        revertChanges,
        startEditMode,
        stopEditMode,
        orientation,
        setOrientation,
        enablePdfLayout,
        setEnablePdfLayout,
        hydrate,
        widgetDefinitions,
        mobile,
        setMobile,
        subscribe,
    ]);
    return (React.createElement(DashboardContext.Provider, { value: results }, children));
};
export const DashboardProviderPassthrough = ({ children, ...value }) => {
    return (React.createElement(DashboardContext.Provider, { value: value }, children));
};
