import { styled } from "@enfusion-ui/web-core";
import React, { useReducer } from "react";
import { Accordion } from "./Accordion";
const AccordionListViewContainer = styled.div `
  display: flex;
  width: 100%;
  flex: 1;
  overflow: auto;
  flex-direction: column;
`;
const StyledAccordion = styled(Accordion) `
  border-top-width: 1px;
  font-weight: bold;
  text-transform: uppercase;
  height: 28px;
  padding: 0 0.25rem 0 0.75rem;
  ${({ top }) => (top ? "border-top-width: 0px;" : "")}
`;
const ListAccordion = ({ entryKey, onClick, ...props }) => {
    const handleClick = React.useCallback(() => {
        onClick(entryKey);
    }, [onClick, entryKey]);
    return React.createElement(StyledAccordion, { ...props, onClick: handleClick });
};
export const AccordionListView = ({ defaultOpen, items, className, style, iconPlacement, accordionTitleStyle, contentContainerStyle, }) => {
    const [openSections, dispatch] = useReducer((state, { type }) => {
        return { ...state, [type]: !state[type] };
    }, defaultOpen);
    const handleOpen = React.useCallback((type) => dispatch({ type }), [dispatch]);
    if (items.length < 1)
        return null;
    const lastItem = items[items.length - 1];
    return (React.createElement(AccordionListViewContainer, { className: className, style: style }, items.map((item, idx) => {
        if (item.component)
            return React.createElement(item.component, { key: item.key, top: idx === 0 });
        return (React.createElement(ListAccordion, { key: item.key, entryKey: item.key, title: item.title, top: idx === 0, last: lastItem.key === item.key, onClick: handleOpen, defaultOpen: openSections[item.key], contentComponent: item.contentComponent, elevated: openSections[item.key], style: accordionTitleStyle, iconPlacement: iconPlacement, contentContainerStyle: contentContainerStyle }));
    })));
};
