import { SEPARATOR } from "@enfusion-ui/core";
import { partition } from "lodash";
export const AVAILABLE_COLUMNS = "availableColumns";
export const SELECTED_COLUMNS = "selectedColumns";
export const createColumns = (columns) => {
    return columns.reduce((res, column) => {
        if (!res[0][column.groupName])
            res[0][column.groupName] = [];
        if (column.selected)
            res[0][column.groupName].push(column);
        else
            res[1].push(column);
        return res;
    }, [{}, []]);
};
export const addColumns = (state, addingColumns, key, destinationIndex) => {
    const [sameGroupNameCols, diffGroupNameCols] = partition(addingColumns, {
        groupName: key,
    });
    const newColumns = state[0];
    sameGroupNameCols.forEach((column, idx) => {
        newColumns[key].splice(destinationIndex + idx, 0, {
            ...column,
            selected: true,
        });
    });
    diffGroupNameCols.forEach((column, idx) => {
        newColumns[column.groupName].splice(destinationIndex + idx, 0, {
            ...column,
            selected: true,
        });
    });
    state[0] = { ...newColumns };
    return state;
};
export const removeColumns = (state, key, value) => {
    const filteredColumns = state[0][key].filter((column) => column.name !== value);
    state[0] = { ...state[0], [key]: filteredColumns };
    return state;
};
export const reorderColumns = (state, key, sourceIndex, destinationIndex) => {
    const newColumns = state[0][key];
    const newColumn = newColumns[sourceIndex];
    newColumns.splice(sourceIndex, 1);
    newColumns.splice(destinationIndex, 0, newColumn);
    state[0] = { ...state[0], [key]: newColumns };
    return state;
};
export const resetColumns = (columns, empty = false) => {
    return columns.reduce((res, column) => {
        if (!res[0][column.groupName])
            res[0][column.groupName] = [];
        if (column.selected && !empty)
            res[0][column.groupName].push(column);
        else
            res[1].push(column);
        return res;
    }, [{}, []]);
};
export const createDroppable = (columns) => {
    return columns.reduce((res, column) => {
        res[column.groupName] = false;
        return res;
    }, {});
};
export const createSelectedColumnsOpen = (columns) => {
    return columns.reduce((res, column) => {
        res[column.groupName] = true;
        return res;
    }, {});
};
export const genDroppableId = (column) => `${column.groupName}${SEPARATOR}${column.name}`;
export const createFilters = (columns, currentValues = {}, defaultChecked = true) => {
    return columns.reduce((res, column) => {
        const categories = column.category.split(",");
        categories.forEach((category) => {
            const current = currentValues[category];
            res[category] = typeof current === "boolean" ? current : defaultChecked;
        });
        return res;
    }, {});
};
