import * as React from "react";
import { useWatch } from "react-hook-form";
import { FormController } from "../layout/FormPanel";
import { NumericInput, NumericInputAsString, } from "./NumericInput";
export const ControlledNumericInput = ({ name, basis, mobileBasis, tabletBasis, desktopBasis, onChange: onChangeExternal, ...props }) => {
    const watchValue = useWatch({ name });
    return (React.createElement(FormController, { name: name, basis: basis, mobileBasis: mobileBasis, tabletBasis: tabletBasis, desktopBasis: desktopBasis, render: ({ onChange, value: _value, ...renderProps }, { invalid }) => {
            return (React.createElement(NumericInput, { ...props, ...renderProps, value: watchValue ?? null, onChange: (value) => {
                    onChange(value);
                    onChangeExternal?.(value);
                }, invalid: invalid }));
        } }));
};
export const ControlledNumericInputAsString = ({ name, basis, mobileBasis, tabletBasis, desktopBasis, onChange: onChangeExternal, ...props }) => {
    return (React.createElement(FormController, { name: name, basis: basis, mobileBasis: mobileBasis, tabletBasis: tabletBasis, desktopBasis: desktopBasis, render: ({ onChange, ...renderProps }, { invalid }) => {
            return (React.createElement(NumericInputAsString, { ...props, ...renderProps, onChange: (value) => {
                    onChange(value);
                    onChangeExternal?.(value);
                }, invalid: invalid }));
        } }));
};
