import { FACTSET_LOADED_KEY, LANDING_REDIRECT_KEY } from "@enfusion-ui/core";
import { ThemeProvider } from "@enfusion-ui/web-core";
import * as React from "react";
import { useMount } from "react-use";
import { Spinner } from "../display";
import { CenterContent, SVGBackground } from "../Styled";
export function LandingView() {
    useMount(() => {
        const newPath = localStorage.getItem(LANDING_REDIRECT_KEY) ||
            `${window.location.origin}${window.location.search}${window.location.hash}`;
        localStorage.removeItem(LANDING_REDIRECT_KEY);
        localStorage.setItem(FACTSET_LOADED_KEY, "1");
        window.location.href = newPath;
    });
    return (React.createElement(ThemeProvider, null,
        React.createElement(SVGBackground, null,
            React.createElement(CenterContent, null,
                React.createElement(Spinner, null)))));
}
