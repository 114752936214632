/* eslint-disable @typescript-eslint/no-explicit-any */
import { useDebounce, useRefCallback } from "@enfusion-ui/hooks";
import * as React from "react";
import { useFormContext, useWatch } from "react-hook-form";
import { useOEMSOrderForm } from "../orderFormContext/context";
import { useCanAccept } from "./useCanAccept";
export const useCanSaveOrder = (readOnly, splitOrder, fxOrder, cdxOrder, varSwap, irsOrder, watchFields, validateStrategyParam) => {
    const { canAccept, issues } = useCanAccept(readOnly, splitOrder, fxOrder, cdxOrder, varSwap, irsOrder, validateStrategyParam);
    const [canTransmit, setCanTransmit] = React.useState(false);
    const { orderId, checkIfTransmittable, completed } = useOEMSOrderForm();
    const { watch, getValues } = useFormContext();
    const webOrderType = useWatch({ name: "webOrderType" });
    const check = useRefCallback(async () => {
        if (canAccept && webOrderType === "Electronic" && !completed) {
            try {
                setCanTransmit(await checkIfTransmittable(getValues()));
            }
            catch (err) {
                console.error("Failed to check if order is transmittable", err.message, err);
            }
        }
        else {
            setCanTransmit(false);
        }
    }, [canAccept, getValues, checkIfTransmittable, webOrderType, completed]);
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const fields = watch(watchFields);
    const values = Object.values(fields);
    useDebounce(check, 500, [orderId, ...values]);
    values.length = 0;
    return {
        canAccept,
        canTransmit,
        issues,
    };
};
