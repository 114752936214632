/* eslint-disable @typescript-eslint/no-explicit-any */
import { EMPTY_RECORD } from "@enfusion-ui/core";
import * as React from "react";
import { useDashboard } from "../core/context";
import { getChannelDataId } from "../core/reducer";
export const WidgetContext = React.createContext(null);
export function useWidget() {
    const context = React.useContext(WidgetContext);
    if (context === null) {
        throw new Error("useWidget must be used within an WidgetContext");
    }
    return context;
}
export function useChannelDataValue(channelDataId) {
    const { channelData } = useDashboard();
    const id = getChannelDataId(channelDataId);
    return id && channelData ? channelData[id] : null;
}
export function useChannelDataValues(channelDataIds) {
    const { channelData } = useDashboard();
    const res = React.useMemo(() => {
        if (!channelDataIds)
            return EMPTY_RECORD;
        return Object.keys(channelDataIds).reduce((res, key) => {
            const id = getChannelDataId(channelDataIds[key]);
            return { ...res, [key]: id && channelData ? channelData[id] : null };
        }, channelDataIds);
    }, [channelData, channelDataIds]);
    return React.useMemo(() => res, [res]);
}
