import { IS_LOCAL } from "../constants";
export function gaEvent(args) {
    if (!IS_LOCAL) {
        window.ga?.("send", "event", {
            eventCategory: args.category,
            eventAction: args.action,
            eventLabel: args.label,
            eventValue: args.value,
            nonInteraction: false,
        });
    }
}
