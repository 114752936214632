export function isScrollableHorizontal(ele) {
    // Compare the width to see if the element has scrollable content
    const hasScrollableContent = ele.scrollWidth > ele.clientWidth;
    // It's not enough because the element's `overflow-x` style can be set as
    // * `hidden`
    // * `hidden !important`
    // In those cases, the scrollbar isn't shown
    const overflowXStyle = window.getComputedStyle(ele).overflowX;
    const isOverflowHidden = overflowXStyle?.indexOf("hidden") !== -1;
    return hasScrollableContent && !isOverflowHidden;
}
export function isScrollableVertical(ele) {
    // Compare the height to see if the element has scrollable content
    const hasScrollableContent = ele.scrollHeight > ele.clientHeight;
    // It's not enough because the element's `overflow-y` style can be set as
    // * `hidden`
    // * `hidden !important`
    // In those cases, the scrollbar isn't shown
    const overflowYStyle = window.getComputedStyle(ele).overflowY;
    const isOverflowHidden = overflowYStyle?.indexOf("hidden") !== -1;
    return hasScrollableContent && !isOverflowHidden;
}
