import * as React from "react";
export function useSyncedRef(ref) {
    const innerRef = React.useRef(null);
    React.useEffect(() => {
        if (!ref)
            return;
        // handle callback refs
        if (typeof ref === "function") {
            ref(innerRef?.current);
        }
        // handle object refs
        else {
            ref.current = innerRef.current;
        }
    });
    // return the new ref
    return innerRef;
}
