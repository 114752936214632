const versionMin = {
    Chrome: 72,
    Firefox: 72,
    Edge: 79,
    Opera: 60,
    Safari: 13,
};
export function checkBrowserCompatibility(agent) {
    const browserVersion = agent.browser.majorVersion;
    if (browserVersion === null) {
        console.log("browserVersion null", agent.browser.fullName);
        return true;
    }
    if (typeof versionMin[agent.browser.fullName] !== "number") {
        console.log("no min version", agent.browser.fullName, browserVersion);
        return false;
    }
    return browserVersion >= versionMin[agent.browser.fullName];
}
