import { faHeading } from "@fortawesome/pro-solid-svg-icons";
export const text = {
    name: "Text",
    minSize: [2, 2],
    icon: faHeading,
    description: "Display static text",
    defaultConfig: {
        content: "New Text",
        key: "Text",
    },
    type: "simpleDisplay",
    category: "dashboard",
};
export const label = {
    name: "Label",
    minSize: [2, 2],
    icon: faHeading,
    description: "Display static label",
    defaultConfig: {
        content: "New Text",
        key: "Text",
    },
    type: "display",
    category: "orderForm",
};
