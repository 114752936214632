import { AUTH_EVENT, BSSO_TOKEN_KEY, checkUserEnabled, checkUserPermission, checkUserPo, checkUserPoId, checkUserType, FACTSET_LOADED_KEY, INTERNAL_PO_IDS, INTERNAL_PO_IDS_LIST, parseBroadcastMessage, } from "@enfusion-ui/core";
import { useMounted, useRefCallback } from "@enfusion-ui/hooks";
import { mobilePostMessage } from "@enfusion-ui/utils";
import queryString from "query-string";
import * as React from "react";
import { REST_API } from "../../utils/api";
import { AppLogging } from "../../utils/logging";
import { restServerBase } from "../../utils/restServer";
import { isDesktop } from "../../utils/window";
import { AUTH_CHANNEL, AuthContext } from "./context";
const AUTH_TOKEN_KEY = "ef-auth-tk";
export const AuthProvider = ({ children, keycloak, afterAuth, beforeLogout, modifyUser = (i) => i, mobileView = false, broadcastChannelPassThrough = false, }) => {
    const [user, setUser] = React.useState(null);
    const [secureAPIToken, setSecureAPIToken] = React.useState(null);
    const [isLoggedInDesktop, setIsLoggedInDesktop] = React.useState(false);
    const [ignoreConflict, setIgnoreConflict] = React.useState(false);
    const [firstHeartbeatDone, setFirstHeartbeatDone] = React.useState(false);
    const [apiError, setApiError] = React.useState(null);
    const defaultFrameRef = React.useRef(false);
    const isMounted = useMounted();
    React.useEffect(() => {
        if (isMounted() && isDesktop()) {
            // eslint-disable-next-line no-restricted-globals
            const parsedQueryString = queryString.parse(location.search, {
                parseBooleans: true,
            });
            if (parsedQueryString) {
                defaultFrameRef.current = !!parsedQueryString.frame;
            }
        }
    }, []);
    React.useEffect(() => {
        if (apiError !== null) {
            console.info("API connection lost");
            mobilePostMessage({
                source: "mobile-app-auth",
                action: "api-connection-lost",
                message: apiError,
            });
        }
        else {
            console.info("API Connection Gained");
            mobilePostMessage({
                source: "mobile-app-auth",
                action: "api-connection-gained",
            });
        }
    }, [apiError]);
    const login = useRefCallback((user, secureAPIToken) => {
        const loginUser = async () => {
            let res = {
                processingOrdId: -1,
                processingOrgName: "Unknown",
                OMS: {
                    enabled: true,
                    readOnly: false,
                },
                Reports: {
                    enabled: true,
                },
                Services: {
                    enabled: true,
                },
                OperationsFiles: {
                    enabled: true,
                },
                CompliancePortal: {
                    enabled: false,
                },
                FactSet: {
                    enabled: false,
                    idpId: "",
                },
                GeneralFiles: {
                    enabled: !!process.env.REACT_APP_ENABLE_FILES,
                },
                BloombergRealtime: {
                    enabled: false,
                    bloombergUUID: null,
                },
                BloombergMobile: {
                    enabled: false,
                },
                permissions: {
                    DashboardEditor: false,
                    DisallowAllReportViewing: false,
                },
                Workbench: {
                    enabled: false,
                },
                ReconDashboard: {
                    enabled: false,
                },
            };
            const baseFlags = { ...res };
            try {
                res = await REST_API.USER.DETAILS.FETCH(user.authToken);
            }
            catch (err) {
                console.error("failed to get flags", err);
            }
            const newUser = modifyUser({
                ...user,
                flags: {
                    ...baseFlags,
                    ...(user.flags || {}),
                    ...res,
                },
            });
            if (isMounted()) {
                setUser(newUser);
                setSecureAPIToken(secureAPIToken);
                if (isDesktop()) {
                    const win = await fin.Window.getCurrent();
                    win.updateOptions({ frame: defaultFrameRef.current });
                }
            }
        };
        return loginUser();
    }, []);
    const heartbeat = useRefCallback(async (userConfig) => {
        try {
            const res = await restServerBase(REST_API.UTILITY.HEARTBEAT.PATH(), userConfig);
            setApiError(null);
            if (!ignoreConflict && res[0] === true) {
                setIsLoggedInDesktop(true);
                mobilePostMessage({
                    source: "mobile-app-auth",
                    action: "duplicate-login",
                });
            }
            return res[1];
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
        }
        catch (err) {
            console.warn("Heartbeat error", err.message, err);
            setApiError(err.message);
        }
    }, [ignoreConflict]);
    React.useEffect(() => {
        let keycloakKeepAliveTimer;
        if (keycloak && keycloak.tokenParsed) {
            const loginStep = async () => {
                const storedAuthToken = localStorage.getItem(AUTH_TOKEN_KEY);
                // eslint-disable-next-line @typescript-eslint/no-explicit-any
                const token = keycloak.tokenParsed;
                const res = await heartbeat({
                    headers: {
                        "X-Requested-With": "XMLHttpRequest",
                        Authorization: `Basic ${btoa(`${token.preferred_username}:${token.enfusion_token}`)}`,
                        SSO_SESSION_ID: token.enfusion_origin_session || token.session_state,
                    },
                });
                setFirstHeartbeatDone(true);
                const { WSSERVERID } = Object.fromEntries(document.cookie
                    .split("; ")
                    .map((x) => x.split(/=(.*)$/, 2).map(decodeURIComponent)));
                const authToken = res?.headers.get("x-auth-token") ?? storedAuthToken;
                if (authToken)
                    localStorage.setItem(AUTH_TOKEN_KEY, authToken);
                await login({
                    username: token.preferred_username,
                    adminUser: token.enfusion_admin,
                    token,
                    authToken,
                    WSSERVERID,
                    flags: {
                        FactSet: {
                            enabled: !!token.factset_idp_id,
                            idpId: token.factset_idp_id,
                        },
                    },
                }, "");
            };
            loginStep()
                .then(() => {
                keycloakKeepAliveTimer = setInterval(() => {
                    keycloak.updateToken(30).catch(() => {
                        mobilePostMessage({
                            source: "mobile-app-auth",
                            action: "logged-out",
                            message: "token expired",
                        });
                    });
                }, 30000); // 30 seconds
            })
                .catch((err) => {
                AppLogging.safeError("loginStep error", err, true);
            });
        }
        return () => {
            clearInterval(keycloakKeepAliveTimer);
        };
    }, [keycloak]);
    React.useEffect(() => {
        let timeoutId = -1;
        if (user) {
            afterAuth?.(user);
            if (firstHeartbeatDone) {
                heartbeat({ headers: { "X-Auth-Token": user.authToken } }).finally(() => {
                    timeoutId = setInterval(() => heartbeat({ headers: { "X-Auth-Token": user.authToken } }), 30000);
                });
            }
        }
        return () => {
            clearInterval(timeoutId);
        };
    }, [user, firstHeartbeatDone, afterAuth]);
    const isEnabled = useRefCallback((flag) => {
        if (!user)
            return false;
        return checkUserEnabled(user, flag);
    }, [user]);
    const hasPerm = useRefCallback((perm) => {
        if (!user)
            return false;
        return checkUserPermission(user, perm);
    }, [user]);
    const hasRestrictedPerm = useRefCallback((perm) => {
        if (isAdmin())
            return false;
        return hasPerm(perm);
    }, [user]);
    const isUserType = useRefCallback((userType) => {
        if (!user)
            return false;
        return checkUserType(user, userType);
    }, [user]);
    const isPo = useRefCallback((poName, caseSensitive = true) => {
        if (!user)
            return false;
        return checkUserPo(user, poName, caseSensitive);
    }, [user]);
    const isPoId = useRefCallback((poId) => {
        if (!user)
            return false;
        return checkUserPoId(user, poId);
    }, [user]);
    const isAdmin = useRefCallback(() => {
        if (!user || !user.adminUser)
            return false;
        return true;
    }, [user]);
    const isInternalPo = useRefCallback(() => {
        if (!user)
            return false;
        return INTERNAL_PO_IDS_LIST.some(isPoId);
    }, [user]);
    const isEnfusionAdmin = useRefCallback(() => {
        return isAdmin() && isPoId(INTERNAL_PO_IDS.Enfusion);
    }, [isAdmin, isPo]);
    const logout = useRefCallback(async (reason) => {
        beforeLogout?.(user);
        if (window.location.pathname.includes("/pop-out")) {
            window.close();
        }
        if (isDesktop()) {
            const win = await fin.Window.getCurrent();
            win.updateOptions({ frame: true });
        }
        console.info("logout user", reason);
        localStorage.removeItem(AUTH_TOKEN_KEY);
        localStorage.removeItem(FACTSET_LOADED_KEY);
        localStorage.removeItem(BSSO_TOKEN_KEY);
        if (!mobileView && isEnabled("FactSet")) {
            window.open("https://login.factset.com/login/logout.html", "_blank");
        }
        if (isMounted()) {
            setUser(null);
            setSecureAPIToken(null);
        }
        mobilePostMessage({
            source: "mobile-app-auth",
            action: "logging-out",
            message: reason || "",
        });
        try {
            console.info("/internal/api/utility/logout called");
            await REST_API.UTILITY.LOGOUT.FETCH();
        }
        catch (err) {
            console.warn("error hitting /logout", err);
        }
        if (keycloak) {
            try {
                console.info("keycloak.logout called");
                mobilePostMessage({
                    source: "mobile-app-auth",
                    action: "logged-out",
                    message: "logout called",
                });
                await keycloak.logout();
            }
            catch (err) {
                console.error("error logging out keycloak", err);
            }
        }
        else {
            mobilePostMessage({
                source: "mobile-app-auth",
                action: "logged-out",
                message: "clear user data",
            });
        }
    }, [isEnabled, mobileView, beforeLogout]);
    const handleMessage = useRefCallback((ev) => {
        const { action, meta } = parseBroadcastMessage(ev);
        if (action === AUTH_EVENT.LOGOUT) {
            console.info("logging out AUTH_LOGOUT");
            logout(meta[0] || "auth logout");
        }
        else if (action === AUTH_EVENT.CONFLICT && !ignoreConflict) {
            console.info("got a AUTH_CONFLICT");
            // logout("auth conflict");
        }
    }, [ignoreConflict, logout]);
    React.useEffect(() => {
        if (!broadcastChannelPassThrough)
            return AUTH_CHANNEL.subscribe(handleMessage);
    }, []);
    const continueSessionDup = useRefCallback(async () => {
        setUser((userData) => {
            const flags = userData?.flags;
            const disableFlag = (flag) => {
                if (flags && flags[flag]) {
                    flags[flag].enabled = false;
                }
            };
            disableFlag("OMS");
            disableFlag("Reports");
            disableFlag("CompliancePortal");
            return {
                ...userData,
                flags,
            };
        });
        setIgnoreConflict(true);
        setIsLoggedInDesktop(false);
        try {
            await REST_API.UTILITY.RESOLVE_CONFLICT.FETCH("Continue");
        }
        catch (err) {
            console.log("continueSessionDup Error ", err);
        }
    }, []);
    const terminateCurrentSession = useRefCallback(async () => {
        try {
            await REST_API.UTILITY.RESOLVE_CONFLICT.FETCH("KillCurrent");
        }
        catch (err) {
            console.log("terminateCurrentSession Error ", err);
        }
        console.info("terminateCurrentSession called");
        await logout("Terminate current session");
    }, [logout]);
    const terminateOtherSession = useRefCallback(async () => {
        setIgnoreConflict(true);
        setIsLoggedInDesktop(false);
        mobilePostMessage({
            source: "mobile-app-auth",
            action: "terminate-other-session",
        });
        try {
            await REST_API.UTILITY.RESOLVE_CONFLICT.FETCH("KillOther");
        }
        catch (err) {
            console.log("TerminateOtherSession Error ", err);
        }
    }, []);
    const value = React.useMemo(() => ({
        apiError,
        isUserType,
        user: firstHeartbeatDone ? user : null,
        secureAPIToken,
        isLoggedInDesktop,
        login,
        logout,
        isEnabled,
        isPo,
        isPoId,
        isInternalPo,
        hasPerm,
        hasRestrictedPerm,
        continueSessionDup,
        terminateOtherSession,
        terminateCurrentSession,
        isAdmin,
        isEnfusionAdmin,
    }), [
        apiError,
        isUserType,
        firstHeartbeatDone,
        JSON.stringify(user),
        secureAPIToken,
        isLoggedInDesktop,
        login,
        logout,
        isEnabled,
        isPo,
        isPoId,
        isInternalPo,
        hasPerm,
        hasRestrictedPerm,
        continueSessionDup,
        terminateOtherSession,
        terminateCurrentSession,
        isAdmin,
        isEnfusionAdmin,
    ]);
    return React.createElement(AuthContext.Provider, { value: value }, children);
};
export const AuthProviderPassthrough = ({ children, ...value }) => {
    return React.createElement(AuthContext.Provider, { value: value }, children);
};
