import { faExclamationCircle } from "@fortawesome/pro-solid-svg-icons";
import * as React from "react";
import { IconButton } from "../../control/IconButton";
import { ErrorAlert } from "../../display/Alert";
import { Portal } from "../../portal/Portal";
export const ErrorIcon = ({ error, align }) => {
    const [showError, setShowError] = React.useState(false);
    const iconRef = React.useRef(null);
    return (React.createElement(React.Fragment, null,
        error && (React.createElement(IconButton, { ref: iconRef, color: "var(--danger)", icon: faExclamationCircle, onClick: () => setShowError((prev) => !prev), style: { padding: 0 } })),
        React.createElement(Portal, { open: showError, attachedRef: iconRef, onClickOutside: () => setShowError(false), align: align },
            React.createElement(ErrorAlert, { error: error || "" }))));
};
