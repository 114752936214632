import { styled } from "@enfusion-ui/web-core";
import * as React from "react";
const Svg = styled.svg `
  font-size: inherit;
  overflow: visible;
`;
export const EnfusionLogo = ({ style, white }) => (React.createElement(Svg, { style: style, height: "100%", width: "100%", viewBox: "0 0 400 200" },
    React.createElement("path", { fill: "#7D5FFF", d: "M101.7,145.7c0,2.1,1.7,3.8,3.8,3.8s3.8-1.7,3.8-3.8c0-2.1-1.7-3.8-3.8-3.8S101.7,143.6,101.7,145.7" }),
    React.createElement("path", { fill: "#7D5FFF", d: "M101.7,145.7c0,2.1,1.7,3.8,3.8,3.8s3.8-1.7,3.8-3.8c0-2.1-1.7-3.8-3.8-3.8S101.7,143.6,101.7,145.7" }),
    React.createElement("path", { fill: "#7D5FFF", d: "M99.8,124.7c0,3.2,2.6,5.7,5.7,5.7s5.7-2.6,5.7-5.7c0-3.2-2.6-5.7-5.7-5.7S99.8,121.6,99.8,124.7" }),
    React.createElement("path", { fill: "#7D5FFF", d: "M63.6,100c0-2.1-1.7-3.8-3.8-3.8c-2.1,0-3.8,1.7-3.8,3.8s1.7,3.8,3.8,3.8C61.9,103.8,63.6,102.1,63.6,100" }),
    React.createElement("path", { fill: "#7D5FFF", d: "M86.5,75.3c0-3.2-2.6-5.7-5.7-5.7c-3.2,0-5.7,2.6-5.7,5.7c0,3.2,2.6,5.7,5.7,5.7C83.9,81,86.5,78.4,86.5,75.3" }),
    React.createElement("path", { fill: "#7D5FFF", d: "M86.5,100c0-3.2-2.6-5.7-5.7-5.7c-3.2,0-5.7,2.6-5.7,5.7s2.6,5.7,5.7,5.7C83.9,105.7,86.5,103.2,86.5,100" }),
    React.createElement("path", { fill: "#7D5FFF", d: "M86.5,124.7c0-3.2-2.6-5.7-5.7-5.7c-3.2,0-5.7,2.6-5.7,5.7c0,3.2,2.6,5.7,5.7,5.7C83.9,130.5,86.5,127.9,86.5,124.7" }),
    React.createElement("path", { fill: "#7D5FFF", d: "M109.3,54.3c0-2.1-1.7-3.8-3.8-3.8s-3.8,1.7-3.8,3.8c0,2.1,1.7,3.8,3.8,3.8S109.3,56.4,109.3,54.3" }),
    React.createElement("path", { fill: "#7D5FFF", d: "M111.2,75.3c0-3.2-2.6-5.7-5.7-5.7s-5.7,2.6-5.7,5.7c0,3.2,2.6,5.7,5.7,5.7S111.2,78.4,111.2,75.3" }),
    React.createElement("path", { fill: "#7D5FFF", d: "M113.1,100c0-4.2-3.4-7.6-7.6-7.6c-4.2,0-7.6,3.4-7.6,7.6s3.4,7.6,7.6,7.6C109.7,107.6,113.1,104.2,113.1,100" }),
    React.createElement("path", { fill: "#7D5FFF", d: "M136,75.3c0-3.2-2.6-5.7-5.7-5.7c-3.2,0-5.7,2.6-5.7,5.7c0,3.2,2.6,5.7,5.7,5.7C133.4,81,136,78.4,136,75.3" }),
    React.createElement("path", { fill: "#7D5FFF", d: "M136,100c0-3.2-2.6-5.7-5.7-5.7c-3.2,0-5.7,2.6-5.7,5.7s2.6,5.7,5.7,5.7C133.4,105.7,136,103.2,136,100" }),
    React.createElement("path", { fill: "#7D5FFF", d: "M155,100c0-2.1-1.7-3.8-3.8-3.8c-2.1,0-3.8,1.7-3.8,3.8s1.7,3.8,3.8,3.8C153.3,103.8,155,102.1,155,100" }),
    React.createElement("path", { fill: white ? "white" : "#1E1A34", d: "M274.8,119.6h-5v29.3h5V119.6z" }),
    React.createElement("path", { fill: white ? "white" : "#1E1A34", d: "M148.1,131.6h-18.3c0.9-5.7,5.2-8.1,9.4-8.1C143.6,123.5,147.2,126.2,148.1,131.6M153.5,135.6c0.6-9.1-4.8-16.6-14.1-16.6c-9.2,0-14.9,6.6-14.9,15.2c0,8.7,6.2,15.2,14.8,15.2c7.4,0,12.3-4.3,13.7-9.5h-5.2c-1.8,3.5-4.8,4.9-8.5,4.9c-5.3,0-9.5-4-9.7-9.3H153.5z" }),
    React.createElement("path", { fill: white ? "white" : "#1E1A34", d: "M180.5,148.9h5v-18c0-7.3-4.5-11.8-11.1-11.8c-3.8,0-7.3,1.6-9.3,5.1v-4.5h-5v29.3h5v-14.5c0-6.9,3.8-10.7,8.5-10.7c4.2,0,6.9,3,6.9,8V148.9z" }),
    React.createElement("path", { fill: white ? "white" : "#1E1A34", d: "M229,148.9h5v-29.3h-5v14.5c0,6.7-3.6,10.7-8.1,10.7c-4,0-6.6-3.1-6.6-8v-17.2h-14.1v-3c0-3.8,1.6-5,4.9-5h4.2V107h-5.1c-5.2,0-9,3-9,9.1v3.5h-6.2v4.6h6.2v24.7h5v-24.7h9.1v13.4c0,7.3,4.4,11.9,10.8,11.9c3.7,0,7.1-1.6,9-5.1V148.9z" }),
    React.createElement("path", { fill: white ? "white" : "#1E1A34", d: "M246.6,126.9c0-2.3,2.2-3.6,5-3.6c2.8,0,4.9,1.6,5.6,4.2h5c-0.5-4.7-4.5-8.4-10.7-8.4c-5.9,0-10.1,3-10.1,7.9c0,4.2,2.5,7.3,7.6,8.6l4.2,1.1c3.5,0.9,4.9,2.3,4.9,4.7c0,2.6-2.4,3.9-5.6,3.9c-3,0-6.2-1.2-6.8-4.8h-5.2c0.5,5.9,5.6,9.1,11.9,9.1c6.1,0,10.8-2.6,10.8-8.5c0-4.5-2.6-7.4-7.5-8.7l-4.7-1.2C248,130.2,246.6,129.1,246.6,126.9" }),
    React.createElement("path", { fill: white ? "white" : "#1E1A34", d: "M276.1,109.3c0-2.1-1.7-3.8-3.8-3.8c-2.1,0-3.8,1.7-3.8,3.8c0,2.1,1.7,3.8,3.8,3.8C274.4,113.1,276.1,111.4,276.1,109.3" }),
    React.createElement("path", { fill: white ? "white" : "#1E1A34", d: "M306.9,134.3c0,5.9-4,10.5-10.1,10.5s-10.2-4.7-10.2-10.5c0-5.9,4.1-10.5,10.2-10.5S306.9,128.4,306.9,134.3M312,134.3c0-8.4-6.5-15.2-15.3-15.2c-8.9,0-15.3,6.8-15.3,15.2c0,8.4,6.4,15.2,15.3,15.2C305.5,149.5,312,142.7,312,134" }),
    React.createElement("path", { fill: white ? "white" : "#1E1A34", d: "M344,148.9v-18c0-7.3-4.5-11.8-11.1-11.8c-3.8,0-7.3,1.6-9.3,5.1v-4.5h-5v29.3h5v-14.5c0-6.9,3.8-10.7,8.5-10.7c4.2,0,6.9,3,6.9,8v17.3H344z" })));
