import { styled } from "@enfusion-ui/web-core";
import * as React from "react";
const Panel = styled.div `
  line-height: 1.25rem;
  flex: 1 1 0%;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  font-size: 0.875rem;
`;
const ItemName = styled.span `
  font-weight: 700;
  margin-right: 0.25rem;
`;
const getTime = (currentTime, timeZone) => {
    try {
        return currentTime.toLocaleTimeString(undefined, {
            timeZone,
        });
    }
    catch (_err) {
        return `${timeZone ?? ""} is not valid`.trim();
    }
};
export const GlobalTimesPanel = ({ locations, }) => {
    const [currentTime, setCurrentTime] = React.useState(new Date());
    React.useEffect(() => {
        const interval = setInterval(() => {
            setCurrentTime(new Date());
        }, 1000);
        return () => clearInterval(interval);
    }, []);
    return (React.createElement(Panel, null, locations.map(({ location, timeZone }) => {
        const formattedTime = getTime(currentTime, timeZone);
        return (React.createElement("div", { key: location },
            React.createElement(ItemName, null, location),
            React.createElement("span", { style: { fontVariantNumeric: "tabular-nums lining-nums" } }, formattedTime)));
    })));
};
