export const SharedAuthContextValues = {
    user: null,
    logout: () => new Promise((accept) => {
        console.error("Not Logged In.");
        accept();
    }),
    isLoggedInDesktop: false,
    isAdmin: () => false,
    isEnfusionAdmin: () => false,
    isInternalPo: () => false,
    hasRestrictedPerm: () => false,
    hasPerm: () => false,
    isEnabled: () => false,
    isUserType: () => false,
    isPo: () => false,
    isPoId: () => false,
};
export function checkBbgEnabled(isEnabled, user, bbgEnvVar, os = "win") {
    return (isEnabled("BloombergMobile") &&
        isEnabled("BloombergRealtime") &&
        !!bbgEnvVar &&
        os === "win" &&
        !!user?.flags?.BloombergRealtime.bloombergUUID);
}
