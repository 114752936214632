import { REST_API } from "../../api";
import { UNKNOWN_INSTRUMENT_ID, UNKNOWN_INSTRUMENT_INFO, } from "../../constants";
import { initialOrderFormState } from "../orders";
import { convertFormValuesToOrder as convertFormValuesToOrderDefault, convertFormValuesToSplitOrder, convertOrderToFormValues, } from "../utils";
const overrideWithDefaults = (orderInfo) => {
    const defaultValues = {
        ...initialOrderFormState,
        ...orderInfo,
        quantity: orderInfo.quantity
            ? `${orderInfo.quantity}`
            : initialOrderFormState.quantity,
        limitPrice: orderInfo.limitPrice ?? initialOrderFormState.limitPrice,
        orderType: orderInfo.orderType ?? initialOrderFormState.orderType,
        webOrderType: orderInfo.webOrderType,
        currentCalculatorType: orderInfo.calculatorType,
        accountAllocation: orderInfo.accountAllocation,
        counterpartyMemo: orderInfo.counterpartyMemo ?? initialOrderFormState.counterpartyMemo,
        darkPools: orderInfo.darkPools ?? initialOrderFormState.darkPools ?? [],
        instructions: orderInfo.instructions || "",
        complianceState: orderInfo.complianceState ?? initialOrderFormState.complianceState,
        locateId: orderInfo.locateId ?? initialOrderFormState.locateId,
    };
    return defaultValues;
};
export const handleOrderChange = async (updatedOrder, prevFormValues) => convertOrderToFormValues(updatedOrder, prevFormValues);
export const getDefaultOrderState = async (restServer, id) => {
    const api = REST_API(restServer);
    try {
        const instrument = await api.INSTRUMENT.GET_INSTRUMENT_INFO.FETCH(id);
        if (instrument) {
            const response = await api.OEMS.GET_DEFAULT_ORDER.FETCH(id !== UNKNOWN_INSTRUMENT_ID ? id : undefined);
            return { ...overrideWithDefaults(response), instrument };
        }
        return undefined;
    }
    catch (err) {
        console.error(err);
        throw err;
    }
};
export const getOrderState = async (restServer, id) => {
    const api = REST_API(restServer);
    const orderInfo = await api.OEMS.GET_ORDER("equity", id);
    const instrument = orderInfo.instrumentId
        ? await api.INSTRUMENT.GET_INSTRUMENT_INFO.FETCH(orderInfo.instrumentId)
        : UNKNOWN_INSTRUMENT_INFO;
    return {
        ...convertOrderToFormValues({
            ...orderInfo,
            webOrderType: orderInfo.webOrderType,
            quantity: orderInfo.quantity,
        }),
        instrument,
    };
};
export const getSplitOrderState = async (restServer, id) => {
    const api = REST_API(restServer);
    const { childOrder } = await api.OEMS.GET_SPLIT_ORDER("equity", id);
    const instrument = childOrder.instrumentId
        ? await api.INSTRUMENT.GET_INSTRUMENT_INFO.FETCH(childOrder.instrumentId)
        : UNKNOWN_INSTRUMENT_INFO;
    return {
        ...convertOrderToFormValues({
            ...childOrder,
            webOrderType: "Electronic",
        }),
        instrument,
    };
};
export const convertFormValuesToOrder = (previousFormState, newFormState) => {
    if (!newFormState) {
        return convertFormValuesToOrderDefault(previousFormState);
    }
    return convertFormValuesToOrderDefault({
        ...previousFormState,
        ...newFormState,
    });
};
export const submitOrder = async (restServer, submissionInfo, { transmit, orderId, splitOrder, acceptComplianceWarnings, acceptValidationWarnings, }) => {
    try {
        const api = REST_API(restServer);
        const bodyContent = {
            ...submissionInfo,
            id: !splitOrder || (splitOrder && acceptComplianceWarnings) ? orderId : 0,
            tradeReason: submissionInfo?.hasOwnProperty("tradeReason")
                ? submissionInfo.tradeReason ?? ""
                : undefined,
            allocReason: submissionInfo?.hasOwnProperty("allocReason")
                ? submissionInfo.allocReason ?? ""
                : undefined,
        };
        let response;
        response = !transmit
            ? await api.OEMS.SAVE_ORDER("equity", bodyContent, {
                acceptComplianceWarnings,
                acceptValidationWarnings,
            })
            : await api.OEMS.SAVE_AND_TRANSMIT_ORDER("equity", bodyContent, {
                acceptComplianceWarnings,
                acceptValidationWarnings,
            });
        return response;
    }
    catch (err) {
        console.error("Error while submitting Equity order", err);
        throw err;
    }
};
export const getCurrentOrder = (data, orderId, splitOrder) => {
    return {
        ...(splitOrder
            ? convertFormValuesToSplitOrder(data, orderId)
            : convertFormValuesToOrderDefault(data)),
        id: orderId,
    };
};
