import { createTestId } from "@enfusion-ui/utils";
import { css, styled } from "@enfusion-ui/web-core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import * as React from "react";
import { Spinner } from "../display";
const selectBgColor = ({ primary, info, danger, success, warning, buttonTheme, }) => {
    if (primary || buttonTheme === "primary") {
        return "primary";
    }
    else if (info || buttonTheme === "info") {
        return "info";
    }
    else if (success || buttonTheme === "success") {
        return "success";
    }
    else if (warning || buttonTheme === "warning") {
        return "warning";
    }
    else if (danger || buttonTheme === "danger") {
        return "danger";
    }
    else if (buttonTheme === "text" || buttonTheme === "text-link") {
        return undefined;
    }
    return "background-color-1";
};
const selectTextColor = ({ primary, buttonTheme, theme, }) => {
    if ((primary || buttonTheme === "primary") && theme.key === "light") {
        return "text-inverted";
    }
    else if (buttonTheme === "text-link") {
        return "primary";
    }
    return "text-normal";
};
export const BasicButton = styled.button `
  font-weight: bold;
  font-size: 0.75em;
  line-height: 2em;
  border: none;
  border-radius: 4px;
  padding: 0.25em 1em;
  cursor: pointer;
  transition: background-color 0.17s;
  width: 100%;
`;
const StyledButton = styled(BasicButton) `
  ${(props) => {
    const bgColor = selectBgColor(props);
    return bgColor
        ? css `
          background-color: var(--${bgColor});
        `
        : "";
}}
  :hover {
    ${(props) => {
    const bgColor = selectBgColor(props);
    return bgColor
        ? css `
            background-color: var(--${bgColor}-hover);
          `
        : "";
}}
    opacity: 0.8;
  }

  color: var(--${selectTextColor});

  :disabled,
  :disabled:hover {
    background-color: var(--background-color-1);
    color: var(--text-muted);
  }
  ${(props) => {
    const bgColor = selectBgColor(props);
    return props.outlined
        ? css `
          border: 1px solid var(--${bgColor});
          color: var(--${bgColor});
          background-color: var(--background-color-1);
          :hover {
            background-color: var(--background-color-1);
          }
          :disabled,
          :disabled:hover {
            border-color: var(--background-color-1);
          }
        `
        : "";
}}
`;
export const Button = React.forwardRef(function Button({ icon, busy, className, children, iconRotation, iconPosition = "right", iconColor = "inherit", theme, ...rest }, ref) {
    return (React.createElement(StyledButton, { className: className, disabled: busy, buttonTheme: theme, ...rest, ref: ref }, busy ? (React.createElement(Spinner, { size: "2x", dataTestId: createTestId("spinner") })) : icon ? (React.createElement(React.Fragment, null, iconPosition === "right" ? (React.createElement(React.Fragment, null,
        children,
        React.createElement(FontAwesomeIcon, { "data-testid": createTestId("icon"), icon: icon, rotation: iconRotation, style: {
                marginLeft: children ? "8px" : "0",
                color: iconColor,
            } }))) : (React.createElement(React.Fragment, null,
        React.createElement(FontAwesomeIcon, { "data-testid": createTestId("icon"), icon: icon, rotation: iconRotation, style: {
                marginRight: children ? "8px" : "0",
                color: iconColor,
            } }),
        children)))) : (children)));
});
