import { css, styled } from "@enfusion-ui/web-core";
const getBorderColor = ({ theme, neutralSeparatorColor, }) => {
    if (!neutralSeparatorColor)
        return "var(--primary)";
    return ["light", "darkCobalt", "darkSolarized", "darkSpaceGray"].includes(theme.key)
        ? "var(--input-border)"
        : "transparent";
};
export const StyledButtonGroup = styled.div `
  width: 100%;
  display: inline-flex;
  border: 1px solid;
  border-color: ${(props) => getBorderColor({ ...props, neutralSeparatorColor: true })};
  border-radius: 4px;

  & > button {
    height: 38px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    border: 0px solid;
    border-color: ${(props) => getBorderColor(props)};
    height: ${({ size }) => size === "large" ? "40px" : size === "small" ? "30px" : "36px"};
    min-width: fit-content;
  }

  ${({ orientation }) => orientation === "vertical"
    ? css `
          flex-direction: column;
          & > button:not(:first-child) {
            border-top-left-radius: 0;
            border-top-right-radius: 0;
            border-top-width: 1px;
          }

          & > button:not(:last-child) {
            border-bottom-left-radius: 0;
            border-bottom-right-radius: 0;
          }
        `
    : css `
          flex-direction: row;

          & > button:not(:first-child) {
            border-top-left-radius: 0;
            border-bottom-left-radius: 0;
            border-left-width: 1px;
          }

          & > button:not(:last-child) {
            border-top-right-radius: 0;
            border-bottom-right-radius: 0;
          }
        `};
`;
