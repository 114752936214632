import { useRefCallback } from "@enfusion-ui/hooks";
import { getFileIcon } from "@enfusion-ui/utils";
import { styled, useSyncedRef } from "@enfusion-ui/web-core";
import { faCaretDown } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { identity } from "lodash";
import * as React from "react";
import { Portal } from "../portal/Portal";
import { MultiSelectExplorer, } from "./MultiSelectExplorer";
import { TextInput } from "./TextInput";
const InputWrapper = styled.div `
  width: 100%;
  min-width: 250px;
`;
const OptionsContainer = styled.div `
  position: relative;
  overflow-y: auto;
  overflow-x: hidden;
  background-color: var(--background-color-1);
  max-height: 150px;
`;
const defaultTextFormatter = (nodes) => {
    if (!nodes)
        return "";
    return nodes.map((i) => i.name).join(",");
};
export const HierarchySelect = React.forwardRef(function HierarchySelect(props, ref) {
    const { name, value, errors, loading, options, onChange, autoFocus = true, clearable, filterNodes = identity, retrieveNodes, onEntryContext, multiSelect = true, renderPortalContent, textFormatter = defaultTextFormatter, defaultValues, ...rest } = props;
    const [inputValue, setInputValue] = React.useState("");
    const [selectionText, setSelectionText] = React.useState("");
    const [selectedValues, setSelectedValues] = React.useState(defaultValues);
    const [menuOpen, setMenuOpen] = React.useState(false);
    const inputRef = useSyncedRef(ref);
    React.useEffect(() => {
        if (value) {
            setSelectionText(textFormatter(value));
        }
        else {
            setSelectionText("");
        }
    }, [value]);
    const handleInputFocus = () => {
        setMenuOpen(true);
    };
    const closeMenu = () => {
        setInputValue("");
        setMenuOpen(false);
    };
    const handleClick = useRefCallback(async (node) => {
        if (!multiSelect) {
            setInputValue(textFormatter([node]));
            onChange?.([node]);
        }
    }, [multiSelect, textFormatter, onChange]);
    const handleClear = useRefCallback(() => {
        setInputValue("");
        setSelectionText(textFormatter(null));
        setSelectedValues([]);
    }, [onChange]);
    const handleSelectionChange = useRefCallback((nodes) => {
        onChange?.(nodes);
        setSelectionText(textFormatter(nodes));
    }, [textFormatter]);
    const handleFilter = useRefCallback((nodes) => {
        const filtered = filterNodes(nodes).map((i) => ({
            ...i,
            nodes: !!i.nodes ? handleFilter(i.nodes) : i.nodes,
        }));
        return filtered.filter((i) => i.name.toLocaleLowerCase().includes(inputValue.toLocaleLowerCase()) ||
            (i.nodes && i.nodes.length > 0));
    }, [inputValue, filterNodes]);
    const filteredOptions = React.useMemo(() => {
        return options ? handleFilter(options) : options;
    }, [inputValue, options]);
    return (React.createElement(InputWrapper, null,
        React.createElement(TextInput, { ...rest, autoFocus: autoFocus, name: name, ref: inputRef, errors: errors, clearable: clearable, onFocus: handleInputFocus, icon: React.createElement(FontAwesomeIcon, { icon: faCaretDown }), onClearValue: clearable ? handleClear : undefined, onChange: (e) => {
                setInputValue(e.target.value);
            }, value: menuOpen ? inputValue : selectionText }),
        React.createElement(Portal, { open: menuOpen, attachedRef: inputRef, onClickOutside: closeMenu },
            React.createElement(OptionsContainer, null,
                React.createElement(MultiSelectExplorer, { loading: loading, values: selectedValues, nodes: options, filteredNodes: filteredOptions ?? [], multiSelect: multiSelect, onEntryClick: handleClick, retrieveNodes: retrieveNodes, checkSelections: multiSelect, onEntryContext: onEntryContext, selectedNodes: value ?? undefined, onSelectionChange: handleSelectionChange, defaultFoldersOpen: inputValue.trim().length > 0, error: name ? errors?.[name]?.message : undefined, getFileIcon: getFileIcon }),
                !!options && !loading && options.length > 0 && (React.createElement(React.Fragment, null, renderPortalContent?.()))))));
});
