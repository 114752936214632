import { oemsColumnMeta, } from "@enfusion-ui/core";
export const columnTypeCategoryStyle = (columnTypeCategory, 
// eslint-disable-next-line @typescript-eslint/no-explicit-any
value) => {
    switch (columnTypeCategory) {
        case "SignSensitiveNumber":
        case "SignSensitivePercent":
        case "SignedQuantity": {
            const n = value;
            return n > 0
                ? { color: "var(--success)" }
                : n < 0
                    ? { color: "var(--danger)" }
                    : null;
        }
        default: {
            return;
        }
    }
};
export const sideSensitiveStyle = (orderSide) => {
    return orderSide === "Buy"
        ? { color: "var(--success)" }
        : { color: "var(--danger)" };
};
export function orderCellStyle({ value, data, colDef }) {
    const blotterRow = data;
    const key = colDef ? colDef.colId : null;
    if (!key || !oemsColumnMeta[key])
        return;
    const { columnTypeCategory, sideSensitive } = oemsColumnMeta[key];
    if (blotterRow && sideSensitive)
        return sideSensitiveStyle(blotterRow.orderSide);
    if (columnTypeCategory && value)
        return columnTypeCategoryStyle(columnTypeCategory, value);
    return;
}
export function orderDetailsCellStyle({ value, colDef }) {
    const key = colDef ? colDef.colId : null;
    if (!key || !oemsColumnMeta[key])
        return;
    const { columnTypeCategory } = oemsColumnMeta[key];
    if (columnTypeCategory && value)
        return columnTypeCategoryStyle(columnTypeCategory, value);
    return;
}
