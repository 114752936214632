import { orderBy } from "lodash";
export function reduceSelectedNodes(nodes = []) {
    const sortedNodes = (nodes.sort((a, b) => (b.depth ?? 0) - (a.depth ?? 0)) ||
        []) ??
        [];
    return sortedNodes.reduce((res, entry) => {
        if (!entry.file) {
            res = res.filter((node) => !node.path.startsWith(`${entry.path}/`));
        }
        res.push(entry);
        return res;
    }, []);
}
export function determineFullness(nodes = []) {
    return nodes.map((entry) => {
        if (entry.file) {
            return {
                ...entry,
                full: true,
                names: [{ name: entry.name, depth: entry.depth }],
            };
        }
        const subNodes = determineFullness(entry.nodes);
        const full = subNodes.length === entry.nodeCount &&
            subNodes.every((i) => i.full);
        return {
            ...entry,
            full,
            names: full
                ? [{ name: entry.name, depth: entry.depth }]
                : subNodes.reduce((res, entry) => [
                    ...res,
                    ...(entry.names || []),
                ], []),
        };
    });
}
export function reduceNodes(nodes = [], maxDepth, depth = 0) {
    const reduceDepth = maxDepth - depth;
    if (reduceDepth === 0)
        return nodes;
    const grouped = nodes.reduce((res, entry) => {
        if (entry.depth === reduceDepth && entry.parentId) {
            const id = entry.parentId;
            res[id] = {
                ...(res[id] || {}),
                nodes: [...(res[id]?.nodes || []), entry],
            };
        }
        else {
            res[entry.id] = {
                ...entry,
                ...(res[entry.id] || {}),
            };
        }
        return res;
    }, {});
    return reduceNodes(Object.values(grouped)?.sort((a, b) => (b.depth ?? 0) - (a.depth ?? 0)), maxDepth, depth + 1);
}
export function hierarchyTextFormatter(nodes, maxDepth, filterDepthZero = true) {
    const placeholderList = determineFullness(reduceNodes(nodes ?? [], maxDepth)).reduce((res, entry) => [...res, ...(entry.names || [])], []);
    return orderBy(placeholderList.filter((i) => (filterDepthZero ? i.depth !== 0 : true)), ["depth"], ["asc"])
        .map((i) => i.name)
        .join(", ");
}
