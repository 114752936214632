import { faBookmark } from "@fortawesome/pro-solid-svg-icons";
import { widgetWithDatasourceDefaultConfig } from "../../../utils";
export const report = {
    name: "Report",
    minSize: [18, 2],
    icon: faBookmark,
    description: "Display a report",
    datasourceConfigIds: ["datasourceId"],
    defaultConfig: {
        ...widgetWithDatasourceDefaultConfig,
    },
    type: "display",
    category: "dashboard",
};
