import { kebabCase } from "lodash";
export function createTestId(base, id, defaultId) {
    if (typeof defaultId === "string")
        return defaultId;
    const prefix = kebabCase(base);
    if (typeof id === "undefined" || id === null)
        return prefix;
    return `${prefix}-${kebabCase(`${id}`)}`;
}
export const testId = (prefix, id) => createTestId(prefix, id);
