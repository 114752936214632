export function createCoreBroadcastChannelFactory(BroadcastChannel) {
    class CoreBroadcastChannel {
        name;
        subscriptions = new Set();
        bc;
        constructor(name, options) {
            this.name = name;
            this.bc = new BroadcastChannel(name, options);
            this.bc.addEventListener("message", (msg) => {
                this.subscriptions.forEach((cb) => cb(msg));
            });
        }
        subscribe = (cb) => {
            this.subscriptions.add(cb);
            return () => {
                this.subscriptions.delete(cb);
            };
        };
        postMessage = (msg, cbRef) => {
            this.bc.postMessage(msg);
            for (const cb of this.subscriptions) {
                if (cb !== cbRef)
                    cb(msg);
            }
        };
        broadcastMessage = (msg) => {
            this.bc.postMessage(msg);
            for (const cb of this.subscriptions) {
                cb(msg);
            }
        };
    }
    return function coreBroadcastChannelFactory(name, options) {
        return new CoreBroadcastChannel(name, options);
    };
}
