export const ZEROS_NUMBERS_MAP = {
    zero: 0,
};
export const SINGLES_NUMBERS_MAP = {
    one: 1,
    two: 2,
    three: 3,
    four: 4,
    five: 5,
    six: 6,
    seven: 7,
    eight: 8,
    nine: 9,
};
export const TEENS_NUMBERS_MAP = {
    ten: 10,
    eleven: 11,
    twelve: 12,
    thirteen: 13,
    fourteen: 14,
    fifteen: 15,
    sixteen: 16,
    seventeen: 17,
    eighteen: 18,
    nineteen: 19,
};
export const TENS_NUMBERS_MAP = {
    twenty: 20,
    thirty: 30,
    forty: 40,
    fifty: 50,
    sixty: 60,
    seventy: 70,
    eighty: 80,
    ninety: 90,
};
export const NUMBER_WORD_MAPPING = {
    ...ZEROS_NUMBERS_MAP,
    ...SINGLES_NUMBERS_MAP,
    ...TEENS_NUMBERS_MAP,
    ...TENS_NUMBERS_MAP,
};
export const NUMBER_MODIFIERS = {
    hundred: 100,
    thousand: 1000,
    million: 1000000,
    billion: 1000000000,
    trillion: 1000000000000,
};
export const CONVERSION_MODIFIERS = {
    milliseconds: 1,
    seconds: 1000,
    minutes: 60000,
    hours: 3600000,
    days: 86400000,
    weeks: 7 * 86400000,
    years: 52 * 7 * 86400000,
};
