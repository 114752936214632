import { noop } from "lodash";
import * as React from "react";
export const ReportsContext = React.createContext(undefined);
export const TableRowsContext = React.createContext(undefined);
export function useReports() {
    const context = React.useContext(ReportsContext);
    if (typeof context === "undefined") {
        return { openReportTab: noop };
    }
    return context;
}
export const ReportsProviderPassthrough = ({ children, ...value }) => (React.createElement(ReportsContext.Provider, { value: value }, children));
