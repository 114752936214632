import { EMPTY_RECORD } from "@enfusion-ui/core";
import { useModalState } from "@enfusion-ui/hooks";
import { styled } from "@enfusion-ui/web-core";
import { faQuestionCircle } from "@fortawesome/pro-light-svg-icons";
import { FontAwesomeIcon, } from "@fortawesome/react-fontawesome";
import { Popover as PopoverBase, positionDefault, } from "@reach/popover";
import isTouchDevice from "is-touch-device";
import { noop } from "lodash";
import * as React from "react";
import { useClickAway } from "react-use";
export const PopoverContainer = styled.div `
  padding: var(--spacing-xl);
  border-radius: var(--radius-l);
  box-shadow: var(--shadow);
  background-color: var(--background-color-2);

  display: flex;
  flex-direction: column;
  font-size: 0.75rem;
  gap: var(--spacing-l);
`;
export const PopoverTitle = styled.div `
  font-weight: 600;
  text-align: center;
`;
export const PopoverContent = styled.div `
  margin: 0;
  padding: 0 var(--spacing-xl);
  color: var(--text-normal);
`;
const InfoIcon = styled(FontAwesomeIcon) `
  cursor: help;

  :hover {
    opacity: 0.5;
  }
`;
export const Popover = ({ open, title, targetRef, position = positionDefault, contentProps = EMPTY_RECORD, titleProps = EMPTY_RECORD, children, testId, onClickOutside, }) => {
    const containerRef = React.useRef(null);
    useClickAway(containerRef, (e) => onClickOutside?.(e));
    if (!open)
        return null;
    return (React.createElement(PopoverBase, { targetRef: targetRef, position: position, "data-testid": testId },
        React.createElement(PopoverContainer, null,
            title && React.createElement(PopoverTitle, { ...titleProps }, title),
            React.createElement(PopoverContent, { ...contentProps }, children))));
};
export function usePopover({ content: children, closeOnClickOutside, ...rest }) {
    const state = useModalState();
    const targetRef = React.useRef(null);
    const content = (React.createElement(Popover, { open: state.open, targetRef: targetRef, onClickOutside: closeOnClickOutside ? state.closeModal : noop, ...rest }, children));
    return { ...state, ref: targetRef, content };
}
export const InfoPopover = ({ size = "sm", color = "var(--primary)", style, iconTestId, children, ...rest }) => {
    const PopoverState = usePopover({
        ...rest,
        content: children,
        closeOnClickOutside: isTouchDevice(),
    });
    const handlers = React.useMemo(() => {
        const actions = {
            onMouseOver: PopoverState.openModal,
            onMouseLeave: PopoverState.closeModal,
        };
        if (isTouchDevice())
            actions.onClick = PopoverState.openModal;
        return actions;
    }, [PopoverState.openModal, PopoverState.closeModal]);
    return (React.createElement(React.Fragment, null,
        React.createElement(InfoIcon, { size: size, color: color, style: style, ref: PopoverState.ref, icon: faQuestionCircle, "data-testid": iconTestId, ...handlers }),
        PopoverState.content));
};
