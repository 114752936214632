export function getSelectedValues(values, options) {
    const result = [];
    values?.forEach((value) => {
        const selectedOption = options.find((option) => option.value === value);
        if (selectedOption) {
            result.push(selectedOption);
        }
    });
    return result;
}
