import * as React from "react";
export const GeneralFilesContext = React.createContext(undefined);
export function useGeneralFiles() {
    const context = React.useContext(GeneralFilesContext);
    if (typeof context === "undefined") {
        throw new Error("useGeneralFiles must be used within a FilesProvider of root 'general'");
    }
    return context;
}
export const ServicesFilesContext = React.createContext(undefined);
export function useServicesFiles() {
    const context = React.useContext(ServicesFilesContext);
    if (typeof context === "undefined") {
        throw new Error("useFiles must be used within a FilesProvider of root 'services'");
    }
    return context;
}
export const OperationFilesContext = React.createContext(undefined);
export function useOperationFiles() {
    const context = React.useContext(OperationFilesContext);
    if (typeof context === "undefined") {
        throw new Error("useOperationFiles must be used within a FilesProvider of root 'operations'");
    }
    return context;
}
