import { faSearchDollar } from "@fortawesome/pro-solid-svg-icons";
export const sourceIdsSelection = {
    name: "Source Ids",
    minSize: [5, 1],
    icon: faSearchDollar,
    description: "Choose source Id(s)",
    defaultConfig: {
        defaultValue: null,
        key: "Source Ids",
    },
    type: "input",
    category: "dashboard",
};
