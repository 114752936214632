import { formatDateToStandardFormat } from "@enfusion-ui/utils";
import { REST_API } from "../../api";
import { initialFXOrderFormState } from "../orders";
import { convertFormValueToFXOrder, convertFormValueToFXSplitOrder, convertFXOrderToFormValues, } from "../utils";
const overrideWithDefaults = (orderInfo) => {
    const defaultValues = {
        ...initialFXOrderFormState,
        ...orderInfo,
        quantity: orderInfo.quantity
            ? `${orderInfo.quantity}`
            : initialFXOrderFormState.quantity,
        webOrderType: orderInfo.webOrderType,
        accountAllocation: orderInfo.accountAllocation,
        counterpartyMemo: orderInfo.counterpartyMemo ?? initialFXOrderFormState.counterpartyMemo,
        instructions: orderInfo.instructions || "",
        complianceState: orderInfo.complianceState ?? initialFXOrderFormState.complianceState,
        farFixingDate: orderInfo.farFixingDate ?? initialFXOrderFormState.farFixingDate,
        farSettleDate: orderInfo.farSettleDate ?? initialFXOrderFormState.farSettleDate,
        allocationTemplateId: orderInfo.allocationTemplateId ??
            initialFXOrderFormState.allocationTemplateId,
        instrumentId: null,
    };
    return defaultValues;
};
export const handleOrderChange = async (updatedOrder, prevFormValues) => convertFXOrderToFormValues(updatedOrder, prevFormValues);
export const getDefaultOrderState = async (restServer, id) => {
    try {
        const response = await REST_API(restServer).OEMS.GET_DEFAULT_ORDER.FETCH(id || undefined);
        const newDefaultValues = overrideWithDefaults(response);
        return newDefaultValues;
    }
    catch (err) {
        console.error(err);
        throw err;
    }
};
export const getOrderState = async (restServer, id) => {
    const orderInfo = await REST_API(restServer).OEMS.GET_ORDER("fx", id);
    return {
        ...convertFXOrderToFormValues(orderInfo),
        instrumentId: null,
    };
};
// TODO: use in FX order
export const getSplitOrderState = async (restServer, id) => {
    const { childOrder } = await REST_API(restServer).OEMS.GET_SPLIT_ORDER("fx", id);
    return {
        ...convertFXOrderToFormValues(childOrder),
        instrumentId: null,
    };
};
export const convertFormValuesToOrder = (
// eslint-disable-next-line @typescript-eslint/no-explicit-any
previousFormState, 
// eslint-disable-next-line @typescript-eslint/no-explicit-any
newFormState) => {
    if (!newFormState) {
        return convertFormValueToFXOrder(previousFormState);
    }
    return convertFormValueToFXOrder({ ...previousFormState, ...newFormState });
};
export const submitOrder = async (restServer, submissionInfo, { transmit, orderId, splitOrder = false, acceptComplianceWarnings, acceptValidationWarnings, }) => {
    try {
        const bodyContent = {
            ...submissionInfo,
            id: !splitOrder || (splitOrder && acceptComplianceWarnings) ? orderId : 0,
            tradeReason: submissionInfo?.hasOwnProperty("tradeReason")
                ? submissionInfo.tradeReason ?? ""
                : undefined,
            allocReason: submissionInfo?.hasOwnProperty("allocReason")
                ? submissionInfo.allocReason ?? ""
                : undefined,
        };
        const newContent = {
            ...bodyContent,
            settleDate: formatDateToStandardFormat(new Date(bodyContent.settleDate ?? new Date())),
            fixingDate: !bodyContent.fixingDate
                ? undefined
                : formatDateToStandardFormat(new Date(bodyContent.fixingDate ?? new Date())),
            farFixingDate: !bodyContent.farFixingDate
                ? undefined
                : formatDateToStandardFormat(new Date(bodyContent.farFixingDate ?? new Date())),
            farSettleDate: !bodyContent.farSettleDate
                ? formatDateToStandardFormat(new Date())
                : formatDateToStandardFormat(new Date(bodyContent.farSettleDate ?? new Date())),
        };
        const response = !transmit
            ? await REST_API(restServer).OEMS.SAVE_ORDER("fx", newContent, { acceptComplianceWarnings, acceptValidationWarnings })
            : await REST_API(restServer).OEMS.SAVE_AND_TRANSMIT_ORDER("fx", newContent, { acceptComplianceWarnings, acceptValidationWarnings });
        return {
            ...response,
            orderInfo: { ...response.orderInfo, instrumentId: null },
        };
    }
    catch (err) {
        console.error("Error when submitting order", err);
        throw err;
    }
};
export const getCurrentOrder = (data, orderId, splitOrder) => {
    return {
        ...(splitOrder
            ? convertFormValueToFXSplitOrder(data, orderId)
            : convertFormValueToFXOrder(data)),
        id: orderId,
    };
};
