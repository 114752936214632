import { faHeading } from "@fortawesome/pro-solid-svg-icons";
import { widgetWithDatasourceDefaultConfig } from "../../../utils";
export const listView = {
    name: "List View",
    minSize: [10, 2],
    icon: faHeading,
    description: "Display a dynamic list view",
    datasourceConfigIds: ["datasourceId"],
    defaultConfig: {
        defaultValue: "",
        key: "List View",
        ...widgetWithDatasourceDefaultConfig,
    },
    type: "input",
    category: "dashboard",
};
