import { styled } from "@enfusion-ui/web-core";
import * as React from "react";
const PanelHeader = styled.div `
  display: flex;
  gap: var(--spacing-xl);
  padding: 0 var(--spacing);
`;
const HeaderTitle = styled.h5 ``;
const HeaderHorizontalRule = styled.hr `
  border: unset;
  border-top: 1px solid ${({ dividerColor }) => dividerColor};
  flex: 1;
`;
export const FormElementHeader = ({ children, style, showDivider = true, dividerColor = "var(--background-color-1)", }) => {
    return (React.createElement(PanelHeader, { style: style },
        React.createElement(HeaderTitle, null, children),
        showDivider && React.createElement(HeaderHorizontalRule, { dividerColor: dividerColor })));
};
