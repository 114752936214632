/* eslint-disable @typescript-eslint/no-explicit-any */
import { noop } from "lodash";
import * as React from "react";
export function useRefCallback(method, deps, defaultValue = noop) {
    const callbackRef = React.useRef(defaultValue);
    // needs to be a ref because of use in a event listener
    callbackRef.current = React.useCallback(method, deps);
    const calledMethod = React.useCallback((...args) => callbackRef.current(...args), [callbackRef]);
    return calledMethod;
}
