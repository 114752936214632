import { mobilePostMessage } from "@enfusion-ui/utils";
import { styled, ThemeProvider } from "@enfusion-ui/web-core";
import { faExclamationCircle, } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import * as React from "react";
import { SVGBackground } from "../Styled";
const ErrorPageContainer = styled(SVGBackground) ``;
const ErrorBox = styled.div `
  background: var(--background-color-0);
  padding: var(--spacing-xxl);
  box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.2);
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  max-width: 420px;
  width: 80%;
`;
const MessageContainer = styled.span `
  margin-top: var(--spacing-xl);
  white-space: pre-line;
`;
export const ErrorPageContent = ({ icon = faExclamationCircle, children, message, }) => (React.createElement(ErrorPageContainer, null,
    React.createElement(ErrorBox, null,
        React.createElement(FontAwesomeIcon, { icon: icon, size: "3x" }),
        React.createElement(MessageContainer, null, message),
        children)));
export const ErrorPage = ({ message, children, ...props }) => {
    React.useEffect(() => {
        mobilePostMessage({
            source: "mobile-app-auth",
            action: "app-error-view",
            payload: message,
        });
    }, [message]);
    return (React.createElement(ThemeProvider, { defaultTheme: "light" },
        React.createElement(ErrorPageContent, { ...props, message: message }, children)));
};
