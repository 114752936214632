export const AppEvent = {
    MoveFile: "MoveFile",
    CopyFile: "CopyFile",
    CreateFile: "CreateFile",
    RenameFile: "RenameFile",
    DeleteFile: "DeleteFile",
    SearchFiles: "SearchFiles",
    SortFiles: "SortFiles",
    LayoutTabsLoad: "LayoutTabsLoad",
    LayoutTabOpen: "LayoutTabOpen",
    LayoutTabClose: "LayoutTabClose",
    OpenContextMenu: "OpenContextMenu",
    CopyShareLink: "CopyShareLink",
    DownloadZipFile: "DownloadZipFile",
    DownloadFile: "DownloadFile",
    UserTracking: "UserTracking",
    UserLoggedOut: "UserLoggedOut",
    SetUser: "SetUser",
    AuthViewFailedToLoad: "AuthViewFailedToLoad",
    UnhandledError: "UnhandledError",
    ErrorBoundary: "ErrorBoundary",
    CaughtError: "CaughtError",
    FormatterErr: "FormatterErr",
    PolicyIssue: "PolicyIssue",
    NotAuthorized: "NotAuthorized",
    Conflict: "Conflict",
    ScreenView: "ScreenView",
    TabSelection: "TabSelection",
    OrderOpened: "OrderOpened",
    SubmitOrder: "SubmitOrder",
    SubmitAndTransmitOrder: "SubmitAndTransmitOrder",
    OrderBlotterOpened: "OrderBlotterOpened",
    SelectOrderBlotterRow: "SelectOrderBlotterRow",
    PositionDetailsOpened: "PositionDetailsOpened",
    DashboardOpened: "DashboardOpened",
    ReportOpened: "ReportOpened",
    ReportBreadcrumbNavigation: "ReportBreadcrumbNavigation",
    FileOpened: "FileOpened",
    ServicesMarketingOpened: "ServicesMarketingOpened",
    InitInstrument: "InitInstrument",
    InitChartSettings: "InitChartSettings",
    MobileVersionOutOfDate: "Mobile Version Out of Date",
    MobileVersionNotSupported: "Mobile Version Not Supported",
};
export const AppEventCategories = {
    Auth: "Authentication",
    Navigation: "Navigation",
    Services: "Services",
    APIExplorer: "API Explorer",
    Compliance: "Compliance",
    Dashboards: "Dashboards",
    GeneralFiles: "General Files",
    Reconciliation: "Reconciliation",
    OEMS: "OEMS",
    Portfolios: "Portfolios",
    Reports: "Reports",
    WatchLists: "Watch List",
    EmbedCharting: "Embed Charting",
    Operations: "Operations",
};
