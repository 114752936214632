/* eslint-disable react/display-name */
import { styled } from "@enfusion-ui/web-core";
import { faTimes } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { ErrorMessage } from "@hookform/error-message";
import * as React from "react";
import { useRef } from "react";
import { IconButton } from "../control/IconButton";
import { InputContainer as MultiInputContainer, InputErrorMessage, InputLabel, InputRequired, } from "./TextInput";
const MimickedInput = styled.div `
  display: flex;
  padding: ${({ hasValues }) => (hasValues ? "0.285rem 0.45rem" : "0.45rem")};
  padding-right: ${({ clearable }) => (clearable ? "0" : "0.45rem")};
  border-radius: 4px;
  color: var(--text-${({ disabled }) => (disabled ? "muted" : "normal")});
  background-color: var(
    --background-color-${({ disabled }) => (disabled ? "0" : "1")}
  );
  border: 1px solid
    var(--${({ hasError }) => (hasError ? "danger" : "background-color-2")});
  outline: none;
  font: inherit;
  font-size: 0.875em;
  cursor: ${({ disabled }) => (disabled ? "default" : "text")};

  :focus-within {
    border-color: var(--${({ hasError }) => (hasError ? "danger" : "primary")});
  }

  :hover {
    border-color: var(
      --${({ hasError, disabled }) => (hasError ? "danger" : disabled ? "background-color-2" : "primary-hover")}
    );
  }
`;
const ValuesContainer = styled.div `
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  flex: 1;
`;
const StrippedInput = styled.input `
  flex: 1;
  width: 100%;
  min-width: 2rem;
  background: 0;
  border: 0;
  outline: 0;
  padding: 0;
  margin: var(--spacing-s);
  line-height: 1.25em;
`;
const Value = styled.div `
  display: flex;
  align-items: center;
  background-color: ${({ disabled }) => disabled ? "var(--background-color-2)" : "var(--primary)"};
  border-radius: 2px;
  padding: 3px 6px;
  margin: 2px;
  line-height: 1.25em;
  overflow: hidden;
`;
const ValueText = styled.div `
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  margin-right: ${({ disabled }) => (disabled ? "0" : "var(--spacing-l)")};
  font-size: 85%;
`;
const IconContainer = styled.div `
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 1rem;
  width: 2rem;
`;
export const MultiInput = React.forwardRef(({ label, name = "multi", values, onChange, inline = false, disabled = false, clearable = false, required, errors, onValueRemoval, ...inputProps }, ref) => {
    const newRef = useRef(null);
    const inputRef = ref ?? newRef;
    const handleMimickedInputClick = () => {
        inputRef.current.focus();
    };
    const handleClear = () => {
        onChange(null);
    };
    const hasError = errors && name && errors[name];
    return (React.createElement(MultiInputContainer, { inline: inline },
        label ? (React.createElement(InputLabel, { htmlFor: name },
            label,
            " ",
            required && React.createElement(InputRequired, null, "*"),
            hasError && (React.createElement(InputErrorMessage, null,
                "\u2013 ",
                React.createElement(ErrorMessage, { errors: errors, name: name }))))) : null,
        React.createElement(MimickedInput, { disabled: disabled, onClick: handleMimickedInputClick, tabIndex: disabled ? undefined : -1, hasValues: !!(values && values.length), clearable: clearable, hasError: hasError },
            React.createElement(ValuesContainer, null,
                values?.map((options) => {
                    return (React.createElement(Value, { key: options.id ?? options.label, title: options.label, disabled: disabled },
                        React.createElement(ValueText, { disabled: disabled }, options.label),
                        !disabled ? (React.createElement(FontAwesomeIcon, { icon: faTimes, onClick: () => onValueRemoval(options.value), style: { cursor: "pointer" }, size: "xs" })) : null));
                }),
                React.createElement(StrippedInput, { ...inputProps, name: name, onChange: onChange, ref: inputRef, id: name, disabled: disabled, autoComplete: "off", required: required })),
            !!values && values.length > 0 && !disabled && clearable ? (React.createElement(IconContainer, null,
                React.createElement(IconButton, { icon: faTimes, onClick: handleClear }))) : null)));
});
