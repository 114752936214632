import { faSearchDollar } from "@fortawesome/pro-solid-svg-icons";
export const generalInfoPanel = {
    name: "General Info Panel",
    minSize: (mobile) => (mobile ? [13, 2] : [19, 2]),
    defaultSize: [19, 10],
    icon: faSearchDollar,
    description: "Choose general info details",
    defaultConfig: {
        defaultValue: null,
        key: "GeneralInfoPanel",
    },
    type: "display",
    category: "orderForm",
};
export const workflowStateSelect = {
    name: "Workflow State Select",
    minSize: [5, 2],
    defaultSize: [5, 3],
    maxSize: [5, 12],
    icon: faSearchDollar,
    description: "Choose workflow state",
    defaultConfig: {
        defaultValue: null,
        key: "WorkflowStateSelect",
    },
    type: "input",
    category: "orderForm",
    webOnly: true,
};
export const tradeReasonSelect = {
    name: "Trade Reason Select",
    minSize: [5, 2],
    defaultSize: [5, 3],
    maxSize: [5, 12],
    icon: faSearchDollar,
    description: "Choose trade reason",
    defaultConfig: {
        defaultValue: null,
        key: "TradeReasonSelect",
    },
    type: "input",
    category: "orderForm",
};
export const parentOrderInput = {
    name: "Parent Order Input",
    minSize: [5, 2],
    defaultSize: [5, 3],
    maxSize: [5, 12],
    icon: faSearchDollar,
    description: "Input parent order",
    defaultConfig: {
        defaultValue: null,
        key: "ParentOrderInput",
    },
    type: "input",
    category: "orderForm",
    webOnly: true,
};
export const offeringTypeSelect = {
    name: "Offering Type Select",
    minSize: [5, 2],
    defaultSize: [5, 3],
    maxSize: [5, 12],
    icon: faSearchDollar,
    description: "Choose offering type",
    defaultConfig: {
        defaultValue: null,
        key: "OfferingTypeSelect",
    },
    type: "input",
    category: "orderForm",
    webOnly: true,
};
export const instructionsInput = {
    name: "Instructions Input",
    minSize: [7, 2],
    defaultSize: [7, 3],
    icon: faSearchDollar,
    description: "Input instructions",
    defaultConfig: {
        defaultValue: null,
        key: "InstructionsInput",
    },
    type: "input",
    category: "orderForm",
};
export const complianceNotesInput = {
    name: "Compliance Notes Input",
    minSize: [7, 2],
    defaultSize: [7, 3],
    icon: faSearchDollar,
    description: "Input compliance notes",
    defaultConfig: {
        defaultValue: null,
        key: "ComplianceNotesInput",
    },
    type: "input",
    category: "orderForm",
    webOnly: true,
};
export const allocationReasonSelect = {
    name: "Allocation Reason Select",
    minSize: [5, 2],
    defaultSize: [5, 3],
    maxSize: [5, 12],
    icon: faSearchDollar,
    description: "Choose allocation reason",
    defaultConfig: {
        defaultValue: null,
        key: "AllocationReasonSelect",
    },
    type: "input",
    category: "orderForm",
};
