/* eslint-disable @typescript-eslint/no-explicit-any */
import * as React from "react";
export function useRefState(value) {
    const valueRef = React.useRef(value);
    const [valueState, setValue] = React.useState(value);
    React.useEffect(() => {
        valueRef.current = valueState;
    }, [valueState]);
    return [valueRef, setValue];
}
