import { replaceDoubleSlashWithSingleSlash } from "@enfusion-ui/utils";
import { PORTFOLIO_LATEST_CONFIG_VERSION } from "../constants";
import DOCUMENTS_API, { DocumentAPIRoots } from "./documents";
import { bindDocumentsApi } from "./utils/documentsBind";
export default (callCreator) => {
    const documentsAPI = DOCUMENTS_API(callCreator);
    return {
        ...bindDocumentsApi(documentsAPI, DocumentAPIRoots.PORTFOLIO),
        DOWNLOAD_PORTFOLIO: (filePath, abortController) => documentsAPI.DOWNLOAD.FETCH(DocumentAPIRoots.PORTFOLIO, filePath, false, abortController),
        STORE_PORTFOLIO: ({ path, rootPath, name, version, config, settings, configVersion = PORTFOLIO_LATEST_CONFIG_VERSION, forceWrite = false, }, abortController) => documentsAPI.UPLOAD.FETCH(DocumentAPIRoots.PORTFOLIO, rootPath, {
            path: replaceDoubleSlashWithSingleSlash(`/${path}/`),
            forceWrite,
            content: {
                name,
                version,
                config,
                settings,
                configVersion,
            },
        }, false, abortController),
    };
};
