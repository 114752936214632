import { last } from "lodash";
export function abortableArgs(call) {
    return (...args) => {
        const callRes = call(...args);
        const methodArguments = typeof callRes === "string"
            ? [callRes, {}]
            : callRes;
        return methodArguments;
    };
}
export function abortable(restServer, call) {
    return ((...args) => {
        const lastArg = last(args);
        const abortController = lastArg instanceof AbortController ? lastArg : undefined;
        const methodArguments = abortableArgs(call)(...args);
        return restServer(methodArguments[0], {
            ...methodArguments[1],
            signal: abortController?.signal,
        });
    });
}
