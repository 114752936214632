import * as React from "react";
import { useThisTab } from "../tabs";
export const DimensionsContext = React.createContext(undefined);
export function useDimensions() {
    const { width, height } = useThisTab();
    const context = React.useContext(DimensionsContext);
    if (typeof context === "undefined") {
        return { width, height };
    }
    const { width: overrideWidth, height: overrideHeight } = context;
    return { width: overrideWidth || width, height: overrideHeight || height };
}
