import queryString from "query-string";
import { varReplace } from "./utils/varReplace";
const baseUrl = "/internal/api/fund/v1";
export const calls = {
    GET_BOOK: {
        url: `${baseUrl}/books/{bookId}`,
        urlParams: {
            bookId: { defaultValue: 1 },
        },
    },
    GET_BOOKS: { url: `${baseUrl}/books/all` },
    GET_BOOKS_LEVELS: { url: `${baseUrl}/books/hierarchies` },
    GET_BOOKS_HIERARCHY: {
        url: `${baseUrl}/books/hierarchy`,
        query: {
            levels: {},
            showInactive: { defaultValue: true },
            searchText: {},
        },
    },
    GET_STRATEGY_BOOKS: { url: `${baseUrl}/books/strategy` },
    GET_FUND_HIERARCHY: { url: `${baseUrl}/accounts/hierarchy` },
    GET_ACCOUNT_DETAILS: {
        url: `${baseUrl}/accounts/{accountId}`,
        urlParams: {
            accountId: { defaultValue: 1 },
        },
    },
    GET_SHARE_CLASSES: { url: `${baseUrl}/shareclasses` },
    GET_LEDGER_HIERARCHY: { url: "/internal/api/gl/v1/ledgers/hierarchy" },
};
export default (callCreator) => {
    return {
        GET_BOOK: callCreator((bookId) => varReplace(calls.GET_BOOK.url, { bookId })),
        GET_BOOKS: callCreator(() => calls.GET_BOOKS.url),
        GET_BOOKS_LEVELS: callCreator(() => calls.GET_BOOKS_LEVELS.url),
        GET_BOOKS_HIERARCHY: callCreator((levels, showInactive, searchText) => queryString.stringifyUrl({
            url: calls.GET_BOOKS_HIERARCHY.url,
            query: {
                levels,
                showInactive,
                searchText,
            },
        })),
        GET_STRATEGY_BOOKS: callCreator(() => calls.GET_STRATEGY_BOOKS.url),
        GET_FUND_HIERARCHY: callCreator(() => calls.GET_FUND_HIERARCHY.url),
        GET_ACCOUNT_DETAILS: callCreator((accountId) => varReplace(calls.GET_ACCOUNT_DETAILS.url, { accountId })),
        GET_SHARE_CLASSES: callCreator(() => calls.GET_SHARE_CLASSES.url),
        GET_LEDGER_HIERARCHY: callCreator(() => calls.GET_LEDGER_HIERARCHY.url),
    };
};
