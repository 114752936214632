/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { useWidget } from "@enfusion-ui/dashboards";
import { createTestId, getSelectOption } from "@enfusion-ui/utils";
import { words } from "lodash";
import * as React from "react";
import { DeskSelect } from "../reportQueryComponents";
import { DatePresetInput } from "./DatePreset";
import { NumericInput } from "./NumericInput";
import { Select } from "./Select";
import { TagInput } from "./TagInput";
import { TextInput } from "./TextInput";
export const ReportParamInput = ({ name, type, multi, value, optionValues, onChange, clearable = true, ...rest }) => {
    const { editedConfig } = useWidget();
    const options = React.useMemo(() => {
        if (optionValues) {
            return optionValues.map((eachValue) => ({
                value: eachValue,
                label: words(eachValue).join(" "),
            }));
        }
        return [];
    }, []);
    const handleParamStaticValueChange = React.useCallback((value) => {
        onChange(name, value);
    }, [name, editedConfig?.paramStaticValues]);
    if (!type) {
        return null;
    }
    switch (type.toLowerCase()) {
        case "id":
            return multi ? (React.createElement(TagInput, { name: name, onChange: (val) => {
                    handleParamStaticValueChange(val?.map((i) => Number(i)).filter((i) => !isNaN(i)) ?? null);
                }, value: value?.map(String) ?? null, clearable: clearable, numeric: true, ...rest })) : (React.createElement(NumericInput, { name: name, onChange: (val) => {
                    handleParamStaticValueChange(val);
                }, value: value, onKeyPress: (e) => {
                    if (!new RegExp(/[0-9]/).test(e.key))
                        e.preventDefault();
                }, formatValue: null, hideControls: true, clearable: clearable, ...rest }));
        case "date":
            return (React.createElement(DatePresetInput, { name: name, value: value, onChange: handleParamStaticValueChange, clearable: clearable, ...rest }));
        case "enum":
            return (React.createElement(Select, { name: name, onChange: (val) => {
                    handleParamStaticValueChange(val
                        ? Array.isArray(val)
                            ? val.map((i) => i.value)
                            : val.value
                        : null);
                }, value: value !== undefined
                    ? getSelectOption(options, value)
                    : null, options: options, clearable: clearable, isMulti: multi, inputId: `${name}-report-param-selection-id`, ...rest }));
        case "desk": {
            return (React.createElement(DeskSelect, { value: value, onChange: (val) => {
                    handleParamStaticValueChange(val ? val.map((i) => i) : null);
                } }));
        }
        default: {
            if (multi) {
                return (React.createElement(TagInput, { name: name, onChange: handleParamStaticValueChange, value: value, clearable: clearable, ...rest }));
            }
            return (React.createElement(TextInput, { name: name, onChange: (e) => {
                    handleParamStaticValueChange(e.target.value);
                }, value: value, clearable: clearable, "data-testid": createTestId("report-param-text-input"), ...rest }));
        }
    }
};
