/* eslint-disable no-nested-ternary */
const defaultEntry = Symbol("|DEFAULT|");
function mapArg(key) {
    return (Array.isArray(key)
        ? Array.isArray(key[0])
            ? key
            : key.map((k) => [k, defaultEntry])
        : [[key, defaultEntry]]);
}
export class IndexSetStore {
    values = new Map();
    addSync = (key) => {
        mapArg(key).forEach(([key, val]) => {
            if (this.values.has(key)) {
                this.values.get(key).add(val);
            }
            else {
                this.values.set(key, new Set([val]));
            }
        });
    };
    add = (key) => new Promise((accept) => {
        accept(this.addSync(key));
    });
    removeSync = (key) => {
        mapArg(key).forEach(([key, val]) => {
            const entry = this.values.get(key);
            if (!!entry && (entry.has(val) || val === defaultEntry)) {
                if (entry.size === 1 || val === defaultEntry)
                    this.values.delete(key);
                else
                    this.values.get(key)?.delete(val);
            }
        });
    };
    remove = (value) => new Promise((accept) => {
        accept(this.removeSync(value));
    });
    removeIndex = (elem) => new Promise((accept) => {
        accept(this.removeIndexSync(elem));
    });
    removeIndexSync = (elem) => {
        const elements = Array.isArray(elem) ? elem : [elem];
        const keys = [...this.values.keys()];
        elements.forEach((eachElem) => {
            // eslint-disable-next-line no-plusplus
            for (let j = 0; j < keys.length; j++) {
                const isDeleted = this.values.get(keys[j])?.delete(eachElem);
                if (isDeleted) {
                    if (this.values.get(keys[j])?.size === 0) {
                        this.values.delete(keys[j]);
                    }
                    break;
                }
            }
        });
    };
    get = () => [...this.values.keys()];
    get size() {
        return this.values.size;
    }
}
