import { faSearchDollar } from "@fortawesome/pro-solid-svg-icons";
export const executionMethodPanel = {
    name: "Execution Method Panel",
    minSize: (mobile) => (mobile ? [27, 2] : [16, 2]),
    defaultSize: [27, 10],
    maxSize: [70, 12],
    icon: faSearchDollar,
    description: "Choose execution method details",
    defaultConfig: {
        defaultValue: null,
        key: "ExecutionMethodPanel",
    },
    type: "display",
    category: "orderForm",
};
export const electronicLimitDetailsPanel = {
    name: "Electronic Limit Details Panel",
    minSize: (mobile) => (mobile ? [12, 2] : [20, 2]),
    defaultSize: [12, 10],
    maxSize: [29, 12],
    icon: faSearchDollar,
    description: "Choose electronic limit entries",
    defaultConfig: {
        defaultValue: null,
        key: "ElectronicLimitDetailsPanel",
    },
    type: "display",
    category: "orderForm",
};
export const electronicExecutionDetailsPanel = {
    name: "Electronic Execution Details",
    minSize: (mobile) => (mobile ? [18, 2] : [24, 2]),
    defaultSize: [24, 10],
    maxSize: [36, 12],
    icon: faSearchDollar,
    description: "Choose electronic execution details",
    defaultConfig: {
        defaultValue: null,
        key: "ElectronicExecutionDetailsPanel",
    },
    type: "display",
    category: "orderForm",
};
export const voiceExecutionPanel = {
    name: "Voice Execution Panel",
    minSize: (mobile) => (mobile ? [9, 2] : [6, 2]),
    defaultSize: [5, 5],
    maxSize: [11, 12],
    icon: faSearchDollar,
    description: "Choose voice execution details",
    defaultConfig: {
        defaultValue: null,
        key: "VoiceExecutionPanel",
    },
    type: "display",
    category: "orderForm",
};
export const electronicRoutingDetailsPanel = {
    name: "Electronic Routing Details Panel",
    minSize: (mobile) => (mobile ? [17, 2] : [13, 2]),
    defaultSize: [13, 10],
    maxSize: [25, 12],
    icon: faSearchDollar,
    description: "Choose electronic routing detail entries",
    defaultConfig: {
        defaultValue: null,
        key: "ElectronicRoutingDetailsPanel",
    },
    type: "display",
    category: "orderForm",
};
export const webOrderTypeSelect = {
    name: "Web Order Type Select",
    minSize: (mobile) => (mobile ? [4, 2] : [2, 2]),
    defaultSize: [2, 3],
    maxSize: [3, 12],
    icon: faSearchDollar,
    description: "Choose web order type",
    defaultConfig: {
        defaultValue: null,
        key: "WebOrderSelect",
    },
    type: "input",
    category: "orderForm",
};
export const orderTypeSelect = {
    name: "Order Type Select",
    minSize: [5, 2],
    defaultSize: [5, 3],
    maxSize: [5, 12],
    icon: faSearchDollar,
    description: "Choose order type",
    defaultConfig: {
        defaultValue: null,
        key: "OrderTypeSelect",
    },
    type: "input",
    category: "orderForm",
};
export const limitPriceInput = {
    name: "Limit Price Input",
    minSize: [5, 2],
    defaultSize: [5, 3],
    maxSize: [5, 12],
    icon: faSearchDollar,
    description: "Input limit price",
    defaultConfig: {
        defaultValue: null,
        key: "LimitPriceInput",
    },
    type: "input",
    category: "orderForm",
};
export const timeInForceSelect = {
    name: "Time in Force Select",
    minSize: [5, 2],
    defaultSize: [5, 3],
    maxSize: [5, 12],
    icon: faSearchDollar,
    description: "Choose time in force",
    defaultConfig: {
        defaultValue: null,
        key: "TimeInForceSelect",
    },
    type: "input",
    category: "orderForm",
};
export const stopPriceInput = {
    name: "Stop Price Input",
    minSize: [5, 2],
    defaultSize: [5, 3],
    maxSize: [5, 12],
    icon: faSearchDollar,
    description: "Input stop price",
    defaultConfig: {
        defaultValue: null,
        key: "StopPriceInput",
    },
    type: "input",
    category: "orderForm",
};
export const electronicInstructionsSelect = {
    name: "Electronic Instructions Select",
    minSize: [5, 2],
    defaultSize: [5, 3],
    maxSize: [5, 12],
    icon: faSearchDollar,
    description: "Choose electronic instruction(s)",
    defaultConfig: {
        defaultValue: null,
        key: "ElectronicInstructionsSelect",
    },
    type: "input",
    category: "orderForm",
};
export const counterpartyMemoInput = {
    name: "Counterparty Memo Input",
    minSize: [5, 2],
    defaultSize: [5, 3],
    icon: faSearchDollar,
    description: "Input counterparty memo",
    defaultConfig: {
        defaultValue: null,
        key: "CounterpartyMemoInput",
    },
    type: "input",
    category: "orderForm",
};
export const electronicExecutionSchemeSelect = {
    name: "Electronic Execution Scheme Select",
    minSize: (mobile) => (mobile ? [11, 2] : [6, 2]),
    defaultSize: [6, 3],
    maxSize: [18, 12],
    icon: faSearchDollar,
    description: "Choose electronic electronic scheme",
    defaultConfig: {
        defaultValue: null,
        key: "ElectronicExecutionSchemeSelect",
    },
    type: "input",
    category: "orderForm",
};
export const routingTargetSelect = {
    name: "Routing Target Select",
    minSize: [5, 2],
    defaultSize: [5, 3],
    maxSize: [5, 12],
    icon: faSearchDollar,
    description: "Choose routing target",
    defaultConfig: {
        defaultValue: null,
        key: "RoutingTargetSelect",
    },
    type: "input",
    category: "orderForm",
};
export const routingCounterpartySelect = {
    name: "Routing Counterparty Select",
    minSize: [5, 2],
    defaultSize: [5, 3],
    maxSize: [5, 12],
    icon: faSearchDollar,
    description: "Choose routing counterparty",
    defaultConfig: {
        defaultValue: null,
        key: "RoutingCounterpartySelect",
    },
    type: "input",
    category: "orderForm",
};
export const routingDestinationSelect = {
    name: "Routing Destination Select",
    minSize: [5, 2],
    defaultSize: [5, 3],
    maxSize: [5, 12],
    icon: faSearchDollar,
    description: "Choose routing destination",
    defaultConfig: {
        defaultValue: null,
        key: "RoutingDestinationSelect",
    },
    type: "input",
    category: "orderForm",
};
export const routingBrokerAccountSelect = {
    name: "Routing Broker Account Select",
    minSize: [5, 2],
    defaultSize: [5, 3],
    maxSize: [5, 12],
    icon: faSearchDollar,
    description: "Choose routing broker account",
    defaultConfig: {
        defaultValue: null,
        key: "RoutingBrokerAccountSelect",
    },
    type: "input",
    category: "orderForm",
};
export const routingStrategySelect = {
    name: "Routing Strategy Select",
    minSize: [5, 2],
    defaultSize: [5, 3],
    maxSize: [5, 12],
    icon: faSearchDollar,
    description: "Choose routing strategy",
    defaultConfig: {
        defaultValue: null,
        key: "RoutingStrategySelect",
    },
    type: "input",
    category: "orderForm",
};
export const routingCapacitySelect = {
    name: "Routing Capacity Select",
    minSize: [5, 2],
    defaultSize: [5, 3],
    maxSize: [5, 12],
    icon: faSearchDollar,
    description: "Choose routing capacity",
    defaultConfig: {
        defaultValue: null,
        key: "RoutingCapacitySelect",
    },
    type: "input",
    category: "orderForm",
};
export const orderStatusSelect = {
    name: "Voice Order Status Select",
    minSize: [5, 2],
    defaultSize: [5, 3],
    maxSize: [5, 12],
    icon: faSearchDollar,
    description: "Choose voice order status",
    defaultConfig: {
        defaultValue: null,
        key: "OrderStatusSelect",
    },
    type: "input",
    category: "orderForm",
};
