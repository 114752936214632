/* eslint-disable @typescript-eslint/no-explicit-any */
import { kebabCase } from "lodash";
import * as React from "react";
import { FormController } from "../layout/FormPanel";
import { TextInput } from "./TextInput";
export const ControlledTextInput = ({ name, basis, mobileBasis, tabletBasis, desktopBasis, rules, control, defaultValue, ...props }) => {
    return (React.createElement(FormController, { basis: basis, mobileBasis: mobileBasis, tabletBasis: tabletBasis, desktopBasis: desktopBasis, name: name, rules: rules, control: control, defaultValue: defaultValue, render: (renderProps, { invalid }) => {
            return (React.createElement(TextInput, { ...props, ...renderProps, invalid: invalid, defaultValue: defaultValue, "data-testid": `text-input-${kebabCase(name)}` }));
        } }));
};
