export const bindDocumentsApi = (documentsAPI, docRoot) => {
    return {
        GET_ROOT_NODE: documentsAPI.GET_ROOT_NODE.bind(this, docRoot),
        GET_SEARCH_ROOT_NODE: documentsAPI.GET_SEARCH_ROOT_NODE.bind(this, docRoot),
        GET_ROOT_NODE_FACTORY: () => documentsAPI.GET_ROOT_NODE_FACTORY(docRoot),
        CREATE_FOLDER: (root, filePath, abortController) => documentsAPI.CREATE_FOLDER.FETCH(docRoot, `${root}/${filePath}`, abortController),
        RENAME_FOLDER: (filePath, newFolderName, forceWrite, abortController) => documentsAPI.RENAME_FOLDER.FETCH(docRoot, filePath, newFolderName, forceWrite, abortController),
        DELETE_ENTRY: (filePath, abortController) => documentsAPI.DELETE_ENTRY.FETCH(docRoot, filePath, abortController),
        MOVE_ENTRY: (filePath, destinationFolder, forceWrite, abortController) => documentsAPI.MOVE_ENTRY.FETCH(docRoot, filePath, destinationFolder, forceWrite, abortController),
        GET_ENTRY: (filePath, abortController) => documentsAPI.GET_ENTRY.FETCH(docRoot, filePath, abortController),
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        DOWNLOAD: (filePath, mobile, abortController) => documentsAPI.DOWNLOAD.FETCH(docRoot, filePath, mobile, abortController),
    };
};
