import BigNumber from "bignumber.js";
import { format, isEqual, parseISO } from "date-fns";
import numbro from "numbro";
import { UNIX_START_OF_TIME } from "./constants";
import { currencySymbols } from "./currencySymbols";
export function setNumbroLanguage(lang) {
    numbro.setLanguage(lang);
}
// eslint-disable-next-line @typescript-eslint/no-explicit-any
function defaultOption(val, defaultVal) {
    if (typeof val !== "undefined")
        return val;
    return defaultVal;
}
const arrayOfSize = (size) => [...new Array(size)];
export const getDecimalSeparator = () => numbro(1.1).format({ mantissa: 1 }).charAt(1);
function cleanNumber(value) {
    const separator = getDecimalSeparator();
    const isNegative = value.trim().startsWith("-");
    const cleanPattern = new RegExp(`[^0-9${separator}]`, "g");
    const cleaned = value.replace(cleanPattern, "");
    const normalized = cleaned.replace(separator, ".");
    return `${isNegative ? "-" : ""}${normalized}`;
}
export function parseNumber(value) {
    return new BigNumber(cleanNumber(value));
}
export function formatNumber(value, options = {}) {
    if (value === "NaN" ||
        value === "" ||
        value === null ||
        typeof value === "undefined" ||
        Number.isNaN(Number(value)))
        return "";
    let fractionDigits = null;
    let reduce = false;
    let formatNegative = true;
    let useGrouping = true;
    let mobileCCY = false;
    let currency;
    let style = "number";
    let forceAverage;
    const resVal = Number(value);
    const isNegative = resVal < 0;
    if (typeof options === "number") {
        fractionDigits = [options, options, options];
    }
    else if (Array.isArray(options)) {
        fractionDigits = [...options, options[1]]; // set break units to max size
    }
    else {
        if (typeof options.fractionDigits === "number") {
            fractionDigits = [
                options.fractionDigits,
                options.fractionDigits,
                options.fractionDigits,
            ];
        }
        else if (Array.isArray(options.fractionDigits)) {
            fractionDigits = [
                ...options.fractionDigits,
                options.fractionDigits[1],
            ]; // set break units to max size
        }
        reduce = defaultOption(options.reduce, reduce);
        forceAverage = defaultOption(options.forceAverage, forceAverage);
        formatNegative = defaultOption(options.formatNegative, formatNegative);
        currency = options.currency;
        style = defaultOption(options.style, style);
        useGrouping = defaultOption(options.useGrouping, useGrouping);
        mobileCCY = defaultOption(options.mobileCCY, mobileCCY);
    }
    let currencySymbol = currency || undefined;
    const key = mobileCCY ? "mobile" : "base";
    if (currencySymbol &&
        currencySymbols[key][currencySymbol]) {
        currencySymbol = currencySymbols[key][currencySymbol];
    }
    const formatOptions = JSON.parse(JSON.stringify({
        output: style === "percent" ? "percent" : undefined,
        thousandSeparated: useGrouping,
        mantissa: typeof fractionDigits?.[1] === "number"
            ? fractionDigits?.[1]
            : undefined,
        forceAverage,
        average: reduce,
        abbreviations: {
            thousand: "k",
            million: "mm",
            billion: "b",
            trillion: "t",
        },
    }));
    formatNegative = formatNegative && isNegative;
    let res = numbro(value).format(formatOptions);
    if (fractionDigits !== null) {
        const separator = getDecimalSeparator();
        const [minDigits, maxDigits, breakDigits] = fractionDigits;
        const baseSplitRes = res.split(separator);
        const splitRes = cleanNumber(res).split(separator);
        const afterDec = (baseSplitRes[1] ?? "").replace(splitRes[1] ?? "", "");
        let countDecimals = splitRes[1]?.length || 0;
        if (countDecimals < minDigits) {
            res = `${splitRes[0]}.${arrayOfSize(minDigits)
                .fill("0")
                .join("")}${afterDec}`;
        }
        else {
            const endZeros = arrayOfSize(breakDigits).fill("0").join("");
            while (minDigits !== maxDigits &&
                cleanNumber(res).endsWith(endZeros) &&
                countDecimals > minDigits) {
                res = numbro(value).format({
                    ...formatOptions,
                    mantissa: Math.max(countDecimals - breakDigits, minDigits),
                });
                countDecimals = cleanNumber(res).split(separator)[1]?.length || 0;
            }
        }
    }
    res = res.replace("-", "").replace("+", "");
    if (style === "currency")
        res = `${currencySymbol || ""}${res}`;
    if (isNegative)
        res = formatNegative ? `(${res})` : `-${res}`;
    return res;
}
export function formatQuoteCellValue(quote, formatNegative = true) {
    // Eventually this will use quote.quotationFormat
    // Hard-coding 2 decimal places to match mazzika for now
    return formatNumber(quote, { fractionDigits: 2, formatNegative });
}
export function formatPercentage(value, fractionDigits = 2, formatNegative = false, forceAverage = undefined, reduce = false, addPlusSign = false) {
    const res = formatNumber(value, {
        fractionDigits,
        formatNegative,
        forceAverage,
        reduce,
        style: "percent",
    });
    if (res.includes("Infinity")) {
        console.warn("got infinity", value, res);
        return res.replace(`${getDecimalSeparator()}00`, "");
    }
    if (addPlusSign && typeof value === "number") {
        return (value > 0 ? "+" : "") + res;
    }
    return res;
}
export function formatCurrency(value, currency, options = {}) {
    const { fractionDigits = 2, formatNegative = true, forceAverage, reduce = false, mobileCCY = false, } = options;
    return formatNumber(value, {
        fractionDigits,
        formatNegative,
        forceAverage,
        reduce,
        style: "currency",
        currency,
        mobileCCY,
    });
}
export function formatDateTimeFormat(value, dateTimeFormat) {
    if (!value)
        return "";
    if (typeof value !== "string") {
        console.warn("date not a string", value);
        return typeof value !== "undefined" && value !== null ? `${value}` : null;
    }
    let parse = (val) => parseISO(val);
    if (!dateTimeFormat) {
        const segments = value.split(" ");
        if (segments.length === 2) {
            segments[0] = segments[0].replace(/\./g, "-");
            value = segments.join("T");
        }
        else {
            parse = (val) => new Date(val);
        }
        dateTimeFormat = "yyyy-MM-dd kk:mm:ss.SSS";
    }
    try {
        return format(parse(value), dateTimeFormat);
    }
    catch (e) {
        console.warn("error parsing date", e);
        return value;
    }
}
// These three formatters are used for OEMS
export function formatDate(value) {
    if (!value)
        return "";
    return formatDateTimeFormat(value, "P");
}
export function formatTime(value) {
    if (!value)
        return "";
    return formatDateTimeFormat(value, "pp");
}
export function formatDateTime(value) {
    if (!value)
        return "";
    return formatDateTimeFormat(value, "Ppp");
}
export function formatPositionIdentifierScenarioCellValue(value) {
    const { asOfDate, accountId, bookId, instrumentId, trsId, positionBlock, ignoreBook, dealId, financialSubtype, } = value;
    const parsedAsOfDate = parseISO(asOfDate);
    const isLatest = isEqual(parsedAsOfDate, UNIX_START_OF_TIME);
    const identifierTokens = [];
    identifierTokens.push(accountId, bookId, instrumentId, trsId);
    if (positionBlock)
        identifierTokens.push(positionBlock);
    identifierTokens.push(ignoreBook);
    if (dealId)
        identifierTokens.push(dealId);
    if (financialSubtype)
        identifierTokens.push(financialSubtype);
    const formattedValue = `${isLatest ? "Latest" : format(parsedAsOfDate, "yyyy-MM-dd")}-R-${identifierTokens.join("/")}`;
    return formattedValue;
}
export function formatForUrl(query) {
    return Object.entries(query).reduce((acc, [key, value]) => {
        if (value === null || value === undefined) {
            return acc;
        }
        if (value instanceof Date) {
            return { ...acc, [key]: value.toISOString() };
        }
        if (typeof value === "string" ||
            typeof value === "number" ||
            typeof value === "boolean") {
            return { ...acc, [key]: value };
        }
        return {
            ...acc,
            [key]: value.dateSelectionType === "AsOfDate"
                ? value.asOfDate
                : value.dateSelectionType,
        };
    }, {});
}
export function getAverageUnits(value, fractionDigits) {
    const options = typeof value === "number" ? [value] : value;
    const entry = Math.max(...options.map((v) => Math.abs(v).toFixed(0).length / 3));
    const params = {
        fractionDigits,
        reduce: true,
        forceAverage: undefined,
        formatNegative: false,
    };
    if (entry <= 1)
        params.reduce = false;
    else if (entry <= 2)
        params.forceAverage = "thousand";
    else if (entry <= 3)
        params.forceAverage = "million";
    else if (entry <= 4)
        params.forceAverage = "billion";
    else
        params.forceAverage = "trillion";
    return params;
}
