import { styled } from "@enfusion-ui/web-core";
import { FontAwesomeIcon, } from "@fortawesome/react-fontawesome";
import * as React from "react";
const CircleIconButtonContainer = styled.div `
  display: flex;
  justify-content: center;
  align-items: center;
  height: 12px;
  width: 12px;
  border: 1px solid var(--border);
  border-radius: 12px;
  color: black;
  font-size: 0.75em;
  cursor: pointer;

  background-color: ${({ active, activeColor }) => {
    if (active)
        return activeColor;
    return "var(--background-accent)";
}};

  border-color: ${({ active, activeColor }) => {
    if (active)
        return activeColor;
    return "var(--border)";
}};
`;
const CircleIconButton = ({ color, icon, iconComp, onClick, active, focus, style, size = "xs", title, }) => {
    return (React.createElement(CircleIconButtonContainer, { style: style, active: active, activeColor: color, onClick: onClick, title: title }, focus ? (icon ? (React.createElement(FontAwesomeIcon, { icon: icon, size: size })) : (iconComp)) : null));
};
export default CircleIconButton;
