/* eslint-disable @typescript-eslint/no-explicit-any */
import { useCallback, useMemo, useRef } from "react";
export const useMap = (initialMap = {}) => {
    const map = useRef(initialMap);
    const stableActions = useMemo(() => ({
        set: (key, entry) => {
            map.current = {
                ...map.current,
                [key]: entry,
            };
        },
        setAll: (newMap) => {
            map.current = newMap;
        },
        remove: (key) => {
            const { [key]: omitIgnored, ...rest } = map.current;
            map.current = rest;
        },
        reset: () => (map.current = initialMap),
    }), [map]);
    const utils = {
        get: useCallback((key) => map.current[key], [map]),
        ...stableActions,
    };
    return [map, utils];
};
