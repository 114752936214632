import { useRefCallback } from "@enfusion-ui/hooks";
import * as React from "react";
import { useBeforeUnload } from "react-use";
import { useThisTab } from "./ThisTabProvider";
export const UnsavedChangeTrackerContext = React.createContext(undefined);
function useUnsavedChangeTracker() {
    const context = React.useContext(UnsavedChangeTrackerContext);
    if (typeof context === "undefined") {
        throw new Error("useUnsavedChangeTracker must be used within a UnsavedChangeTrackerProvider");
    }
    return context;
}
export const UnsavedChangeTrackerProvider = ({ children }) => {
    const [unsavedChangeRecord, setUnsavedChangeRecord] = React.useState({});
    const [windowWillCloseSubscriptions, setWindowWillCloseSubscriptions] = React.useState(new Map());
    const beforeUnloadHandler = useRefCallback(() => {
        const callbacks = Array.from(windowWillCloseSubscriptions.entries()).reduce((res, item) => {
            if (item[1]) {
                res.before.push(item[0]);
            }
            else {
                res.after.push(item[0]);
            }
            return res;
        }, { before: [], after: [] });
        callbacks.before.forEach((i) => i());
        const shouldStopClose = Object.values(unsavedChangeRecord).some((i) => i);
        if (!shouldStopClose) {
            callbacks.after.forEach((i) => i());
        }
        return shouldStopClose;
    }, [unsavedChangeRecord, windowWillCloseSubscriptions]);
    /* if any unsaved change exist, should show confirmation when refresh or close window */
    useBeforeUnload(beforeUnloadHandler, "Has unsaved changes");
    const subscribeToWindowWillClose = useRefCallback((cb, callBeforeCheck = false) => {
        setWindowWillCloseSubscriptions((prev) => {
            prev.set(cb, callBeforeCheck);
            return prev;
        });
        // Unsubscribe handler
        return () => {
            setWindowWillCloseSubscriptions((prev) => {
                prev.delete(cb);
                return prev;
            });
        };
    }, []);
    const setHasUnsavedChange = useRefCallback((tabId, hasUnsavedChange = true) => setUnsavedChangeRecord((prev) => ({
        ...prev,
        [tabId]: hasUnsavedChange,
    })), []);
    return (React.createElement(UnsavedChangeTrackerContext.Provider, { value: {
            subscribeToWindowWillClose,
            setHasUnsavedChange,
        } }, children));
};
export function useTrackUnsavedChanges(hasUnsavedChanges) {
    const { id: tabId } = useThisTab();
    const { setHasUnsavedChange } = useUnsavedChangeTracker();
    React.useEffect(() => {
        setHasUnsavedChange(tabId, hasUnsavedChanges);
    }, [tabId, hasUnsavedChanges]);
}
export function useOnWindowWillClose(cb, callBeforeCheck) {
    const { subscribeToWindowWillClose } = useUnsavedChangeTracker();
    React.useEffect(() => {
        return subscribeToWindowWillClose(cb, callBeforeCheck);
    }, [cb]);
}
