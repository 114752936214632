import { isElectron } from "@enfusion-ui/utils";
export function isDesktop() {
    if (typeof fin !== "undefined" &&
        typeof fin?.InterApplicationBus !== "undefined")
        return true;
    return false;
}
export function getParentWindow() {
    return window.opener || window.parent;
}
export function isPopOut() {
    return !!getParentWindow() && window.location.pathname.includes("pop-out");
}
export function openTabWindow(id, width, height) {
    const url = `${window.location.origin}/pop-out?v=${btoa(id)}`;
    if (isElectron()) {
        const { ipcRenderer } = window.require("electron");
        ipcRenderer.send("pop-out", {
            url,
            id,
        });
        return;
    }
    return window.open(url, id, `toolbar=no,location=no,directories=no,status=no,menubar=no,scrollbars=yes,resizable=yes,width=${width},height=${height},top=${window.screen.height / 2 - height / 2}+",left=${window.screen.width / 2 - width / 2}`);
}
