import { styled } from "@enfusion-ui/web-core";
import * as React from "react";
import { Button } from "../control";
import { SingleColumnGridWithGap } from "../Styled";
import { Modal } from "./Modal";
const ContentContainer = styled.div `
  margin-top: 8px;
`;
const ActionContainer = styled.div `
  display: grid;
  grid-template-columns: 1fr;
`;
export const InfoModal = ({ open, title, children, onClose, }) => {
    return (React.createElement(Modal, { isOpen: open, onClose: onClose, title: title, allowDismissal: true, content: React.createElement(SingleColumnGridWithGap, null,
            React.createElement(ContentContainer, null, children),
            React.createElement(ActionContainer, null,
                React.createElement(Button, { onClick: onClose }, "Close"))) }));
};
