import { debounce } from "lodash";
import * as React from "react";
import { useMounted } from "./useMounted";
export const NO_TEMPLATES_FOUND = "No templates found";
export function useRestServerOptions(restSeverFetch, endpointArgs) {
    const args = endpointArgs instanceof Object ? endpointArgs : { endpoint: endpointArgs };
    const { endpoint, map: optionsMap = (data) => ({
        value: data,
        label: `${data}`,
    }), convertResponse = (i) => i, validate = (response) => !`${response[0]}`.includes(NO_TEMPLATES_FOUND), filter, } = args;
    const isMounted = useMounted();
    const [options, setOptions] = React.useState([]);
    const [loading, setLoading] = React.useState(() => !!endpoint);
    const fetchRef = React.useRef(null);
    React.useEffect(() => {
        const getOptions = async () => {
            if (endpoint) {
                try {
                    const res = await restSeverFetch(endpoint);
                    if (!validate(res))
                        throw new Error("Failed validation");
                    const response = convertResponse(res);
                    const filteredResponse = filter ? response.filter(filter) : response;
                    if (isMounted())
                        setOptions([
                            ...new Set(filteredResponse.map((v, idx) => JSON.stringify(optionsMap(v, idx, filteredResponse)))),
                        ].map((v) => JSON.parse(v)));
                }
                catch (error) {
                    console.warn(`Failed to fetch data from ${endpoint}`, error?.message);
                }
                finally {
                    if (isMounted())
                        setLoading(false);
                }
            }
        };
        fetchRef.current?.cancel();
        if (endpoint && isMounted()) {
            fetchRef.current = debounce(getOptions, 400);
            setLoading(true);
            setOptions([]);
            fetchRef.current();
        }
        else {
            setLoading(false);
            setOptions([]);
        }
    }, [endpoint]);
    return { options, loading };
}
export function basicInfoMap(entry) {
    return { label: entry.name, value: entry.id };
}
export function useRestAbortableOptions(fetchCall, endpointArgs) {
    const abortController = React.useRef(new AbortController());
    const args = typeof endpointArgs === "function"
        ? { map: endpointArgs }
        : endpointArgs ?? {};
    const { map: optionsMap = (data) => ({
        value: data,
        label: `${data}`,
    }), convertResponse = (i) => i, validate = (response) => response.length >= 1 && !`${response[0]}`.includes(NO_TEMPLATES_FOUND), filter, } = args;
    const isMounted = useMounted();
    const [options, setOptions] = React.useState([]);
    const [loading, setLoading] = React.useState(true);
    const fetchRef = React.useRef(null);
    React.useEffect(() => {
        const getOptions = async () => {
            if (fetchCall) {
                try {
                    if (abortController.current.signal.aborted)
                        abortController.current = new AbortController();
                    const res = await fetchCall(abortController.current);
                    if (!validate(res))
                        throw new Error("Failed validation");
                    const response = convertResponse(res);
                    const filteredResponse = filter ? response.filter(filter) : response;
                    if (isMounted())
                        setOptions([
                            ...new Set(filteredResponse.map((v, idx) => JSON.stringify(optionsMap(v, idx, filteredResponse)))),
                        ].map((v) => JSON.parse(v)));
                }
                catch (error) {
                    console.warn("Failed to fetch data", error);
                }
                finally {
                    if (isMounted())
                        setLoading(false);
                }
            }
        };
        fetchRef.current?.cancel();
        if (fetchCall && isMounted()) {
            fetchRef.current = debounce(getOptions, 400);
            setLoading(true);
            setOptions([]);
            fetchRef.current();
        }
        else {
            setLoading(false);
            setOptions([]);
        }
        return () => abortController.current.abort();
    }, [abortController, fetchCall]);
    return { options, loading, abortController: abortController.current };
}
