import semver from "semver";
export function validAppVersion() {
    if (window.MOBILE_APP_VERSION &&
        !!process.env.REACT_APP_MOBILE_APP_CURRENT_VERSION) {
        if (semver.valid(process.env.REACT_APP_MOBILE_APP_CURRENT_VERSION) &&
            semver.valid(window.MOBILE_APP_VERSION)) {
            return !semver.lt(window.MOBILE_APP_VERSION, process.env.REACT_APP_MOBILE_APP_CURRENT_VERSION);
        }
        else {
            console.error("The min app current version or app version vars are not valid version strings");
        }
    }
    return true;
}
export function validAppVersionMin() {
    if (window.MOBILE_APP_VERSION &&
        !!process.env.REACT_APP_MOBILE_APP_MIN_VERSION) {
        if (semver.valid(process.env.REACT_APP_MOBILE_APP_MIN_VERSION) &&
            semver.valid(window.MOBILE_APP_VERSION)) {
            return !semver.lt(window.MOBILE_APP_VERSION, process.env.REACT_APP_MOBILE_APP_MIN_VERSION);
        }
        else {
            console.error("The min app min version or app version vars are not valid version strings");
        }
    }
    return true;
}
