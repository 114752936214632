import * as React from "react";
// Will return true if the input value was ever true
export function useEverTrue(value) {
    const [everTrue, setEverTrue] = React.useState(value);
    React.useEffect(() => {
        if (!everTrue && value) {
            setEverTrue(true);
        }
    }, [everTrue, value]);
    return everTrue;
}
