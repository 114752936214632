import { addBusinessDays, endOfDay, endOfMonth, endOfQuarter, endOfWeek, endOfYear, format, parse, parseISO, startOfDay, startOfMonth, startOfQuarter, startOfWeek, startOfYear, subBusinessDays, subMonths, subQuarters, subWeeks, subYears, } from "date-fns";
import { words } from "lodash";
const defaultDatePeriodSelection = {
    after: 0,
    before: 0,
    dateSelection: {
        asOfDate: null,
        dateSelectionType: "PreviousBusinessDate",
    },
    increment: 1,
    period: "Day",
};
export const DATE_INPUT_TYPE_OPTIONS = [
    {
        label: "Date",
        value: "date",
    },
    {
        label: "Preset",
        value: "preset",
    },
    {
        label: "Period",
        value: "period",
    },
    {
        label: "Range",
        value: "range",
    },
    {
        label: "Symbol Range",
        value: "symbol-range",
    },
];
export const SEMI_CUSTOM_RANGE_SYMBOLS = [
    "CustomToPBD",
    "CustomToDate",
    "CustomToNBD",
    "CustomToTPlus3",
];
export const SYMBOL_RANGE_OPTIONS = [
    { label: "AllDates", value: "AllDates" },
    { label: "Custom", value: "Custom" },
    { label: "Today", value: "Today" },
    { label: "This Week", value: "ThisWeek" },
    { label: "This Week-to-date", value: "ThisWeekToDate" },
    { label: "This Month", value: "ThisMonth" },
    { label: "This Month-to-date", value: "ThisMonthToDate" },
    { label: "This Quarter", value: "ThisQuarter" },
    { label: "This Quarter-to-date", value: "ThisQuarterToDate" },
    { label: "This Year", value: "ThisYear" },
    { label: "This Year-to-date", value: "ThisYearToDate" },
    { label: "Last Week", value: "LastWeek" },
    { label: "Last Month", value: "LastMonth" },
    { label: "Last Quarter", value: "LastQuarter" },
    { label: "Last Year", value: "LastYear" },
    { label: "Last PBD-to-date", value: "LastPreviousBusinessDay" },
    { label: "Previous Business Day", value: "PBD" },
    { label: "This Week-to-PBD", value: "ThisWeekToPBD" },
    { label: "This Month-to-PBD", value: "ThisMonthToPBD" },
    { label: "This Quarter-to-PBD", value: "ThisQuarterToPBD" },
    { label: "This Year-to-PBD", value: "ThisYearToPBD" },
    { label: "Next Business Day", value: "NBD" },
    { label: "This Week-to-NBD", value: "ThisWeekToNBD" },
    { label: "This Month-to-NBD", value: "ThisMonthToNBD" },
    { label: "This Quarter-to-NBD", value: "ThisQuarterToNBD" },
    { label: "This Year-to-NBD", value: "ThisYearToNBD" },
    { label: "This Year-to-Previous Month End", value: "ThisYearToPME" },
    { label: "Inception-to-date", value: "InceptionToDate" },
    { label: "Inception-to-PBD", value: "InceptionToPBD" },
    { label: "Inception-to-NBD", value: "InceptionToNBD" },
    { label: "Today-onwards", value: "TodayOnwards" },
    { label: "Custom-to-PBD", value: "CustomToPBD" },
    { label: "Custom-to-date", value: "CustomToDate" },
    { label: "Custom-to-NBD", value: "CustomToNBD" },
    { label: "Custom-to-T+3", value: "CustomToTPlus3" },
];
export const chicagoTimeZone = "America/Chicago";
export const generalDateFormat = "yyyy-MM-dd";
export const generalDateAndTimeFormat = "yyyy-MM-dd HH:mm:ss";
export const nowZoned = (_timezone) => new Date();
export const DateRangePresetEval = {
    AllDates: () => [null, null],
    Custom: () => [null, null],
    Today: (timezone) => [nowZoned(timezone), nowZoned(timezone)],
    ThisWeek: (timezone) => [
        startOfWeek(nowZoned(timezone)),
        endOfWeek(nowZoned(timezone)),
    ],
    ThisWeekToDate: (timezone) => [
        startOfWeek(nowZoned(timezone)),
        nowZoned(timezone),
    ],
    ThisMonth: (timezone) => [
        startOfMonth(nowZoned(timezone)),
        endOfMonth(nowZoned(timezone)),
    ],
    ThisMonthToDate: (timezone) => [
        startOfMonth(nowZoned(timezone)),
        nowZoned(timezone),
    ],
    ThisQuarter: (timezone) => [
        startOfQuarter(nowZoned(timezone)),
        endOfQuarter(nowZoned(timezone)),
    ],
    ThisQuarterToDate: (timezone) => [
        startOfQuarter(nowZoned(timezone)),
        nowZoned(timezone),
    ],
    ThisYear: (timezone) => [
        startOfYear(nowZoned(timezone)),
        endOfYear(nowZoned(timezone)),
    ],
    ThisYearToDate: (timezone) => [
        startOfYear(nowZoned(timezone)),
        nowZoned(timezone),
    ],
    LastWeek: (timezone) => {
        const lastWeekDate = subWeeks(nowZoned(timezone), 1);
        return [startOfWeek(lastWeekDate), endOfWeek(lastWeekDate)];
    },
    LastMonth: (timezone) => {
        const lastMonthDate = subMonths(nowZoned(timezone), 1);
        return [startOfMonth(lastMonthDate), endOfMonth(lastMonthDate)];
    },
    LastQuarter: (timezone) => {
        const lastMonthDate = subQuarters(nowZoned(timezone), 1);
        return [startOfQuarter(lastMonthDate), endOfQuarter(lastMonthDate)];
    },
    LastYear: (timezone) => {
        const lastMonthDate = subYears(nowZoned(timezone), 1);
        return [startOfYear(lastMonthDate), endOfYear(lastMonthDate)];
    },
    LastPreviousBusinessDay: (timezone) => [
        subBusinessDays(nowZoned(timezone), 1),
        nowZoned(timezone),
    ],
    PBD: (timezone) => [
        subBusinessDays(nowZoned(timezone), 1),
        subBusinessDays(nowZoned(timezone), 1),
    ],
    ThisWeekToPBD: (timezone) => [
        startOfWeek(nowZoned(timezone)),
        subBusinessDays(nowZoned(timezone), 1),
    ],
    ThisMonthToPBD: (timezone) => [
        startOfMonth(nowZoned(timezone)),
        subBusinessDays(nowZoned(timezone), 1),
    ],
    ThisQuarterToPBD: (timezone) => [
        startOfQuarter(nowZoned(timezone)),
        subBusinessDays(nowZoned(timezone), 1),
    ],
    ThisYearToPBD: (timezone) => [
        startOfYear(nowZoned(timezone)),
        subBusinessDays(nowZoned(timezone), 1),
    ],
    NBD: (timezone) => [
        addBusinessDays(nowZoned(timezone), 1),
        addBusinessDays(nowZoned(timezone), 1),
    ],
    ThisWeekToNBD: (timezone) => [
        startOfWeek(nowZoned(timezone)),
        addBusinessDays(nowZoned(timezone), 1),
    ],
    ThisMonthToNBD: (timezone) => [
        startOfMonth(nowZoned(timezone)),
        addBusinessDays(nowZoned(timezone), 1),
    ],
    ThisQuarterToNBD: (timezone) => [
        startOfQuarter(nowZoned(timezone)),
        addBusinessDays(nowZoned(timezone), 1),
    ],
    ThisYearToNBD: (timezone) => [
        startOfYear(nowZoned(timezone)),
        addBusinessDays(nowZoned(timezone), 1),
    ],
    ThisYearToPME: (timezone) => [
        startOfYear(nowZoned(timezone)),
        endOfMonth(subMonths(nowZoned(timezone), 1)),
    ],
    InceptionToDate: (timezone) => [null, nowZoned(timezone)],
    InceptionToPBD: (timezone) => [
        null,
        subBusinessDays(nowZoned(timezone), 1),
    ],
    InceptionToNBD: (timezone) => [
        null,
        addBusinessDays(nowZoned(timezone), 1),
    ],
    TodayOnwards: (timezone) => [nowZoned(timezone), null],
    CustomToPBD: (timezone) => [
        null,
        subBusinessDays(nowZoned(timezone), 1),
    ],
    CustomToDate: (timezone) => [null, nowZoned(timezone)],
    CustomToNBD: (timezone) => [
        null,
        addBusinessDays(nowZoned(timezone), 1),
    ],
    CustomToTPlus3: (timezone) => [
        null,
        addBusinessDays(nowZoned(timezone), 3),
    ],
};
export const isValidDate = (d) => typeof d === "object" && d instanceof Date && !Number.isNaN(d.getTime());
export const getDateSelection = (valueDate, defaultValue) => {
    if (typeof valueDate !== "undefined" && valueDate !== null) {
        if (typeof valueDate === "string") {
            return {
                dateSelectionType: valueDate,
                asOfDate: null,
            };
        }
        if (isValidDate(valueDate))
            return {
                dateSelectionType: "AsOfDate",
                asOfDate: format(valueDate, "yyyy-MM-dd"),
            };
        if (typeof valueDate.dateSelectionType === "string") {
            return valueDate;
        }
    }
    return defaultValue;
};
export const convertDatePeriodSelection = (selection) => {
    if (typeof selection?.period === "string") {
        return selection;
    }
    const dateSelection = getDateSelection(selection);
    if (!dateSelection)
        return {};
    return {
        ...defaultDatePeriodSelection,
        dateSelection,
    };
};
export function getPeriodDateSelectionValue(value, inputType) {
    if (inputType === "period") {
        return value;
    }
    return convertDatePeriodSelection(value);
}
export function getDateSelectionValue(value, inputType, side) {
    if (value) {
        if (inputType === "date" && typeof value === "string") {
            return getDateSelection(new Date(value)) ?? null;
        }
        if (inputType === "period") {
            return getPeriodDateSelectionValue(value, inputType).dateSelection;
        }
        if (inputType === "range") {
            const key = side === "start" ? "startDate" : "endDate";
            return value?.[key] ?? null;
        }
        if (inputType === "symbol-range") {
            const key = side === "start" ? "fromDate" : "toDate";
            const date = value?.[key];
            return (getDateSelection(date ? parse(date, generalDateFormat, new Date()) : null) ?? null);
        }
        return getDateSelection(value) ?? null;
    }
    return null;
}
export function getPresetValue(value) {
    if (value?.dateSelectionType === "AsOfDate" && value?.asOfDate) {
        return parseISO(value.asOfDate);
    }
    return value?.dateSelectionType ?? null;
}
export function getDateRangeSymbol(value, inputType) {
    if (value && inputType === "symbol-range") {
        return value?.symbol;
    }
    return undefined;
}
export const getDatePreset = (webDateSelection) => {
    if (webDateSelection.asOfDate) {
        return format(new Date(webDateSelection.asOfDate), "yyyy-MM-dd");
    }
    return webDateSelection.dateSelectionType;
};
export function getDateRange(key, formatStr = generalDateFormat, timezone = chicagoTimeZone) {
    const dateRange = DateRangePresetEval[key](timezone);
    return [
        isValidDate(dateRange[0]) ? format(dateRange[0], formatStr) : undefined,
        isValidDate(dateRange[1]) ? format(dateRange[1], formatStr) : undefined,
    ];
}
const tPlus = (timezone, days) => addBusinessDays(nowZoned(timezone), days);
export const DatePresetEval = {
    Latest: (timezone) => nowZoned(timezone),
    PreviousBusinessDate: (timezone) => subBusinessDays(nowZoned(timezone), 1),
    TMinusTwo: (timezone) => subBusinessDays(nowZoned(timezone), 2),
    Today: (timezone) => nowZoned(timezone),
    EndOfToday: (timezone) => endOfDay(nowZoned(timezone)),
    StartOfToday: (timezone) => startOfDay(nowZoned(timezone)),
    LastWeek: (timezone) => subWeeks(startOfWeek(nowZoned(timezone), {
        weekStartsOn: 1,
    }), 1),
    StartOfWeek: (timezone) => startOfWeek(nowZoned(timezone), {
        weekStartsOn: 1,
    }),
    LastMonth: (timezone) => subMonths(nowZoned(timezone), 1),
    StartOfMonth: (timezone) => startOfMonth(nowZoned(timezone)),
    EndOfThisMonth: (timezone) => endOfMonth(nowZoned(timezone)),
    StartOfPreviousMonth: (timezone) => startOfMonth(subMonths(nowZoned(timezone), 1)),
    TPlus5: (timezone) => tPlus(timezone, 5),
    TPlus20: (timezone) => tPlus(timezone, 20),
    TPlus30: (timezone) => tPlus(timezone, 30),
    TPlus50: (timezone) => tPlus(timezone, 50),
    TPlus90: (timezone) => tPlus(timezone, 90),
    AsOfDate: () => "",
};
export const DatePresetEvalLabelOverrides = {
    TMinusTwo: "T-2",
    TPlus5: "T+5",
    TPlus20: "T+20",
    TPlus30: "T+30",
    TPlus50: "T+50",
    TPlus90: "T+90",
    LastWeek: "A Week Ago",
    LastMonth: "Previous Month",
};
export const DatePresetEvalTooltipFormatOverrides = {
    EndOfToday: generalDateAndTimeFormat,
    StartOfToday: generalDateAndTimeFormat,
    LastMonth: "MMMM",
};
function getDatePresetTooltipMethod(key, formatStr = generalDateFormat) {
    if (key === "AsOfDate")
        return () => "";
    return (timezone) => format(DatePresetEval[key](timezone), formatStr);
}
const DatePresetOptionsMap = Object.keys(DatePresetEval).reduce((res, key) => ({
    ...res,
    [key]: {
        value: key,
        label: DatePresetEvalLabelOverrides[key] ?? words(key).join(" "),
        tooltip: getDatePresetTooltipMethod(key, DatePresetEvalTooltipFormatOverrides[key]),
    },
}), {});
export const DatePresetOptions = {
    all: [
        DatePresetOptionsMap.Latest,
        DatePresetOptionsMap.Today,
        DatePresetOptionsMap.EndOfToday,
        DatePresetOptionsMap.StartOfToday,
        DatePresetOptionsMap.PreviousBusinessDate,
        DatePresetOptionsMap.TMinusTwo,
        DatePresetOptionsMap.LastWeek,
        DatePresetOptionsMap.StartOfWeek,
        DatePresetOptionsMap.StartOfMonth,
        DatePresetOptionsMap.LastMonth,
        DatePresetOptionsMap.EndOfThisMonth,
        DatePresetOptionsMap.StartOfPreviousMonth,
        DatePresetOptionsMap.TPlus5,
        DatePresetOptionsMap.TPlus20,
        DatePresetOptionsMap.TPlus30,
        DatePresetOptionsMap.TPlus50,
        DatePresetOptionsMap.TPlus90,
        DatePresetOptionsMap.AsOfDate,
    ],
    value: [
        DatePresetOptionsMap.Today,
        DatePresetOptionsMap.PreviousBusinessDate,
        DatePresetOptionsMap.TMinusTwo,
        DatePresetOptionsMap.LastWeek,
        DatePresetOptionsMap.StartOfWeek,
        DatePresetOptionsMap.StartOfMonth,
        DatePresetOptionsMap.LastMonth,
        DatePresetOptionsMap.EndOfThisMonth,
        DatePresetOptionsMap.StartOfPreviousMonth,
        DatePresetOptionsMap.TPlus5,
        DatePresetOptionsMap.TPlus20,
        DatePresetOptionsMap.TPlus30,
        DatePresetOptionsMap.TPlus50,
        DatePresetOptionsMap.TPlus90,
        DatePresetOptionsMap.AsOfDate,
    ],
    period: [
        DatePresetOptionsMap.Latest,
        DatePresetOptionsMap.Today,
        DatePresetOptionsMap.PreviousBusinessDate,
        DatePresetOptionsMap.TMinusTwo,
        DatePresetOptionsMap.LastWeek,
        DatePresetOptionsMap.StartOfWeek,
        DatePresetOptionsMap.StartOfMonth,
        DatePresetOptionsMap.LastMonth,
        DatePresetOptionsMap.EndOfThisMonth,
        DatePresetOptionsMap.StartOfPreviousMonth,
        DatePresetOptionsMap.AsOfDate,
    ],
};
export function getEvalDate(timeZone, value, parseDate) {
    const evalDate = typeof value === "undefined" || value === null
        ? null
        : value.dateSelectionType === "AsOfDate"
            ? value.asOfDate
                ? parseDate
                    ? parseDate(value.asOfDate)
                    : parseISO(value.asOfDate)
                : null
            : DatePresetEval[value.dateSelectionType](timeZone);
    return isValidDate(evalDate) ? evalDate : null;
}
export function formatDateToStandardFormat(date) {
    if (!date)
        return undefined;
    return date.toISOString().split("T")[0];
}
export function getUTCDate(date) {
    return new Date(Date.UTC(date.getFullYear(), date.getMonth(), date.getDate(), 0, 0, 0));
}
export function getUTCDateString(date) {
    return formatDateToStandardFormat(getUTCDate(date));
}
const zeroPadDigits = (value, padQnty = 2) => value.toString().padStart(padQnty, "0");
const formatDate = (date) => [
    date.getFullYear(),
    zeroPadDigits(date.getMonth() + 1),
    zeroPadDigits(date.getDate()),
].join("-");
const isValidDateFormat = (date) => {
    const regex = /^\d{4}-\d{2}-\d{2}$/;
    if (date.match(regex) === null) {
        return false;
    }
    return true;
};
export function parseDateString(dateStr) {
    if (typeof dateStr !== "string" || !isValidDateFormat(dateStr))
        return null;
    return parse(dateStr, "yyyy-MM-dd", new Date());
}
export const getTerminationDate = (tenor, startDate) => {
    const terminationDate = new Date(startDate);
    const year = terminationDate.getFullYear();
    const month = terminationDate.getMonth();
    const day = terminationDate.getDate();
    const multiplier = Number(tenor.slice(0, -1));
    const period = tenor.slice(-1);
    if (period === "Y") {
        return new Date(year + multiplier, month, day);
    }
    if (period === "M") {
        if (month + multiplier >= 12) {
            return new Date(year, month + multiplier, day);
        }
        return new Date(year + 1, month + multiplier - 12, day);
    }
    return terminationDate;
};
export const getMaturity = (tenor, startDate) => {
    if (!startDate || (!isValidDateFormat(startDate) && tenor.length < 2)) {
        return "";
    }
    const MATURITY_MONTH_INTERVAL = 3; // Maturity month must be March, June, September or December
    const MATURITY_DAY = 20; // Always the 20th
    const terminationDate = getTerminationDate(tenor, startDate); // e.g. 2022-01-05 + 5Y = 2027-01-05
    let terminationMonth = terminationDate.getMonth();
    if (terminationMonth % MATURITY_MONTH_INTERVAL !== 0) {
        terminationMonth =
            (Math.floor(terminationMonth / MATURITY_MONTH_INTERVAL) + 1) * 3; // Set month to next valid maturity month
    }
    terminationDate.setMonth(terminationMonth);
    if (terminationDate.getDate() >= MATURITY_DAY) {
        // When already on or past the 20th for this maturity date, move to next valid maturity month.
        terminationDate.setMonth(terminationMonth + MATURITY_MONTH_INTERVAL - 1);
    }
    terminationDate.setDate(MATURITY_DAY);
    return formatDate(terminationDate);
};
export const convertDateToUTCDate = (date) => {
    const values = date.split("-");
    return getUTCDate(new Date(Number(values[0]), Number(values[1]) - 1, Number(values[2])));
};
