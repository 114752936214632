import { styled } from "@enfusion-ui/web-core";
import { faSquare, faWindowRestore } from "@fortawesome/pro-regular-svg-icons";
import { faMinus, faTimes } from "@fortawesome/pro-solid-svg-icons";
import * as React from "react";
import { EnfusionIcon } from "../icons";
import { closeWindow, maximizeWindow, minimizeWindow } from "./actions";
import BoxIconButton from "./BoxIconButton";
const ActionContainer = styled.div `
  height: 23px;
  display: flex;
  flex-direction: row;
  -webkit-app-region: no-drag !important;
  -app-region: no-drag !important;
`;
const LeftTitleContainer = styled.div `
  height: 20px;
  flex: 1;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  opacity: ${({ active }) => (active ? "1" : "0.5")};
  padding-left: var(--spacing);
  padding-right: ${({ actions }) => (actions ? "var(--spacing)" : "0px")};
`;
const WinTopBar = ({ active, actions, title, maximized, onContextMenu }) => {
    return (React.createElement(React.Fragment, null,
        React.createElement(LeftTitleContainer, { actions: actions, active: active, onContextMenu: onContextMenu }, title ? (React.createElement(React.Fragment, null,
            React.createElement(EnfusionIcon, null),
            title)) : null),
        actions ? (React.createElement(ActionContainer, null,
            React.createElement(BoxIconButton, { active: active, icon: faMinus, title: "minimize", onClick: minimizeWindow }),
            React.createElement(BoxIconButton, { active: active, icon: maximized ? faWindowRestore : faSquare, title: maximized ? "restore" : "maximize", onClick: maximizeWindow }),
            React.createElement(BoxIconButton, { active: active, icon: faTimes, title: "close", color: "var(--danger)", onClick: closeWindow }))) : null));
};
export default WinTopBar;
