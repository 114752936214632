import { styled } from "@enfusion-ui/web-core";
import { faSkullCrossbones } from "@fortawesome/pro-solid-svg-icons";
import * as React from "react";
import { Button } from "../control";
import { ContentMessage } from "./ContentMessage";
export const ErrorBoundaryContainer = styled.div `
  color: var(--background-accent);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: 100vh;
`;
const initialState = {
    hasError: false,
    error: null,
};
export class ErrorBoundary extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            ...initialState,
            hasError: typeof props.hasError === "undefined" ? false : !!props.hasError,
            error: typeof props.hasError === "string" ? new Error(props.hasError) : null,
        };
    }
    reset() {
        this.setState(initialState);
    }
    resetErrorBoundary = (...args) => {
        this.reset();
        this.props.onReset?.(...args);
    };
    static getDerivedStateFromError(error) {
        return { hasError: true, error };
    }
    componentDidCatch(error, info) {
        this.props.onError?.(error, info);
    }
    render() {
        if (this.state.hasError) {
            const details = this.props.showDetails
                ? this.state.error?.message
                : undefined;
            return (React.createElement(ErrorBoundaryContainer, { style: this.props.style, className: this.props.className },
                React.createElement(ContentMessage, { icon: this.props.icon || faSkullCrossbones, message: this.props.message ?? "Sorry, something has gone wrong.", details: details, onlyTitleDetails: this.props.onlyTitleDetails, onIconClick: this.props.iconClickReset ? this.resetErrorBoundary : undefined }),
                this.props.showReset && (React.createElement("div", { style: !!details ? { marginTop: "var(--spacing)" } : {} },
                    React.createElement(Button, { onClick: this.resetErrorBoundary }, "Reset")))));
        }
        return this.props.children;
    }
}
export const StyleableErrorBoundary = ({ children, ...props }) => (React.createElement(ErrorBoundary, { ...props }, children));
