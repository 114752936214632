import { parseISO } from "date-fns";
import { pick } from "lodash";
import { formatCurrency, formatDateTimeFormat, formatNumber, formatPercentage, formatPositionIdentifierScenarioCellValue, } from "./formatters";
export const formatReportValue = (
// eslint-disable-next-line @typescript-eslint/no-explicit-any
data, column, options) => {
    if (!data || data.value === null)
        return "";
    const { value } = data;
    const formatNumberValue = (fractionDigits) => {
        const { numberStyle } = column;
        const reduce = options?.numberFormat?.reduce ?? options?.numberReductionFormat;
        const rest = pick(options?.numberFormat || {}, [
            "forceAverage",
            "formatNegative",
            "currency",
            "useGrouping",
        ]);
        if (numberStyle === "Currency" && data.currency)
            return formatCurrency(value, data.currency, {
                fractionDigits,
                forceAverage: rest.forceAverage,
                reduce,
                formatNegative: rest.formatNegative,
            });
        if (numberStyle === "Percent")
            return formatPercentage(value, fractionDigits, rest.formatNegative, rest.forceAverage, reduce);
        return formatNumber(value, {
            fractionDigits,
            reduce,
            ...rest,
        });
    };
    switch (column.dataType) {
        case "Boolean": {
            return value ? "True" : "False";
        }
        case "Date": {
            const date = parseISO(value);
            if (date.getFullYear() === 9999 &&
                date.getMonth() === 0 &&
                date.getDate() === 1)
                return "TBD";
            const { dateTimeFormat } = column;
            return formatDateTimeFormat(value, dateTimeFormat);
        }
        case "Integer":
            return formatNumberValue(0);
        case "Double": {
            const { decimalPlaces } = column;
            let fractionDigits = decimalPlaces;
            if (typeof options?.numberFormat?.fractionDigits !== "undefined")
                fractionDigits = options?.numberFormat?.fractionDigits;
            return formatNumberValue(fractionDigits);
        }
        case "PositionIdentifierScenario":
            return formatPositionIdentifierScenarioCellValue(value);
        case "HTMLLink": {
            return data;
        }
        case "String":
            return value;
        default:
            return `${value}`;
    }
};
