const baseUrl = "/internal/api/marketdata";
export const calls = {
    GET_CUSTOM_BENCHMARKS: { url: `${baseUrl}/custombenchmarks` },
    SET_FACTSET_TOKEN: {
        url: `${baseUrl}/authorization/token/factset`,
        method: "POST",
        body: "placeholder",
        headers: {
            "Content-Type": "text/plain;charset=UTF-8",
        },
    },
    SET_BLOOMBERG_TOKEN: {
        url: `${baseUrl}/authorization/token/bloomberg`,
        method: "POST",
        body: "placeholder",
        headers: {
            "Content-Type": "text/plain;charset=UTF-8",
        },
    },
};
export default (callCreator) => {
    return {
        GET_CUSTOM_BENCHMARKS: callCreator(() => calls.GET_CUSTOM_BENCHMARKS.url),
        SET_FACTSET_TOKEN: callCreator((token, ignoreAuth = false) => [
            calls.SET_FACTSET_TOKEN.url,
            {
                method: calls.SET_FACTSET_TOKEN.method,
                body: token,
                ignoreAuth,
                headers: calls.SET_FACTSET_TOKEN.headers,
            },
        ]),
        SET_BLOOMBERG_TOKEN: callCreator((token, ignoreAuth = false) => [
            calls.SET_BLOOMBERG_TOKEN.url,
            {
                method: calls.SET_BLOOMBERG_TOKEN.method,
                body: token,
                ignoreAuth,
                headers: calls.SET_BLOOMBERG_TOKEN.headers,
            },
        ]),
    };
};
