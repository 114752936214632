import { faCheckSquare } from "@fortawesome/pro-solid-svg-icons";
export const toggle = {
    name: "Switch",
    minSize: [5, 1],
    icon: faCheckSquare,
    description: "Display a dynamic checkbox",
    defaultConfig: {
        defaultValue: false,
        key: "Switch",
    },
    type: "input",
    category: "dashboard",
};
