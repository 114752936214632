/* eslint-disable import/no-anonymous-default-export */
import { WebReportQueryTypes, } from "@enfusion-ui/types";
import { cleanPath, replaceDoubleSlashWithSingleSlash, } from "@enfusion-ui/utils";
import queryString from "query-string";
import DOCUMENTS_API, { DocumentAPIRoots } from "./documents";
import { bindDocumentsApi } from "./utils/documentsBind";
const baseUrl = "/internal/api/reports";
export const calls = {
    RUN_REPORT: {
        url: `${baseUrl}/run`,
        method: "POST",
        body: {},
        query: {
            reportURL: { optional: false, defaultValue: "" },
        },
    },
    GENERATE_REPORT_DATE_QUERY: {
        url: `${baseUrl}/query/date/generate`,
        method: "POST",
        body: {},
    },
    GET_REPORTS_QUERY: {
        url: `${baseUrl}/query/{kind}`,
        urlParams: {
            pnl: {
                defaultValue: "pnl",
                options: ["pnl", "position", "trade", "taxlots"],
            },
        },
        query: {
            groupingType: {
                defaultValue: WebReportQueryTypes.Instrument,
                options: Object.values(WebReportQueryTypes),
            },
        },
    },
    GET_REPORTS_TRADE_QUERY: {
        url: `${baseUrl}/query/trade`,
        query: {
            groupingType: {
                defaultValue: WebReportQueryTypes.Instrument,
                options: Object.values(WebReportQueryTypes),
            },
            startDate: {},
            endDate: {},
        },
    },
    GET_REPORTS_CPTYCOMMISSION_QUERY: {
        url: `${baseUrl}/query/cptycommission`,
        query: {
            startDate: {},
            endDate: {},
        },
    },
    GET_SAVED_QUERY: {
        url: `${baseUrl}/query`,
        query: {
            report: { optional: false, defaultValue: "" },
        },
    },
    GET_DEFAULT_QUERY: {
        url: `${baseUrl}/query/default`,
        query: {
            queryType: {
                defaultValue: WebReportQueryTypes.Instrument,
                options: Object.values(WebReportQueryTypes),
            },
        },
    },
    GET_REPORT_METADATA: {
        url: `${baseUrl}/query/metadata`,
        query: {
            report: { optional: false, defaultValue: "" },
        },
    },
    GET_GROUPING_TEMPLATES: {
        url: `${baseUrl}/query/groupingtemplates`,
        query: {
            reportType: { optional: false, defaultValue: "" },
        },
    },
    GET_LEDGERS: { url: "/internal/api/gl/v1/ledgers/all" },
};
export default (callCreator) => {
    const documentsAPI = DOCUMENTS_API(callCreator);
    return {
        ...bindDocumentsApi(documentsAPI, DocumentAPIRoots.REPORT),
        GET_SAVED_QUERY: callCreator((path) => {
            const report = cleanPath(path);
            let url = calls.GET_SAVED_QUERY.url;
            const fixedReport = report?.startsWith("fixed/");
            if (fixedReport) {
                url = `${url}${report?.replace("fixed", "")}`;
            }
            return queryString.stringifyUrl({
                url,
                query: fixedReport ? {} : { report },
            });
        }),
        // GET /internal/api/reports/query/default?report={path}
        // type        | name      | value             | required | default |
        // query param | queryType | REPORT_QUERY_TYPE | required | null    |
        GET_DEFAULT_QUERY: callCreator((queryType) => queryString.stringifyUrl({
            url: calls.GET_DEFAULT_QUERY.url,
            query: { queryType },
        })),
        GET_REPORT_METADATA: callCreator((path) => queryString.stringifyUrl({
            url: calls.GET_REPORT_METADATA.url,
            query: { report: path },
        })),
        GET_GROUPING_TEMPLATES: callCreator((reportType) => queryString.stringifyUrl({
            url: calls.GET_GROUPING_TEMPLATES.url,
            query: { reportType },
        })),
        GET_LEDGERS: callCreator(() => calls.GET_LEDGERS.url),
        STORE_REPORT: ({ path, subRoot, reportQuery, forceWrite = false, }, abortController) => documentsAPI.UPLOAD.FETCH(DocumentAPIRoots.REPORTS, subRoot, {
            path: replaceDoubleSlashWithSingleSlash(`/${path}/`),
            forceWrite,
            reportQuery,
        }, false, abortController),
    };
};
