import { UNKNOWN_INSTRUMENT_ID, UNKNOWN_INSTRUMENT_INFO } from "../constants";
export const initialOrderFormState = {
    instrumentId: UNKNOWN_INSTRUMENT_ID,
    instrument: UNKNOWN_INSTRUMENT_INFO,
    allocationSchemeId: null,
    currentCalculatorType: "IncrementalQuantity",
    targetCompId: "",
    targetVal: 0,
    accountAllocation: null,
    allocReason: "",
    assignedTrader: null,
    assignedTradingDesk: null,
    counterpartyMemo: "",
    darkPools: [],
    executionSchemeId: null,
    instructions: "",
    executionInstructions: [],
    limitPrice: 0,
    stopPrice: 0,
    locateId: "",
    locateBrokerIds: [],
    orderSide: null,
    orderType: "Limit",
    portfolioManager: null,
    quantity: "0",
    strategyBookId: null,
    swapOrder: false,
    traderDiscretion: false,
    timeInForce: "Day",
    tradeReason: "",
    settleCcyId: 0,
    notional: "0",
    localNotional: "0",
    allocationInstructions: "",
    webOrderType: "Undecided",
    counterpartyId: null,
    destination: null,
    strategy: null,
    capacity: null,
    brokerAccount: null,
    selectedBrokers: null,
    reviewedBy: "",
    reviewedOn: "",
    complianceNotes: "",
    complianceState: undefined,
    filledQty: null,
    openQty: null,
    status: "",
    statusText: "",
    lastUpdate: "",
    tradeReportingIndicator: "",
    avgPrice: null,
    offeringType: null,
    parentOrderId: null,
    workflowState: null,
    qoeValue: "",
    locates: null,
    allocationType: "Percent",
    counterpartyShortName: null,
    allocationTemplateId: 0,
    customOrderEditMode: false,
    notionalCcy: null,
    localNotionalCcy: null,
};
export const initialFXOrderFormState = {
    ...initialOrderFormState,
    targetCompId: "",
    currency: "",
    currencyPair: "",
    deliverable: false,
    farFixingDate: "",
    farSettleDate: "",
    fixingDate: "",
    fxType: "Forward",
    id: 0,
    instrumentKeywords: null,
    lastExecutionUpdateDateTime: "",
    lastStatus: undefined,
    lastStatusText: "",
    settleDate: "",
    tradeDiscretion: false,
    trsFinancingOverrides: null,
    destination: "",
};
export const initialCDXOrderFormState = {
    ...initialOrderFormState,
    cdxRoot: "",
    cdxIndex: null,
    ccp: null,
    ccpShortName: "",
    fcmdcmShortName: "",
    fcmDcmId: null,
    tenor: null,
    cdxUnwind: false,
    otrOnly: false,
    clearingType: "Cleared",
    cdxInstrument: null,
    maturityDate: "",
    shouldDisableInstrumentFields: false,
    tenorShortName: "",
};
export const initialVarSwapOrderFormState = {
    ...initialOrderFormState,
    atm: true,
    dayCount: "BUS_252",
    effectiveDate: "",
    expiryDate: "",
    fixingRate: null,
    fixingRateInstrument: null,
    holidayCenters: [],
    indicativeStrike: 0,
    lowerBarrier: null,
    lowerCap: null,
    notionalCcy: null,
    nratio: null,
    referenceInstrumentId: null,
    referenceInstrument: null,
    referenceInstrumentPrice: 0,
    strike: 0,
    underlyingInstrumentId: UNKNOWN_INSTRUMENT_ID,
    underlyingInstrument: null,
    upperBarrier: null,
    upperCap: null,
    varianceUnits: null,
    vegaNotional: null,
};
export const initialIRSOrderFormState = {
    ...initialOrderFormState,
    atm: true,
    fixedNotional: "0",
    rate: 0,
    clearingType: null,
    ccp: null,
    ccpShortName: "",
    fcmDcmId: null,
    fcmdcmShortName: "",
    quickTemplate: null,
    irsInstrument: UNKNOWN_INSTRUMENT_INFO,
    tenor: null,
    startDate: "",
    endDate: "",
};
