import { styled } from "@enfusion-ui/web-core";
import * as React from "react";
import { InputLabel } from "../inputs/TextInput";
import { Button } from "./Button";
const Container = styled.div `
  width: 100%;
`;
const StyledButton = styled(Button) `
  height: 38px;
`;
export const LabeledButton = ({ label, hideLabelPlaceholder, containerStyles, children, ...props }) => {
    return (React.createElement(Container, { style: containerStyles },
        !hideLabelPlaceholder && React.createElement(InputLabel, null, label || React.createElement(React.Fragment, null, "\u00A0")),
        React.createElement(StyledButton, { ...props, style: { minWidth: "38px" } }, children)));
};
