import { useFormContext } from "react-hook-form";
import { UNKNOWN_INSTRUMENT_ID } from "../../constants";
import { useOEMSOrderForm } from "../orderFormContext/context";
export function useCanAccept(readOnly, splitOrder, fxOrder, cdxOrder, varSwap, irsOrder, validateStrategyParam) {
    const { completed, maxQuantity } = useOEMSOrderForm();
    const { watch } = useFormContext();
    if (completed) {
        return {
            canAccept: false,
            issues: [],
        };
    }
    if (readOnly) {
        return {
            canAccept: false,
            issues: [
                { message: "Write permission missing.", section: "details" },
            ],
        };
    }
    const { orderSide, quantity, portfolioManager, assignedTradingDesk, assignedTrader, customOrderEditMode, strategyParamsValid, } = watch([
        "orderSide",
        "quantity",
        "portfolioManager",
        "assignedTradingDesk",
        "assignedTrader",
        "customOrderEditMode",
        "strategyParamsValid",
    ]);
    const issues = [];
    const addRequired = (msg, section, inputId) => {
        issues.push({ message: `${msg} Required`, section, inputId });
    };
    const addIssue = (message, section, inputId) => {
        issues.push({ message, section, inputId });
    };
    const getDefaultChecks = () => {
        if (!orderSide)
            addRequired("Order Side", "details", "order-side");
        if (!portfolioManager || portfolioManager === "0")
            addRequired("Portfolio Manager", "general", "assignment-portfolio-manager-selection-id");
        if (!assignedTradingDesk || assignedTradingDesk === "0")
            addRequired("Assigned Trading Desk", "general", "assignment-desk-selection-id");
        if (!assignedTrader || assignedTrader === "0")
            addRequired("Assigned Trader", "general", "assignment-trader-selection-id");
    };
    if (fxOrder) {
        const { webOrderType, destination, targetCompId, farSettleDate, settleDate, fixingDate, farFixingDate, currencyPair, fxType, currency, } = watch([
            "webOrderType",
            "destination",
            "targetCompId",
            "farSettleDate",
            "settleDate",
            "fixingDate",
            "farFixingDate",
            "fxType",
            "currencyPair",
            "currency",
        ]);
        if (!currencyPair)
            addRequired("Currency Pair", "details", "currency-pair-id");
        if (!currency)
            addRequired("Currency", "details", "fx-order-detail-currency-selection-id");
        getDefaultChecks();
        if (!quantity || Number(quantity) <= 0)
            addRequired("Quantity", "details", "quantity-id");
        if (!!quantity && Number(quantity) > Number(maxQuantity)) {
            addIssue(`Order quantity exceeds parent idle quantity of ${maxQuantity}`, "details", "quantity-id");
        }
        if (webOrderType === "Electronic" && targetCompId === null)
            addRequired("Target", "details", "target-selection-id");
        if (webOrderType === "Electronic" && destination === null)
            addRequired("Destination", "details", "destination-selection-id");
        if (fxType === "Swap" && !farSettleDate)
            addRequired("Far Forward Date", "details", "far-settle-date");
        if (!settleDate)
            addRequired("Forward Date", "details", "settle-date");
        if (fixingDate &&
            settleDate &&
            new Date(fixingDate).getTime() > new Date(settleDate).getTime())
            addIssue("Near Leg Fixing must be before Near Leg Forward", "details", "settle-date");
        if (farSettleDate &&
            settleDate &&
            new Date(settleDate).getTime() >= new Date(farSettleDate).getTime())
            addIssue("Near Leg Forward must be before Far Leg Forward", "details", "far-settle-date");
        if (farFixingDate &&
            settleDate &&
            new Date(farFixingDate).getTime() > new Date(farSettleDate).getTime())
            addIssue("Far Leg Fixing must be before Far Leg Forward", "details", "settle-date");
    }
    else if (cdxOrder) {
        const { cdxRoot, cdxIndex, notional, fcmDcmId, orderSide, tenor, ccp, webOrderType, destination, targetCompId, } = watch([
            "cdxRoot",
            "notional",
            "ccp",
            "fcmDcmId",
            "tenor",
            "orderSide",
            "webOrderType",
            "destination",
            "targetCompId",
            "cdxIndex",
        ]);
        if (!cdxRoot)
            addRequired("CDX Root", "details", "cdx-root-id");
        if (!cdxIndex)
            addRequired("CDX Index", "details", "cdx-instrument");
        if (!orderSide)
            addRequired("Order Side", "details", "order-side");
        if (!tenor)
            addRequired("Tenor", "details", "cdx-tenor-select-id");
        if (!notional || notional === "0")
            addRequired("Notional", "details", "notional-id");
        if (!ccp)
            addRequired("CCP", "details", "cdx-ccp-input-id");
        if (!fcmDcmId)
            addRequired("FCM/DCM", "details", "cdx-fcm-dcm-input-id");
        if (!!notional && Number(notional) > Number(maxQuantity)) {
            addIssue(`Order notional exceeds parent idle notional of ${maxQuantity}`, "details", "notional-id");
        }
        if (webOrderType === "Electronic") {
            if (targetCompId === null)
                addRequired("Target", "details", "target-selection-id");
            if (destination === null)
                addRequired("Destination", "details", "destination-selection-id");
        }
        getDefaultChecks();
    }
    else if (varSwap) {
        const { underlyingInstrumentId, effectiveDate, expiryDate, notionalCcy, vegaNotional, } = watch([
            "underlyingInstrumentId",
            "effectiveDate",
            "expiryDate",
            "notionalCcy",
            "vegaNotional",
        ]);
        if (!underlyingInstrumentId ||
            underlyingInstrumentId === UNKNOWN_INSTRUMENT_ID)
            addRequired("Underlying", "details", "underlying-id");
        if (!notionalCcy)
            addRequired("Notional Currency", "details", "notional-currency");
        if (!vegaNotional)
            addRequired("Vega Notional", "details", "vega-notional");
        if (effectiveDate &&
            expiryDate &&
            new Date(effectiveDate).getTime() > new Date(expiryDate).getTime())
            addIssue("Expiry Date must be after Effective Date", "details", "expiry-date");
        if (!!vegaNotional && Number(vegaNotional) > Number(maxQuantity)) {
            addIssue(`Order quantity exceeds parent idle quantity of ${maxQuantity}`, "details", "quantity-id");
        }
    }
    else if (irsOrder) {
        const { fixedNotional, fcmDcmId, orderSide, quickTemplate, ccp, webOrderType, destination, targetCompId, clearingType, startDate, endDate, } = watch([
            "fixedNotional",
            "ccp",
            "fcmDcmId",
            "tenor",
            "orderSide",
            "quickTemplate",
            "webOrderType",
            "destination",
            "targetCompId",
            "clearingType",
            "startDate",
            "endDate",
        ]);
        if (!fixedNotional || fixedNotional === "0")
            addRequired("Notional", "details", "notional-id");
        if (!clearingType)
            addRequired("Clearing Type", "details", "clearing-type-selection-id");
        if (clearingType === "Cleared") {
            if (!ccp)
                addRequired("CCP", "details", "cdx-ccp-input-id");
            if (!fcmDcmId)
                addRequired("FCM", "details", "cdx-fcm-dcm-input-id");
        }
        if (!quickTemplate) {
            addRequired("Quick Templates", "details", "irs-quick-templates-input-id");
        }
        if (!startDate)
            addRequired("Start Date", "details", "start-date");
        if (!endDate)
            addRequired("End Date", "details", "end-date");
        if (startDate &&
            endDate &&
            new Date(startDate).getTime() > new Date(endDate).getTime())
            addIssue("Start Date must be before End Date", "details", "start-date");
        if (!orderSide)
            addRequired("Order Side", "details", "order-side");
        if (!!fixedNotional && Number(fixedNotional) > Number(maxQuantity)) {
            addIssue(`Order notional exceeds parent idle notional of ${maxQuantity}`, "details", "notional-id");
        }
        if (webOrderType === "Electronic") {
            if (targetCompId === null)
                addRequired("Target", "details", "target-selection-id");
            if (destination === null)
                addRequired("Destination", "details", "destination-selection-id");
        }
        getDefaultChecks();
    }
    else {
        const { instrument, orderType, limitPrice, stopPrice, webOrderType, destination, targetCompId, strategy, } = watch([
            "instrument",
            "orderType",
            "limitPrice",
            "stopPrice",
            "webOrderType",
            "destination",
            "targetCompId",
            "strategy",
        ]);
        if (!instrument || instrument.id === UNKNOWN_INSTRUMENT_ID)
            addRequired("Instrument", "details", "instrument-select");
        getDefaultChecks();
        if (!quantity || Number(quantity) <= 0)
            addRequired("Quantity", "details", "quantity-id");
        if (!!quantity && Number(quantity) > Number(maxQuantity)) {
            addIssue(`Order quantity exceeds parent idle quantity of ${maxQuantity}`, "details", "quantity-id");
        }
        if (instrument?.financialSubType?.parent !== "Future") {
            if ((orderType === "Limit" || orderType === "StopLimit") &&
                (!limitPrice || limitPrice === "0"))
                addRequired("Limit Price", "details", "limit-price-id");
            if ((orderType === "Stop" || orderType === "StopLimit") &&
                (!stopPrice || stopPrice === "0"))
                addRequired("Stop Price", "details", "stop-price-id");
        }
        if (webOrderType === "Electronic") {
            if (targetCompId === null)
                addRequired("Target", "details", "target-selection-id");
            if (destination === null)
                addRequired("Destination", "details", "destination-selection-id");
            if (!!validateStrategyParam) {
                if (destination?.requiresStrategy && strategy === null)
                    addRequired("Strategy", "details", "routing-strategy-selection-id");
                if (destination?.requiresStrategy &&
                    strategy !== null &&
                    !strategyParamsValid) {
                    addIssue("Strategy validation failed", "details");
                }
            }
            else if (destination?.requiresStrategy && strategy === null) {
                addIssue("Currently unable to manually change strategy", "details", "routing-strategy-selection-id");
            }
        }
        if (splitOrder && (!webOrderType || webOrderType === "Undecided"))
            addRequired("Order Type", "details", "web-order-type-id");
    }
    if (customOrderEditMode)
        addIssue("Cannot accept orders in edit mode", "details", "custom-order-edit-mode-id");
    return {
        canAccept: issues.length === 0,
        issues,
    };
}
