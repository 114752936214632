import { faSearchDollar } from "@fortawesome/pro-solid-svg-icons";
export const genericInputSelection = {
    name: "Generic Input Selection",
    minSize: [4, 1],
    icon: faSearchDollar,
    description: "Choose generic input select",
    defaultConfig: {
        defaultValue: null,
        key: "Generic Input Select",
    },
    type: "input",
    category: "dashboard",
};
