import BigNumber from "bignumber.js";
import { identity } from "lodash";
import { getDecimalSeparator, parseNumber } from "./formatters";
const MULTIPLIERS = {
    h: 100,
    t: 1000,
    k: 1000,
    m: 1e6,
    b: 1e9,
};
const OPERATOR_KEYS = ["*", "/", "+", "-", "(", ")"];
export const countDecimalPlaces = (num) => num
    .absoluteValue()
    .minus(num.integerValue(BigNumber.ROUND_DOWN))
    .toString()
    .split(getDecimalSeparator())[1]?.length ?? 0;
function parseMulti(str, allowDecimals = true) {
    let multiplier = 1;
    if (str.length > 1) {
        const lastChar = str[str.length - 1].toLowerCase();
        multiplier =
            MULTIPLIERS[lastChar] !== undefined ? MULTIPLIERS[lastChar] : 1;
    }
    const parsedValue = parseNumber(str);
    const evaluated = parsedValue.isNaN()
        ? null
        : parsedValue.multipliedBy(multiplier);
    if (evaluated === null)
        return null;
    return allowDecimals
        ? evaluated
        : evaluated.decimalPlaces(0, BigNumber.ROUND_FLOOR);
}
export function parseInput(str, enableMultiplier, skipOperatorEval, allowDecimals = true, extraParser = identity) {
    if (!str)
        return null;
    const val = extraParser ? extraParser(str) : str;
    if (!enableMultiplier) {
        const res = parseNumber(val);
        return skipOperatorEval ? res.toString() : res;
    }
    let stripedString = val.replace(/\s+/g, "");
    if (stripedString.startsWith("+") || stripedString.startsWith("-"))
        stripedString = `0${stripedString}`;
    while (stripedString.endsWith("=")) {
        stripedString = stripedString.slice(0, -1);
    }
    const list = OPERATOR_KEYS.reduce((newStr, key) => newStr.replaceAll(key, ` ${key} `), stripedString)
        .trim()
        .replace(/\s+/g, " ")
        .split(" ")
        .map((i) => {
        if (OPERATOR_KEYS.includes(i))
            return i;
        return parseMulti(i, allowDecimals) ?? 0;
    });
    try {
        if (skipOperatorEval)
            return list.join("");
        // eslint-disable-next-line no-new-func
        return new BigNumber(Function(`return ${list.join("")};`)());
    }
    catch (err) {
        // eslint-disable-next-line no-console
        console.error("parseInput err", `${list.join("")}`, err);
        return null;
    }
}
export const parseStringToNumber = (val, { increment = 0, enableMultiplier = false, allowDecimals = true, min, max, extraParser, } = {}) => {
    if (!val && val !== 0)
        return null;
    const newValue = typeof val === "string"
        ? parseInput(val, enableMultiplier, false, allowDecimals, extraParser)
        : new BigNumber(val);
    if (newValue !== null && !Number.isNaN(newValue)) {
        const numStepDecimal = countDecimalPlaces(new BigNumber(increment));
        const numValueDecimal = countDecimalPlaces(newValue);
        const numDecimal = numStepDecimal >= numValueDecimal ? numStepDecimal : numValueDecimal;
        const multiplier = 10 ** numDecimal;
        let finalVal = numDecimal === 0
            ? newValue.plus(increment)
            : new BigNumber(newValue
                .plus(increment)
                .multipliedBy(multiplier)
                .toFixed(numDecimal, 1)).dividedBy(multiplier);
        if (typeof min !== "undefined" && finalVal.isLessThan(min)) {
            finalVal = new BigNumber(min);
        }
        if (typeof max !== "undefined" && finalVal.isGreaterThan(max)) {
            finalVal = new BigNumber(max);
        }
        return finalVal;
    }
    throw new Error("Not a valid number");
};
export function bigValue(value) {
    return value !== null ? new BigNumber(value).valueOf() : null;
}
