/* eslint-disable @typescript-eslint/no-explicit-any */
import { EMPTY_RECORD } from "@enfusion-ui/core";
import { formatChannelParamValues } from "@enfusion-ui/utils";
import { cloneDeep, omit } from "lodash";
import * as React from "react";
import { BREAK_POINT_KEYS_V2, BREAK_POINTS_FALLBACK_MAP_V2, BREAK_POINTS_MAP_V2, CELL_SIZE, FIXED_BREAK_POINTS_MAP_V2, } from "../core/constants";
import { useChannelDataValues, useWidget, } from "../widget/context";
import { WIDGET_DEFINITIONS } from "../widgets/definitions";
import { useDashboard } from "./context";
import { defaultDashboardConfigV2 } from "./reducer";
export const getBreakPointKey = (width, numOfCols) => {
    const breakPointKey = width <= 0
        ? "xxs"
        : BREAK_POINT_KEYS_V2.find((key) => typeof numOfCols === "number"
            ? numOfCols === BREAK_POINTS_MAP_V2[key]
            : width >= FIXED_BREAK_POINTS_MAP_V2[key]);
    return (breakPointKey ||
        BREAK_POINT_KEYS_V2[BREAK_POINT_KEYS_V2.length - 1]);
};
export const getDimensions = (widgets = [], placement = {}, isLayoutLocked, mobile) => {
    return (widgets || []).reduce((res, widgetDef) => {
        const { type, id } = widgetDef;
        const isResizable = !isLayoutLocked;
        const isDraggable = !isLayoutLocked;
        const hiddenWidget = WIDGET_DEFINITIONS[type]?.hidden === true;
        const minSize = hiddenWidget
            ? [0, 0]
            : (typeof WIDGET_DEFINITIONS[type]?.minSize === "function"
                ? WIDGET_DEFINITIONS[type]?.minSize?.(mobile)
                : WIDGET_DEFINITIONS[type]?.minSize) || [1, 1];
        const maxSize = hiddenWidget
            ? [0, 0]
            : WIDGET_DEFINITIONS[type]?.maxSize || [Number.MAX_SAFE_INTEGER, 12];
        const layoutSizes = Object.keys(placement);
        res.rows.min = Number.MAX_SAFE_INTEGER;
        res.rows.max = 0;
        for (const key of layoutSizes) {
            const layout = placement[key];
            const entryIdx = layout.findIndex((entry) => entry.i === id);
            if (entryIdx !== -1) {
                const entry = layout[entryIdx];
                res.rows.min = Math.min(res.rows.min, entry.y + entry.h);
                res.rows.max = Math.max(res.rows.max, entry.y + entry.h);
                res.rows[key] = Math.max(res.rows[key] ?? 0, entry.y + entry.h);
                res.layout[key] = [
                    ...(res.layout[key] || []),
                    {
                        i: id,
                        x: entry.x,
                        y: entry.y,
                        h: Math.max(entry.h, minSize[0]),
                        w: Math.max(entry.w, minSize[1]),
                        minH: minSize[0],
                        minW: minSize[1],
                        maxH: maxSize[0],
                        maxW: maxSize[1],
                        isResizable,
                        isDraggable,
                    },
                ];
            }
        }
        return res;
    }, { rows: {}, layout: {} });
};
export const getCols = (dimensions, isTabletDevice) => {
    const cols = isTabletDevice ? dimensions.cols + 5 : dimensions.cols + 20;
    const deviceWidth = isTabletDevice
        ? Math.floor((window.innerWidth - 5) / CELL_SIZE)
        : Math.floor((window.innerWidth - 60) / CELL_SIZE);
    return Math.max(cols, deviceWidth);
};
const DEFAULT_COLORS = [
    "#7CB5EC",
    "#434348",
    "#90ED7D",
    "#F7A35C",
    "#8085E9",
    "#F15C80",
    "#E4D354",
    "#2B908F",
    "#F45B5B",
    "#91E8E1",
];
export const getDefaultColor = (idx) => {
    return DEFAULT_COLORS[idx % DEFAULT_COLORS.length];
};
export const cleanWidgetsConfigV2 = (config, allDatasourceIdsUsed = new Set()) => {
    return Object.keys(config).reduce((acc, key) => {
        acc[key].placement = config[key].placement;
        acc[key].widgets = [
            ...config[key].widgets
                .reduce((res, entry) => {
                const def = WIDGET_DEFINITIONS[entry.type];
                if (def) {
                    if (def.datasourceConfigIds) {
                        for (const key of def.datasourceConfigIds) {
                            const val = entry.config[key];
                            if (typeof val === "string") {
                                allDatasourceIdsUsed.add(val);
                            }
                        }
                    }
                    res.set(entry.id, entry);
                }
                else {
                    for (const placementKey of Object.keys(acc[key].placement)) {
                        acc[key].placement[placementKey] = [
                            ...acc[key].placement[placementKey]
                                .reduce((a, e) => {
                                if (e.i !== entry.id) {
                                    a.set(e.i, e);
                                }
                                return a;
                            }, new Map())
                                .values(),
                        ];
                    }
                }
                return res;
            }, new Map())
                .values(),
        ];
        return acc;
    }, { ...cloneDeep(defaultDashboardConfigV2) });
};
export const cleanDashboardsConfigV2 = ({ config, settings, ...rest }) => {
    return {
        ...rest,
        config: cleanWidgetsConfigV2(config),
        settings: {
            ...omit(settings, "showBackgroundGrid"),
            compactType: settings.compactType === "bestFit"
                ? null
                : settings.compactType,
        },
    };
};
export const convertV1ConfigToV2 = ({ datasources, settings: v1Settings, widgets, name, version, }) => {
    const config = {
        ...cloneDeep(defaultDashboardConfigV2),
    };
    widgets.forEach((widget) => {
        const { type, id, config: widgetConfig, properties, gridId, colSpan, colStart, rowSpan, rowStart, } = widget;
        config[gridId].widgets.push({
            type,
            id,
            properties,
            config: widgetConfig,
        });
        config[gridId].placement = BREAK_POINT_KEYS_V2.reduce((res, key) => {
            return {
                ...res,
                [key]: [
                    ...(res[key] || []),
                    { i: id, x: colStart, y: rowStart, w: colSpan, h: rowSpan },
                ],
            };
        }, config[gridId].placement || {});
    });
    return {
        config,
        datasources,
        settings: { ...v1Settings, theme: v1Settings.theme ?? "dashDark" },
        name,
        version,
        configVersion: 2,
    };
};
export const useDashboardDatasourceParams = () => {
    const { config: { paramChannelIds = EMPTY_RECORD, paramStaticValues = EMPTY_RECORD, reportParams = EMPTY_RECORD, urlParams: urlParamsBase = EMPTY_RECORD, datasourceId, }, } = useWidget();
    const { getDatasource } = useDashboard();
    const datasource = getDatasource?.(datasourceId) ?? undefined;
    const paramChannelData = useChannelDataValues(paramChannelIds);
    const transformedData = formatChannelParamValues(paramChannelIds, paramChannelData);
    const urlParams = React.useMemo(() => {
        return [
            ...Object.keys(urlParamsBase),
            ...Object.keys(datasource?.pathParams || {}),
        ].reduce((res, param) => ({
            ...res,
            [`url.${param}`]: transformedData[param] ?? urlParamsBase[param],
        }), {});
    }, [JSON.stringify(urlParamsBase), JSON.stringify(transformedData)]);
    return React.useMemo(() => {
        return {
            ...reportParams,
            ...transformedData,
            ...paramStaticValues,
            ...urlParams,
        };
    }, [
        JSON.stringify(paramChannelData),
        JSON.stringify(paramStaticValues),
        JSON.stringify(reportParams),
        JSON.stringify(urlParams),
    ]);
};
export const FIRST_COLUMN_KEY = "::-first-column-::";
function getOptionsFromColumns(columns) {
    return columns?.reduce((res, column) => {
        const { headerName, name, hidden } = column;
        if (name && headerName) {
            res.push({
                label: hidden ? `${headerName} (hidden)` : headerName,
                value: name,
            });
        }
        return res;
    }, []);
}
export const useDatasourceColumnsList = (useReports) => {
    const { editedConfig: { datasourceId }, } = useWidget();
    const { dataStore } = useReports();
    const { getDatasource } = useDashboard();
    const datasource = React.useMemo(() => datasourceId && getDatasource ? getDatasource(datasourceId) : undefined, [datasourceId]);
    const datasourceOptions = datasource ? dataStore[datasource.id] : null;
    return React.useMemo(() => [
        ...(getOptionsFromColumns(datasourceOptions?.columnDefs) ?? []),
        {
            label: "First Column (internal)",
            value: FIRST_COLUMN_KEY,
        },
    ], [datasourceOptions]);
};
export function getColumnDef(columnName, columns) {
    return columnName === FIRST_COLUMN_KEY
        ? Object.values(columns || {}).find((i) => i.visible !== false) || null
        : columns?.[columnName] || null;
}
function rowCountWithFallback(d, k, minCount = 0) {
    const rowCount = d.rows[k] ?? d.rows[BREAK_POINTS_FALLBACK_MAP_V2[k]] ?? 0;
    return rowCount > 0 ? rowCount : minCount;
}
export const calculateGridSizing = (mainGridDimensions, width, tabHeight, stickyGridDimensions, editMode, numOfCols, scrollBarWidth = 0) => {
    const coreTabHeight = (tabHeight ?? CELL_SIZE + 30) - 30;
    const breakpoint = getBreakPointKey(width ?? 0, editMode ? numOfCols : undefined);
    const stickyRows = stickyGridDimensions
        ? rowCountWithFallback(stickyGridDimensions, breakpoint, 10)
        : 0;
    const stickyGridHeight = stickyRows * CELL_SIZE;
    const mainRows = Math.max(rowCountWithFallback(mainGridDimensions, breakpoint), Math.floor(coreTabHeight / CELL_SIZE));
    const mainGridHeight = stickyGridHeight + mainRows * CELL_SIZE;
    const needScrollBar = mainGridHeight > coreTabHeight;
    const widthOr = (orVal) => {
        let newWidth = orVal;
        if (typeof width === "number") {
            newWidth = width - (needScrollBar ? scrollBarWidth + 1 : 0);
            if (editMode) {
                newWidth =
                    FIXED_BREAK_POINTS_MAP_V2[breakpoint] -
                        (needScrollBar ? scrollBarWidth + 1 : 0);
            }
        }
        return newWidth;
    };
    return {
        innerWidth: widthOr(0),
        outerWidth: widthOr("100%"),
        height: {
            main: mainGridHeight,
            sticky: stickyGridHeight,
        },
    };
};
