export function getStorageFileUrl(root, filePath) {
    return `${window.location.origin}/internal/api/storage/download/${root}?filePath=${encodeURIComponent(filePath)}`;
}
export function getXHRContent(url, responseType = "arraybuffer") {
    return new Promise((accept, reject) => {
        const req = new XMLHttpRequest();
        req.open("GET", url, true);
        req.responseType = responseType;
        req.onload = () => {
            accept(req);
        };
        req.onerror = (e) => {
            reject(e);
        };
        req.send();
    });
}
