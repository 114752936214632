import { createCoreBroadcastChannel } from "@enfusion-ui/hooks";
import * as React from "react";
export const DEFAULT_DROPPED_ID = "__dropping_id__";
export const DASHBOARD_CONTEXT_CHANNEL_NAME = "ef-db-channel";
export const DASHBOARD_CONTEXT_REVERT = "revert";
export const DASHBOARD_CONTEXT_REFRESH = "refresh";
export const DASHBOARD_CONTEXT_CHANNEL = createCoreBroadcastChannel(DASHBOARD_CONTEXT_CHANNEL_NAME, { webWorkerSupport: false });
export const DashboardContext = React.createContext(null);
export function useDashboard(callbacks) {
    const context = React.useContext(DashboardContext);
    if (context === null) {
        throw new Error("useDashboard must be used within an DashboardContext");
    }
    React.useEffect(() => {
        return context.subscribe(callbacks);
    }, [callbacks]);
    return context;
}
