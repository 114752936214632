import { styled } from "@enfusion-ui/web-core";
import { faEllipsisV } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { isUndefined } from "lodash";
import Menu, { MenuItem } from "rc-menu";
import * as React from "react";
import { BasicButton, Portal } from "../../";
const TabMenuContainer = styled.div `
  width: 31px;
  padding-bottom: 8px;
  display: flex;
  justify-content: center;
  align-items: center;
`;
const TabMenuButton = styled(BasicButton) `
  background-color: transparent;
  height: 23px;
  align-items: center;
  justify-content: center;
  display: flex;

  :hover {
    background-color: var(--primary-hover);
    opacity: 0.8;
  }

  color: var(--text-normal);

  :disabled,
  :disabled:hover {
    background-color: var(--background-color-1);
    color: var(--text-muted);
  }
`;
const MenuItemText = styled.div `
  max-width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;
export function createTabMenu() {
    return React.memo(function TabMenuCore({ items, selectedTab, onSelect, getTabLabel, }) {
        const [visible, setVisible] = React.useState(false);
        const menuRef = React.useRef(null);
        const closeMenu = () => setVisible(false);
        const openMenu = () => setVisible(true);
        const handleMenuClick = ({ key }) => {
            closeMenu();
            onSelect(key);
        };
        return (React.createElement(React.Fragment, null,
            React.createElement(TabMenuContainer, null,
                React.createElement(TabMenuButton, { ref: menuRef, onClick: openMenu },
                    React.createElement(FontAwesomeIcon, { icon: faEllipsisV }))),
            React.createElement(Portal, { open: visible, attachedRef: menuRef, onClickOutside: closeMenu, align: "right" },
                React.createElement(Menu, { selectedKeys: !isUndefined(selectedTab) ? [selectedTab] : [] }, items.map(({ label, key }) => (React.createElement(MenuItem, { key: key, onClick: handleMenuClick },
                    React.createElement(MenuItemText, null, getTabLabel?.(key) ?? label ?? ""))))))));
    });
}
