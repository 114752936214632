import * as React from "react";
import { calculateGridSizing } from "../core/utils";
import { useGridDimensions } from "./useGridDimensions";
export const useGridSizing = (tabWidth, tabHeight, scrollBarWidth, editMode, mainGridProps, stickyGridProps, numOfCols) => {
    const mainGridDimensions = useGridDimensions(mainGridProps);
    const stickyGridDimensions = useGridDimensions(stickyGridProps);
    return React.useMemo(() => ({
        ...calculateGridSizing(mainGridDimensions, tabWidth, tabHeight, stickyGridDimensions, editMode, editMode ? numOfCols : null, scrollBarWidth),
        dimensions: {
            main: mainGridDimensions,
            sticky: stickyGridDimensions,
        },
    }), [
        tabWidth,
        tabHeight,
        mainGridDimensions,
        stickyGridDimensions,
        editMode,
        numOfCols,
    ]);
};
