import { format, parseISO } from "date-fns";
export const columnTypes = {
    dateColumn: {
        valueFormatter: (params) => {
            return format(parseISO(params.value), "yyyy-MM-dd");
        },
        filter: "agMultiColumnFilter",
        filterParams: {
            filters: [
                {
                    filter: "agDateColumnFilter",
                    filterParams: {
                        comparator: (filterDate, cellValue) => {
                            if (cellValue === null)
                                return -1;
                            return parseISO(cellValue).getTime() - filterDate.getTime();
                        },
                    },
                },
                {
                    filter: "agSetColumnFilter",
                    filterParams: {
                        comparator: (valueA, valueB) => {
                            return parseISO(valueA).getTime() - parseISO(valueB).getTime();
                        },
                    },
                },
            ],
        },
        comparator: (valueA, valueB) => parseISO(valueA).getTime() - parseISO(valueB).getTime(),
    },
    standardColumn: {
        filter: "agMultiColumnFilter",
        filterParams: {
            filters: [
                {
                    filter: "agTextColumnFilter",
                    filterParams: {
                        defaultOption: "startsWith",
                    },
                },
                {
                    filter: "agSetColumnFilter",
                },
            ],
        },
    },
};
