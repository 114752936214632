/* eslint-disable @typescript-eslint/no-non-null-assertion */
import { createTestId } from "@enfusion-ui/utils";
import { css, styled } from "@enfusion-ui/web-core";
import { faCheckSquare, faMinusSquare, faSquare, } from "@fortawesome/pro-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { noop } from "lodash";
import * as React from "react";
import { IconButton } from "../control/IconButton";
import { InfoPopover } from "../display/Popover";
import { InputLabelControl } from "./TextInput";
const CheckboxContainer = styled.div `
  flex: 1 0 auto;
  display: flex;
  align-items: center;
  justify-content: ${({ labelPlacement }) => labelPlacement === "right" ? "flex-end" : "space-between"};
  padding: 0.5rem;
  min-height: 38px;
  max-height: 38px;
  border-radius: 4px;
  cursor: ${({ disabled }) => (disabled ? "not-allowed" : "pointer")};
  flex-direction: ${({ labelPlacement }) => labelPlacement === "right" ? "row-reverse" : "row"};
  color: var(
    ${({ disabled }) => (disabled ? "--text-muted" : "--text-normal")}
  );
  background-color: var(
    ${({ disabled }) => disabled ? "--background-color-0" : "--input-background"}
  );
  border: 1px solid
    var(${({ hasError }) => (hasError ? "--danger" : "--input-border")});

  :hover {
    border: 1px solid
      var(
        ${({ hasError, disabled }) => hasError
    ? "--danger"
    : disabled
        ? "--input-border"
        : "--primary-hover"}
      );
  }
`;
const CheckboxLabel = styled(InputLabelControl) `
  font-size: 0.875em;
  pointer-events: none;
  touch-action: none;
  margin: 0px;
  flex-grow: 1;
  margin-left: var(--spacing);
  white-space: nowrap;
  ${({ labelPlacement }) => labelPlacement === "left" ? "margin-right: var(--spacing);" : undefined}
  ${({ labelNoSpace, truncatedLabel }) => labelNoSpace || truncatedLabel ? "white-space: nowrap;" : undefined}

${({ truncatedLabel }) => truncatedLabel
    ? css `
          overflow: hidden;
          text-overflow: ellipsis;
        `
    : undefined}
`;
const defaultIconStyle = { width: 18, height: 18 };
export const Checkbox = ({ checked, label, onChange, name, errors, required, disabled, style, labelPlacement = "left", noContainer = false, topLabelPlaceholder = false, tabIndex = 0, infoText, iconColor, iconSize = "2x", iconStyle = defaultIconStyle, checkboxContainerStyle, labelNoSpace = false, truncatedLabel = false, id, dataTestId, }) => {
    const handleChange = (e) => {
        if (noContainer && onChange) {
            e.stopPropagation();
        }
        onChange?.(checked === false ? true : false);
    };
    let icon = checked ? faCheckSquare : faSquare;
    if (checked === null) {
        icon = faMinusSquare;
    }
    if (noContainer) {
        return (React.createElement(IconButton, { disabled: disabled, style: { padding: 0 }, icon: icon, size: iconSize, iconStyle: iconStyle, onClick: handleChange }));
    }
    const hasError = !!errors && !!name && !!errors[name];
    return (React.createElement("div", { style: {
            width: "100%",
            overflow: truncatedLabel ? "hidden" : undefined,
            ...(style || {}),
        }, id: id },
        (topLabelPlaceholder || labelPlacement === "top") && (React.createElement(InputLabelControl, { labelPlaceholder: true, label: topLabelPlaceholder ? "" : label, name: name, errors: errors, required: required })),
        React.createElement(CheckboxContainer, { style: checkboxContainerStyle, hasError: hasError, disabled: disabled, labelPlacement: labelPlacement, onClick: disabled ? noop : handleChange, onKeyPress: (e) => {
                if (e.code === "Enter" || e.code === "Space") {
                    e.preventDefault();
                    handleChange(e);
                }
            }, tabIndex: tabIndex, "data-testid": createTestId("checkbox", label, dataTestId) },
            infoText ? (React.createElement(InfoPopover, { color: iconColor }, infoText)) : undefined,
            labelPlacement !== "top" && (React.createElement(CheckboxLabel, { "data-testid": createTestId("checkbox", label, dataTestId), labelPlaceholder: true, label: label, labelPlacement: labelPlacement, labelNoSpace: labelNoSpace, required: required, truncatedLabel: truncatedLabel })),
            React.createElement(FontAwesomeIcon, { icon: icon, size: iconSize, style: iconStyle }))));
};
