import { useMounted, useRefCallback } from "@enfusion-ui/hooks";
import { css, isScrollableHorizontal, styled, useThisTab, } from "@enfusion-ui/web-core";
import * as React from "react";
import { usePrevious } from "react-use";
import { createTabMenu } from "./TabMenu";
const TabListContainer = styled.div `
  width: 100%;
  max-width: ${({ maxWidth }) => (maxWidth ? `${maxWidth}px` : "100%")};
  display: flex;
  flex-direction: row;
  ${({ borderBottom }) => borderBottom
    ? css `
          border-bottom: 1px solid var(--text-normal-alpha);
        `
    : ""};
`;
const TabListRowContainer = styled.div `
  width: 100%;
  overflow: hidden;
  flex: 1;
`;
const TabListRow = styled.div `
  height: ${({ height }) => height}px;
  width: 100%;
  display: flex;
  flex-direction: row;
  min-width: 0;
  overflow-y: hidden;
  overflow-x: auto;
  box-sizing: content-box;
  position: relative;
  ${({ showMenu }) => showMenu
    ? css `
          :after {
            content: "";
            min-width: 35px;
            width: 35px;
            height: 100%;
            display: block;
          }
        `
    : ""}

  ::-webkit-scrollbar {
    height: 0px !important;
    width: 0px !important;
  }
`;
function Tab({ tab, getTabLabel, onSelect, active, TabComponent, disabled, scrollToView = true, }) {
    const thisTabRef = React.useRef(null);
    const prevActive = usePrevious(active);
    const handleSelect = useRefCallback(() => {
        if (active && thisTabRef.current) {
            if (scrollToView)
                thisTabRef.current.scrollIntoView({ behavior: "smooth" });
            onSelect(tab.key, {
                width: thisTabRef.current?.clientWidth,
                left: thisTabRef.current?.offsetLeft,
            });
        }
    }, [disabled, active, scrollToView, thisTabRef, tab]);
    React.useLayoutEffect(() => {
        handleSelect();
    }, []);
    React.useEffect(() => {
        if (!prevActive)
            handleSelect();
    }, [prevActive, active]);
    return (React.createElement(TabComponent, { tab: tab, getTabLabel: getTabLabel, onClick: () => {
            onSelect(tab.key, {
                width: thisTabRef.current?.clientWidth,
                left: thisTabRef.current?.offsetLeft,
            });
        }, active: active, disabled: disabled, ref: thisTabRef }));
}
const SelectionBar = styled.div `
  position: absolute;
  bottom: 0;
  height: 2px;
  left: 0px;
  width: 0px;
  background-color: var(--primary);
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  transition: left 0.5s ease-in-out, width 0.5s 0.1s;
`;
const TabMenuShadow = styled.div `
  width: 40px;
  margin-left: -40px;
  background: linear-gradient(
    270.32deg,
    #202225 36.95%,
    rgba(32, 34, 37, 0) 105.8%
  );
  z-index: 1;
`;
export function createBaseTabList() {
    const TabMenu = createTabMenu();
    return React.memo(function BaseTabList({ value, tabs, onSelect, getTabLabel, height, TabComponent, underlineSelectedTab = false, borderBottom = false, disabled, scrollToView, className, }) {
        const [selected, setSelected] = React.useState(value);
        const [barStyle, setBarStyle] = React.useState({});
        const [showMenu, setShowMenu] = React.useState(false);
        const rowRef = React.useRef(null);
        const { width } = useThisTab();
        const isMounted = useMounted();
        React.useEffect(() => {
            if (isMounted())
                setSelected(value);
        }, [value]);
        React.useEffect(() => {
            if (rowRef.current && isMounted()) {
                if (isScrollableHorizontal(rowRef.current)) {
                    setShowMenu(true);
                }
                else if (showMenu) {
                    setShowMenu(false);
                }
            }
        }, [width]);
        const changeBarStyle = useRefCallback((newStyle) => {
            if (JSON.stringify(newStyle) !== JSON.stringify(barStyle)) {
                requestAnimationFrame(() => {
                    if (isMounted())
                        setBarStyle(newStyle);
                });
            }
        }, [barStyle, isMounted]);
        const handleSelect = useRefCallback((key, style) => {
            if (isMounted()) {
                if (selected !== key) {
                    setSelected(key);
                    onSelect(key);
                }
                if (style)
                    changeBarStyle(style);
            }
        }, [onSelect, changeBarStyle, selected]);
        return (React.createElement(TabListContainer, { maxWidth: width, borderBottom: borderBottom, className: className },
            React.createElement(TabListRowContainer, null,
                React.createElement(TabListRow, { ref: rowRef, showMenu: showMenu, height: height },
                    tabs.map((tab) => {
                        return (React.createElement(Tab, { key: tab.key, getTabLabel: getTabLabel, onSelect: !disabled ? handleSelect : () => null, active: selected === tab.key, disabled: disabled, tab: tab, TabComponent: TabComponent, scrollToView: scrollToView }));
                    }),
                    underlineSelectedTab && React.createElement(SelectionBar, { style: barStyle }))),
            showMenu && tabs.length > 0 && (React.createElement(React.Fragment, null,
                React.createElement(TabMenuShadow, null),
                React.createElement(TabMenu, { items: tabs, onSelect: handleSelect, selectedTab: selected, getTabLabel: getTabLabel })))));
    });
}
export const BaseTabList = createBaseTabList();
