import { faHeading } from "@fortawesome/pro-solid-svg-icons";
export const textInput = {
    name: "Text Input",
    minSize: [5, 1],
    icon: faHeading,
    description: "Display a dynamic text input",
    defaultConfig: {
        defaultValue: "",
        key: "Text Input",
    },
    type: "input",
    category: "dashboard",
};
